import { useState, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControlLabel,
  Box,
  Checkbox,
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
} from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useDispatch, useSelector } from '../../../../redux/store';
import { HOST_API_URL } from '../../../../config-global';
import { deleteOrderGuide, updateOrderGuidePricing } from '../../../../api/order-guides';
import { setOrderGuideItemsAction } from '../../../../redux/slices/orderGuides';
import { CustomQuickFilter } from '../../../../reusable-components/datagrid/custom-filters';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import VendorGuide from './VendorGuide';
import { AwaitButton } from '../../../../reusable-components/await-button';

CustomToolbar.propTypes = {
  tempOrderGuide: PropTypes.array,
  previousOrderGuide: PropTypes.array,
  setTempOrderGuide: PropTypes.func,
  setFilters: PropTypes.func,
  loading: PropTypes.bool,
  setQuickFilter: PropTypes.func,
  setActiveFilter: PropTypes.func,
  filteredData: PropTypes.object,
};

export default function CustomToolbar({
  tempOrderGuide,
  setQuickFilter,
  setTempOrderGuide,
  loading,
  previousOrderGuide,
  setFilters,
  setActiveFilter,
  filteredData,
}) {
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const {
    data: { orderGuide, orderGuideActivations, templates },
  } = useSelector((state) => state.orderGuides);
  const dispatch = useDispatch();

  const [currentOrderGuide, setCurrentOrderGuide] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (currentOrderGuide) {
      setActive(currentOrderGuide?.active);
    }
  }, [currentOrderGuide]);

  useEffect(() => {
    setCurrentOrderGuide(
      orderGuideActivations.find((orderGuide) => orderGuide.id === parseInt(id, 10)) ||
        templates.find((template) => template.id === parseInt(id, 10))
    );
  }, [orderGuideActivations, id, templates]);

  const downloadGuide = async () => {
    window.open(`${HOST_API_URL}Api/DownloadOrderGuide?orderGuideActivationId=${id}`, '_blank');
  };

  const handleCancel = () => {
    setTempOrderGuide(previousOrderGuide);
  };

  const handleDelete = async () => {
    const response = await deleteOrderGuide(id);
    if (response.status === 200) {
      navigate('/dashboard/admin/order-guides');
    }
  };

  const handleRefresh = async () => {
    await updateOrderGuidePricing(id);
  };

  const handleUpdateOrderGuide = async () => {
    await dispatch(
      setOrderGuideItemsAction(
        id,
        active,
        tempOrderGuide?.map((item) => item.itemId),
        navigate
      )
    );
  };

  if (!orderGuide?.orderGuide?.length && !tempOrderGuide?.length && loading) return 'Loading...';

  const dataByCategory = filteredData.unactiveData.filter(
    (item) => item?.agoraCategory?.id === currentOrderGuide?.agoraCategoryId
  );

  if (!currentOrderGuide) return null;

  return (
    <>
      <Box>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
          <Box sx={{ mb: 1 }}>
            <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
              {!currentOrderGuide?.locked && (
                <>
                  <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} />
                  {'Active '}
                </>
              )}
            </ScopeGuard>
            {(orderGuide.orderGuide !== tempOrderGuide || active !== currentOrderGuide?.active) && !loading ? (
              <>
                <AwaitButton
                  variant="outlined"
                  color="primary"
                  sx={{ mr: 1 }}
                  onClick={handleUpdateOrderGuide}
                  size="small"
                >
                  Update Order Guide
                </AwaitButton>
                <Button sx={{ mr: 1 }} onClick={handleCancel} variant="outlined" color="error" size="small">
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Tooltip title="Download" arrow>
                  <IconButton color="info" onClick={downloadGuide}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>

                <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
                  {!currentOrderGuide?.locked && (
                    <Tooltip title="Vendor Preferences" arrow>
                      <VendorGuide
                        facilityId={currentOrderGuide?.facilityId}
                        agoraCategoryId={currentOrderGuide?.agoraCategoryId}
                      />
                    </Tooltip>
                  )}
                  {!currentOrderGuide?.locked && (
                    <ConfirmDialog
                      icon="refresh"
                      title="Refresh Pricing"
                      color="warning"
                      content="Are you sure you want to fetch prices for this order guide? This may take some time."
                      actionButton="Refresh"
                      tooltip="Refresh Pricing"
                      action={handleRefresh}
                    />
                  )}
                  {!currentOrderGuide?.locked && (
                    <ConfirmDialog
                      icon="delete-outline"
                      title="Delete"
                      color="error"
                      content="Are you sure want to delete?"
                      actionButton="Delete"
                      action={handleDelete}
                      tooltip="Delete"
                    />
                  )}
                </ScopeGuard>
              </>
            )}
          </Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.primary.main }}>
            {currentOrderGuide?.facility || currentOrderGuide?.templateName} - {currentOrderGuide?.agoraCategory}
          </Typography>
          <Grid item>
            <Grid direction={'row'} container justifyContent="flex-end" alignItems="flex-end">
              <Box>
                {' '}
                <ScopeGuard scopes={['Edit-OrderGuide']} allowAdmin>
                  <Tooltip title="Add all to guide" placement="top" arrow>
                    <IconButton
                      color="info"
                      onClick={() => {
                        const newOrderGuide = [
                          ...tempOrderGuide,
                          ...dataByCategory?.map((item) => ({
                            productId: item.id,
                            itemId: item.productDetails[0].id,
                          })),
                        ];
                        setTempOrderGuide(newOrderGuide);
                      }}
                    >
                      <Iconify icon="mdi:plus-circle-outline" color="primary" width={28} height={28} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remove all from guide" placement="top" arrow>
                    <IconButton
                      color="info"
                      onClick={() => {
                        setTempOrderGuide([]);
                      }}
                    >
                      <Iconify icon="mdi:remove-circle-outline" color="error" width={28} height={28} />
                    </IconButton>
                  </Tooltip>{' '}
                </ScopeGuard>
              </Box>
              <Box sx={{ mb: 1 }}>
                <CustomQuickFilter
                  setQuickFilter={setQuickFilter}
                  setFilters={setFilters}
                  setActiveFilter={setActiveFilter}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
