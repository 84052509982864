import { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Menu, MenuItem, Tooltip } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { useSelector, useDispatch } from '../../../redux/store';
import { changeInvoiceItemDataFromRedux } from '../../../redux/slices/invoicing';

SubCategory.propTypes = {
  row: PropTypes.object,
  facilityId: PropTypes.number,
  invoiceId: PropTypes.number,
};

export default function SubCategory({ row, facilityId, invoiceId }) {
  const dispatch = useDispatch();

  const {
    data: { invoiceData },
  } = useSelector((state) => state.invoicing);

  const { subcategories } = invoiceData;

  const hasAccess = useScopeCheck(['Invoice-Edit'], true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const filteredCategories =
    subcategories?.filter((sub) => sub.facilityId === facilityId && sub.categoryId === row.agoraCategory?.id) || [];
  return (
    <>
      {filteredCategories?.length > 0 ? (
        <>
          <Tooltip
            title={
              <>
                {row?.agoraSubcategory?.name || 'Select a subcategory'}{' '}
                {row?.agoraSubcategory?.glCode && `(${row?.agoraSubcategory?.glCode})`}
              </>
            }
            placement="top"
            arrow
            sx={{ width: '100%' }}
          >
            {hasAccess ? (
              <Typography
                variant="subtitle2"
                sx={{
                  border: 1,
                  width: '100%',
                  borderRadius: '3px',
                  position: 'relative',
                  display: 'flex', // Use flexbox
                  alignItems: 'center',
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: `#C4CDD5`,
                  },
                  my: '4px',
                  py: '2px',
                  px: 0.5,
                }}
                onClick={handleClick}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden', // Hide overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflow
                    whiteSpace: 'nowrap', // Prevent wrapping
                    pr: 2,
                  }}
                >
                  {row?.agoraSubcategory?.name || ''}{' '}
                  {row?.agoraSubcategory?.glCode && `(${row?.agoraSubcategory?.glCode})`}
                </Box>
                <Box>
                  <Iconify icon={'mdi:chevron-down'} />
                </Box>
              </Typography>
            ) : (
              <Typography
                // color={stringToColor(row.agoraCategory?.name)}
                variant="subtitle2"
                sx={{
                  border: 1,
                  width: '100%',
                  borderRadius: '3px',
                  position: 'relative',
                  display: 'flex', // Use flexbox
                  alignItems: 'center',

                  my: '4px',
                  py: '5px',
                  px: 0.5,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden', // Hide overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflow
                    whiteSpace: 'nowrap', // Prevent wrapping
                    pr: 2,
                  }}
                >
                  {row?.agoraSubcategory?.name || ''}{' '}
                  {row?.agoraSubcategory?.glCode && `(${row?.agoraSubcategory?.glCode})`}
                </Box>
              </Typography>
            )}
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {filteredCategories?.map((sub) => (
              <MenuItem
                key={sub.id}
                onClick={() => {
                  dispatch(
                    changeInvoiceItemDataFromRedux(
                      {
                        id: row.id,
                        field: 'agoraSubcategory',
                        value: {
                          mainCategoryId: sub.categoryId,
                          id: sub.subcategoryId,
                          name: sub.subcategory,
                          glCode: sub.glCode,
                        },
                      },
                      invoiceId
                    )
                  );
                  handleClose();
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }} color={sub.subcategory}>
                  {sub.subcategory} {sub.glCode && `(${sub.glCode})`}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Box
          component="div"
          sx={{
            fontSize: '0.70rem',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            color: 'warning.main',
          }}
        >
          No Subcategories available.
        </Box>
      )}
    </>
  );
}
