import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { filterForPreferredVendor } from '../redux/slices/orderGuides';

const useFilteredForPreferredVendor = () => {
  const dispatch = useDispatch();

  // Get the necessary parts of the state
  const preferences = useSelector((state) => state.orderGuides.data.preferences);
  const restricted = useSelector((state) => state.orderGuides.data.restricted);
  const allProducts = useSelector((state) => state.orderGuides.data.allProducts);
  const productsFiltered = useSelector((state) => state.orderGuides.productsFiltered);

  useEffect(() => {
    if (!productsFiltered && allProducts.length > 0 && (preferences.length > 0 || restricted.length > 0)) {
      dispatch(filterForPreferredVendor(preferences, restricted, allProducts));
    }
  }, [dispatch, preferences, restricted, allProducts, productsFiltered]);
};

export default useFilteredForPreferredVendor;
