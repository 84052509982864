import { Avatar, Box, Card, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getPendingOrders } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { stringToPrimaryColor } from '../../../../utils/cssStyles';
import { fDateTime } from '../../../../utils/formatTime';

export default function OrderReviewers() {
  const { id = '' } = useParams();
  const { orderById } = useSelector((state) => state.orders);
  const {
    data: { pendingOrders },
  } = useSelector((state) => state.orders);
  const orderReviews = pendingOrders?.find((o) => o.id === orderById[id]?.id)?.reviews;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (!pendingOrders?.length > 0) {
        await dispatch(getPendingOrders());
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {orderReviews?.length > 0 && (
        <Box sx={{ minWidth: '300px', width: '100%', mb: 2 }}>
          <Card sx={{ p: 1, width: '100%' }}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="primary.main">
                  Reviewers:
                </Typography>
              </Grid>
              {orderReviews &&
                orderReviews?.map((order) =>
                  order.reviewed ? (
                    <Grid item key={order.reviewerEmail}>
                      <Tooltip
                        title={
                          <Box>
                            <Typography sx={{ display: 'block' }} variant="caption">
                              Reviewed by: {order.reviewerName} {order.reviewerEmail}
                            </Typography>
                            <Typography sx={{ display: 'block' }} variant="caption">
                              Reviewed on: {fDateTime(order.timeStamp)}
                            </Typography>
                          </Box>
                        }
                      >
                        <Avatar
                          sx={{
                            bgcolor: stringToPrimaryColor(order.reviewerName),
                            width: '100%',
                            height: 30,
                            fontSize: 14,
                            fontWeight: 'regular',
                            px: 1,
                          }}
                          variant="rounded"
                        >
                          {order.reviewerName.substring(0, order.reviewerName.indexOf(' '))}
                        </Avatar>
                      </Tooltip>
                    </Grid>
                  ) : (
                    <Grid item key={order.reviewerEmail}>
                      <Tooltip
                        title={
                          <Typography variant="caption">
                            Awaiting review by {order.reviewerName} {order.reviewerEmail}
                          </Typography>
                        }
                      >
                        <Avatar
                          sx={{
                            px: 1,
                            bgcolor: '#ffffff',
                            color: stringToPrimaryColor(order.reviewerName),
                            border: '1px solid black',
                            borderColor: stringToPrimaryColor(order.reviewerName),
                            width: '100%',
                            height: 30,
                            fontSize: 14,
                            fontWeight: 'regular',
                          }}
                          variant="rounded"
                        >
                          {order.reviewerName.substring(0, order.reviewerName.indexOf(' '))}
                        </Avatar>
                      </Tooltip>
                    </Grid>
                  )
                )}
            </Grid>
          </Card>
        </Box>
      )}
    </>
  );
}
