import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Tooltip,
  Tabs,
  Tab,
  Typography,
  Badge,
  Button,
} from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { ORDER_INFORMATION } from '../../../../reusable-components/datagrid/noFlexInvoiceColumns';
import { useDispatch, useSelector } from '../../../../redux/store';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import { TabPanel } from '../../../../reusable-components/tab-panel';
import OrderInformation from './OrderInformation';
import CompletedOrderInformation from './CompletedOrderInformation';
import InvoicePdfAttachment from './InvoicePdfAttachment';

ReviewInvoice.propTypes = {
  selectedFileId: PropTypes.number,
  highlightMissingGls: PropTypes.bool,
};

export default function ReviewInvoice({ selectedFileId, highlightMissingGls }) {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { invoiceItems, maximumItemDiscrepancy, invoice } = invoiceItemData;
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (selectedFileId !== 0) {
      setValue(0);
      setExpanded(true);
    }
  }, [selectedFileId]);
  useEffect(() => {
    if (highlightMissingGls) {
      setValue(1);
      setExpanded(true);
    }
  }, [highlightMissingGls]);
  const countErrors = () =>
    invoiceItems?.reduce((accumulator, currentValue) => {
      const moreSentThenOrdered = currentValue.quantity > currentValue.expectedAmount;
      const overMax = Math.abs(currentValue.price - currentValue.expectedItemPrice) > maximumItemDiscrepancy;

      if (moreSentThenOrdered) accumulator++;
      if (overMax) accumulator++;
      return accumulator;
    }, 0);
  return (
    <>
      <Box sx={accordionBoxStyle}>
        <Accordion
          expanded={expanded}
          sx={accordionStyle}
          onChange={(event, newValue) => {
            setExpanded(newValue);
          }}
        >
          <AccordionSummary
            sx={{
              color: 'info.main',
            }}
            expandIcon={<Iconify icon="ic:outline-expand-more" color="info" height="24px" width="24px" />}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>Review invoice &nbsp;</Typography>
          </AccordionSummary>
          <AccordionDetails sx={accordionDetailsStyle}>
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="Attachment" />
              {invoice?.sourceMethod !== 'Manual' && (
                <Tab
                  label={
                    <Badge
                      badgeContent={countErrors()}
                      color="error"
                      sx={{
                        '& .MuiBadge-badge': {
                          top: -5,
                        },
                      }}
                    >
                      Electronic invoice
                    </Badge>
                  }
                />
              )}

              {invoice?.sourceMethod !== 'Manual' && <Tab label="Previous order" />}
            </Tabs>
            <TabPanel value={value} index={0}>
              <InvoicePdfAttachment selectedFileId={selectedFileId} />
            </TabPanel>
            {invoice?.sourceMethod !== 'Manual' && (
              <TabPanel value={value} index={1}>
                <OrderInformation highlightMissingGls={highlightMissingGls} />
              </TabPanel>
            )}
            {invoice?.sourceMethod !== 'Manual' && (
              <TabPanel value={value} index={2}>
                <CompletedOrderInformation />
              </TabPanel>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

const accordionBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  border: 'solid 1px #c1c9d0',
  width: '100%',
  mb: '20px',
};

const accordionStyle = {
  width: '100%',
  // '&.Mui-expanded': {
  //   minHeight: '550px',
  //   maxHeight: '1700px',
  // },
};
const accordionDetailsStyle = {
  paddingTop: 0,
};
