import * as React from 'react';
import { useState } from 'react';
// External libraries
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';

export default function RentalCategoryDropdown() {
  const navigate = useNavigate();

  const categoryOptions = [
    { label: 'DME', value: 'DME' },
    { label: 'Respiratory', value: 'Respiratory' },
  ];

  const handleCategoryClick = (event) => {
    setSelectedCategory(event.target.value);
    navigate(`/dashboard/rentals/new-rental?category=${event.target.value}`);
  };
  const [selectedCategory, setSelectedCategory] = useState('DME');

  return (
    <>
      <Select
        label="Select Category"
        value={selectedCategory}
        onChange={handleCategoryClick}
        sx={{
          color: '#8cc53f',
          fontWeight: '15px',
          '& .MuiSelect-icon': {
            color: '#8cc53f',
          },
        }}
      >
        {categoryOptions.map((category) => (
          <MenuItem key={category.label} value={category.value}>
            {category.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
