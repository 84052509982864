import { Alert, Box, Card, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { fDate } from '../../../../utils/formatTime';
import VendorDate from './VendorDate';
import VendorNote from './VendorNote';

VendorInfo.propTypes = {
  allReqDates: PropTypes.array,
  setAllReqDates: PropTypes.func,
};

export default function VendorInfo({ allReqDates, setAllReqDates }) {
  const { id = '' } = useParams();

  const [open, setOpen] = useState(false);
  const { orderById, tempOrderListById = [] } = useSelector((state) => state.orders);
  const { pendingOrders } = useSelector((state) => state.orders.data);
  const { items = [] } = orderById[id] || {};

  const handleClick = () => {
    setOpen(!open);
  };

  const getUniqueItemsByVendorId = (items) => {
    const uniqueVendorIds = new Set();
    return items.filter((item) => {
      if (!uniqueVendorIds.has(item.vendorId)) {
        uniqueVendorIds.add(item.vendorId);
        return true;
      }
      return false;
    });
  };

  const uniqueItems = useMemo(
    () => getUniqueItemsByVendorId([...items, ...(tempOrderListById[id] || [])] || []),
    [items, id]
  );

  const facUserShipDate = useMemo(
    () => pendingOrders.find((o) => o.id === orderById[id]?.id)?.requestedShipDate,
    [pendingOrders, id, orderById]
  );

  useEffect(() => {
    if (facUserShipDate) {
      setAllReqDates((prevReqDates) => {
        const newReqDates = uniqueItems
          .filter((i) => !orderById[id].vendorDateMessages.some((m) => m.vendorId === i.vendorId))
          .map((i) => {
            const vendorDate = prevReqDates.some((x) => x.vendorId === i.vendorId)
              ? prevReqDates.find((x) => x.vendorId === i.vendorId)?.date
              : new Date(facUserShipDate);
            return {
              vendorId: i.vendorId,
              date: vendorDate,
            };
          });

        return [...newReqDates];
      });
    }
  }, [orderById]);

  return (
    <>
      <Box sx={{ minWidth: '300px', width: '100%' }}>
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="primary">
              Vendors
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="primary">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>
            {facUserShipDate && (
              <Grid container sx={{ width: '100%' }} justifyContent="center">
                <Alert sx={{ width: '100%', height: '35px', fontSize: 12, py: 0, px: 1, m: 0 }} severity="info">
                  Client requested ship date: {fDate(facUserShipDate)}
                </Alert>
              </Grid>
            )}
          </Grid>
          {open && (
            <Box>
              {uniqueItems.map((item, index) => (
                <Box key={index}>
                  <Divider sx={{ width: '100%', my: 1, backgroundColor: '#000' }} />
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.info.main,
                      }}
                    >
                      {item.vendor}
                    </Typography>
                  </Grid>
                  <VendorNote item={item} />
                  <VendorDate item={item} allReqDates={allReqDates} setAllReqDates={setAllReqDates} />
                </Box>
              ))}
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
}
