import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceView } from '../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../redux/store';
import { getInvoiceBreakdown, getInvoiceDataFromRedux, getUsersInInvoiceRole } from '../../redux/slices/invoicing';

export default function Invoice() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    data: { usersInInvoiceRole },
  } = useSelector((state) => state.invoicing);
  useEffect(() => {
    async function fetchData() {
      await dispatch(getInvoiceBreakdown(id));
      if (usersInInvoiceRole?.length === 0) await dispatch(getUsersInInvoiceRole());
    }
    fetchData();
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Invoice | Platform Solutions</title>
      </Helmet>
      <InvoiceView />
    </>
  );
}
