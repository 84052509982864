import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  Box,
  Tooltip,
  DialogContentText,
  Autocomplete,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { SINGLE_INVOICE } from '../../../reusable-components/datagrid/invoiceColumns';
import Category from './Category';
import SubCategory from './SubCategory';
import Price from './Price';
import TaxAmount from './TaxAmount';
import { fCurrency, fPercent, fNumber, formatMoney } from '../../../utils/formatNumber';
import InvoiceInfo from './InvoiceInfo';
import { useDispatch, useSelector } from '../../../redux/store';
import { changeInvoiceDataFromRedux, getInvoiceItemsDataFromRedux } from '../../../redux/slices/invoicing';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../reusable-components/iconify';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { postInvoiceForm } from '../../../api/invoicing';

InvoiceViewTab.propTypes = {
  row: PropTypes.object,
};
export default function InvoiceViewTab({ row }) {
  const dispatch = useDispatch();

  const {
    data: { invoiceItemDataById, invoiceData },
  } = useSelector((state) => state.invoicing);
  const { facilities } = invoiceData;

  const [newFacility, setNewFacility] = useState(null);

  const isLoading = useSelector((state) => state.invoicing.isLoading);

  const { invoice, invoiceItems, maxShipping, maximumItemDiscrepancy, maximumItemDiscrepancyPercent } =
    invoiceItemDataById[row?.id] || {};
  if (!invoice || !invoiceItems) return null;
  const { total, shipping } = invoice || {};

  const categoryDropdown = {
    field: 'categoryDropdown',
    headerName: 'Category',
    type: 'customSelect',
    onCellClick: (params) => {
      params.event.stopPropagation();
    },
    width: 140,
    renderCell: (params) => <Category invoiceId={row?.id} row={params.row} facilityId={invoice.facilityId} />,
    valueGetter: (params) => params.row?.agoraCategory?.name || '',
    path: 'agoraCategory.name',
  };

  const subCategoryDropdown = {
    field: 'subCategoryDropdown',
    headerName: 'Subcategory',
    type: 'customSelect',
    onCellClick: (params) => {
      params.event.stopPropagation();
    },
    width: 240,
    renderCell: (params) => <SubCategory invoiceId={row?.id} row={params.row} facilityId={invoice.facilityId} />,
    valueGetter: (params) => params.row.agoraSubcategory?.name || 'No Subcategories available.',
    path: 'agoraSubcategory.name',
  };

  const price = {
    field: 'price',
    headerName: 'Price',
    flex: 0.75,
    type: 'customText',
    renderCell: (params) => <Price invoiceId={row?.id} row={params.row} />,
  };
  const taxAmount = {
    field: 'taxAmount',
    headerName: 'Tax Amount',
    flex: 0.75,
    type: 'customText',
    renderCell: (params) => <TaxAmount invoiceId={row?.id} row={params.row} />,
  };

  const expectedItemPrice = {
    field: 'expectedItemPrice',
    headerName: 'Our Price',
    width: 100,
    renderCell: (params) => {
      const actualPrice = Math.abs(params.row.price);

      let itemMaxDiscrepancyAmount = 0;
      if (maximumItemDiscrepancyPercent > 0 && maximumItemDiscrepancy > 0) {
        itemMaxDiscrepancyAmount = Math.min(
          maximumItemDiscrepancy,
          (maximumItemDiscrepancyPercent * actualPrice) / 100
        );
      } else {
        itemMaxDiscrepancyAmount = Math.max(
          maximumItemDiscrepancy,
          (maximumItemDiscrepancyPercent * actualPrice) / 100
        );
      }

      const overMax = Math.abs(actualPrice - params.row.expectedItemPrice) > itemMaxDiscrepancyAmount;

      const notExact = actualPrice !== params.row.expectedItemPrice;

      const discrepancyString =
        maximumItemDiscrepancy > 0 && maximumItemDiscrepancyPercent > 0
          ? ` (${fCurrency(maximumItemDiscrepancy)} and ${fPercent(maximumItemDiscrepancyPercent)} discrepancy limit)`
          : maximumItemDiscrepancy > 0
          ? ` (${fCurrency(maximumItemDiscrepancy)} discrepancy limit)`
          : maximumItemDiscrepancyPercent > 0
          ? ` (${fPercent(maximumItemDiscrepancyPercent)} discrepancy limit)`
          : '';

      return (
        <Tooltip
          title={
            overMax
              ? `Our price does not match their price${discrepancyString}`
              : notExact
              ? `Price within acceptable range${discrepancyString}`
              : ''
          }
          placement="top"
          arrow
        >
          <Box color={overMax ? 'error.main' : notExact ? 'warning.main' : 'black'}>
            {notExact && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
            {fCurrency(params.row.expectedItemPrice)}
          </Box>
        </Tooltip>
      );
    },
    valueGetter: (params) => {
      const actualPrice = Math.abs(params.row.price);
      const overMax = actualPrice - params.row.expectedItemPrice > maximumItemDiscrepancy;
      const notExact = actualPrice !== params.row.expectedItemPrice;

      return overMax
        ? `99999999${params.row.expectedItemPrice}`
        : notExact
        ? `88888888${params.row.expectedItemPrice}`
        : params.row.expectedItemPrice;
    },
  };

  const quantity = {
    field: 'quantity',
    headerName: 'Sent',
    width: 100,
    renderCell: (params) => {
      const moreSentThenOrdered = params.row.quantity > params.row.expectedAmount;
      return (
        <Tooltip
          title={moreSentThenOrdered ? `More sent then ordered (${params.row.expectedAmount})` : ''}
          placement="top"
          arrow
        >
          <Box color={moreSentThenOrdered ? 'error.main' : 'black'}>
            {moreSentThenOrdered && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
            {fNumber(params.row.quantity)}
          </Box>
        </Tooltip>
      );
    },
  };

  const sum = invoiceItems.reduce(
    (acc, item) => acc + item.quantity * parseFloat(item?.price) + parseFloat(item?.taxAmount) || 0,
    0
  );

  const actualTotal = parseFloat(sum) + parseFloat(shipping);

  const updateFacility = async () => {
    const response = await postInvoiceForm({
      facilityId: newFacility.value,
      id: invoice.id,
      vendorId: invoice.vendorId,
      invoiceId: invoice.invoiceId,
      vendorInvoiceId: invoice.vendorInvoiceId,
      expectedPrice: invoice.expectedPrice,
      shipping: invoice.shipping,
      total: invoice.total,
      sent: invoice.sent,
      date: invoice.date,
      glPostingDate: invoice.date,
    });
    if (response.status === 200) {
      dispatch(getInvoiceItemsDataFromRedux(invoice.id));
      // dispatch(getPendingInvoicing({ pending: true }));
    }
  };

  return (
    <>
      <Box>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
          <Grid item>
            <InvoiceInfo />
          </Grid>
          <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
            <Grid item>
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                <Tooltip
                  title={shipping > maxShipping && `Shipping over maximum allowed ${fCurrency(maxShipping)}`}
                  arrow
                >
                  <Typography variant="caption" color={shipping > maxShipping && 'error'} sx={{ fontStyle: 'italic' }}>
                    Shipping
                  </Typography>
                </Tooltip>
                <TextField
                  error={shipping > maxShipping}
                  size="small"
                  label=""
                  sx={{ maxWidth: 150 }}
                  value={shipping}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  type="number"
                  onChange={(e) => {
                    const newValue = formatMoney(e.target.value);
                    dispatch(changeInvoiceDataFromRedux({ field: 'shipping', value: newValue, invoiceId: row.id }));
                  }}
                  variant="outlined"
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </ScopeGuard>
          <Grid item>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                Vendor Total
              </Typography>
              <Typography variant="subtitle1">{fCurrency(total)}</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                Total
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: fCurrency(total) === fCurrency(actualTotal) ? 'primary.main' : 'error.main' }}
              >
                {fCurrency(actualTotal)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {invoice.facilityId === 0 ? (
          <Box sx={{ color: 'red', textAlign: 'center', mb: 2 }}>
            <p>
              <strong>Warning:</strong> This invoice does not have a facility assigned. Please assign a facility to this
              invoice. So that the invoice can be processed.
            </p>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Facility</DialogContentText>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  sx={{
                    width: 400,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    '& .MuiFormLabelRoot ': 'none',
                  }}
                  type="new-password"
                  options={facilities}
                  autoHighlight
                  getOptionLabel={(option) => option?.label}
                  value={newFacility || null}
                  onChange={(event, value) => {
                    setNewFacility(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={updateFacility}>
                  Assign Facility
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid item xs={12}>
            <CustomDataGrid
              gridId="invoicing-invoice-view-tab"
              boxSX={{ height: 'calc(100vh - 340px)' }}
              data={invoiceItems || []}
              isModal
              gridColumns={[
                ...SINGLE_INVOICE.slice(0, 5),
                expectedItemPrice,
                quantity,
                price,
                taxAmount,
                ...SINGLE_INVOICE.slice(5),
                categoryDropdown,
                subCategoryDropdown,
              ]}
              maxValue={{ ppdBudget: 2 }}
              sort={[{ field: 'expectedItemPrice', sort: 'desc' }]}
              isLoading={isLoading}
            />
          </Grid>
        )}
      </Box>
    </>
  );
}
