import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Menu, MenuItem } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { useSelector, useDispatch } from '../../../redux/store';
import { changeInvoiceItemDataFromRedux } from '../../../redux/slices/invoicing';

Category.propTypes = {
  row: PropTypes.object,
  facilityId: PropTypes.number,
  invoiceId: PropTypes.number,
};

export default function Category({ row, facilityId, invoiceId }) {
  const dispatch = useDispatch();

  const {
    data: { invoiceData },
  } = useSelector((state) => state.invoicing);

  const { categories } = invoiceData;

  const hasAccess = useScopeCheck(['Invoice-Edit'], true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const filteredCategories = useMemo(
    () => categories?.filter((category) => category.facilityId === facilityId),
    [categories, facilityId]
  );

  return (
    <>
      {filteredCategories?.length > 0 ? (
        <>
          {hasAccess ? (
            <Typography
              variant="subtitle2"
              sx={{
                border: 1,
                width: '100px',
                borderRadius: '3px',
                position: 'relative',
                display: 'flex', // Use flexbox
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: `#C4CDD5`,
                },
                my: '4px',
                py: '2px',
                px: 0.5,
              }}
              onClick={handleClick}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  pr: 2,
                }}
              >
                {row?.agoraCategory?.name || ''}
              </Box>
              <Box>
                <Iconify icon={'mdi:chevron-down'} />
              </Box>
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              sx={{
                border: 1,
                width: '100px',
                borderRadius: '3px',
                position: 'relative',
                display: 'flex', // Use flexbox
                alignItems: 'center',
                my: '4px',
                py: '5px',
                px: 0.5,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'hidden', // Hide overflow
                  textOverflow: 'ellipsis', // Add ellipsis for overflow
                  whiteSpace: 'nowrap', // Prevent wrapping
                  pr: 2,
                }}
              >
                {row?.agoraCategory?.name || ''}
              </Box>
            </Typography>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {filteredCategories?.map((category) => (
              <MenuItem
                key={category.categoryId}
                onClick={() => {
                  dispatch(
                    changeInvoiceItemDataFromRedux(
                      {
                        id: row.id,
                        field: 'agoraCategory',
                        value: { id: category.categoryId, name: category.category },
                      },
                      invoiceId
                    )
                  );
                  dispatch(
                    changeInvoiceItemDataFromRedux(
                      {
                        id: row.id,
                        field: 'agoraSubcategory',
                        value: null,
                      },
                      invoiceId
                    )
                  );
                  handleClose();
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {category.category}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Box
          component="div"
          sx={{
            fontSize: '0.70rem',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            color: 'warning.main',
          }}
        >
          No Categories available.
        </Box>
      )}
    </>
  );
}
