import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, IconButton, Paper, Divider, Button, InputBase } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from '../../../../redux/store';
import { addOrderCommentForm, editOrderCommentForm, deleteOrderComment } from '../../../../api/orders';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { updateOrderCommentsInRedux } from '../../../../redux/slices/orders';

VendorNote.propTypes = {
  item: PropTypes.object,
};

export default function VendorNote({ item }) {
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const [openNote, setOpenNote] = useState(null);
  const [openNoteId, setOpenNoteId] = useState(null);
  const [currentNote, setCurrentNote] = useState('');

  const { orderById } = useSelector((state) => state.orders);
  const { orderComments } = orderById[id] || {};

  const addNote = async (vendorId) => {
    const data = {
      orderNumber: id,
      comment: currentNote,
      vendorIds: [vendorId],
    };

    const res = await addOrderCommentForm(data);
    if (res.status === 200) {
      dispatch(
        updateOrderCommentsInRedux({
          orderId: id,
          commentArray: [...orderComments, res.data],
        })
      );
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };
  const editNoteFunc = async (vendorId) => {
    const data = {
      id: openNoteId,
      orderNumber: id,
      comment: currentNote,
      vendorIds: [vendorId],
    };
    const res = await editOrderCommentForm(data);
    if (res.status === 200) {
      dispatch(
        updateOrderCommentsInRedux({
          orderId: id,
          commentArray: orderComments.map((note) => (note.id === openNoteId ? res.data : note)),
        })
      );
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };
  const deleteNote = async (noteId) => {
    const res = await deleteOrderComment(noteId);
    if (res.status === 200) {
      dispatch({
        orderId: id,
        commentArray: updateOrderCommentsInRedux(orderComments.filter((note) => note.id !== noteId)),
      });
      setCurrentNote('');
      setOpenNote(false);
      setOpenNoteId(null);
    }
  };

  const handleNoteClick = () => {
    setOpenNote(!openNote);
  };
  const handleEdit = (comment, noteId) => {
    setOpenNote(true);
    setOpenNoteId(noteId);
    setCurrentNote(comment);
  };

  const filterUniqueNotesByVendorId = (notes) => {
    const uniqueNotes = {};
    notes.forEach((note) => {
      const vendorId = note.vendorIds?.[0];
      if (vendorId) {
        if (!uniqueNotes[vendorId]) {
          uniqueNotes[vendorId] = [];
        }
        uniqueNotes[vendorId].push(note);
      }
    });
    return uniqueNotes;
  };

  const uniqueNotesLookup = useMemo(() => filterUniqueNotesByVendorId(orderComments || []), [orderComments]);

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ pl: 1 }}>
        <Typography variant="body2">Vendor notes:</Typography>{' '}
        <ScopeGuard scopes={['Edit-Order']} allowAdmin>
          <Box
            onClick={() => handleNoteClick()}
            sx={{
              display: 'flex', // Use flexbox
              alignItems: 'flex-bottom', // Vertically align items to center
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Typography component="span" sx={{ color: (theme) => theme.palette.secondary.main }}>
              <Iconify icon="clarity:note-line" width={16} height={16} />
            </Typography>
            <Typography
              variant="subtitle2"
              color="secondary"
              component="span" // Use span here to prevent nested paragraphs
              sx={{
                textDecoration: 'underline',
              }}
            >
              Add note
            </Typography>
          </Box>
        </ScopeGuard>
      </Grid>
      <>
        <Box sx={{ pl: 1 }}>
          {uniqueNotesLookup[item.vendorId]?.map((note, index) => (
            <Grid container direction="row" key={index}>
              <Box
                sx={{
                  display: 'flex', // Use flexbox
                  alignItems: 'flex-bottom',
                  pl: 1, // Vertically align items to center
                }}
              >
                <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                  <Typography
                    component="span"
                    sx={{
                      color: (theme) => theme.palette.error.main,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => deleteNote(note.id)}
                  >
                    <Iconify icon="material-symbols:delete" width={16} height={16} />
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      color: (theme) => theme.palette.info.main,
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      pl: 1,
                    }}
                    onClick={() => handleEdit(note.comment, note.id)}
                  >
                    <Iconify icon="material-symbols:edit" width={15} height={15} />
                  </Typography>
                </ScopeGuard>
                <Typography
                  variant="subtitle2"
                  sx={{
                    ml: 1,
                  }}
                >
                  {note.comment}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Box>
      </>
      {openNote && (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: 'solid 1px #8dc63f',
            mt: 1,
          }}
        >
          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              padding: 0,
              lineHeight: '16px',
            }}
            placeholder="Add vendor note"
            multiline
            value={currentNote}
            onChange={(e) => {
              setCurrentNote(e.target.value);
            }}
          />
          <Divider sx={{ height: 20, ml: 0.5 }} orientation="vertical" />

          <IconButton
            color="primary"
            onClick={() => (openNoteId ? editNoteFunc(item.vendorId) : addNote(item.vendorId))}
          >
            <Iconify icon="mdi:send" width={20} height={20} />
          </IconButton>
        </Paper>
      )}
    </Box>
  );
}
