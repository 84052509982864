import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CompletedOrderView } from '../../orders/order';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getPreviousOrderInfoByNumber } from '../../../../redux/slices/orders';

export default function CompletedOrderInformation() {
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState(null);

  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const { invoice } = invoiceItemData;

  const getPrevOrderInfo = async (invoiceId) => {
    const id = await dispatch(getPreviousOrderInfoByNumber(invoiceId));
    setOrderId(id);
    return id;
  };

  useEffect(() => {
    if (invoice?.invoiceId) getPrevOrderInfo(invoice?.invoiceId);
  }, [invoice?.invoiceId]);

  return (
    <>
      <Box sx={{ maxHeight: '400px' }}>
        <CompletedOrderView newInvoiceView overrideOrderId={orderId} />
      </Box>
    </>
  );
}
