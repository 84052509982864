import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Stack, Card, ListItemText } from '@mui/material';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { getOrderEmailRules, deleteOrderEmailRule } from '../../../../api/account';
import { fCurrency } from '../../../../utils/formatNumber';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import EditIconButton from '../../../../reusable-components/basic-buttons/EditIconButton';
import UserOrderEmailSettingForm from './UserOrderEmailSettingForm';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import AddButton from '../../../../reusable-components/basic-buttons/AddButton';

UserOrderEmailSettings.propTypes = {
  user: PropTypes.object,
};

export default function UserOrderEmailSettings({ user }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editRule, setEditRule] = useState();
  const [openForm, setOpenForm] = useState();

  const fetchData = () => {
    getOrderEmailRules()
      .then((data) => {
        data.orderEmailRules.forEach((rule) => {
          if (rule.facilityId)
            rule.facility = data.facilities.find((facility) => facility.value === rule.facilityId)?.label;
          if (rule.categoryId)
            rule.category = data.categories.find((category) => category.value === rule.categoryId)?.label;
          if (rule.vendorId) rule.vendor = data.vendors.find((vendor) => vendor.value === rule.vendorId)?.label;
        });
        setData(data);
      })
      .catch(() => enqueueSnackbar('Failed to load order email settings', { variant: 'error' }))
      .finally(() => setIsLoading(false));
  };

  const deleteRule = (id) => {
    deleteOrderEmailRule(id)
      .then(() => {
        enqueueSnackbar('Rule deleted successfully', { variant: 'success' });
        fetchData();
      })
      .catch(() => enqueueSnackbar('Something has gone wrong', { variant: 'error' }));
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  const gridFields = [
    {
      field: 'rule',
      headerName: 'Rule',
      flex: 1,
      valueGetter: (params) => {
        const rule = params.row;
        return `Facility is ${rule.facility ? rule.facility : 'any facility'} and category is ${
          rule.category ? rule.category : 'any category'
        }
          ${rule.totalOver ? ` and order total is over ${fCurrency(rule.totalOver)}` : ''}
          ${rule.vendor ? ` and item's vendor is ${rule.vendor}` : ''}
          ${rule.itemPriceOver ? ` and item's price is over ${fCurrency(rule.itemPriceOver)}` : ''}`;
      },
      type: 'customSelect',
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 50,
      renderCell: (params) => (
        <>
          <EditIconButton
            onClick={() => {
              setEditRule(params.row);
              setOpenForm(true);
            }}
          />
          <ConfirmDialog
            content={`Are you sure you want to delete this rule?`}
            action={() => deleteRule(params.row.id)}
          />
        </>
      ),
    },
  ];

  return (
    <Card sx={{ p: 3, minWidth: '300px' }}>
      <ListItemText
        primary="Order Email Settings"
        secondary="When a order is created an alert will be sent based on these rules"
        primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
        secondaryTypographyProps={{ component: 'span' }}
      />
      <Stack spacing={1} sx={{ p: 3, borderRadius: 2, bgcolor: 'background.neutral' }}>
        <CustomDataGrid
          gridId="account-order-email-settings"
          sx={{ height: '280px' }}
          boxSX={{ height: 'auto' }}
          tableBoxSX={{ height: 'auto' }}
          scrollbarHeight={null}
          data={data?.orderEmailRules || []}
          gridColumns={gridFields}
          isLoading={isLoading}
          CustomLeftToolbar={() => <AddButton onClick={() => setOpenForm(true)}>Add Rule</AddButton>}
        />
      </Stack>
      <UserOrderEmailSettingForm
        categories={data.categories}
        facilities={data.facilities}
        vendors={data.vendors}
        editRow={editRule}
        openForm={openForm}
        fetchData={fetchData}
        close={() => {
          setOpenForm(false);
          setEditRule(null);
        }}
      />
    </Card>
  );
}
