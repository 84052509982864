import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Grid,
  Typography,
  Box,
  Button,
  TextField,
  DialogActions,
} from '@mui/material';
import { useSelector } from '../../../../redux/store';
import { newUser } from '../../../../api/user';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function NewUserModal() {
  const [open, setOpen] = useState(false);
  const {
    data: { facilities, positions },
  } = useSelector((state) => state.users);
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    facilityId: '',
    facility: '',
    position: '',
    positionId: '',
    password: '',
  });

  const handleSave = async () => {
    const response = await newUser({ ...value });
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.data.user.id) {
      window.location.href = `/dashboard/admin/users/${response.data.user.id}`;
    } else
      enqueueSnackbar(`Error: Could not create user`, {
        variant: 'error',
      });
  };

  const handleClickOpen = () => {
    setValue({
      firstName: '',
      lastName: '',
      email: '',
      facilityId: '',
      facility: '',
      position: '',
      positionId: '',
      password: '',
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      firstName: '',
      lastName: '',
      email: '',
      facilityId: '',
      facility: '',
      position: '',
      positionId: '',
      password: '',
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mr: 1 }}
        size="small"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        New user
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          New User
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" sx={{ p: 1 }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Grid item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">First name</Typography>
                  <TextField
                    name="firstName"
                    placeholder="First name"
                    value={value.firstName}
                    onChange={(e) => setValue({ ...value, firstName: e.target.value })}
                    size="small"
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">Last name</Typography>
                  <TextField
                    name="lastName"
                    placeholder="Last name"
                    size="small"
                    fullWidth
                    value={value.lastName}
                    onChange={(e) => setValue({ ...value, lastName: e.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Grid item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">Email</Typography>
                  <TextField
                    name="email"
                    fullWidth
                    placeholder="Email"
                    size="small"
                    value={value.email}
                    onChange={(e) => setValue({ ...value, email: e.target.value })}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">Password</Typography>
                  <TextField
                    name="password"
                    placeholder="Password"
                    size="small"
                    fullWidth
                    value={value.password}
                    onChange={(e) => setValue({ ...value, password: e.target.value })}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">Position</Typography>
                  <Autocomplete
                    // freeSolo
                    size="small"
                    fullWidth
                    value={value.position}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    options={positions.map((position, index) => ({ id: index + 1, label: position }))}
                    filterOptions={(options, params) => {
                      const filtered = options.filter((option) =>
                        option.label.toLowerCase().includes(params.inputValue.toLowerCase())
                      );
                      if (params.inputValue !== '') {
                        filtered.push({ label: `Create ${params.inputValue}` });
                      }
                      return filtered;
                    }}
                    onChange={(event, newValue) => {
                      if (newValue?.label?.startsWith('Create ')) {
                        const newPosition = newValue?.label?.replace('Create ', '');
                        setValue({ ...value, position: newPosition });
                      } else {
                        setValue({ ...value, position: newValue?.label });
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label="Search or select" />}
                    sx={{
                      mt: 1,
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabel-root ': { display: 'none' },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              sx={{ mt: 1.5 }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Box sx={{ width: '100%' }}>
                  <Typography variant="subtitle1">Facility</Typography>
                  <Autocomplete
                    fullWidth
                    size="small"
                    value={value.facility}
                    onChange={(e, newValue) =>
                      setValue({ ...value, facilityId: newValue?.id, facility: newValue?.label })
                    }
                    options={facilities.map((facility) => ({ id: facility.value, label: facility.label }))}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderInput={(params) => <TextField {...params} label="Search or select" />}
                    sx={{
                      mt: 1,
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabel-root ': { display: 'none' },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={
              value.firstName === '' ||
              value.lastName === '' ||
              value.email === '' ||
              value.facility === '' ||
              value.position === ''
            }
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
