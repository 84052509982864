import { Badge, Box, Grid, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { BookingAccount, FacilitiesTab, FacilityTab } from '.';
import CensusModal from '../census/CensusModal';
import Iconify from '../../../../reusable-components/iconify';
// import { Budgets } from '../budgets';
import { getFacilityData } from '../../../../api/facility';
import { BudgetsView } from '../budgets';
import ApprovalWorkflowTab from './ApprovalWorkflowTab';
import { ScopeGuard } from '../../../../reusable-components/scopes';

export default function FacilitiesView() {
  const [tab, setTab] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getFacilityData();
      const { companies, facilities } = response.data;
      setCompanies(companies);
      setData(facilities);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching facility data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      setSelectedRow(null);
    }
    setTab(newValue);
  };

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return (
          <FacilitiesTab
            setSelectedRow={setSelectedRow}
            setTab={setTab}
            companies={companies}
            data={data}
            fetchData={fetchData}
            isLoading={isLoading}
          />
        );
      case 1:
        return (
          <FacilityTab
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            companies={companies}
            data={data}
            fetchData={fetchData}
            setTab={setTab}
          />
        );
      case 2:
        return <BookingAccount selectedRow={selectedRow} companies={companies} data={data} fetchData={fetchData} />;
      case 3:
        return <BudgetsView selectedRow={selectedRow} />;
      case 4:
        return (
          <ScopeGuard scopes={['Super-Admin']}>
            <CensusModal isDialog={false} open setOpen={() => null} facilityId={selectedRow.id} />
          </ScopeGuard>
        );
      case 5:
        return (
          <ScopeGuard scopes={['Super-Admin']}>
            <ApprovalWorkflowTab
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              companies={companies}
              data={data}
              fetchData={fetchData}
              setTab={setTab}
            />
          </ScopeGuard>
        );

      default:
        return (
          <FacilitiesTab
            setSelectedRow={setSelectedRow}
            setTab={setTab}
            companies={companies}
            data={data}
            fetchData={fetchData}
            isLoading={isLoading}
          />
        );
    }
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{
              borderRadius: '4px 4px 0px 0px',
            }}
          >
            <CustomTab
              label={
                <>
                  <Box>
                    <Typography variant="subtitle1" color="success.contrastText" component="span">
                      Facilities
                    </Typography>
                    {selectedRow && (
                      <Tooltip title="Remove selected vendor">
                        <Typography component="span" sx={{ color: 'info.main' }}>
                          <Iconify
                            icon="mdi:close"
                            id={tab.id}
                            onClick={() => setSelectedRow(null)}
                            width={20}
                            height={20}
                            sx={{ ml: '5px', mb: '-5px' }}
                          />{' '}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </>
              }
            />
            {selectedRow && (
              <CustomVendorTab
                label={
                  <>
                    <Typography variant="subtitle1" color="info.main">
                      {selectedRow.shortName || selectedRow.facilityName}
                    </Typography>
                  </>
                }
              />
            )}
            {selectedRow && (
              <CustomTab
                label={
                  <>
                    <Box>
                      <Typography variant="subtitle1" color="success.contrastText" component="span">
                        Booking Account
                      </Typography>
                    </Box>
                  </>
                }
              />
            )}
            {selectedRow && (
              <CustomTab
                label={
                  <>
                    <Box>
                      <Typography variant="subtitle1" color="success.contrastText" component="span">
                        Budgets
                      </Typography>
                    </Box>
                  </>
                }
              />
            )}
            {selectedRow && (
              <CustomTab
                label={
                  <>
                    <ScopeGuard scopes={['Super-Admin']}>
                      <Box>
                        <Typography variant="subtitle1" color="success.contrastText" component="span">
                          Census
                        </Typography>
                      </Box>
                    </ScopeGuard>
                  </>
                }
              />
            )}
            {selectedRow && (
              <CustomTab
                label={
                  <>
                    <ScopeGuard scopes={['Super-Admin']}>
                      <Box>
                        <Badge
                          badgeContent="Beta"
                          color="info"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          sx={{
                            '& .MuiBadge-badge': {
                              right: 17,
                              bottom: 13,
                              border: '2px solid #fff',
                              padding: '0 4px',
                            },
                          }}
                        >
                          <Typography variant="subtitle1" color="success.contrastText" component="span">
                          Review Workflow
                          </Typography>
                        </Badge>
                      </Box>
                    </ScopeGuard>
                  </>
                }
              />
            )}
          </Tabs>
        </Box>
      </Grid>

      {renderTabContent()}
    </Box>
  );
}

const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '160px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CustomVendorTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  minWidth: '170px',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#f4f6f8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
