import { createSlice } from '@reduxjs/toolkit';
// utils
import { getUsersData, getUserRoleData, updateUserSecurity, setUserEnabledState } from '../../api/user';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {
    facilities: [],
    positions: [],
    userRoleTemplates: [],
    userRoleTypeList: [],
    users: [],
    unsavedChanges: false,
    user: {
      roles: [],
      roleValues: [],
      templateId: null,
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      facilityId: null,
      position: '',
      roleShort: '',
      facility: '',
      lockoutEnd: null,
      emailConfirmed: null,
    },
  },
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDataSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },

    updateUserEnabledStatus(state, action) {
      state.isLoading = false;
      state.data.user = { ...state.data.user, lockoutEnd: new Date(action.payload) };
    },

    getUserSuccess(state, action) {
      state.isLoading = false;
      state.data.unsavedChanges = false;
      state.data.user = {
        ...state.data.users.find((user) => user.id === action.payload.userId),
        ...action.payload.user,
      };
    },

    setUserAsEmpty(state, action) {
      state.isLoading = false;
      state.data.unsavedChanges = false;
      state.data.user = {
        roles: [],
        roleValues: [],
        templateId: null,
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        facilityId: null,
        position: '',
        roleShort: '',
        facility: '',
        lockoutEnd: null,
        emailConfirmed: null,
      };
    },

    updateUsersRole(state, action) {
      state.data.unsavedChanges = true;
      const { updatedRoles } = action.payload;
      state.data.user.roles = updatedRoles;
      state.isLoading = false;
    },
    updateUsersRolesValues(state, action) {
      state.data.unsavedChanges = true;
      const {
        data: { updatedRoleValues, useUserFacility, roles, userRoleTypeList, user },
      } = action.payload;

      const userFacilityRoles = [];

      if (useUserFacility) {
        const modelId = user.facilityId;
        roles.forEach((role) => {
          const roleList = userRoleTypeList.find((roleType) => roleType.id === role.roleId);

          if (roleList) {
            roleList?.innerOptionValues?.forEach((innerOption) => {
              userFacilityRoles.push({
                userPermissionTypeId: roleList.id,
                modelId,
                modelAttachmentId: innerOption.key,
              });
            });
          }
        });
      }

      state.data.user.roleValues = updatedRoleValues.concat(userFacilityRoles);
      state.isLoading = false;
    },
    updateTemplateId(state, action) {
      state.data.unsavedChanges = true;
      const { templateId } = action.payload;
      state.data.user.templateId = templateId;
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------
export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getUsersData();
      dispatch(slice.actions.getDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function userRoleData(userId, users) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (users === 0) {
        const response = await getUsersData();
        dispatch(slice.actions.getDataSuccess(response.data));
      }

      const response = await getUserRoleData(userId);
      dispatch(slice.actions.getUserSuccess({ user: response.data, userId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setUserEmpty() {
  return async (dispatch) => {
    dispatch(slice.actions.setUserAsEmpty());
  };
}

export function updateEnableStatus(id, enable) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await setUserEnabledState(id, enable);
      dispatch(slice.actions.updateUserEnabledStatus(response.data));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function updateUserSecurityData(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await updateUserSecurity(data);
      dispatch(slice.actions.getUserSuccess({ user: response.data, userId: data.id }));
      return response;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return null;
    }
  };
}

export function updateUsersRolesInRedux(updatedRoles) {
  return async (dispatch) => {
    dispatch(slice.actions.updateUsersRole({ updatedRoles }));
  };
}

export function updateUsersRoleValuesInRedux(updatedRoleValues, useUserFacility, roles, userRoleTypeList, user) {
  return async (dispatch) => {
    dispatch(
      slice.actions.updateUsersRolesValues({
        data: {
          updatedRoleValues,
          useUserFacility,
          roles,
          userRoleTypeList,
          user,
        },
      })
    );
  };
}
export function updateTemplateIdInRedux(templateId) {
  return async (dispatch) => {
    dispatch(slice.actions.updateTemplateId({ templateId }));
  };
}
