import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { invoiceGLItemsForm, getInvoice, getManualInvoicing } from '../../../../redux/slices/invoicing';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import Iconify from '../../../../reusable-components/iconify';
import { bookInvoice2 } from '../../../../api/invoicing';
import { useScopeCheck } from '../../../../reusable-components/scopes';

SubmitInvoice.propTypes = { glRows: PropTypes.array, setEnableMarkAsSent: PropTypes.func };
export default function SubmitInvoice({ glRows, setEnableMarkAsSent }) {
  const { id } = useParams();
  const {
    data: { manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitComplete, setSubmitComplete] = useState(false);

  const {
    data: { invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);

  const { invoice } = invoiceItemData;
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (submitComplete && invoice.sourceMethod === 'Manual') {
      if (manualInvoicing?.length === 0) navigate(`/dashboard/invoicing?filter=new`);
      else {
        const nextId = manualInvoicing?.[0]?.id;
        navigate(`/dashboard/invoicing/${nextId}`);
      }
    }
  }, [manualInvoicing, submitComplete]);
  async function submit() {
    setSubmitComplete(false);
    const response = await bookInvoice2(invoice, invoiceGLItems);
    if (response.status === 200) {
      if (response.data.keepFormOpen) {
        enqueueSnackbar(response.data.message, {
          variant: response.data.success ? 'success' : 'error',
          persist: true,
        });
        if (response.data.message?.startsWith(`Invoice '${invoice?.vendorInvoiceId}' already in Intacct.`)) {
          setEnableMarkAsSent(true);
        }
      } else {
        enqueueSnackbar(response.data.message, { variant: response.data.success ? 'success' : 'error' });
      }
    } else {
      enqueueSnackbar('Unable to submit invoice', { variant: 'error' });
    }
    if (response.data.success) {
      await dispatch(getManualInvoicing());
      setSubmitComplete(true);
    }
    setIsSubmitting(false);
  }
  useEffect(() => {
    //submit only once invoiceGlItems finished updating
    if (invoiceGLItems?.length > 0 && isSubmitting) {
      submit();
    }
  }, [invoiceGLItems, isSubmitting]);
  const saveGlItems = async () => {
    await dispatch(invoiceGLItemsForm({ invoiceGlItems: glRows }));
    await dispatch(getInvoice(id));
    setIsSubmitting(true);
  };
  return (
    <>
      <Tooltip
        title={
          invoice?.glPostingDate === null ? 'Gl posting date is required' : glRows?.length <= 0 ? 'Missing GLs' : ''
        }
        placement="top"
        arrow
      >
        <Box>
          <AwaitButton
            variant="contained"
            color="secondary"
            onClick={saveGlItems}
            loading={isSubmitting}
            loadingPosition="start"
            disabled={invoice?.glPostingDate === null || glRows?.length === 0 || !hasEditAccess}
            startIcon={<Iconify icon="fa:paper-plane-o" />}
          >
            Submit Invoice
          </AwaitButton>
        </Box>
      </Tooltip>
    </>
  );
}
