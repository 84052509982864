import { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { EMAIL_LIST } from '../../../../reusable-components/datagrid/emailColumns';
import { getSentEmailsData } from '../../../../api/emails';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { DateRangePickerSelect } from '../../../../reusable-components/date-range-picker';

export default function EmailsView() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const getSentEmails = async () => {
    setIsLoading(true);
    const response = await getSentEmailsData({
      startDate: searchParams.get('start'),
      endDate: searchParams.get('end'),
    });
    if (response.status === 200) {
      setData(response.data);
    } else {
      enqueueSnackbar('Error fetching emails', { variant: 'error' });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSentEmails({ startDate: searchParams.get('start'), endDate: searchParams.get('end') });
  }, [searchParams]);

  const customToolbar = useCallback(() => <DateRangePickerSelect />, []);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-email-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={EMAIL_LIST}
          sort={[{ field: 'dateAdded', sort: 'desc' }]}
          isLoading={isLoading}
          disableSelectionOnClick
          CustomLeftToolbar={customToolbar}
        />
      </Grid>
    </>
  );
}
