import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Autocomplete,
  Typography,
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Grid,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from '../../../../redux/store';
import { TabPanel } from '../../../../reusable-components/tab-panel';
import {
  getOrderGuidesByFacilityAndCategory,
  getOrderGuideVendorPreferences,
  saveOrderGuideVendorPreferences,
} from '../../../../redux/slices/orderGuides';
import { AwaitButton } from '../../../../reusable-components/await-button';

VendorGuide.propTypes = {
  facilityId: PropTypes.number,
  agoraCategoryId: PropTypes.number,
  overrideId: PropTypes.number,
  extraCall: PropTypes.bool,
};

export default function VendorGuide({ overrideId, facilityId, agoraCategoryId, extraCall = false }) {
  const dispatch = useDispatch();
  const {
    data: { preferences, vendors, subcategories, orderGuide },
  } = useSelector((state) => state.orderGuides);

  let { id = '' } = useParams();
  if (overrideId) id = overrideId;

  const { restrictedVendors } = orderGuide;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open && extraCall) {
      dispatch(getOrderGuidesByFacilityAndCategory(id));
      dispatch(getOrderGuideVendorPreferences(id));
    }
  }, [agoraCategoryId, facilityId, open, extraCall, id]);

  const [value, setValue] = useState(0);
  const [tempPreferences, setTempPreferences] = useState(preferences.filter((preference) => !preference.isRestricted));

  const [tempRestricted, setTempRestricted] = useState(restrictedVendors);
  function restrictedObjToArrOfArrOfObj(restrictedVendors) {
    const groups = {};
    restrictedVendors?.forEach((obj) => {
      const key = obj.agoraSubcategoryId;
      if (!groups[key]) {
        groups[key] = [];
      }
      // Create new object with value and label properties, plus all properties of the original object
      const newObj = { ...obj, value: obj.vendorId, label: obj.vendor };
      groups[key].push(newObj);
    });
    return Object.values(groups);
  }
  useEffect(() => {
    setTempPreferences(preferences.filter((preference) => !preference.isRestricted));
    setTempRestricted(restrictedObjToArrOfArrOfObj(restrictedVendors));
  }, [restrictedVendors, preferences]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTempPreferences(preferences.filter((preference) => !preference.isRestricted));
    setTempRestricted(restrictedObjToArrOfArrOfObj(restrictedVendors));
  };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSave = async () => {
    const activePreferences = tempPreferences && tempPreferences.length > 0 ? tempPreferences : null;

    const activeRestricted = tempRestricted && tempRestricted.length > 0 ? tempRestricted.flat() : null;
    dispatch(
      saveOrderGuideVendorPreferences({
        id,
        tempPreferences: activePreferences,
        tempRestricted: activeRestricted,
        setOpenFunc: () => setOpen(false),
      })
    );
  };
  return (
    <>
      <IconButton color="secondary" onClick={handleClickOpen} id="action-button" className="action-button">
        <TuneIcon id="action-button" className="action-button" />
      </IconButton>

      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box sx={{ width: '25vw' }}>
            <Tabs value={value} onChange={handleTabChange}>
              <Tab label="Preferred vendor" />
              <Tab label="Restricted Vendors" />
            </Tabs>
          </Box>
        </DialogTitle>
        {subcategories.length === 0 ? (
          <CircularProgress
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
            }}
          />
        ) : (
          <DialogContent sx={{ width: '100%' }}>
            <>
              <TabPanel value={value} index={0}>
                <Box>
                  <Grid container spacing={2}>
                    {subcategories.map((subcategory, index) => (
                      <React.Fragment key={subcategory.id}>
                        {index === 0 && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mt: 1, mb: 0.5 }}>
                              Autofill vendor
                            </Typography>
                            <Autocomplete
                              size="small"
                              fullWidth
                              isOptionEqualToValue={(option, value) => option?.value === value?.value}
                              options={vendors}
                              onChange={(e, newValue) => {
                                if (newValue === null) {
                                  setTempPreferences([]);
                                } else {
                                  setTempPreferences(
                                    subcategories.map((subcategory) => ({
                                      vendor: newValue?.label,
                                      vendorId: newValue?.value,
                                      agoraSubcategoryId: subcategory.id,
                                      agoraSubcategory: subcategory.name,
                                    }))
                                  );
                                }
                              }}
                              renderInput={(params) => <TextField {...params} label="Search or select" />}
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                          </Grid>
                        )}

                        <Grid item xs={6} key={subcategory.id}>
                          <Typography variant="subtitle1" sx={{ mt: 1, mb: 0.5 }} key={subcategory.id}>
                            {subcategory.name}
                          </Typography>
                          <Autocomplete
                            size="small"
                            fullWidth
                            value={
                              tempPreferences?.find(
                                (preference) =>
                                  !preference.isRestricted && preference.agoraSubcategoryId === subcategory.id
                              )?.vendor || ''
                            }
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            options={vendors}
                            onChange={(e, newValue) => {
                              if (newValue === null) {
                                setTempPreferences([
                                  ...tempPreferences.filter(
                                    (preferences) => preferences.agoraSubcategoryId !== subcategory.id
                                  ),
                                ]);
                              } else {
                                setTempPreferences([
                                  ...tempPreferences.filter(
                                    (preferences) => preferences.agoraSubcategoryId !== subcategory.id
                                  ),
                                  {
                                    vendor: newValue?.label,
                                    vendorId: newValue?.value,
                                    agoraSubcategoryId: subcategory.id,
                                    agoraSubcategory: subcategory.name,
                                  },
                                ]);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} label="Search or select" />}
                            sx={{
                              '& legend': { display: 'none' },
                              '& fieldset': { top: 0 },
                              '& .MuiFormLabel-root ': { display: 'none' },
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box>
                  <Grid container spacing={2}>
                    {subcategories.map((subcategory, index) => (
                      <React.Fragment key={subcategory.id}>
                        {index === 0 && (
                          <Grid item xs={12}>
                            <Typography variant="subtitle1" sx={{ mt: 1, mb: 0.5 }}>
                              Autofill vendor
                            </Typography>
                            <Autocomplete
                              size="small"
                              fullWidth
                              multiple
                              isOptionEqualToValue={(option, value) => option?.value === value?.value}
                              options={vendors}
                              onChange={(e, newValue) => {
                                if (newValue === null) {
                                  setTempRestricted([]);
                                } else {
                                  setTempRestricted(
                                    subcategories.map((subcategory) =>
                                      newValue?.map((vendor) => ({
                                        vendor: vendor?.label,
                                        vendorId: vendor?.value,
                                        label: vendor?.label,
                                        value: vendor?.value,
                                        agoraSubcategoryId: subcategory.id,
                                        agoraSubcategory: subcategory.name,
                                      }))
                                    )
                                  );
                                }
                              }}
                              value={vendors.filter((vendor) => {
                                if (!tempRestricted) {
                                  return false;
                                }
                                return tempRestricted?.every((subArray) => {
                                  if (!Array.isArray(subArray)) {
                                    subArray = [subArray];
                                  }

                                  return subArray?.some((obj) => obj.vendorId === vendor?.value);
                                });
                              })}
                              renderInput={(params) => <TextField {...params} label="Search or select" />}
                              sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                                '& .MuiFormLabel-root ': { display: 'none' },
                              }}
                            />
                          </Grid>
                        )}

                        <Grid item xs={6} key={subcategory.id}>
                          <Typography variant="subtitle1" sx={{ mt: 1, mb: 0.5 }} key={subcategory.id}>
                            {subcategory.name}
                          </Typography>
                          <Autocomplete
                            size="small"
                            fullWidth
                            value={
                              tempRestricted?.find((subArray) => {
                                if (!Array.isArray(subArray)) {
                                  subArray = [subArray];
                                }

                                return subArray && subArray?.some((obj) => obj.agoraSubcategoryId === subcategory.id);
                              }) || []
                            }
                            multiple
                            getOptionLabel={(option) => (option ? option.label : '')}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            options={vendors}
                            onChange={(e, newValues) => {
                              if (newValues === null) {
                                setTempRestricted([
                                  ...tempRestricted.filter((subArray) =>
                                    subArray?.some((obj) => obj.agoraSubcategoryId !== subcategory.id)
                                  ),
                                ]);
                              } else {
                                setTempRestricted([
                                  ...tempRestricted.filter((subArray) =>
                                    subArray?.some((obj) => obj.agoraSubcategoryId !== subcategory.id)
                                  ),
                                  newValues.map((vendor) => ({
                                    vendor: vendor?.label,
                                    vendorId: vendor?.value,
                                    label: vendor?.label,
                                    value: vendor?.value,
                                    agoraSubcategoryId: subcategory.id,
                                    agoraSubcategory: subcategory.name,
                                  })),
                                ]);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} label="Search or select" />}
                            sx={{
                              '& legend': { display: 'none' },
                              '& fieldset': { top: 0 },
                              '& .MuiFormLabel-root ': { display: 'none' },
                            }}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                </Box>
              </TabPanel>
            </>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
