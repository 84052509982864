import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import { useDispatch } from '../../../redux/store';
import { changeInvoiceItemDataFromRedux } from '../../../redux/slices/invoicing';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { formatMoney } from '../../../utils/formatNumber';

TaxAmount.propTypes = {
  row: PropTypes.object,
  invoiceId: PropTypes.number,
};

export default function TaxAmount({ row, invoiceId }) {
  const dispatch = useDispatch();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const origTaxAmount = useMemo(() => row?.taxAmount, []);

  return hasEditAccess ? (
    <>
      <TextField
        size="small"
        type="number"
        value={row.taxAmount || ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={(e) => {
          const newValue = formatMoney(e.target.value);
          if (!Number.isNaN(newValue)) {
            dispatch(
              changeInvoiceItemDataFromRedux({ id: row.id, field: 'taxAmount', value: Number(newValue) }, invoiceId)
            );
          }
        }}
      />
    </>
  ) : (
    <>
      <TextField
        size="small"
        type="number"
        disabled
        value={row.taxAmount || ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </>
  );
}
