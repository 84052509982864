import { Box, Card, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderOccupantsData, postLeaveOrder } from '../../../../api/orders';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar, CustomAvatarGroup } from '../../../../reusable-components/custom-avatar';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { fDateTime } from '../../../../utils/formatTime';
import AltAddressDialog from './AltAddressDialog';

OrderInfo.propTypes = {
  setAltAddress: PropTypes.func,
  altAddress: PropTypes.object,
};

export default function OrderInfo({ setAltAddress, altAddress }) {
  const { id = '' } = useParams();
  const [copied, setCopied] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const { orderById } = useSelector((state) => state.orders);
  const { facility, orderNumber, submittedBy, dateAdded, onBehalfOf, department } = orderById[id] || {};
  const { name, email } = submittedBy || { name: '', email: '' };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = (e) => {
    setCopied(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAltAddress({
      street: '',
      phoneNumber: '',
      city: '',
      state: '',
      zip: '',
    });
  };
  const [occupiedBy, setOccupiedBy] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOrderOccupantsData(id);
      if (response.data.length) {
        setOccupiedBy(response.data);
      }
    };

    if (open) fetchData();
  }, [id, open]);

  // Handle postLeaveOrder when page exits or closes
  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      event.preventDefault();
      await postLeaveOrder(id);
    };

    window.addEventListener('pagehide', handleBeforeUnload);
    return () => {
      window.removeEventListener('pagehide', handleBeforeUnload);
    };
  }, [id]);

  return (
    <>
      <Box
        sx={{
          minWidth: '300px',
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="primary">
              {facility || 'Loading...'}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="primary">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>
          </Grid>
          {open && (
            <Box>
              <Box>
                <Divider sx={{ width: '100%', my: 1, backgroundColor: '#000' }} />

                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ pb: 1.5 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.info.main }}>
                    Order #{orderNumber || id}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex', // Use flexbox
                      alignItems: 'flex-bottom', // Vertically align items to center
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        py: 0.5,
                      }}
                      component="span"
                      variant="body2"
                    >
                      Submitted by:
                    </Typography>
                    <Tooltip title={copied ? `Copied!` : `Copy ${email || ''} to clipboard`}>
                      <Typography
                        component="span"
                        sx={{
                          ml: 0.5,
                          textDecoration: 'underline',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        variant="body2"
                        onClick={() => {
                          navigator.clipboard.writeText(email);
                          setCopied(true);
                        }}
                      >
                        {name} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography sx={{ py: 0.5 }} variant="body2">
                    Submitted on: {fDateTime(dateAdded)}
                  </Typography>
                  <Typography sx={{ py: 0.5 }} variant="body2">
                    On behalf of: {onBehalfOf}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Department: {department}
                  </Typography>

                  <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                    <Divider sx={{ width: '100%' }} />
                    <Box sx={{ mb: 1 }}>
                      <Typography component="span" variant="body2">
                        Alternative Address:
                        <IconButton color="secondary" onClick={handleClickOpen}>
                          <Iconify icon="mdi:pencil" width={20} height={20} />
                        </IconButton>
                      </Typography>

                      {altAddress.street && (
                        <Typography variant="caption" color="textSecondary" display="block">
                          {altAddress.street}
                        </Typography>
                      )}
                      {(altAddress.city || altAddress.state || altAddress.zip) && (
                        <Typography variant="caption" color="textSecondary" display="block">
                          {`${altAddress.city || ''} ${altAddress.state || ''} ${altAddress.zip || ''}`.trim()}
                        </Typography>
                      )}
                      {altAddress.phoneNumber && (
                        <Typography variant="caption" color="textSecondary" display="block">
                          {altAddress.phoneNumber}
                        </Typography>
                      )}
                    </Box>
                  </ScopeGuard>

                  <Divider sx={{ width: '100%' }} />
                  <Typography variant="body2">Occupied by: </Typography>
                  <Box>
                    <>
                      <CustomAvatarGroup max={100} size="small">
                        {occupiedBy?.map((participant, index) => (
                          <CustomAvatar name={participant} index={index} key={index} />
                        ))}
                      </CustomAvatarGroup>
                    </>
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Card>
      </Box>
      <AltAddressDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        handleClose={handleClose}
        altAddress={altAddress}
        setAltAddress={setAltAddress}
        handleCloseDialog={handleCloseDialog}
      />
    </>
  );
}
