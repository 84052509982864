import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Badge, Typography, Grid, Button, Box } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { fCurrency } from '../../../../../utils/formatNumber';
import { useDispatch, useSelector } from '../../../../../redux/store';
import Iconify from '../../../../../reusable-components/iconify';
import { removeItemFromCart, bulkDeleteCart } from '../../../../../redux/slices/cart';
import { ConfirmDialog } from '../../../../../reusable-components/confirm-dialog';
import { CART } from '../../../../../reusable-components/datagrid/cartColumns';
import { useScopeCheck, ScopeGuard } from '../../../../../reusable-components/scopes';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';

ShoppingCart.propTypes = {
  items: PropTypes.object,
  showSelection: PropTypes.string,
  handleClose: PropTypes.func,
  setShowSelection: PropTypes.func,
  showErrorMsg: PropTypes.bool,
};

export default function ShoppingCart({ items, showSelection, showErrorMsg, handleClose, setShowSelection }) {
  const hasEditAccess = useScopeCheck(['Edit-Order'], true);
  const dispatch = useDispatch();
  const {
    data: { unsavedChanges },
  } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const { facilityId = '' } = useParams();
  const [selectedToDelete, setSelectedToDelete] = useState([]);
  const [allExpanded, setAllExpanded] = useState(false);

  const { items: shoppingCartItems } = items;
  const [pinnedColumns, setPinnedColumns] = useState({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'picture'],
    right: [showErrorMsg ? 'error' : '', 'vendor', 'total', 'quantity', 'actions'],
  });
  const handleDeleteItem = ({ itemId, punchoutItemId }) => {
    dispatch(
      removeItemFromCart({
        facilityId,
        itemId,
        punchoutItemId,
      })
    );
  };

  const [price, setPrice] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemErrorCount, setItemErrorCount] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    let totalItems = 0;
    setItemErrorCount(0);

    items.items.forEach((item) => {
      totalPrice += item.price * item.quantity;
      if (item.quantity > 0) totalItems++;
      if (item.errors?.length > 0) {
        setItemErrorCount((prev) => prev + 1);
      }
    });

    setPrice(totalPrice);
    setTotalItems(totalItems);
  }, [items]);

  const bulkDelete = (selectedToDelete) => {
    // handleClose();
    dispatch(bulkDeleteCart(facilityId, selectedToDelete));
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
            <Grid item>
              <Iconify icon={items?.icon} width={35} height={35} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{ mb: 1, ml: 1, mr: 2 }}>
                {items?.agoraCategory} cart
              </Typography>
            </Grid>
            {showSelection === 'Select items to delete' ? (
              <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <ConfirmDialog
                    icon="delete-outline"
                    title="Delete all carts?"
                    color="error"
                    content="Doing so will delete all items selected. Are you sure you want to delete all items selected?"
                    actionButton="Delete selected items"
                    button="outlined"
                    fullWidth
                    action={() => bulkDelete(selectedToDelete)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => setShowSelection('')}
                    variant="outlined"
                    color="inherit"
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    navigate(
                      `/dashboard/orders/new-order/${facilityId}?categoryId=${
                        items?.agoraCategoryId
                      }&category=${encodeURIComponent(items?.agoraCategory)}`
                    );
                  }}
                  disabled={unsavedChanges}
                >
                  Continue shopping
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Typography variant="subtitle1" sx={{ mt: 1.5, ml: 1, mr: 2 }}>
                Total ({totalItems} item{totalItems !== 1 ? 's' : ''}): {fCurrency(price)}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                mr: 1,
              }}
            >
              <ScopeGuard scopes={['View-Order']} allowAdmin>
                <Grid item>
                  <Badge badgeContent={itemErrorCount} color="error">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      sx={{ backgroundColor: '#fff' }}
                      onClick={() => {
                        navigate(`/dashboard/orders/new-order/checkout/${items?.agoraCategoryId}`);
                      }}
                      disabled={unsavedChanges}
                    >
                      Checkout {items?.agoraCategory} cart
                    </Button>
                  </Badge>
                </Grid>
              </ScopeGuard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      facilityId,
      itemErrorCount,
      items?.agoraCategory,
      items?.agoraCategoryId,
      items?.icon,
      navigate,
      price,
      setShowSelection,
      showSelection,
      totalItems,
      unsavedChanges,
      selectedToDelete,
    ]
  );

  if (!items) {
    return null;
  }
  const actions = {
    field: 'actions',
    headerName: '',
    flex: 1,
    renderCell: (params) => (
      <ConfirmDialog
        icon="delete-outline"
        title="Delete item?"
        color="error"
        content={`Are you sure want to delete ${params.row.description}?`}
        actionButton="Delete"
        action={() => handleDeleteItem({ itemId: params.row.itemId, punchoutItemId: params.row.punchoutItemId })}
      />
    ),
    id: 'actions',
    className: 'actions',
  };

  return (
    <>
      <Box>
        <Grid item xs={12}>
          <CustomDataGrid
            gridId="orders-new-order-cart"
            boxSX={{ height: allExpanded ? `calc(100vh - 240px)` : 260 }}
            // scrollbarHeight={55}
            scrollbarHeight={allExpanded ? 97 : 52}
            data={shoppingCartItems}
            gridColumns={[...CART, hasEditAccess && actions]}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={(params) => {
              setPinnedColumns(params.pinnedColumns);
            }}
            checkboxSelection={showSelection === 'Select items to delete'}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedToDelete(newSelection);
            }}
            rowSelectionModel={selectedToDelete}
            dontGetRowHeight
            CustomLeftToolbar={CustomLeftToolbar}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={0.5}
          sx={{
            textDecoration: 'underline',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => setAllExpanded(!allExpanded)}
        >
          <Typography variant="subtitle2">{allExpanded ? 'Close' : 'View full grid'} </Typography>
          <Iconify icon={allExpanded ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
        </Grid>
      </Box>
    </>
  );
}
