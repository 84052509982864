import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Collapse,
  Card,
  Typography,
  Grid,
  Link,
  Tooltip,
  Box,
  IconButton,
  Stack,
  TextField,
  InputAdornment,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import { useSelector, useDispatch } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import Iconify from '../../../../reusable-components/iconify';
import { fDateTime } from '../../../../utils/formatTime';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import CommentEditor from '../../../../reusable-components/comment-editor';
import { addInvoiceLog, deleteInvoiceLog } from '../../../../redux/slices/invoicing';
import { getUserData } from '../../../../api/account';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';

LogTemplate.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.element,
  logEntry: PropTypes.object,
};
function LogTemplate({ icon, message, logEntry }) {
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 55);
    }
  }, [message]);

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(logEntry?.user?.email)
      .then(() => {
        enqueueSnackbar('Email copied to clipboard!', {
          variant: 'info',
        });
      })
      .catch((err) => {
        console.error('Failed to copy email: ', err);
      });
  };

  return (
    <>
      <Card
        onClick={() => setExpanded(!expanded)}
        sx={{
          ...borderBoxStyle,
          ...(isOverflowing && { cursor: 'pointer' }),
        }}
      >
        <Stack container direction="row" alignItems="center" spacing={1}>
          <Box>
            <Iconify icon={icon} color="info" height="18px" width="18px" />
          </Box>
          <Box sx={{ flex: 1, whiteSpace: 'normal', wordBreak: 'break-word' }}>
            <Collapse collapsedSize={55} in={expanded}>
              <Box ref={contentRef} sx={{ fontSize: '14px', display: 'flex', alignItems: 'center' }} minHeight={55}>
                {message}
              </Box>
            </Collapse>
          </Box>
          {isOverflowing && (
            <Box>
              <Iconify
                icon={!expanded ? 'ic:outline-expand-more' : 'ic:outline-expand-less'}
                color="info"
                height="18px"
                width="18px"
              />
            </Box>
          )}
          {user.id === logEntry.user.id && (
            <Box>
              <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
                <ConfirmDialog
                  icon="delete-outline"
                  title="Delete"
                  color="error"
                  content={`Are you sure you want to delete this invoice file?`}
                  actionButton="Delete"
                  action={async (e) => {
                    const response = await dispatch(deleteInvoiceLog(logEntry.id));
                    if (response === 'success') {
                      enqueueSnackbar('Your comment has been deleted.', { variant: 'success' });
                    } else enqueueSnackbar('Failed to delete comment. ', { variant: 'error' });
                  }}
                />
              </ScopeGuard>
            </Box>
          )}
        </Stack>
      </Card>
      <Grid container justifyContent={'end'} p={0.5}>
        {logEntry?.user?.id === '0' ? (
          <Typography
            color="text.secondary"
            sx={{
              fontSize: '12px',
              display: 'inline',
            }}
          >
            {logEntry?.user?.fullName}&nbsp;
          </Typography>
        ) : (
          <Tooltip
            onClick={handleCopyToClipboard}
            title={
              <Stack direction={'row'}>
                <Icon icon="ph:copy-bold" width="1rem" height="1rem" /> &nbsp;{logEntry?.user?.email}
              </Stack>
            }
            arrow
          >
            <Typography
              color="text.secondary"
              sx={{
                fontSize: '12px',
                display: 'inline',
              }}
            >
              {logEntry?.user?.fullName}&nbsp;
            </Typography>
          </Tooltip>
        )}

        <Typography color="text.secondary" sx={{ fontSize: '12px', display: 'inline' }}>
          {fDateTime(logEntry?.dateAdded)}
        </Typography>
      </Grid>
    </>
  );
}
InvoiceComment.propTypes = {
  logEntry: PropTypes.object,
  setComment: PropTypes.func,
  setNewLog: PropTypes.func,
};
function InvoiceComment({ logEntry, setComment, setNewLog }) {
  const { user } = useSelector((state) => state.user);
  const [emails, setEmails] = useState([]);
  useEffect(() => {
    const extractMentionIds = () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(logEntry.extraInfo, 'text/html');
      const mentionElements = doc.querySelectorAll('.mention');
      const ids = Array.from(mentionElements).map((el) => el.getAttribute('data-id'));
      return ids;
    };
    setEmails(extractMentionIds());
  }, [logEntry.extraInfo]);
  const htmlMessage = (
    <>
      <Box>
        <div dangerouslySetInnerHTML={{ __html: logEntry.extraInfo }} />
        {emails?.includes(user.email) && (
          <Link
            onClick={(e) => {
              e.stopPropagation();
              setComment(`
<p>
  <span
    class="mention"
    data-index="1"
    data-denotation-char=""
    data-value="${logEntry?.user?.fullName}"
    data-id="${logEntry?.user?.email}"
  >
    <span contenteditable="false">
      <span class="ql-mention-denotation-char"></span>
      ${logEntry?.user?.fullName}
    </span>
  </span>
</p>
`);
              setNewLog(true);
            }}
          >
            Click to answer
          </Link>
        )}
      </Box>
    </>
  );
  return <LogTemplate icon={'bi:chat'} message={htmlMessage} logEntry={logEntry} />;
}
//Invoice added by users name
InvoiceAdded.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceAdded({ logEntry }) {
  const htmlMessage = (
    <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
      Invoice added
    </Typography>
  );
  return <LogTemplate icon={'ic:baseline-sync'} message={htmlMessage} logEntry={logEntry} />;
}
//<users name> has rejected this invoice INVOICE NOTE
InvoiceRejection.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceRejection({ logEntry }) {
  const htmlMessage = (
    <>
      <Tooltip title={logEntry.extraInfo}>
        <Typography color="text.secondary" sx={{ textDecoration: 'underline', fontSize: '14px' }}>
          Rejected this invoice
        </Typography>
      </Tooltip>
    </>
  );
  return <LogTemplate icon={'fluent:text-change-reject-20-filled'} message={htmlMessage} logEntry={logEntry} />;
}
//<user name> has approved the invoice
InvoiceApproval.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceApproval({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Approved the invoice</Typography>
    </>
  );
  return <LogTemplate icon={'mdi:approve'} message={htmlMessage} logEntry={logEntry} />;
}
//<user name> has submitted the invoice to __________
InvoiceSubmission.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceSubmission({ logEntry }) {
  const htmlMessage = (
    <Typography color="text.secondary" sx={{ fontSize: '14px' }}>
      Submitted the invoice to {logEntry.extraInfo}
    </Typography>
  );
  return <LogTemplate icon={'tabler:send'} message={htmlMessage} logEntry={logEntry} />;
}
//<users name>has indicated  the invoice is not theirs to handle
InvoiceHandlingMismatch.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceHandlingMismatch({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Indicated the invoice is not theirs to handle</Typography>
    </>
  );
  return <LogTemplate icon={'ic:round-do-not-disturb-alt'} message={htmlMessage} logEntry={logEntry} />;
}
//user has indicated that this is a duplicate invoice
InvoiceDuplicateIndicator.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceDuplicateIndicator({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Indicated that this is a duplicate invoice</Typography>
    </>
  );
  return <LogTemplate icon={'heroicons:document-duplicate'} message={htmlMessage} logEntry={logEntry} />;
}

//<users name> added a ticket for this invoice
InvoiceTicketCreation.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceTicketCreation({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Added a&nbsp;</Typography>
      <Link
        onClick={() => {}}
        color="secondary.main"
        component="button"
        sx={{
          textDecoration: 'underline',
          display: 'inline',
        }}
      >
        ticket
      </Link>
      <Typography
        color="text.secondary"
        sx={{
          display: 'inline',
        }}
      >
        &nbsp;for this invoice
      </Typography>
    </>
  );
  return <LogTemplate icon={'majesticons:chat-line'} message={htmlMessage} logEntry={logEntry} />;
}
//<users name> has updated x on the invoice

InvoiceFieldUpdate.propTypes = {
  logEntry: PropTypes.object,
};
function InvoiceFieldUpdate({ logEntry }) {
  const htmlMessage = (
    <>
      <Typography color="text.secondary">Updated "{logEntry.extraInfo}" on the invoice</Typography>
    </>
  );
  return <LogTemplate icon={'clarity:update-line'} message={htmlMessage} logEntry={logEntry} />;
}
NewInvoiceComment.propTypes = {
  setNewLog: PropTypes.func,
  comment: PropTypes.string,
  setComment: PropTypes.func,
};
function NewInvoiceComment({ setNewLog, comment, setComment }) {
  const inputRef = useRef(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const {
    data: { usersInInvoiceRole },
  } = useSelector((state) => state.invoicing);
  const saveNewComment = async () => {
    const response = await dispatch(addInvoiceLog({ invoiceId: id, messageType: 'Comment', extraInfo: comment }));
    if (response === 'success') {
      enqueueSnackbar('Your comment has been added.', { variant: 'success' });
    } else enqueueSnackbar('Failed to add comment. ', { variant: 'error' });
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handleChange = (value) => {
    setComment(value);
  };

  return (
    <>
      <Box sx={{ borderRadius: '6px', border: 'solid 1px #4ca5e7', mb: '20px' }}>
        <Box>
          <CommentEditor value={comment} onChange={handleChange} users={usersInInvoiceRole} />
        </Box>

        <Box>
          <Grid item sx={{ borderTop: 'solid 1px #c1c9d0' }}>
            <Grid container justifyContent={'end'}>
              <Grid>
                <IconButton color="error" onClick={() => setNewLog(false)} id="action-button" className="action-button">
                  <DeleteOutlineIcon />
                </IconButton>
              </Grid>
              <Grid>
                <IconButton color="info" onClick={saveNewComment} id="action-button" className="action-button">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export const InvoiceLog = () => {
  const dispatch = useDispatch();

  const {
    data: { invoiceLogData },
  } = useSelector((state) => state.invoicing);
  const [newLog, setNewLog] = useState(true);
  const logMessagesArray = [
    {
      messageType: 'Reject',
      extraInfo: 'RejectNote',
      user: { id: 1, fullName: 'John Doe', email: 'john.dfffffffffffffffffdoe@example.com' },
      dateAdded: '2024-03-07',
    },
    {
      messageType: 'Approve',
      extraInfo: '/',
      user: { id: 2, fullName: 'Jane Smith', email: 'jane.smith@example.com' },
      dateAdded: '2024-03-08',
    },
    {
      messageType: 'Submit',
      extraInfo: 'intacct:',
      user: { id: 3, fullName: 'Bob Johnson', email: 'bob.johnson@example.com' },
      dateAdded: '2024-03-09',
    },
    {
      messageType: 'HandleMismatch',
      extraInfo: '/',
      user: { id: 4, fullName: 'Alice Brown', email: 'alice.brown@example.com' },
      dateAdded: '2024-03-10',
      handleMismatchComponent: <InvoiceHandlingMismatch />,
    },
    {
      messageType: 'Duplicate',
      extraInfo: '/',
      user: { id: 5, fullName: 'Charlie Green', email: 'charlie.green@example.com' },
      dateAdded: '2024-03-11',
      duplicateComponent: <InvoiceDuplicateIndicator />,
    },
    {
      messageType: 'NewTicket',
      extraInfo: 'TicketId',
      user: { id: 6, fullName: 'Eve White', email: 'eve.white@example.com' },
      dateAdded: '2024-03-12',
      newTicketComponent: <InvoiceTicketCreation />,
    },
    {
      messageType: 'FieldUpdate',
      extraInfo: 'UpdatedField',
      user: { id: 7, fullName: 'Frank Black', email: 'frank.black@example.com' },
      dateAdded: '2024-03-13',
      fieldUpdateComponent: <InvoiceFieldUpdate />,
    },
    // Add more objects as needed
  ];
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  const [comment, setComment] = useState('');
  useEffect(() => {
    setNewLog(false);
  }, [invoiceLogData]);
  return (
    <>
      <Box
        sx={{
          borderRadius: '4px',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          backgroundColor: '#fff',
          overflow: 'auto',
          border: 'solid 1px #c1c9d0',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 2,
            padding: '25px',
          }}
        >
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography variant="h8" color="info.main">
                Invoice log
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title={'Comment'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setNewLog(true);
                    setComment('');
                  }}
                  disabled={!hasEditAccess}
                >
                  <Iconify icon={'bi:chat'} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        <Scrollbar sx={{ maxHeight: '400px', mx: 3 }}>
          <Box>
            {newLog && <NewInvoiceComment setNewLog={setNewLog} comment={comment} setComment={setComment} />}
            {invoiceLogData?.map((logEntry) => {
              switch (logEntry.messageType) {
                case 'NewInvoice':
                  return <InvoiceAdded logEntry={logEntry} />;
                case 'Comment':
                  return <InvoiceComment setNewLog={setNewLog} logEntry={logEntry} setComment={setComment} />;
                case 'Reject':
                  return <InvoiceRejection logEntry={logEntry} />;
                case 'Approve':
                  return <InvoiceApproval logEntry={logEntry} />;
                case 'Submit':
                  return <InvoiceSubmission logEntry={logEntry} />;
                case 'HandleMismatch':
                  return <InvoiceHandlingMismatch logEntry={logEntry} />;
                case 'Duplicate':
                  return <InvoiceDuplicateIndicator logEntry={logEntry} />;
                case 'NewTicket':
                  return <InvoiceTicketCreation logEntry={logEntry} />;
                case 'FieldUpdate':
                  return <InvoiceFieldUpdate logEntry={logEntry} />;
                default:
                  return <div />;
              }
            })}
          </Box>
        </Scrollbar>
      </Box>
    </>
  );
};
const borderBoxStyle = {
  borderRadius: '6px',
  border: 'solid 1px #4ca5e7',
  mt: '10px',
  px: '5px',
  boxShadow: 'none',
};
