import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { fDayMonthDay, fDayMonthDy, hoursLeftToDate } from '../../../../utils/formatTime';
import { ScopeGuard } from '../../../../reusable-components/scopes';

VendorDate.propTypes = {
  item: PropTypes.object,
  allReqDates: PropTypes.array,
  setAllReqDates: PropTypes.func,
};

export default function VendorDate({ item, allReqDates, setAllReqDates }) {
  const { id = '' } = useParams();
  const { orderById } = useSelector((state) => state.orders);
  const { vendorDateMessages = [] } = orderById[id] || {};

  const { vendorId } = item;

  const filterUniqueMessagesByVendorId = (messages) => {
    const uniqueMessages = {};
    messages.forEach((msg) => {
      const { vendorId, mainMessage } = msg;
      if (!uniqueMessages[vendorId]) {
        uniqueMessages[vendorId] = [];
      }
      if (mainMessage) {
        uniqueMessages[vendorId].unshift(msg);
      } else uniqueMessages[vendorId].push(msg);
    });
    return uniqueMessages;
  };
  const uniqueMessagesLookup = useMemo(
    () => filterUniqueMessagesByVendorId(vendorDateMessages || []),
    [vendorDateMessages]
  );

  const noVendorDate = uniqueMessagesLookup[item.vendorId]?.find(
    (msg) => msg.message === "Vendor doesn't accept requested dates "
  );

  const selectedDate = allReqDates?.find((date) => date.vendorId === vendorId);

  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <Box>
      {!noVendorDate && (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-center" sx={{ pl: 1 }}>
          <Typography
            variant="body2"
            sx={{
              color: (theme) => theme.palette.grey[700],
            }}
          >
            Ship dates:
          </Typography>
          <ScopeGuard scopes={['Edit-Order']} allowAdmin>
            <Box
              sx={{
                display: 'flex', // Use flexbox
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              <Typography component="span" sx={{ color: (theme) => theme.palette.secondary.main }}>
                <Iconify icon="mdi:calendar" width={16} height={16} />
              </Typography>
              <Typography variant="subtitle2" color="secondary" component="span" sx={{ textDecoration: 'underline' }}>
                {selectedDate ? `${fDayMonthDy(selectedDate.date)} - Requested date` : 'Request date'}
              </Typography>
              <Iconify
                color="secondary"
                icon={calendarOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'}
                width={16}
                height={16}
              />
            </Box>
          </ScopeGuard>
        </Grid>
      )}
      {calendarOpen && (
        <DateCalendar
          value={selectedDate?.date}
          onChange={(newDate) => {
            setAllReqDates((prevDates) => {
              const existingDateIndex = prevDates?.findIndex((dateEntry) => dateEntry.vendorId === vendorId);

              if (existingDateIndex !== -1) {
                return prevDates.map((dateEntry, index) =>
                  index === existingDateIndex ? { ...dateEntry, date: newDate } : dateEntry
                );
              }
              return [...prevDates, { vendorId, date: newDate }];
            });
            setCalendarOpen(false);
          }}
          onClose={() => setCalendarOpen(false)}
        />
      )}
      {uniqueMessagesLookup[item.vendorId]?.length > 0 &&
        uniqueMessagesLookup[item.vendorId]?.map((item, index) => (
          <Box key={index}>
            {item.mainMessage ? (
              <Grid container direction="row" key={index} sx={{ pl: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[700],
                  }}
                >
                  {item.message}
                </Typography>
              </Grid>
            ) : (
              <Grid container direction="row" key={index} sx={{ pl: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) => theme.palette.grey[700],
                  }}
                >
                  {item.message} - {item.relativeDate ? hoursLeftToDate(item.date) : fDayMonthDay(item.date)}
                </Typography>
              </Grid>
            )}
          </Box>
        ))}
    </Box>
  );
}
