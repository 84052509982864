import { Autocomplete, Box, Chip, Popover, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from '../../../../redux/store';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';

// ----------------------------------------------------------------------

NewUserAddTicket.propTypes = {
  newUsers: PropTypes.array,
  setNewUsers: PropTypes.func,
  currentTicket: PropTypes.string,
};

export default function NewUserAddTicket({ newUsers, setNewUsers, currentTicket }) {
  const { users: usersListById } = useSelector((state) => state.tickets.data.ticketInfoById[currentTicket] || []);

  const {
    data: { users: usersList },
  } = useSelector((state) => state.tickets);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleUsers = (newValue) => {
    const updatedUsers = newValue.map((item) => {
      if (typeof item === 'string') {
        return { id: item, value: item };
      }
      return item;
    });
    setNewUsers(updatedUsers);
  };

  if (!usersListById?.length && !usersList?.length) {
    return null;
  }

  return (
    <>
      <Tooltip title="Add user">
        <CustomAvatar
          name={'+'}
          showTooltip={false}
          onClick={handleOpen}
          sx={{
            cursor: 'pointer',
            backgroundColor: 'primary.main',
          }}
        />
      </Tooltip>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
          },
        }}
      >
        <Box sx={{ p: 2, maxWidth: 400, minWidth: 400, overflow: 'hidden' }}>
          {' '}
          <Autocomplete
            multiple
            id="tags-outlined"
            options={usersList || usersListById}
            getOptionLabel={(option) => `${option.value} - ${option.id}`}
            value={newUsers}
            onChange={(event, newValue) => {
              handleUsers(newValue);
            }}
            freeSolo
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="CC additional users"
                placeholder="Add user"
                fullWidth={false} // Add this property
                sx={{ maxWidth: 400, minWidth: 350 }} // Add this property
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Box sx={{ mb: 1, display: 'inline-block' }} key={index}>
                  <Chip
                    label={option.value ?? option.id}
                    {...getTagProps({ index })}
                    avatar={<CustomAvatar name={option.value ?? option.id} />}
                    sx={{ mr: 1, mb: 1 }}
                  />
                </Box>
              ))
            }
          />
        </Box>
      </Popover>
    </>
  );
}
