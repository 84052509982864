import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { cloudinaryFull, cloudinaryThumbnail } from '../../utils/formatCloudinary';
import { fCurrency, fifthDecimalCurrency } from '../../utils/formatNumber';
import Image from '../image';
import Lightbox from '../lightbox';

export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(pictures)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={[pictures].map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
const picture = {
  field: 'pictures',
  headerName: 'Image',
  width: 100,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};
const description = {
  field: 'description',
  headerName: 'Description',
  width: 300,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const price = {
  field: 'price',
  headerName: 'Price',
  width: 120,
  renderCell: (params) => fCurrency(params.row.price),
  valueGetter: (params) => params.row.price,
  type: 'customMoney',
  headerAlign: 'left',
  align: 'left',
};

const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 100,
  type: 'customSelect',
};

const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'Vendor Id',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) =>
    `${params.row.uomAmount ? params.row.uomAmount : ''}/${params.row.uomType ? params.row.uomType : ''}`,
  width: 100,
  type: 'customSelect',
};

const perItem = {
  field: 'perItem',
  headerName: 'Per Item',
  width: 100,
  renderCell: (params) => fifthDecimalCurrency(params.row.price / params.row.uomAmount),
  valueGetter: (params) => params.row.price / params.row.uomAmount,
  type: 'customMoney',
  headerAlign: 'left',
  align: 'left',
};
const total = {
  field: 'total',
  headerName: 'Total',
  width: 100,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.row.quantity * params.row.price),
  valueGetter: (params) => params.row.quantity * params.row.price,
};
const subcategory = {
  field: 'agoraSubcategory',
  headerName: 'Subcategory',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  width: 150,
  type: 'customSelect',
};
const category = {
  field: 'agoraCategory',
  headerName: 'Category',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  width: 100,
  type: 'customSelect',
};

const agoraNumber = {
  field: 'productId',
  headerName: 'PS #',
  width: 100,
  renderCell: (params) => `PS${params.row.productId}`,
  valueGetter: (params) => `PS${params.row.productId}`,
  type: 'customText',
};
const ordered = {
  field: 'ordered',
  headerName: 'Ordered/Approved',
  width: 100,
  renderCell: (params) => `${params.row.originalQuantity} | ${params.row.quantity}`,
  type: 'customText',
  valueGetter: (params) => `${params.row.originalQuantity} | ${params.row.quantity}`,
};
const isPPD = {
  field: 'isPPD',
  headerName: 'PPD',
  renderCell: (params) => (params.value ? 'Yes' : ''),
  width: 100,
  type: 'customSelect',
};

const ORDER_COLUMNS = [
  picture,
  description,
  vendorItemId,
  agoraNumber,
  uom,
  perItem,
  price,
  total,
  category,
  subcategory,
  vendor,
  ordered,
  isPPD
  // budget,
  // status,
];
const COPY_ORDER_VIEW = [picture, description, ordered, price, total, vendor];
const PENDING_ORDER = [description];

export { ORDER_COLUMNS, PENDING_ORDER, COPY_ORDER_VIEW };
