import { Helmet } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Divider } from '@mui/material';
import { useSelector, useDispatch } from '../../../../redux/store';
import RentalItem from './RentalItem';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { getRentalItems } from '../../../../redux/slices/rentals';

export default function RentalItemsSelect() {
  const dispatch = useDispatch();
  const {
    data: { items },
  } = useSelector((state) => state.rentals);

  const [showEditToCart, setShowEditToCart] = useState(false);
  const [searchParams] = useSearchParams();
  const selectedCategory = searchParams.get('category');

  useEffect(() => {}, [showEditToCart, setShowEditToCart]);

  useEffect(() => {
    dispatch(getRentalItems());
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>Rental Items | Platform Solutions</title>
      </Helmet>

      <div style={{ width: '100%' }}>
        <header>
          <Divider
            flexItem
            sx={{
              height: '2px',
              opacity: 0.39,
              backgroundColor: '#4ca5e7',
              ml: 2,
              mb: 2,
              mt: 2,
            }}
          />
        </header>
      </div>

      {/* Display items based on the selected category */}
      <Scrollbar sx={{ height: 'calc(100vh - 300px)' }}>
        <Grid container spacing={4} sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          {items?.map((item) => (
            <RentalItem key={item.id} item={item} />
          ))}{' '}
        </Grid>
      </Scrollbar>
    </>
  );
}
