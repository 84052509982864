import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import { ScopeGuard } from '../scopes';

PinToggle.propTypes = {
  value: PropTypes.bool,
};

export default function PinToggle({ value }) {
  return (
    <>
      <ScopeGuard scopes={['Facility-Manager']}>
        {value ? (
          <IconButton
            color="primary"
            onClick={(event) => {
              event.removePin = true;
            }}
            sx={{
              opacity: 0.7,
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <ArrowCircleDownOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            onClick={(event) => {
              event.addPin = true;
            }}
            sx={{
              opacity: 0.7,
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <ArrowCircleUpOutlinedIcon />
          </IconButton>
        )}
      </ScopeGuard>
    </>
  );
}
