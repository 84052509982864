import { Avatar, Card, Grid, IconButton, MenuItem, Select, Typography, Box, Chip, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { editTicketData } from '../../../../redux/slices/tickets';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import NewUser from './NewUser';

export default function TicketInfo() {
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');

  const { mainTicket } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};
  const { facilities } = useSelector((state) => state.tickets.data);
  const dispatch = useDispatch();
  const [editFacility, setEditFacility] = useState(false);
  const [participants, setParticipants] = useState([]);
  const {
    data: { users: allUsers },
  } = useSelector((state) => state.tickets);
  const { ticketMessages, users, ticketNumber } =
    useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};

  useEffect(() => {
    const recipients = [];
    users?.forEach((user) => {
      if (!user.userName) {
        const userObj = allUsers.find((u) => u.id === user.userEmail);

        recipients.push({ id: user.userEmail, value: userObj?.value || null });
      } else {
        recipients.push({ id: user.userEmail, value: user.userName });
      }
    });
    setParticipants(recipients);
  }, [allUsers, ticketMessages, users]);

  const handleDelete = (newValue) => {
    editTicket({
      Id: ticketNumber || id,
      value: newValue.filter((user) => user.id).map((user) => user.id),
      updateType: 'Users',
    });
    setParticipants(newValue);
  };
  const [facility, setFacility] = useState(facilities.find((facility) => facility.id === mainTicket?.facilityId));
  const facilityDropdownOptions = facilities
    .filter((f) => f.shortName !== null)
    .map((f) => (
      <MenuItem key={f.id} value={f.id}>
        {f.shortName}
      </MenuItem>
    ));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setFacility(facilities.find((facility) => facility.id === mainTicket?.facilityId));
  }, [mainTicket, facilities]);

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: mainTicket?.ticketNumber || Id, value, updateType }));
    if (response) {
      enqueueSnackbar('Facility updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Facility failed to update', { variant: 'error' });
    }
    return response;
  };

  const handleChange = async (event) => {
    setEditFacility(false);
    const edited = await editTicket({ Id: ticketNumber || id, value: event.target.value, updateType: 'FacilityId' });
    if (edited) setFacility(facilities.find((f) => f.id === event.target.value));
  };

  const handleCancel = () => {
    setEditFacility(false);
  };

  return (
    <Card sx={{ height: '100%', mb: 2, p: 2 }}>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item sx={{ mr: 3 }}>
              <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main }}>
                <Iconify icon="mdi:building" width={30} height={30} />
              </Avatar>
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                {mainTicket?.facility && (
                  <Grid item>
                    {editFacility ? (
                      <>
                        <Select
                          value={facility?.id}
                          onChange={handleChange}
                          displayEmpty
                          sx={{
                            textAlign: 'left',
                            minWidth: 150,
                            borderRadius: '5px',
                            maxHeight: '30px',
                            border: `solid 1px info.main`,
                            marginLeft: '-15px',
                            fontSize: '15px',
                            color: (theme) => theme.palette.primary.main,
                            '& .MuiSelect-icon': {
                              color: (theme) => theme.palette.primary.main,
                            },
                          }}
                        >
                          {facilityDropdownOptions}
                        </Select>
                        <IconButton onClick={() => handleCancel()} sx={{ ml: 0 }} color="secondary">
                          <Iconify icon="material-symbols:cancel" width={15} height={5} sx={{ mr: 0.5 }} />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography variant="subtitle1" color="primary">
                          {facility?.shortName}
                          <ScopeGuard scopes={['Tickets-Edit']} allowAdmin>
                            <IconButton onClick={() => setEditFacility(true)} sx={{ ml: 0 }} color="secondary">
                              <Iconify icon="material-symbols:edit" width={15} height={5} sx={{ mr: 0.5 }} />
                            </IconButton>
                          </ScopeGuard>
                        </Typography>
                      </>
                    )}
                  </Grid>
                )}
                {facility?.company && (
                  <Grid item sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      {facility?.company}
                    </Typography>
                  </Grid>
                )}
                {facility?.phoneNumber && (
                  <Grid item>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                      <Typography variant="subtitle2" display="inline">
                        <Iconify
                          icon="ic:baseline-local-phone"
                          width={16}
                          height={16}
                          color="secondary"
                          sx={{ mr: 0.5 }}
                        />
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        {facility?.phoneNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {facility?.nhaEmail && (
                  <Grid item>
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                      <Typography variant="subtitle2" display="inline">
                        <Iconify icon="ic:outline-email" width={16} height={16} color="secondary" sx={{ mr: 0.5 }} />
                      </Typography>
                      <Typography variant="subtitle2" display="inline">
                        {facility?.nhaEmail}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="space-between" sx={{ maxWidth: '100%' }}>
        <NewUser participants={participants} setParticipants={setParticipants} />
      </Stack>
      <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={4}>
        <Grid item sx={{ mt: 2 }}>
          <Box>
            {participants?.length > 0 && (
              <>
                <Box>
                  {participants?.map((participant, index) => (
                    // console.log(participant);

                    <Box key={participant.id}>
                      <Chip
                        key={participant.id}
                        label={participant.value || participant?.id}
                        onDelete={() => handleDelete(participants.filter((user) => user.id !== participant.id))}
                        avatar={
                          <CustomAvatar
                            name={participant.value || participant?.id}
                            email={participant.value && participant?.id}
                            index={index}
                            key={index}
                            sx={{
                              '&.MuiChip-avatar': {
                                color: '#fff',
                              },
                            }}
                          />
                        }
                        sx={{ mr: 1, mb: 1 }}
                      />
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
