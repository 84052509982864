import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveTicketsData,
  getCompletedTicketsData,
  getTicketMessagesData,
  getTicketMessagesFromOrdersData,
  getTicketDataData,
  postSendTicketMessage,
  postEditTicketData,
  postToggleTicketsComplete,
  getTicketPageData,
  postSetTicketMessagesRead,
} from '../../api/tickets';
import { getUnreadData } from './dashboard';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  ticketIsLoading: false,
  error: null,
  data: {
    activeTickets: [],
    completedTickets: [],
    ticketById: {},
    ticketInfoById: {},
    facilities: [],
    users: [],
    unreadCount: 0,
  },
};

const slice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startTicketLoading(state) {
      state.isLoading = true;
      state.ticketIsLoading = true;
    },
    clearItemData(state) {
      state.data.ticketInfoById = {};
      state.data.ticketById = {};
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getActiveTicketsSuccess(state, action) {
      state.isLoading = false;
      state.data.activeTickets = action.payload;
    },

    getCompletedTicketsSuccess(state, action) {
      state.isLoading = false;
      state.data.completedTickets = action.payload;
    },

    getTicketMessagesSuccess(state, action) {
      state.isLoading = false;
      state.data.ticketById[action.payload.id] = action.payload;
      state.ticketIsLoading = false;
    },

    getTicketDataSuccess(state, action) {
      state.isLoading = false;
      state.data.ticketInfoById[action.payload.id] = action.payload;
    },

    sendTicketMessageSuccess(state, action) {
      state.isLoading = false;
      const { ticketId, messages } = action.payload;
      state.data.ticketById[ticketId].ticketMessages.push(...messages);
    },

    updateSingleTicket(state, action) {
      const urlParams = new URLSearchParams(window.location.search);
      const filter = urlParams.get('filter');
      const updatedTicket = action.payload;
      const { activeTickets, completedTickets } = state.data;

      if (filter === 'active') {
        const updatedActiveTickets = activeTickets.map((t) =>
          t.id === updatedTicket.id ? { ...t, ...updatedTicket } : t
        );
        state.data.activeTickets = updatedActiveTickets;
      } else if (filter === 'completed') {
        const updatedCompletedTickets = completedTickets.map((t) =>
          t.id === updatedTicket.id ? { ...t, ...updatedTicket } : t
        );
        state.data.completedTickets = updatedCompletedTickets;
      }
      state.isLoading = false;
    },

    updateTicketsComplete(state, action) {
      const urlParams = new URLSearchParams(window.location.search);
      const filter = urlParams.get('filter');
      const { activeTickets, completedTickets } = state.data;
      const updatedTicketArray = action.payload;
      const updatedTicketIds = updatedTicketArray.map((t) => t.id);
      if (filter === 'active') {
        const updatedActiveTickets = activeTickets.filter((t) => !updatedTicketIds.includes(t.id));
        state.data.activeTickets = updatedActiveTickets;
      } else if (filter === 'completed') {
        const updatedCompletedTickets = completedTickets.filter((t) => !updatedTicketIds.includes(t.id));
        state.data.completedTickets = updatedCompletedTickets;
      }
      state.isLoading = false;
    },

    updateTicketInfo(state, action) {
      const updatedTicketInfo = action.payload;
      if (updatedTicketInfo.parentTicketId > 0) {
        const updatedSubTickets = state.data.ticketInfoById[updatedTicketInfo.parentTicketId].subtickets.map((t) =>
          t.id === updatedTicketInfo.id ? { ...updatedTicketInfo } : t
        );
        state.data.ticketInfoById[updatedTicketInfo.parentTicketId].subtickets = updatedSubTickets;
      } else {
        state.data.ticketInfoById[updatedTicketInfo.id].mainTicket = updatedTicketInfo;
      }
    },

    markTicketAsReadInRedux(state, action) {
      const ticketId = action.payload;
      const { activeTickets } = state.data;

      const updatedTicketMessages = activeTickets.map((ticket) => {
        if (ticket.id === parseInt(ticketId, 10)) {
          ticket.messageCount = 0;
          return ticket;
        }
        return ticket;
      });
      state.data.activeTickets = updatedTicketMessages;
    },

    updateListFromObj(state, action) {
      const objList = action.payload;
      Object.keys(objList).forEach((key) => {
        state.data[key] = objList[key];
      });
      state.isLoading = false;
      state.data = { ...state.data, ...objList };
    },

    getUnreadTicketCountSuccess(state, action) {
      const { unreadCount } = action.payload;
      state.isLoading = false;
      state.unreadCount = unreadCount;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateSingleTicket } = slice.actions;

export function getActiveTickets() {
  return async (dispatch) => {
    dispatch(slice.actions.clearItemData());
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActiveTicketsData();
      dispatch(slice.actions.getActiveTicketsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCompletedTickets({ startDate, endDate }) {
  startDate = new Date(startDate).toISOString().split('T')[0];
  endDate = new Date(endDate).toISOString().split('T')[0];
  return async (dispatch) => {
    dispatch(slice.actions.clearItemData());
    dispatch(slice.actions.startLoading());
    try {
      const response = await getCompletedTicketsData({ startDate, endDate });
      dispatch(slice.actions.getCompletedTicketsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTicketMessages({ id }) {
  return async (dispatch) => {
    dispatch(slice.actions.startTicketLoading());
    try {
      const response = await getTicketMessagesData(id);
      dispatch(slice.actions.getTicketMessagesSuccess({ ...response.data, id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getTicketMessagesFromOrder(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTicketMessagesFromOrdersData(id);
      dispatch(slice.actions.getTicketMessagesSuccess({ ...response.data, id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getTicketData(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTicketDataData({ id });
      dispatch(slice.actions.getTicketDataSuccess({ ...response.data, id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendTicketMessage({
  message,
  ticketNumber,
  notify = false,
  files,
  users,
  sentBy,
  fullName,
  overrideTicketNumber,
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const placeHolderMsgs = [];

    if (message && message !== '' && message !== '<p><br></p>') {
      placeHolderMsgs.push({
        id: 0,
        ticketNumber,
        note: message,
        file: false,
        attachFileId: 0,
        textRich: true,
        sentBy,
        fullName,
        sentOn: new Date().toISOString(),
        systemMessage: false,
        deleteFlag: false,
        emailMessage: false,
      });
    }

    if (files?.length) {
      placeHolderMsgs.push({
        id: 1,
        ticketNumber,
        note: 'Files Loading...',
        file: false,
        attachFileId: 0,
        textRich: true,
        sentBy,
        fullName,
        sentOn: new Date().toISOString(),
        systemMessage: false,
        deleteFlag: false,
        emailMessage: false,
      });
    }

    dispatch(
      slice.actions.sendTicketMessageSuccess({
        ticketId: overrideTicketNumber || ticketNumber,
        messages: placeHolderMsgs,
      })
    );
    try {
      const response = await postSendTicketMessage({ message, ticketNumber, notify, files, users });
      dispatch(slice.actions.getTicketMessagesSuccess({ ...response.data, id: overrideTicketNumber || ticketNumber }));
      return true;
    } catch (error) {
      console.error({ error });
      dispatch(slice.actions.hasError(error));
    }
    return false;
  };
}

export function editTicketData({ Id, value, updateType }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postEditTicketData({ Id, value, updateType });
      if (response.data) {
        if (updateType === 'Complete' || updateType === 'Users') {
          dispatch(getTicketMessages({ id: Id }));
        } else {
          dispatch(slice.actions.updateTicketInfo(response.data));
        }
        return true;
      }
    } catch (error) {
      console.error({ error });
      dispatch(slice.actions.hasError(error));
      return false;
    }
    return true;
  };
}

export function toggleTicketsComplete({ ticketIds, isComplete }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await postToggleTicketsComplete({ ticketIds, isComplete });
      if (response.data) {
        dispatch(slice.actions.updateTicketsComplete(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFacilitiesAndUsersList(callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getTicketPageData();
      dispatch(slice.actions.updateListFromObj(response.data));
      if (callback) callback(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setUnreadTicketCount(ticketCount) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getUnreadTicketCountSuccess({ unreadCount: ticketCount }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAsRead(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.markTicketAsReadInRedux(id));
      const response = await postSetTicketMessagesRead({ id, markAsUnread: false });
      if (response.status === 200) {
        dispatch(getUnreadData());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
