import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Autocomplete,
  IconButton,
  Button,
  Checkbox,
  Tooltip,
} from '@mui/material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import { useSelector, useDispatch } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { getProductOrderGuide } from '../../../../redux/slices/catalog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { addProductToOrderGuides } from '../../../../api/catalog';
import { AwaitButton } from '../../../../reusable-components/await-button';

OrderHistoryDialog.propTypes = {
  productId: PropTypes.number,
  agoraCategoryId: PropTypes.number,
};

export default function OrderHistoryDialog({ productId, agoraCategoryId }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const {
    data: { productOrderGuide, agoraCategories },
  } = useSelector((state) => state.catalog);
  const isLoading = useSelector((state) => state.catalog.isLoading);
  const [value, setValue] = useState(agoraCategories.filter((item) => agoraCategoryId === item.id)[0]);

  useEffect(() => {
    setData(productOrderGuide.orderGuides);
  }, [productOrderGuide.orderGuides]);

  useEffect(() => {
    if (open) {
      dispatch(getProductOrderGuide(productId, agoraCategoryId));
    }
  }, [agoraCategoryId, dispatch, open, productId]);

  const handleClickOpen = (e) => {
    e.addToOrderGuide = true;
    setOpen(true);
  };

  const handleClose = () => {
    setData([]);
    setOpen(false);
  };

  const handleSave = async () => {
    const selected = data.filter((item) => item.hasItem).map((item) => item.orderGuideId);
    const response = await addProductToOrderGuides(selected, productId, value.id);
    if (response.status === 200) {
      handleClose();
    }
  };

  const handleChangeCategory = (newValue) => {
    setValue(newValue);
    dispatch(getProductOrderGuide(productId, newValue.id));
  };

  const handleCheck = (event, id) => {
    const { checked } = event.target;
    const newOrderGuides = data?.map((item) => {
      if (item.orderGuideId === id) {
        return { ...item, hasItem: checked };
      }
      return item;
    });
    setData(newOrderGuides);
  };
  const handleSelectAllCheck = () => {
    if (!selectAll) {
      const newOrderGuidesWithOutDisabled = data
        ?.filter((item) => !item.facilityRestricted)
        .map((item) => ({ ...item, hasItem: true }));
      setData(newOrderGuidesWithOutDisabled);
      setSelectAll(true);
    } else {
      const newOrderGuidesWithOutDisabled = data
        ?.filter((item) => !item.facilityRestricted)
        .map((item) => ({ ...item, hasItem: false }));
      setData(newOrderGuidesWithOutDisabled);
      setSelectAll(false);
    }
  };

  const ORDER_GUIDE = [
    {
      field: 'facility',
      headerName: 'Facility',
      flex: 2,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      ),
      type: 'customSelect',
    },
    {
      field: 'company',
      headerName: 'Group',
      flex: 2,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            fontSize: '0.7rem',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
          }}
        >
          {params.value}
        </Box>
      ),
      type: 'customSelect',
    },
    {
      field: 'hasItem',
      headerName: 'Facility',
      flex: 1,
      renderCell: (params) => {
        const isActive = params.value;
        return (
          <Checkbox
            checked={isActive}
            onChange={(event) => handleCheck(event, params.row.orderGuideId)}
            disabled={params.row?.facilityRestricted}
          />
        );
      },
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          indeterminate={data.some((item) => item.hasItem) && !selectAll}
          onChange={handleSelectAllCheck}
        />
      ),
    },
  ];
  const CustomLeftToolbar = () => (
    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Add/remove a facility</DialogContentText>
  );

  return (
    <div>
      <Tooltip title="Add To Order Guide" placement="bottom" arrow>
        <IconButton color="info" size="small" onClick={handleClickOpen}>
          <AddToQueueIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Add To Order Guides
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <DialogContentText sx={{ fontWeight: 'bold' }}>Select a category</DialogContentText>{' '}
          <Autocomplete
            sx={{
              mt: 1,
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              '& .MuiFormLabelRoot ': 'none',
            }}
            type="new-password"
            size="small"
            disableClearable
            options={agoraCategories.filter((item) => productOrderGuide.agoraCategories.includes(item.id))}
            autoHighlight
            getOptionLabel={(option) => option?.name}
            getOptionSelected={(option, value) => option.name === value.name}
            value={value}
            onChange={(event, newValue) => {
              handleChangeCategory(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name=""
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            )}
          />
          <Grid item xs={12} sx={{ mt: 1 }}>
            <CustomDataGrid
              gridId="admin-catalog-order-guide"
              boxSX={{ width: '100%', height: 'calc(100vh - 310px)' }}
              sort={[{ field: 'facility', sort: 'asc' }]}
              data={data}
              gridColumns={ORDER_GUIDE}
              id="orderGuideId"
              isModal
              isLoading={isLoading}
              CustomLeftToolbar={CustomLeftToolbar}
              getRowClassName={(params) => (params.row.facilityRestricted ? 'disabled' : '')}
              forceReRender={data}
              sx={{
                '& .disabled': {
                  pointerEvents: 'none',
                  color: 'rgba(0, 0, 0,0.65)',
                  backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton variant="contained" color="primary" size="medium" sx={{ width: 150 }} onClick={handleSave}>
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
