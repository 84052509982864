import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Card, Divider, Tooltip } from '@mui/material';
import { useParams } from 'react-router-dom';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
import { useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

OrderInfo.propTypes = {
  overrideOrderId: PropTypes.string,
};

export default function OrderInfo({ overrideOrderId }) {
  const { id: orderId = '' } = useParams();
  const id = overrideOrderId || orderId;

  const [copied, setCopied] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const { orderById } = useSelector((state) => state.orders);
  const {
    facility = '',
    orderNumber = '',
    items = [],
    submittedBy = { name: '', email: '' },
    onBehalfOf = '',
    approvedBy = '',
    department = '',
    dateAdded = '',
    dateApproved = '',
    requestedDates = [],
    orderComments = [],
    address = {},
  } = orderById[id] || {};
  const { name, email } = submittedBy || { name: '', email: '' };

  return (
    <>
      <Box
        sx={{
          minWidth: '300px',
          width: '100%',
          mb: 2,
        }}
      >
        <Card sx={{ p: 1, width: '100%' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            onClick={handleClick}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'bold' }} color="primary">
              {facility}
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', cursor: 'pointer' }} color="primary">
              <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
            </Typography>
          </Grid>
          {open && (
            <Box>
              <Box>
                <Divider sx={{ width: '100%', my: 1, backgroundColor: '#000' }} />

                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ pb: 1.5 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.info.main }}>
                    Order #{orderNumber || id}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.primary.main }}>
                    {fCurrency(
                      items?.filter((item) => item.approved).reduce((acc, item) => acc + item.price * item.quantity, 0)
                    )}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex', // Use flexbox
                      alignItems: 'flex-bottom', // Vertically align items to center
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        py: 0.5,
                      }}
                      component="span"
                      variant="body2"
                    >
                      Submitted by:
                    </Typography>
                    <Tooltip title={copied ? `Copied!` : `Copy ${email} to clipboard`}>
                      <Typography
                        component="span"
                        sx={{
                          ml: 0.5,
                          textDecoration: 'underline',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        variant="body2"
                        onClick={() => {
                          navigator.clipboard.writeText(email);
                          setCopied(true);
                        }}
                      >
                        {name} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography sx={{ py: 0.5 }} variant="body2">
                    On behalf of: {onBehalfOf}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-bottom',
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        py: 0.5,
                      }}
                      component="span"
                      variant="body2"
                    >
                      Approved by:
                    </Typography>
                    <Tooltip title={copied ? `Copied!` : `Copy ${approvedBy} to clipboard`}>
                      <Typography
                        component="span"
                        sx={{
                          ml: 0.5,
                          textDecoration: 'underline',
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        variant="body2"
                        onClick={() => {
                          navigator.clipboard.writeText(approvedBy);
                          setCopied(true);
                        }}
                      >
                        {approvedBy} <Iconify icon={'ph:copy'} sx={{ pt: 0.5 }} />
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Department: {department}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Ordered: {fMonthDayTimeYear(dateAdded)}
                  </Typography>
                  <Typography variant="body2" sx={{ py: 0.5 }}>
                    Approved: {fMonthDayTimeYear(dateApproved)}
                  </Typography>
                  {requestedDates?.length > 0 && (
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', py: 0.5 }}>
                        Requested Dates
                      </Typography>
                      {requestedDates.map((req) => (
                        <Typography key={req.id} variant="caption" color="textSecondary" display="block">
                          {fMonthDayTimeYear(req.date)}
                        </Typography>
                      ))}
                    </>
                  )}
                  {orderComments?.length > 0 && (
                    <>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', py: 0.5 }}>
                        Comments
                      </Typography>
                      {orderComments.map((comment) => (
                        <Typography key={comment.id} variant="caption" color="textSecondary" display="block">
                          ({comment.vendor}): {comment.comment}
                        </Typography>
                      ))}
                    </>
                  )}
                  <Divider sx={{ width: '100%', mb: 1 }} />{' '}
                  <Box>
                    Address
                    {address?.street && (
                      <Typography variant="caption" color="textSecondary" display="block">
                        {address?.street}
                      </Typography>
                    )}
                    {(address?.city || address?.state || address?.zip) && (
                      <Typography variant="caption" color="textSecondary" display="block">
                        {`${address?.city || ''} ${address?.state || ''} ${address?.zip || ''}`.trim()}
                      </Typography>
                    )}
                    {address?.phoneNumber && (
                      <Typography variant="caption" color="textSecondary" display="block">
                        {address?.phoneNumber}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Box>
            </Box>
          )}
        </Card>
      </Box>
    </>
  );
}
