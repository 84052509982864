import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { postVendorForm } from '../../../../api/warehouse-vendors';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

WarehouseVendorModal.propTypes = {
  edit: PropTypes.bool,
  row: PropTypes.object,
  data: PropTypes.array,
  fetchData: PropTypes.func,
};

export default function WarehouseVendorModal({ edit, row, data, fetchData }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    id: null,
    name: null,
    globalVendor: false,
  });

  const isDuplicateRule = useMemo(
    () => !edit && !!data.find((ruleVal) => ruleVal.name?.toLowerCase().trim() === value.name?.toLowerCase().trim()),
    [data, value]
  );

  const handleSave = async () => {
    const response = await postVendorForm(value, edit ? row?.id : null);
    if (response.data) {
      enqueueSnackbar('Warehouse vendor updated successfully', { variant: 'success' });
      setOpen(false);
    } else {
      enqueueSnackbar('Failed to update warehouse vendor', { variant: 'error' });
    }
    fetchData();
  };

  const handleClickOpen = () => {
    setValue({
      id: edit ? row.id : null,
      name: edit ? row.name : '',
      globalVendor: edit ? row.globalVendor : false,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setValue({
      id: null,
      name: '',
      globalVendor: false,
    });
    setOpen(false);
  };

  return (
    <>
      {edit ? (
        <Tooltip title="Edit">
          <IconButton edge="end" color="secondary" onClick={handleClickOpen} sx={{ mr: 0.5 }}>
            {' '}
            <EditIcon />{' '}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
          size="small"
          onClick={handleClickOpen}
          startIcon={<Iconify icon="eva:plus-outline" />}
        >
          Add Vendor
        </Button>
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {edit ? 'Edit' : 'Add'} Warehouse Vendor
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  {isDuplicateRule && (
                    <Alert severity="error" sx={{ width: '100%' }}>
                      This vendor already exists
                    </Alert>
                  )}
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={value.name}
                    onChange={(e) => setValue({ ...value, name: e.target.value })}
                    sx={{
                      mt: 1,
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabel-root ': { display: 'none' },
                    }}
                  />
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Box>
                  <Typography variant="subtitle1" sx={{ mb: 1 }}>
                    <Checkbox
                      checked={value.globalVendor}
                      onChange={(event, val) => setValue({ ...value, globalVendor: val })}
                    />{' '}
                    Unique accounts
                    <Box sx={{ color: 'info.main', display: 'inline' }}>
                      <Tooltip title="Makes sure no site has a duplicate account">
                        <Iconify
                          icon="material-symbols:info-outline"
                          width={18}
                          height={18}
                          style={{ paddingTop: '5px', paddingLeft: '2px' }}
                        />
                      </Tooltip>
                    </Box>
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            sx={{ width: 150 }}
            onClick={handleSave}
            disabled={!value.name || isDuplicateRule}
          >
            {edit ? 'Save' : 'Add Vendor'}
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
