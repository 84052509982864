import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {
  cartCheckWithData,
  updatePrice,
  closeErrorDialog,
  removeItemFromErrorList,
} from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { CART_CHECK } from '../../../../reusable-components/datagrid/pendingOrderColumns';
import Iconify from '../../../../reusable-components/iconify';
import { setItemsPPD } from '../../../../api/orders';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import ActionButtons from './ActionButtons';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { AwaitButton } from '../../../../reusable-components/await-button';

CartCheck.propTypes = {
  data: PropTypes.array,
};

export default function CartCheck({ data }) {
  const { id = '' } = useParams();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { orderById, cartDialog, cartCheckItemsById, openErrorDialog, firstTime } = useSelector(
    (state) => state.orders
  );
  const { items = [], facilityId } = orderById[id] || {};

  // const [isDialogOpen, setIsDialogOpen] = useState(cartDialog);
  const [isLoading, setIsLoading] = useState(false);
  // const [firstTime, setFirstTime] = useState(true);

  // const openDialog = () => {
  //   setIsDialogOpen(true);
  // };

  const hasError = (items) => items?.some((item) => item.errors?.length > 0);

  const cartCheckFunction = async () => {
    setIsLoading(true);
    const cartCheck = await dispatch(cartCheckWithData(id, facilityId, data));

    const { items, vendorErrors } = cartCheck;

    if (hasError(items)) {
      enqueueSnackbar('There are errors in the order', { variant: 'error' });
    } else {
      enqueueSnackbar('Order checked successfully', { variant: 'success' });
    }

    if (vendorErrors?.length > 0) {
      vendorErrors.forEach((error) => {
        enqueueSnackbar(error, { variant: 'error', autoHideDuration: null });
      });
    }

    setIsLoading(false);
  };

  const handleMakeItemsPPD = async () => {
    const items = updatedCartItems
      ?.filter((i) => i.errors.some((i) => i.includes('PPD mismatch')))
      .map((i) => ({ id: i.id, itemId: i.itemId, isPPD: true, facilityId }));

    const res = await setItemsPPD(items);

    if (res.status === 200) {
      items.forEach((item) => dispatch(removeItemFromErrorList(id, item.id)));
    }
  };

  const [updatedCartItems, setUpdatedCartItems] = useState([]);

  useEffect(() => {
    if (firstTime && data?.length > 0) {
      cartCheckFunction();
    }
  }, [firstTime, data]);

  useEffect(() => {
    let hasErrors = false;
    const mergedItems = items
      ?.map((item) => {
        const cartCheckItem = cartCheckItemsById[id]?.find((cci) => cci.id === item.id);
        let merged = {
          ...item,
        };
        if (cartCheckItem) {
          if (cartCheckItem.errors && cartCheckItem.errors?.length > 0) {
            hasErrors = true;
            merged = {
              ...merged,
              id: item.id,
              errors: cartCheckItem.errors,
              itemDirectReplacements: cartCheckItem.itemDirectReplacements || [],
              itemSubstitutes: cartCheckItem.itemSubstitutes || [],
              updatedPrice: cartCheckItem.price > 0 ? cartCheckItem.price : item.price,
            };
          }
        }
        return merged;
      })
      .filter((mergedItem) => {
        const originalItem = items?.find((item) => item.id === mergedItem.id);
        return (
          (mergedItem.errors && mergedItem.errors?.length > 0) ||
          (originalItem && mergedItem.price !== originalItem.price)
        );
      });

    setUpdatedCartItems(mergedItems);
  }, [items, cartCheckItemsById]);

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        {updatedCartItems.filter((item) => item.price > 0 && item.updatedPrice !== item.price)?.length > 0 && (
          <AwaitButton
            variant="contained"
            size="small"
            onClick={async () => {
              dispatch(
                updatePrice(
                  id,
                  facilityId,
                  updatedCartItems.filter((item) => item.price > 0 && item.updatedPrice !== item.price)
                )
              );
            }}
          >
            Update All Prices
          </AwaitButton>
        )}
        {updatedCartItems?.filter((i) => i.errors.some((i) => i.includes('PPD mismatch')))?.length > 0 && (
          <AwaitButton variant="contained" size="small" onClick={handleMakeItemsPPD}>
            Make All PPD
          </AwaitButton>
        )}
      </Grid>
    ),
    [dispatch, facilityId, updatedCartItems]
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => <ActionButtons row={params.row} />,
    id: 'actions',
    className: 'actions',
  };

  return (
    <>
      <AwaitButton
        sx={{ mr: 1 }}
        color={updatedCartItems?.length === 0 && cartDialog ? 'success' : 'secondary'}
        size="small"
        variant="outlined"
        startIcon={<Iconify icon="ph:list-magnifying-glass" />}
        onClick={cartCheckFunction}
        loading={isLoading}
      >
        Check Order
      </AwaitButton>

      <Dialog
        open={openErrorDialog /*&& updatedCartItems?.length !== 0*/}
        onClose={() => dispatch(closeErrorDialog())}
        maxWidth="full"
        fullWidth
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          Item Errors
          <IconButton
            onClick={() => dispatch(closeErrorDialog())}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>

        <>
          <DialogContent>
            <Box sx={{ width: '100%', minHeight: '36vh' }}>
              <CustomDataGrid
                gridId="orders-cart-check"
                boxSX={{ height: 'calc(100vh - 340px)' }}
                data={updatedCartItems}
                gridColumns={[...CART_CHECK, actions]}
                sort={[{ field: 'facility', sort: 'asc' }]}
                CustomLeftToolbar={CustomLeftToolbar}
                isModal
              />
            </Box>
          </DialogContent>{' '}
        </>
      </Dialog>
    </>
  );
}
