import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  DialogContentText,
  Autocomplete,
  Box,
  Tooltip,
  Typography,
  IconButton,
} from '@mui/material';
import CustomDataGrid from '../../../../../reusable-components/datagrid/CustomDataGrid';

import { useSelector, useDispatch } from '../../../../../redux/store';
import { CATALOG_ITEM } from '../../../../../reusable-components/datagrid/catalogColumns';
import Iconify from '../../../../../reusable-components/iconify';
import { ConfirmDialog } from '../../../../../reusable-components/confirm-dialog';
import { updateProductFormData, splitProductData } from '../../../../../api/warehouse';
import EditItemDialog from '../../catalog/item/EditItemDialog';
import NewItemDialog from '../../catalog/NewItemDialog';
import { getProductDetails } from '../../../../../redux/slices/warehouse';
import { ScopeGuard } from '../../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

WarehouseItemView.propTypes = {
  row: PropTypes.object,
};

export default function WarehouseItemView({ row }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete = async (rowId, data) => {
    const removedArray = data.filter((row) => row.id !== rowId);
    const additionalCategories = categories.slice(1);
    const response = await updateProductFormData({
      categories: additionalCategories,
      allItems: removedArray,
      productId: productDetails.productId,
      description,
      notes,
      agoraCategoryId: categories[0].agoraCategoryId,
      agoraSubcategoryId: categories[0].agoraSubcategoryId,
    });

    if (response.status === 200) {
      if (row !== undefined) {
        dispatch(getProductDetails(row.productId, false));
      } else dispatch(getProductDetails(id, false));
      enqueueSnackbar('Item deleted successfully', { variant: 'success' });
    }
  };

  const [data, setData] = useState([]);
  const [editParent, setEditParent] = useState(false);
  const [categories, setCategories] = useState([
    {
      agoraCategoryId: null,
      agoraSubcategoryId: null,
    },
  ]);
  const [splitItems, setSplitItems] = useState(false);
  const [selected, setSelected] = useState([]);
  const { orderById } = useSelector((state) => state.orders);
  const { unsavedChanges } = orderById[id] || {};

  const {
    data: { productDetails, agoraCategories, agoraSubcategories },
  } = useSelector((state) => state.warehouse);

  const isLoading = useSelector((state) => state.warehouse.isLoading);

  useEffect(() => {
    if (productDetails.productDetails?.length > 0) {
      setData(productDetails.productDetails);
      setCategories([
        ...[
          {
            agoraCategoryId: productDetails?.agoraCategoryId,
            agoraSubcategoryId: productDetails?.agoraSubcategoryId,
          },
        ],
      ]);
      setDescription(productDetails?.description);
      setNotes(productDetails?.notes);
    }
  }, [productDetails]);

  const [description, setDescription] = useState(productDetails?.description || '');
  const [notes, setNotes] = useState(productDetails?.notes || '');

  const ActionButtons = (params) => (
    <>
      <ScopeGuard superAdminOnly>
        <EditItemDialog
          pageId={id}
          data={data}
          id={params.row.id}
          vendorItemId={params.row.vendorItemId}
          productDetails={productDetails}
          productId={productDetails.productId}
          description={productDetails.description}
          agoraCategoryId={productDetails.agoraCategoryId}
          agoraSubcategoryId={productDetails.agoraSubcategoryId}
          notes={productDetails.notes}
          categories={categories}
          editParent={editParent}
          row={row}
          isWarehouseItem
        />
        <ConfirmDialog
          icon="delete-outline"
          title="Delete"
          color="error"
          content={`Are you sure you want to delete this item?`}
          actionButton="Delete"
          action={() => handleDelete(params.row.id, data)}
          disabled={editParent || unsavedChanges || row?.vendorItemId === params.row?.vendorItemId}
        />
      </ScopeGuard>
    </>
  );

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  const saveProduct = async () => {
    const additionalCategories = categories.slice(1);
    const response = await updateProductFormData({
      categories: additionalCategories,
      allItems: data,
      productId: productDetails?.productId,
      description,
      notes,
      agoraCategoryId: categories[0].agoraCategoryId,
      agoraSubcategoryId: categories[0].agoraSubcategoryId,
    });
    if (response.status === 200) {
      if (row !== undefined) {
        dispatch(getProductDetails(row.productId, false));
      } else dispatch(getProductDetails(id, false));
      enqueueSnackbar('Item updated successfully', { variant: 'success' });
      setEditParent(false);
    }
  };

  const splitData = async () => {
    const response = await splitProductData({
      productId: productDetails?.productId,
      itemsToSplit: selected,
    });
    if (response.status === 200) {
      dispatch(getProductDetails(id, false));
      enqueueSnackbar('Item split successfully', { variant: 'success' });
      setSelected([]);
      setSplitItems(false);
    }
  };

  const customToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          {splitItems && (
            <>
              <AwaitButton
                variant="contained"
                sx={{ ml: 1, mr: 1 }}
                size="small"
                onClick={splitData}
                disabled={selected.length < 1 || selected.length === data.length}
              >
                Confirm Split
              </AwaitButton>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setSelected([]);
                  setSplitItems(false);
                }}
              >
                Cancel
              </Button>{' '}
            </>
          )}
          {!splitItems && (
            <AwaitButton
              variant="outlined"
              sx={{ ml: 1 }}
              size="small"
              onClick={() => setSplitItems(true)}
              startIcon={<Iconify icon={'lucide:split'} />}
            >
              Split items
            </AwaitButton>
          )}
        </Box>
      </Grid>
    ),
    [splitItems, selected.length, data.length]
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button
              variant="text"
              onClick={() => {
                navigate(`/dashboard/super-admin/item-warehouse`);
              }}
              startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
              sx={{
                color: '#454F5B',
                fontWeight: 'bold',
              }}
            >
              Back to warehouse
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Item info #{productDetails?.productId}
          </Typography>
          <ScopeGuard superAdminOnly>
            <Tooltip title={'Edit details'}>
              <IconButton
                color="secondary"
                disabled={unsavedChanges}
                onClick={(e) => {
                  e.edit = true;
                  setEditParent(true);
                }}
              >
                <Iconify icon={'material-symbols:edit'} />
              </IconButton>
            </Tooltip>
          </ScopeGuard>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Description *</DialogContentText>{' '}
                  <TextField
                    size="small"
                    disabled={!editParent}
                    label=""
                    fullWidth
                    multiline
                    value={description || ''}
                    variant="outlined"
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              {categories?.map((category, index) => (
                <Grid
                  key={`${index}-${category.agoraCategoryId}-${category.agoraSubcategoryId}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item xs={6}>
                    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
                      {index === 0 ? 'Main category * ' : index === 1 ? 'Additional categories *' : ' '}
                    </DialogContentText>
                    <Autocomplete
                      size="small"
                      disabled={!editParent}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiFormLabelRoot ': 'none',
                        width: '90%',
                      }}
                      type="new-password"
                      options={agoraCategories || []}
                      autoHighlight
                      getOptionLabel={(option) => option?.name}
                      value={agoraCategories?.find((cat) => cat.id === categories[index].agoraCategoryId)}
                      onChange={(event, newValue) => {
                        setCategories((prevCategories) => {
                          const newCategories = [...prevCategories];
                          newCategories[index].agoraCategoryId = newValue?.id;
                          return newCategories;
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label=""
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={editParent ? 5 : 6}>
                    <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
                      {index === 0 ? 'Main subcategory *' : index === 1 ? 'Additional subcategories *' : ' '}
                    </DialogContentText>
                    <Autocomplete
                      size="small"
                      disabled={!categories[index].agoraCategoryId || !editParent}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        '& .MuiFormLabelRoot ': 'none',
                        width: editParent ? '90%' : '100%',
                      }}
                      type="new-password"
                      options={
                        agoraSubcategories.filter((cat) => cat.mainCategoryId === categories[index].agoraCategoryId) ||
                        []
                      }
                      autoHighlight
                      getOptionLabel={(option) => option?.name}
                      value={agoraSubcategories.find((cat) => cat.id === categories[index].agoraSubcategoryId)}
                      onChange={(event, newValue) => {
                        setCategories((prevCategories) => {
                          const newCategories = [...prevCategories];
                          newCategories[index].agoraSubcategoryId = newValue?.id;
                          return newCategories;
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label=""
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {editParent && (
                    <Grid
                      item
                      xs={1}
                      sx={{
                        mt: 4,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          if (categories.length === 1) {
                            setCategories([{ agoraCategoryId: null, agoraSubcategoryId: null }]);
                          } else {
                            setCategories((prevCategories) => {
                              const newCategories = [...prevCategories];
                              newCategories?.splice(index, 1);
                              return newCategories;
                            });
                          }
                        }}
                      >
                        <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            </>
          </Grid>

          <Grid item xs={5}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Notes</DialogContentText>
            <TextField
              disabled={!editParent}
              size="small"
              label=""
              value={notes || ''}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              fullWidth
              multiline
              minRows={categories.length === 1 ? 5 : categories.length * 4}
              variant="outlined"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </Grid>
        </Grid>
        {editParent ? (
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
            {' '}
            <Button
              variant="outlined"
              color="error"
              size="medium"
              sx={{ width: 150, mr: 2 }}
              onClick={() => {
                setCategories([
                  {
                    agoraCategoryId: productDetails?.agoraCategoryId,
                    agoraSubcategoryId: productDetails?.agoraSubcategoryId,
                  },
                ]);
                setDescription(productDetails?.description);
                setNotes(productDetails?.notes);
                setEditParent(false);
              }}
            >
              Cancel
            </Button>
            <AwaitButton
              variant="contained"
              color="primary"
              size="medium"
              sx={{ width: 150 }}
              onClick={saveProduct}
              disabled={
                !description || categories.some((category) => !category.agoraCategoryId || !category.agoraSubcategoryId)
              }
            >
              Save
            </AwaitButton>
          </Grid>
        ) : (
          <ScopeGuard superAdminOnly>
            <NewItemDialog
              parentInfo={{
                description,
                notes,
                categories,
                id: productDetails?.productId,
              }}
              row={row}
              data={data}
              isWarehouseItem
            />
          </ScopeGuard>
        )}
      </Grid>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-warehouse-item-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          data={data}
          gridColumns={[...CATALOG_ITEM, actions]}
          CustomLeftToolbar={customToolbar}
          sort={[{ field: 'perUnit', sort: 'asc' }]}
          getRowId={(row) => row.id}
          checkboxSelection={splitItems}
          onRowSelectionModelChange={(newSelection) => {
            setSelected(newSelection);
          }}
          rowSelectionModel={selected}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
