import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { Document, Page } from 'react-pdf';
import { Box, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useDropzone } from 'react-dropzone';
import Iconify from '../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { addInvoiceFile, getInvoiceFiles } from '../../../../redux/slices/invoicing';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { HOST_API_URL } from '../../../../config-global';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { generateInvoicePdfFromData, deleteInvoiceFile } from '../../../../api/invoicing';

InvoicePdfAttachment.propTypes = {
  setSelectedFileId: PropTypes.func,
};
export default function InvoicePdfAttachment({ setSelectedFileId }) {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const {
    data: { invoiceFiles },
  } = useSelector((state) => state.invoicing);
  const { enqueueSnackbar } = useSnackbar();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0] === undefined) return;
      addFile(acceptedFiles[0]);
    },
  });

  const addFile = async (file) => {
    const response = await dispatch(addInvoiceFile(id, file));
    if (response === 'success') {
      enqueueSnackbar('File added successfully', { variant: 'success' });
      setSelectedFileId(0);
    }
  };
  const handleClickAttachment = async (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    if (file.fileExtension !== 'pdf') {
      window.open(`${HOST_API_URL}Api/Invoices/DownloadInvoiceFile?id=${file.id}&mainFile=false`, '_blank');
    } else setSelectedFileId(file.id);
  };
  const generatePDF = async (e) => {
    e.stopPropagation();
    setIsSubmitting(true);
    try {
      const response = await generateInvoicePdfFromData(id);
      if (response.status === 200) {
        enqueueSnackbar('PDF generated successfully', { variant: 'success' });
        await dispatch(getInvoiceFiles(id));
      }
    } catch (error) {
      enqueueSnackbar('PDF generation failed', { variant: 'error' });
    }

    setIsSubmitting(false);
  };

  const handleDelete = async (fileId) => {
    const response = await deleteInvoiceFile(fileId);

    if (response.status === 200) {
      enqueueSnackbar('File deleted successfully', { variant: 'success' });
      await dispatch(getInvoiceFiles(id));
    }
  };
  return (
    <>
      <>
        <Box
          sx={mainBoxStyle}
          {...getRootProps()}
          onClick={() => {
            if (hasEditAccess) openDropzone();
          }}
        >
          <input {...getInputProps()} />
          <Grid container justifyContent="space-between" alignItems={'center'}>
            <Grid item>
              <Typography variant="h8" color="info.main">
                Invoice attachments
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <LoadingButton
                    color="secondary"
                    type="submit"
                    loading={isSubmitting}
                    onClick={generatePDF}
                    disabled={!hasEditAccess}
                  >
                    <Tooltip title="Generate PDF invoice">
                      <PictureAsPdfIcon />
                    </Tooltip>
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <IconButton color="secondary" onClick={() => {}} disabled={!hasEditAccess}>
                    <Tooltip title="Drag and drop">
                      <Iconify icon={'material-symbols:upload'} />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Scrollbar sx={{ maxHeight: '350px' }}>
            <Box sx={{}}>
              <>
                {invoiceFiles?.map((file, index) => (
                  <Tooltip
                    title={file.fileExtension !== 'pdf' ? `${file.fileExtension} file` : file.fileName}
                    key={index}
                  >
                    <Box
                      sx={borderBoxStyle}
                      onClick={(e) => {
                        handleClickAttachment(e, file);
                      }}
                    >
                      <Grid container alignItems={'center'}>
                        <Grid item xs={2}>
                          <Typography color="#4ca5e7" fontSize={'1.5rem'}>
                            <Icon icon="ph:file-duotone" />
                          </Typography>
                        </Grid>
                        <Grid item sx={{ maxWidth: '200px', overflow: 'hidden' }} xs={8}>
                          <Typography color="text.secondary">
                            {file.fileExtension !== 'pdf' ? `${file.fileExtension} file` : file.fileName}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ maxWidth: '200px', overflow: 'hidden' }} xs={2}>
                          <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
                            <ConfirmDialog
                              icon="delete-outline"
                              title="Delete"
                              color="error"
                              content={`Are you sure you want to delete this invoice file?`}
                              actionButton="Delete"
                              action={(e) => {
                                handleDelete(file.id);
                              }}
                            />
                          </ScopeGuard>
                        </Grid>
                      </Grid>
                    </Box>
                  </Tooltip>
                ))}
              </>
            </Box>
          </Scrollbar>
        </Box>
      </>
    </>
  );
}

const mainBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  padding: '25px',
  overflow: 'auto',
  border: 'solid 1px #c1c9d0',
  //maxHeight: '450px',
};
const borderBoxStyle = {
  borderRadius: '6px',
  border: 'solid 1px #4ca5e7',
  p: '1px 20px',
  margin: '20px 0',
  cursor: 'pointer',
};
