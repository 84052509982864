import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@mui/material';
import { AwaitButton } from '../../../../../reusable-components/await-button';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { clearOrderState } from '../../../../../redux/slices/orders';
import { SubmitOrderApi } from '../../../../../api/cart';
import { useSnackbar } from '../../../../../reusable-components/snackbar';

SubmitOrder.propTypes = {
  cartItems: PropTypes.array,
  itemErrorCount: PropTypes.number,
  thePersonFor: PropTypes.string,
  requestedDate: PropTypes.object,
};

export default function SubmitOrder({ cartItems, itemErrorCount, thePersonFor, requestedDate }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { facility, personFor },
  } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmitOrder = async () => {
    dispatch(clearOrderState());
    setLoading(true);

    const response = await SubmitOrderApi({
      facilityId: facility.value,
      personFor: thePersonFor,
      items: cartItems,
      requestedDate,
    });

    if (response.status === 200) {
      if (response.data.success) navigate(response.data.message)
      else {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        setLoading(false);
      }
    }
  };

  return (
    <>
      <AwaitButton
        variant="contained"
        size="small"
        fullWidth
        onClick={handleSubmitOrder}
        loading={loading}
        disabled={!thePersonFor}
      >
        <Badge badgeContent={itemErrorCount} color="error">
          Submit Order
        </Badge>
      </AwaitButton>
    </>
  );
}
