import { Autocomplete, Box, DialogContentText, FormControl, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { changeInvoiceDataFromRedux } from '../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../redux/store';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import Iconify from '../../../reusable-components/iconify';
import { fCurrency } from '../../../utils/formatNumber';
import AddEditInvoiceLine from './AddEditInvoiceLine';
import { AwaitButton } from '../../../reusable-components/await-button';

SubmitInvoiceView.propTypes = {
  invoiceItemRows: PropTypes.array,
  setInvoiceItemRows: PropTypes.func,
  errors: PropTypes.string,
  row: PropTypes.object,
  invoiceId: PropTypes.number,
};

export default function SubmitInvoiceView({ invoiceItemRows, setInvoiceItemRows, errors, row, invoiceId }) {
  const dispatch = useDispatch();
  const {
    data: { invoiceItemDataById, invoiceData },
  } = useSelector((state) => state.invoicing);
  const [addDialog, setAddDialog] = useState(false);

  const { invoiceItems, invoice, bookingType } = invoiceItemDataById[row?.id] || {};

  useEffect(() => {
    if (invoiceItems) {
      const groupedData = {};
      // Step 1: Group items by glCode, calculating transaction amounts with the new formula.
      invoiceItems.forEach((item) => {
        // Calculate the transaction amount for the current item.
        const transactionAmount = item.quantity * item.price + item.taxAmount;

        if (!groupedData[item.agoraSubcategory.glCode]) {
          groupedData[item.agoraSubcategory.glCode] = { ...item, transactionAmount: 0, shipping: 0 };
        }
        // Sum the calculated transaction amount for items with the same glCode.
        groupedData[item.agoraSubcategory.glCode].transactionAmount += transactionAmount;
      });

      // Step 2: Calculate shipping for each glCode group.
      const shippingAmount = shipping || 0;
      const totalRows = Object.keys(groupedData).length;

      // Convert shippingAmount to cents to avoid floating point arithmetic issues.
      const shippingAmountInCents = Math.round(shippingAmount * 100);
      let remainder = shippingAmountInCents % totalRows;
      const baseShippingAmountInCents = (shippingAmountInCents - remainder) / totalRows;

      Object.keys(groupedData).forEach((glCode, index) => {
        let shippingForThisItemInCents = baseShippingAmountInCents;
        if (remainder > 0) {
          shippingForThisItemInCents += 1;
          remainder -= 1; // Decrease remainder until it's fully allocated.
        }
        // Convert shippingForThisItemInCents back to dollars and assign it to the group.
        groupedData[glCode].shipping = shippingForThisItemInCents / 100;

        // Calculate transactionTotal for each grouped item.
        groupedData[glCode].transactionTotal = groupedData[glCode].transactionAmount + groupedData[glCode].shipping;
      });

      // Set the processed data, converting the groupedData object back into an array.
      setInvoiceItemRows(Object.values(groupedData));
    }
  }, [invoiceItems]);

  const removeTransaction = (id) => () => {
    setInvoiceItemRows(invoiceItemRows.filter((item) => item.id !== id));
  };

  const { vendors, facilities } = invoiceData;

  useEffect(() => {
    if (invoice?.date) {
      dispatch(changeInvoiceDataFromRedux({ field: 'glPostingDate', value: date, invoiceId }));
    }
  }, [invoice?.date]);

  if (!invoice) return null;
  const disabledBookingType = bookingType === 'Stellar' || bookingType === 'NHC';

  const {
    vendorInvoiceId,
    date,
    description,
    referenceNumber,
    transactionDate,
    dueDate,
    glPostingDate = date,
    total,
    shipping,
  } = invoice;

  const actualTotal = invoiceItemRows.reduce((acc, item) => acc + item.transactionTotal, 0);

  const PROCESS_INVOICE = [
    {
      field: 'glCode',
      headerName: 'GL Account',
      flex: 1,
      type: 'customText',
      renderCell: (params) => params.row.agoraSubcategory.glCode,
    },
    {
      field: 'transactionAmount',
      headerName: 'Transaction Amount',
      flex: 1,
      valueGetter: (params) =>
        params.row.transactionAmount
          ? fCurrency(params.row.transactionAmount)
          : fCurrency(params.row.quantity * params.row.price + params.row.taxAmount),
    },
    {
      field: 'shipping',
      headerName: 'Shipping Amount*',
      flex: 1,
      valueGetter: (params) => fCurrency(params.row.shipping),
    },
    {
      field: 'transactionTotal',
      headerName: 'Total Transaction Amount*',
      flex: 1,
      valueGetter: (params) => fCurrency(params.row.transactionTotal),
    },
    {
      field: 'memo',
      headerName: 'Memo',
      flex: 1.5,
      renderCell: (params) => (
        <Box
          component="div"
          sx={{
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            fontSize: '0.75rem',
          }}
        >
          {params.row.memo}
        </Box>
      ),
      type: 'customSelect',
      valueGetter: (params) => params.row.memo,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <>
            <AddEditInvoiceLine
              row={params.row}
              isEdit
              setInvoiceItemRows={setInvoiceItemRows}
              disabled={disabledBookingType}
            />
            <ConfirmDialog
              icon="delete-outline"
              title="Delete"
              color="error"
              content={`Are you sure you want to delete this transaction?`}
              actionButton="Delete"
              action={removeTransaction(params.row.id)}
            />
          </>
        );
      },
      id: 'actions',
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>PO #</DialogContentText>{' '}
                  <TextField
                    size="small"
                    label=""
                    fullWidth
                    value={invoice.invoiceId || ''}
                    onChange={(e) => {
                      dispatch(changeInvoiceDataFromRedux({ field: 'invoiceId', value: e.target.value, invoiceId }));
                    }}
                    variant="outlined"
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>

          <Grid item xs={6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: errors ? 0 : 2 }}>Bill Number</DialogContentText>
            {errors && (
              <DialogContentText
                sx={{
                  color: 'error.main',
                  fontStyle: 'italic',
                  fontSize: '0.75rem',
                }}
              >
                Confirm bill isn't a duplicate before editing bill #
              </DialogContentText>
            )}
            <TextField
              size="small"
              label=""
              value={vendorInvoiceId || ''}
              onChange={(e) => {
                dispatch(changeInvoiceDataFromRedux({ field: 'vendorInvoiceId', value: e.target.value, invoiceId }));
              }}
              fullWidth
              variant="outlined"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText
                    sx={{ fontWeight: 'bold', mt: 2, color: !invoice?.facilityId ? 'error.main' : '' }}
                    error={!invoice?.facilityId}
                  >
                    Facility
                  </DialogContentText>{' '}
                  <Autocomplete
                    size="small"
                    sx={{
                      width: '100%',
                      '& legend': { display: 'none' },
                      '& fieldset': { top: 0 },
                      '& .MuiFormLabelRoot ': 'none',
                    }}
                    type="new-password"
                    options={facilities}
                    autoHighlight
                    getOptionLabel={(option) => option?.label}
                    value={facilities?.find((vendor) => vendor?.value === invoice.facilityId) || null}
                    onChange={(event, value) => {
                      if (value)
                        dispatch(changeInvoiceDataFromRedux({ field: 'facilityId', value: value.value, invoiceId }));
                      if (value)
                        dispatch(changeInvoiceDataFromRedux({ field: 'facility', value: value.label, invoiceId }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        variant="outlined"
                        error={!invoice?.facilityId}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>

          <Grid item xs={6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Vendor</DialogContentText>
            <Autocomplete
              size="small"
              disabled={disabledBookingType}
              sx={{
                width: '100%',
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
                '& .MuiFormLabelRoot ': 'none',
              }}
              type="new-password"
              options={vendors}
              autoHighlight
              getOptionLabel={(option) => option?.label}
              value={vendors?.find((vendor) => vendor?.value === invoice.vendorId) || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Transaction Date</DialogContentText>{' '}
                  <Box>
                    {' '}
                    <FormControl fullWidth>
                      <DatePicker
                        slotProps={{ textField: { size: 'small' } }}
                        label=""
                        value={new Date(transactionDate || date) || null}
                        onChange={(e) => {
                          dispatch(changeInvoiceDataFromRedux({ field: 'transactionDate', value: e, invoiceId }));
                        }}
                      />
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            </>
          </Grid>

          <Grid item xs={4}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>GL Posting Date</DialogContentText>
            <Box>
              <FormControl fullWidth>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label=""
                  value={new Date(glPostingDate || date) || null}
                  onChange={(e) => {
                    dispatch(changeInvoiceDataFromRedux({ field: 'glPostingDate', value: e, invoiceId }));
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Due Date</DialogContentText>
            <Box>
              {' '}
              <FormControl fullWidth>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label=""
                  value={dueDate ? new Date(dueDate) : null}
                  onChange={(e) => {
                    if (e) dispatch(changeInvoiceDataFromRedux({ field: 'dueDate', value: e, invoiceId }));
                  }}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                  <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>
                    Description
                    <Tooltip title="The description is for the booking vendor and is not saved.">
                      <Box sx={{ color: 'info.main', display: 'inline' }}>
                        <Iconify icon="material-symbols:info-outline" width={14} height={14} />
                      </Box>
                    </Tooltip>
                  </DialogContentText>{' '}
                  <TextField
                    size="small"
                    label=""
                    fullWidth
                    multiline
                    value={description || ''}
                    variant="outlined"
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onChange={(e) => {
                      dispatch(changeInvoiceDataFromRedux({ field: 'description', value: e.target.value, invoiceId }));
                    }}
                  />
                </Grid>
              </Grid>
            </>
          </Grid>

          <Grid item xs={6}>
            <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Reference Number</DialogContentText>
            <TextField
              size="small"
              label=""
              value={referenceNumber || ''}
              onChange={(e) => {
                dispatch(changeInvoiceDataFromRedux({ field: 'referenceNumber', value: e.target.value, invoiceId }));
              }}
              fullWidth
              multiline
              variant="outlined"
              inputProps={{
                autoComplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: 1, mt: 2 }}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" spacing={2}>
              <Grid item>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    Shipping
                  </Typography>
                  <Typography variant="subtitle1">{fCurrency(shipping)}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    Vendor Total
                  </Typography>
                  <Typography variant="subtitle1">{fCurrency(total)}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box sx={{ textAlign: 'right' }}>
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    Invoice Total
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                    {fCurrency(actualTotal)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Tooltip title={disabledBookingType ? `You can't add a GL for ${bookingType} invoices` : 'Add'}>
            <span>
              {' '}
              {/*span is here so the tooltip can work on a disabled button*/}
              <AwaitButton
                variant="contained"
                size="small"
                disabled={disabledBookingType}
                onClick={() => setAddDialog(true)}
                startIcon={<Iconify icon="eva:plus-outline" />}
                color="secondary"
              >
                Add Transaction
              </AwaitButton>
            </span>
          </Tooltip>
        </Grid>

        <Box sx={{ width: '100%', height: 'calc(100vh - 600px)' }}>
          <DataGridPro
            getRowHeight={() => 'auto'}
            rows={invoiceItemRows || []}
            columns={PROCESS_INVOICE}
            getRowId={(row) => row.id}
            disableSelectionOnClick
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableColumnPinning
            disableColumnReorder
          />
        </Box>
      </Grid>

      {addDialog && (
        <AddEditInvoiceLine isEdit={false} setAddDialog={setAddDialog} setInvoiceItemRows={setInvoiceItemRows} />
      )}
    </>
  );
}
