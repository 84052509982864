import { useCallback, useState, useEffect } from 'react';
import { Grid, Typography, Button, IconButton, Tooltip } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify';
import { getVendors, getCategories, getVendorItems } from '../../../../api/vendor-catalog';
import {
  VENDOR_CATALOG,
  VENDOR_CATALOG_ITEMS,
  FULL_CATALOG,
} from '../../../../reusable-components/datagrid/vendorCatalog';
import { HOST_API_URL } from '../../../../config-global';

export default function VendorCatalogView() {
  const [vendors, setVendors] = useState([]);
  const [currentVendor, setCurrentVendor] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);

  const getVendorsData = async () => {
    const response = await getVendors();
    if (response.status === 200) {
      setVendors(response.data);
    }
  };

  const getCategoriesData = async () => {
    const response = await getCategories(currentVendor.id);
    if (response.status === 200) {
      setCategories(response.data);
    }
  };

  const getItemsData = async () => {
    // const response = await getVendorItems(vendorId, categoryId);
    const response = await getVendorItems(currentVendor.id, categoryId.id);
    if (response.status === 200) {
      setItems(response.data);
    }
  };

  useEffect(() => {
    getVendorsData();
  }, []);

  useEffect(() => {
    if (currentVendor) {
      getCategoriesData();
    }
  }, [currentVendor]);

  useEffect(() => {
    if (categoryId) {
      getItemsData();
    }
  }, [categoryId]);

  const customToolbar2 = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: 2 }}>
          <IconButton onClick={() => setCurrentVendor('')} sx={{ mb: -0.4, mr: 0.5 }}>
            <Iconify icon="bx:arrow-back" width={20} height={20} />
          </IconButton>

          <Typography variant="h6">{currentVendor.name}</Typography>

          <Tooltip title="This may take some time depending on the catalog size" arrow>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mx: 2 }}
              size="small"
              onClick={() => {
                window.open(
                  `${HOST_API_URL}Api/VendorCatalog/DownloadVendorCatalog?vendorId-${currentVendor.id}&categoryId=0`,
                  '_blank'
                );
              }}
              startIcon={<Iconify icon="file-icons:microsoft-excel" />}
            >
              Download Full Catalog
            </Button>
          </Tooltip>
        </Grid>
      </>
    ),
    [currentVendor.name]
  );

  const customToolbar3 = useCallback(
    () => (
      // <IconButton onClick={() => setCategoryId('')}>
      //   <Iconify icon="twemoji:back-arrow" width={40} height={40} />
      // </IconButton>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" sx={{ mt: 1 }}>
        <IconButton onClick={() => setCategoryId('')} sx={{ mb: -0.4, mr: 0.5 }}>
          <Iconify icon="bx:arrow-back" width={20} height={20} />
        </IconButton>

        <Typography variant="h6">
          {currentVendor.name} | {categoryId.name}
        </Typography>

        <Tooltip title="This may take some time depending on the catalog size" arrow>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ mx: 2 }}
            size="small"
            onClick={() => {
              window.open(
                `${HOST_API_URL}Api/VendorCatalog/DownloadVendorCatalog?vendorId-${currentVendor.id}&categoryId=${categoryId}`,
                '_blank'
              );
            }}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download Catalog
          </Button>
        </Tooltip>
      </Grid>
    ),
    [categoryId.name, currentVendor.name]
  );

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ mt: 1 }}>
        {categoryId ? (
          //step 3
          <CustomDataGrid
            gridId="admin-vendor-catalog-view-step-3"
            boxSX={{ width: '100%', height: 'calc(100vh - 300px)' }}
            data={items}
            gridColumns={FULL_CATALOG}
            CustomLeftToolbar={customToolbar3}
          />
        ) : currentVendor ? (
          //step 1
          <CustomDataGrid
            gridId="admin-vendor-catalog-view-items-step-1"
            boxSX={{ width: '100%', height: 'calc(100vh - 300px)' }}
            data={categories}
            gridColumns={VENDOR_CATALOG_ITEMS}
            onRowClick={(params) => {
              setCategoryId(params.row);
            }}
            CustomLeftToolbar={customToolbar2}
          />
        ) : (
          //step 1
          <CustomDataGrid
            gridId="admin-vendor-catalog-view-step-1"
            boxSX={{ width: '100%', height: 'calc(100vh - 300px)' }}
            data={vendors}
            gridColumns={VENDOR_CATALOG}
            onRowClick={(params) => {
              setCurrentVendor(params.row);
            }}
          />
        )}
      </Grid>
    </>
  );
}
