import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TextField, Typography, Grid, Button, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { addItemToAnOrderInRedux, removeItemFromErrorList } from '../../../../redux/slices/orders';
import { fCurrency } from '../../../../utils/formatNumber';
import { cartCheckApiForOneItem } from '../../../../api/cart';
import { AwaitButton } from '../../../../reusable-components/await-button';

ReplaceItemQuantityField.propTypes = {
  // product: PropTypes.object,
  row: PropTypes.object,
  errorRow: PropTypes.object,
};

export default function ReplaceItemQuantityField({ row, errorRow }) {
  const { id = '' } = useParams();
  const dispatch = useDispatch();
  const { orderById } = useSelector((state) => state.orders);
  const { facilityId } = orderById[id] || {};
  const [quantity, setQuantity] = useState(errorRow.quantity);
  const [errorArray, setErrorArray] = useState(null);

  const [loading, setLoading] = useState(false);
  const [checkedItem, setCheckedItem] = useState(false);

  const actionReplace = () => {
    dispatch(
      addItemToAnOrderInRedux({
        ...row,
        orderId: id,
        approved: true,
        unsavedId: 0,
        itemId: row.id,
        quantity,
        vendorId: errorRow.vendorId,
        vendor: errorRow.vendor,
      })
    );
    dispatch(
      addItemToAnOrderInRedux({
        ...errorRow,
        orderId: id,
        approved: false,
        errors: [],
        itemDirectReplacements: [],
        itemSubstitutes: [],
      })
    );

    dispatch(removeItemFromErrorList(id, errorRow.id));
  };

  const cartCheckFunction = async () => {
    setLoading(true);
    setErrorArray([]);
    const response = await cartCheckApiForOneItem(facilityId, [
      {
        vendorId: row.vendorId,
        quantity,
        price: row.price,
        vendorItemId: row?.sku || row.vendorItemId,
        uomType: row.uomType,
      },
    ]);
    setCheckedItem(true);

    if (response.data?.items[0]?.errors?.length > 0) {
      setErrorArray(response.data?.items[0]?.errors);
    }
    setLoading(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <TextField
            size="small"
            value={quantity || ''}
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (!Number.isNaN(newValue) && newValue >= 0) {
                setQuantity(newValue);
                setCheckedItem(false);
              }
            }}
            sx={{ maxWidth: '70px', width: '70px' }}
          />
        </Grid>
        <Grid item sx={{ minWidth: '70px' }}>
          <Typography variant="subtitle2"> {fCurrency(quantity * row.price)}</Typography>
        </Grid>
        <Grid item>
          <AwaitButton
            color="secondary"
            variant="outlined"
            endIcon={
              loading ? (
                <Iconify icon="svg-spinners:270-ring-with-bg" />
              ) : (
                <Iconify icon="ph:list-magnifying-glass-bold" />
              )
            }
            onClick={cartCheckFunction}
            size="small"
          >
            Check
          </AwaitButton>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              errorArray?.length > 0
                ? errorArray.map((error) => (
                    <div key={error}>
                      {error}
                      <br />
                    </div>
                  ))
                : ''
            }
          >
            <Button
              variant="outlined"
              color={!checkedItem ? 'secondary' : errorArray?.length ? 'error' : 'primary'}
              onClick={actionReplace}
              size="small"
            >
              Select
            </Button>
          </Tooltip>
        </Grid>
        {row.uomType !== errorRow.uomType || row.uomAmount !== errorRow.uomAmount ? (
          <Grid item>
            <Tooltip
              title={
                <div>
                  <div>
                    UOM is different then current item {errorRow.uomAmount}/{errorRow.uomType}
                  </div>

                  {row.uomAmount !== errorRow.uomAmount && (
                    <div>
                      <div>
                        <span>{errorRow.uomAmount}</span> <span>=={'>'}</span> <span>{row.uomAmount}</span>
                      </div>
                    </div>
                  )}
                  {row.uomType !== errorRow.uomType && (
                    <div>
                      <span>{errorRow.uomType}</span> <span>=={'>'}</span> <span>{row.uomType}</span>
                    </div>
                  )}
                </div>
              }
            >
              <Iconify icon="material-symbols:info-outline" color="info" sx={{ mt: 1 }} />
            </Tooltip>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
}
