import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Button, LinearProgress, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fCurrency } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import { useSelector } from '../../../../redux/store';

// ----------------------------------------------------------------------

export default function OrderTotal() {
  const { id = '' } = useParams();
  const [vendorTotals, setVendorTotals] = useState([]);
  const { orderById, tempOrderListById = [] } = useSelector((state) => state.orders);
  const { vendorMinimums, items = [] } = orderById[id] || {};

  useEffect(() => {
    if (items?.length > 0 || vendorMinimums?.length > 0) {
      const allItems = [...(items || []), ...(tempOrderListById[id] || [])];
      const cartTotals = allItems
        .filter((item) => item.approved) // filter only approved items
        .reduce((acc, item) => {
          const { vendorId, price, quantity, vendor } = item;
          const vendorMinimum = vendorMinimums?.find((min) => min.vendorId === vendorId);
          const total = price * quantity;
          const vendorTotal = acc.find((total) => total.vendorId === vendorId);
          if (vendorTotal) {
            vendorTotal.total += total;
          } else {
            acc.push({ vendorId, vendor, total, minimum: vendorMinimum?.minimum ?? 0 });
          }
          return acc;
        }, []);

      setVendorTotals(cartTotals);
    }
  }, [items, vendorMinimums, id]);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        sx={{ mr: 1 }}
        color="secondary"
        size="small"
        variant="outlined"
        endIcon={<Iconify icon="mdi:chevron-down" />}
        startIcon={
          vendorTotals.filter((vt) => vt.total < vt.minimum).length > 0 && <Iconify icon="jam:alert" color="error" />
        }
        onClick={handleClick}
      >
        Order total{' '}
        {fCurrency(
          [...items, ...(tempOrderListById[id] || [])]
            ?.filter((item) => item.approved && item.deleted !== true)
            .reduce((acc, item) => acc + item.price * item.quantity, 0)
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {vendorTotals.length > 0 ? (
          vendorTotals.map((vt, index) => (
            <MenuItem onClick={handleClose} key={index}>
              <Box
                sx={{
                  flexGrow: 1,
                  position: 'relative',
                  mb: 1,
                }}
              >
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
                    {vt.vendor}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ textAlign: 'left', ml: 15 }}>
                    Vendor minimum {fCurrency(vt.minimum)}
                  </Typography>
                </Grid>
                <StyledBox onClick={handleClose}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    sx={{ color: vt.total >= vt.minimum || vt.minimum <= 0 ? 'white' : 'inherit' }}
                  >
                    {vt.minimum > 0 ? `Total ${fCurrency(vt.total)} ` : `Total ${fCurrency(vt.total)}`}
                  </Typography>
                </StyledBox>

                <StyledLinearProgress
                  variant="determinate"
                  value={
                    (vt.total / (vt.minimum || vt.total)) * 100 > 100
                      ? 100
                      : (vt.total / (vt.minimum || vt.total)) * 100
                  }
                  color={vt.minimum > 0 ? (vt.total >= vt.minimum ? 'primary' : 'error') : 'primary'}
                />
              </Box>
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>
            <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
              There are no vendor minimums
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: '4px',
  height: 23,
  border: `1px solid ${theme.palette.primary.light}`,
  backgroundColor: '#fff',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiLinearProgress-barColorError': {
    backgroundColor: theme.palette.error.main,
  },
  '& .MuiLinearProgress-barColorWarning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  maxHeight: 23, // borderlinearprogress root.height
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '8px',
  '& span': {
    width: '100%',
  },
  '&:hover': {
    cursor: 'pointer',
  },
  borderRadius: '4px',
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));
