import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Menu, MenuItem, Button, Grid, Tabs, Tab } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useSelector, useDispatch } from '../../../redux/store';
import { fNumber } from '../../../utils/formatNumber';
import { toggleTicketsComplete } from '../../../redux/slices/tickets';
import { CustomQuickFilter } from '../../../reusable-components/datagrid/custom-filters';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';
import { useScopeCheck } from '../../../reusable-components/scopes';

CustomToolbar.propTypes = {
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.string,
  isFiltered: PropTypes.func,
  selected: PropTypes.array,
  setQuickFilter: PropTypes.func,
  setFilters: PropTypes.func,
  setActiveFilter: PropTypes.func,
};
export default function CustomToolbar({
  setFilters,
  setShowSelection,
  showSelection,
  isFiltered,
  selected,
  setQuickFilter = () => {},
  setActiveFilter = () => {},
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { unreadCount } = useSelector((state) => state.tickets);
  const hasPinnedGridAccess = useScopeCheck(['Facility-Manager']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setShowSelection(e.target.textContent);
    setAnchorEl(null);
  };
  const handleCancel = () => {
    setShowSelection('');
    setAnchorEl(null);
  };
  const toggleComplete = (value) => {
    setShowSelection('');
    setAnchorEl(null);
    dispatch(toggleTicketsComplete({ ticketIds: selected, isComplete: value }));
  };

  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        setShowSelection('');
        setAnchorEl(null);
        navigate(`/dashboard/tickets?filter=active`);
        break;
      case 1:
        setShowSelection('');
        setAnchorEl(null);
        navigate(
          `/dashboard/tickets?filter=completed&start=${new Date(
            new Date().setDate(new Date().getDate() - 29)
          ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: isFiltered('active') ? 0 : -1 }}
      >
        <Box>
          <Tabs value={isFiltered('active') ? 0 : 1} onChange={handleTabChange}>
            <Tab label={`Open Tickets${unreadCount > 0 ? ` (${fNumber(unreadCount)})` : ''}`} />
            <Tab label="Completed Tickets" />
            <Box sx={{ mx: 2, mt: 2 }}>
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="outlined"
                size="small"
                startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {searchParams.get('filter') === 'active' ? (
                  <MenuItem onClick={handleClose}>Mark as complete</MenuItem>
                ) : (
                  <MenuItem onClick={handleClose}>Mark as incomplete</MenuItem>
                )}
              </Menu>
            </Box>
            {!isFiltered('active') && (
              <Grid container alignItems="flex-end" justifyContent="space-between">
                <Grid item>
                  <DateRangePickerSelect />
                </Grid>
              </Grid>
            )}
          </Tabs>
        </Box>
        {isFiltered('active') && hasPinnedGridAccess && (
          <Grid item>
            <CustomQuickFilter
              setActiveFilter={setActiveFilter}
              setQuickFilter={setQuickFilter}
              setFilters={setFilters}
              applyQuickFilter
              advancedFilter
              advancedSearchBase="tickets"
            />
          </Grid>
        )}

        <Grid item xs={12} sx={{ mb: 1.5 }}>
          {showSelection && (
            <>
              <Button
                variant="contained"
                sx={{ mr: 1 }}
                onClick={() => toggleComplete(showSelection === 'Mark as complete')}
              >
                {showSelection}
              </Button>
              <Button sx={{ mr: 1 }} onClick={handleCancel}>
                Cancel
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
