import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { InvoicingView, StatementView, InvoicingVendors } from '../../components/dashboard/invoicing';
import { useDispatch, useSelector } from '../../redux/store';
import CustomToolbar from '../../components/dashboard/invoicing/CustomToolbar';
import { ReportsView } from '../../components/dashboard/reports';
import {
  getPendingInvoicing,
  getPreviousInvoicing,
  getStatementsInvoicing,
  getInvoiceDataFromRedux,
  getVendors,
  getUsersInInvoiceRole,
  getManualInvoicing,
} from '../../redux/slices/invoicing';
import { useScopeCheck } from '../../reusable-components/scopes';

export default function Invoicing() {
  const isSuperAdmin = useScopeCheck(['Super-Admin']);

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [startDate, setStartDate] = useState(searchParams.get('start'));
  const [endDate, setEndDate] = useState(searchParams.get('end'));
  useEffect(() => {
    setFilter(searchParams.get('filter'));
    setStartDate(searchParams.get('start'));
    setEndDate(searchParams.get('end'));
  }, [searchParams]);
  const {
    data: { invoiceData },
  } = useSelector((state) => state.invoicing);
  const getInvoiceFormData = async () => {
    dispatch(getInvoiceDataFromRedux());
  };

  useEffect(() => {
    if ((invoiceData?.length === 0 && filter === 'pending') || filter === 'previous' || filter === 'pendingFilter') {
      getInvoiceFormData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (filter === 'pending') {
      dispatch(getPendingInvoicing({ pending: true }));
    } else if (filter === 'previous') {
      dispatch(getPreviousInvoicing({ startDate, endDate, pending: false }));
    } else if (filter === 'new' && isSuperAdmin) {
      dispatch(getManualInvoicing());
    } else if (filter === 'incomplete') {
      dispatch(getStatementsInvoicing());
    } else if (filter === 'complete') {
      dispatch(getStatementsInvoicing());
    } else if (filter === 'vendors') {
      dispatch(getVendors());
    }
    dispatch(getUsersInInvoiceRole());
  }, [filter, dispatch, startDate, endDate]);
  const [activeRows, setActiveRows] = useState([]);
  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === searchParams.get('filter');
    return <CustomToolbar isFiltered={isFiltered} activeRows={activeRows} />;
  }, [searchParams, activeRows]);

  return (
    <>
      <Helmet>
        <title>Invoicing | Platform Solutions</title>
      </Helmet>
      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {filter === 'previous' ||
          filter === 'pending' ||
          filter === 'pendingFilter' ||
          (filter === 'new' && isSuperAdmin) ? (
            <InvoicingView CustomToolbar={customToolbar} setActiveRows={setActiveRows} />
          ) : filter === 'incomplete' || filter === 'complete' ? (
            <StatementView CustomToolbar={customToolbar} setActiveRows={setActiveRows} />
          ) : filter === 'reports' ? (
            <ReportsView CustomToolbar={customToolbar} invoiceView />
          ) : (
            <InvoicingVendors CustomToolbar={customToolbar} setActiveRows={setActiveRows} />
          )}
        </Grid>
      </Grid>
    </>
  );
}
