import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { postDeleteWarehouseVendor } from '../../../../api/warehouse-vendors';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { WAREHOUSE_VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import WarehouseVendorFileModal from './WarehouseVendorFilesModal';
import WarehouseVendorModal from './WarehouseVendorModal';

WarehouseVendors.propTypes = {
  vendorData: PropTypes.array,
  isLoading: PropTypes.bool,
  fetchVendorData: PropTypes.func,
};

export default function WarehouseVendors({ vendorData, fetchVendorData, isLoading }) {
  const { enqueueSnackbar } = useSnackbar();

  const deleteWarehouseVendor = async (id) => {
    try {
      const response = await postDeleteWarehouseVendor(id);
      if (response.status === 200) {
        enqueueSnackbar('Warehouse vendor deleted successfully', { variant: 'success' });
        fetchVendorData();
      } else {
        enqueueSnackbar('Problem deleting Warehouse vendor', { variant: 'error' });
      }
    } catch (error) {
      console.error('Error deleting warehouse vendor:', error);
    }
  };

  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <WarehouseVendorFileModal edit row={params.row} vendorData={vendorData} fetchData={fetchVendorData} />
          <WarehouseVendorModal edit row={params.row} data={vendorData} fetchData={fetchVendorData} />

          <ConfirmDialog
            icon="delete-outline"
            title="Delete"
            color="error"
            content={`Are you sure you want to delete ${params.row.name}?`}
            actionButton="Delete"
            action={() => deleteWarehouseVendor(id)}
          />
        </>
      );
    },
    id: 'actions',
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <Box>
          <WarehouseVendorModal data={vendorData} fetchData={fetchVendorData} />
        </Box>
      </Grid>
    ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorData]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-warehouse-vendors"
          boxSX={{ height: 'calc(100vh - 340px)' }}
          data={vendorData}
          gridColumns={[...WAREHOUSE_VENDORS, actionButtons]}
          CustomLeftToolbar={CustomLeftToolbar}
          pinnedColumns={{ right: ['actions'] }}
          sort={[{ field: 'name', sort: 'asc' }]}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
