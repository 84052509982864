// src/utils/axios.js
import axios from 'axios';
import { getCancelTokenSource, removeCancelTokenSource, generateRequestKey } from './cancelToken';

const axiosInstance = axios.create({
  baseURL: 'Api/',
});

axiosInstance.interceptors.request.use((config) => {
  const requestKey = generateRequestKey(config);
  const cancelTokenSource = getCancelTokenSource(requestKey);
  config.cancelToken = cancelTokenSource.token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    const requestKey = generateRequestKey(response.config);
    removeCancelTokenSource(requestKey);
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('Request canceled, duplicate call', error.message);
    } else if (error.response?.status === 401) {
      window.location.href = `/auth/login?returnUrl=${window.location.href}`;
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
