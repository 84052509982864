import PropTypes from 'prop-types';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, LinearProgress, Box } from '@mui/material';
import { fCurrency, fPercent } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import { HistoryModal } from './index';

BudgetBar.propTypes = {
  budget: PropTypes.number,
  total: PropTypes.number,
  history: PropTypes.number,
  budgetDate: PropTypes.any,
  includeUnapproved: PropTypes.bool,
  catId: PropTypes.number,
  facId: PropTypes.any,
  subcategoryId: PropTypes.number,
};

export default function BudgetBar({ budget, total, history, includeUnapproved, budgetDate, catId, facId, subcategoryId }) {
  const [openModal, setOpenModal] = useState(false);
  const handleClickOpen = () => {
    setOpenModal(true);
  };
  const determineColor = (value) => {
    if (value > 76) {
      return 'error';
    }
    if (value > 50) {
      return 'warning';
    }
    return 'primary';
  };
  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      <StyledBox
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleClickOpen}
      >
        <Typography variant="subtitle2" component="span" sx={{ color: history > budget ? 'white' : 'inherit' }}>
          History{' '}
          {history - budget > 0
            ? `${fCurrency(history || 0.0)} (${fPercent(Math.floor((history / budget) * 100))}) | ${fCurrency(
                history - budget
              )} over budget `
            : history === 0
            ? '$0.00'
            : `${fCurrency(history || 0.0)} (${fPercent(Math.floor((history / budget) * 100))})`}
        </Typography>
        <Iconify icon={'fluent:window-new-20-regular'} />
      </StyledBox>
      <StyledTopLinearProgress
        variant="determinate"
        value={(history / budget) * 100 > 100 ? 100 : (history / budget) * 100}
        color={determineColor((history / budget) * 100)}
      />
      <HistoryModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        includeUnapproved={includeUnapproved}
        budgetDate={budgetDate}
        catId={catId}
        facId={facId}
        subcategoryId={subcategoryId}
      />
      {/* BOTTOM BOX */}
      <StyledBox>
        <Typography variant="subtitle2" component="span" sx={{ color: total > budget ? 'white' : 'inherit' }}>
          {total - budget > 0
            ? `${fCurrency(total || 0.0)} (${fPercent(Math.floor((total / budget) * 100))}) | ${fCurrency(
                total - budget
              )} over budget`
            : total === 0
            ? '$0.00'
            : `${fCurrency(total || 0.0)} (${fPercent(Math.floor((total / budget) * 100))})`}
        </Typography>
      </StyledBox>
      <StyledBottomLinearProgress
        variant="determinate"
        value={(total / budget) * 100 > 100 ? 100 : (total / budget) * 100}
        color={determineColor((total / budget) * 100)}
      />
    </Box>
  );
}
const StyledTopLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: '4px 4px 0 0',
  height: 30,
  border: `2px solid ${theme.palette.primary.light}`,
  borderBottom: `0.5px solid ${theme.palette.primary.lighter}`,
  backgroundColor: '#fff',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.primary.lighter,
  },
  '& .MuiLinearProgress-barColorError': {
    backgroundColor: theme.palette.error.lighter,
  },
  '& .MuiLinearProgress-barColorWarning': {
    backgroundColor: theme.palette.warning.lighter, // Change this to whatever color you desire for warning
  },
}));
const StyledBottomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: ' 0 0 4px 4px',
  height: 30,
  border: `2px solid ${theme.palette.primary.light}`,
  borderTop: 'none',
  backgroundColor: '#fff',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiLinearProgress-barColorError': {
    backgroundColor: theme.palette.error.main,
  },
  '& .MuiLinearProgress-barColorWarning': {
    backgroundColor: theme.palette.warning.main,
  },
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
  maxHeight: 30, // borderlinearprogress root.height
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '8px',
  '& span': {
    width: '100%',
  },
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));
