import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Typography } from '@mui/material';
import { fCurrency, fifthDecimalCurrency } from '../../utils/formatNumber';
import { cloudinaryThumbnail, cloudinaryFull } from '../../utils/formatCloudinary';
import Image from '../image';
import Lightbox from '../lightbox';
import UOMDropdownForCart from './UOMDropdownForCart';
import QuantityCartField from './QuantityCartField';
import { stringToColor } from '../../utils/cssStyles';

export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(pictures?.[0])}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
export function RenderImageFromUrls({ params }) {
  RenderImageFromUrls.propTypes = {
    params: PropTypes.object,
  };
  const { pictureUrls } = params.row;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={pictureUrls?.[0]}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictureUrls.map((url) => ({ src: url }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
const category = {
  field: 'category',
  headerName: 'Category',
  type: 'customSelect',
  valueGetter: (params) => params.row.agoraCategory?.name || '',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 115,
  renderCell: (params) => (
    <>
      <Tooltip title={params.row.agoraCategory || ''}>
        <Typography
          // color={stringToColor(params.row.agoraCategory)}
          variant="subtitle2"
          sx={{
            border: 1,
            width: '100px',
            borderRadius: '3px',
            position: 'relative',
            display: 'flex', // Use flexbox
            alignItems: 'center',
            my: '4px',
            py: '6px',
            px: 0.5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis for overflow
              whiteSpace: 'nowrap', // Prevent wrapping
              pr: 2,
            }}
          >
            {params.row.agoraCategory || ''}
          </Box>
        </Typography>
      </Tooltip>
    </>
  ),
};
const subCategory = {
  field: 'subCategory',
  headerName: 'Subcategory',
  type: 'customSelect',
  valueGetter: (params) => params.row.agoraSubcategory || '',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 140,
  renderCell: (params) => (
    <>
      <Tooltip title={params.row.agoraSubcategory || ''}>
        <Typography
          // color={stringToColor(params.row.agoraCategory?.name)}
          variant="subtitle2"
          sx={{
            border: 1,
            width: '125px',
            borderRadius: '3px',
            position: 'relative',
            display: 'flex', // Use flexbox
            alignItems: 'center',
            my: '4px',
            py: '6px',
            px: 0.5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis for overflow
              whiteSpace: 'nowrap', // Prevent wrapping
              pr: 2,
            }}
          >
            {params.row.agoraSubcategory || ''}
          </Box>
        </Typography>
      </Tooltip>
    </>
  ),
};
const picture = {
  field: 'picture',
  headerName: 'Image',
  width: 80,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};

const description = {
  field: 'description',
  headerName: 'Description',
  width: 160,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const error = {
  field: 'error',
  headerName: 'Error',
  width: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        color: 'red',
      }}
    >
      {params.row.errors?.map((error) => (
        <div key={error}>{error}</div>
      ))}
    </Box>
  ),
  type: 'customText',
};

const price = {
  field: 'price',
  headerName: 'Price',
  width: 120,
  renderCell: (params) =>
    params.row.productDetailUOMs?.length > 0 ? (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.8rem',
        }}
      >
        {params.row.productDetailUOMs?.map((obj, index) => (
          <div key={index}>{`${fCurrency(obj.price)} - ${obj.uomAmount}/${obj.uomType}`}</div>
        ))}
      </Box>
    ) : (
      fCurrency(params.row.price)
    ),
  valueGetter: (params) => params.row.price,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};
const perItem = {
  field: 'perItem',
  headerName: 'Per Item',
  width: 100,
  // renderCell: (params) =>
  //   fifthDecimalCurrency(params.row.uomAmount === 0 ? params.row.price / 1 : params.row.price / params.row.uomAmount),
  renderCell: (params) =>
    params.row.productDetailUOMs?.length > 0 ? (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.8rem',
        }}
      >
        {params.row.productDetailUOMs?.map((obj, index) => (
          <div key={index}>{`${fifthDecimalCurrency(obj.price / obj.uomAmount)}`}</div>
        ))}
      </Box>
    ) : (
      fifthDecimalCurrency(params.row.uomAmount === 0 ? params.row.price / 1 : params.row.price / params.row.uomAmount)
    ),
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};
const changeUom = {
  field: 'change_uom',
  headerName: 'UOM',
  renderCell: (params) => {
    const uomAmount = params.row.uomAmount || '';
    const uomOrType = params.row.uomType || params.row.uom || '';

    return params.row.productDetailUOMs?.length > 0 ? (
      <UOMDropdownForCart row={params.row} />
    ) : (
      `${uomAmount}/${uomOrType}`
    );
  },
  valueGetter: (params) => params.row.uomType,
  width: 90,
  type: 'customSelect',
};
const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) => {
    const uomAmount = params.row.uomAmount || '';
    const uomOrType = params.row.uomType || params.row.uom || '';

    return `${uomAmount}/${uomOrType} `;
  },
  valueGetter: (params) => params.row.uomType,
  width: 90,
  type: 'customSelect',
};

const total = {
  field: 'total',
  headerName: 'Total',
  width: 80,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.row.quantity * params.row.price),
  valueGetter: (params) => params.row.quantity * params.row.price,
};

const vendorName = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 100,
  // renderCell: (params) => params.row.vendorName,
  renderCell: (params) => (
    <Tooltip
      title={params.row.errors?.map((error) => (
        <div key={error}>{error}</div>
      ))}
    >
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          color: params.row.errors?.length > 0 && 'error.main',
        }}
      >
        {params.row.vendor}
      </Box>
    </Tooltip>
  ),
  type: 'customText',
};

const sku = {
  field: 'sku',
  headerName: 'SKU',
  width: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        '&:hover': {
          cursor: 'copy',
        },
      }}
      onClick={() => {
        navigator.clipboard.writeText(params.value);
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.sku,
  type: 'customText',
};

const psNum = {
  field: 'productId',
  headerName: 'PS#',
  width: 90,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        '&:hover': {
          cursor: 'copy',
        },
      }}
      onClick={() => {
        navigator.clipboard.writeText(params.value);
      }}
    >
      PS{params.value}
    </Box>
  ),
  valueGetter: (params) => `PS${params.row.productId}`,
  type: 'customText',
};

const quantity = {
  field: 'quantity',
  headerName: 'Qty',
  width: 120,
  renderCell: (params) => <QuantityCartField row={params.row} />,
};
const quantityPlain = {
  field: 'quantity',
  headerName: 'Qty',
  width: 70,
};

const CART = [
  picture,
  description,
  subCategory,
  psNum,
  sku,
  perItem,
  price,
  changeUom,
  error,
  vendorName,
  quantity,
  total,
];
const CHECKOUT = [
  picture,
  description,
  category,
  subCategory,
  psNum,
  sku,
  perItem,
  price,
  uom,
  error,
  vendorName,
  quantityPlain,
  total,
];
const CHECKOUT_SINGLE_CART = [
  picture,
  description,
  subCategory,
  psNum,
  sku,
  perItem,
  price,
  uom,
  error,
  vendorName,
  quantityPlain,
  total,
];

export { CART, CHECKOUT, CHECKOUT_SINGLE_CART };
