import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Typography,
  Button,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Iconify from '../../../../reusable-components/iconify';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { invoiceGLItemsForm, getInvoice, changeInvoiceField } from '../../../../redux/slices/invoicing';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fCurrency, twoDecimal } from '../../../../utils/formatNumber';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';

GlItemsForm.propTypes = {
  expandGl: PropTypes.bool,
  setExpandGl: PropTypes.func,
  glRows: PropTypes.array,
  setHighlightMissingGls: PropTypes.func,
};
export default function GlItemsForm({ expandGl, setExpandGl, glRows, setHighlightMissingGls }) {
  const { id } = useParams();

  const dispatch = useDispatch();
  const {
    data: {
      invoiceData: { categories, subcategories },
      invoiceItemData,
      invoiceGLItems,
    },
  } = useSelector((state) => state.invoicing);
  const isLoading = useSelector((state) => state.invoicing.isLoading);
  const error = useSelector((state) => state.invoicing.error);
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);
  const { enqueueSnackbar } = useSnackbar();
  const [editGlItems, setEditGlItems] = useState(false);
  const [unsavedData, setUnsavedData] = useState(null);
  const [itemsMissingGls, setItemsMissingGls] = useState(false);
  const {
    control,
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    mode: 'onChange',
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'invoiceGlValues',
  });
  const [isEditing, setIsEditing] = useState({});

  const handleFocus = (e) => {
    setIsEditing((prev) => ({ ...prev, [e?.target?.name]: true }));
    e.target.select();
  };

  const handleBlur = (e) => {
    setIsEditing((prev) => ({ ...prev, [e?.target?.name]: false }));
  };
  //update disable/enable gl save button
  useEffect(() => {
    // if (dataLoaded) {
    const keysToCheck = ['agoraCategoryId', 'agoraSubcategoryId', 'amount', 'shipping', 'memo'];
    if (unsavedData != null) {
      const onEdit = getValues('invoiceGlValues')?.map(
        (item, index) =>
          !keysToCheck.every((key) => {
            const value1 = item[key];
            const value2 = glRows[index]?.[key];
            if (!Number.isNaN(+value2)) return Number(value1) === Number(value2);
            return (value1 === null && value2 === '') || (value1 === '' && value2 === null) || value1 === value2;
          })
      );

      setEditGlItems(onEdit.some((edit) => edit));
    } else {
      setEditGlItems(false);
    }
    // }
  }, [unsavedData]);
  useEffect(() => {
    resetGlData();
  }, [glRows]);
  useEffect(() => {
    setItemsMissingGls(invoiceItemData?.invoiceItems?.filter((item) => item.agoraSubcategory.id === 0)?.length > 0);
  }, [invoiceItemData?.invoiceItems]);

  const resetGlData = () => {
    reset({
      invoiceGlValues: glRows,
    });
  };
  const handleDeleteGl = async (index) => {
    const glToDelete = fields?.[index];
    if (glToDelete?.glItemId !== 0) {
      glToDelete.deleteFlag = true;
      await dispatch(invoiceGLItemsForm({ invoiceGlItems: [glToDelete] }));
      await dispatch(getInvoice(id));
    } else remove(index);
  };
  const handleGlsFormSave = async () => {
    setUnsavedData(null);
    const newShipping = getValues('invoiceGlValues').reduce(
      (accumulator, currentValue) => parseFloat(accumulator) + parseFloat(currentValue.shipping),
      0
    );
    await dispatch(changeInvoiceField(id, { key: 'Shipping', value: newShipping }));
    const response = await dispatch(invoiceGLItemsForm({ invoiceGlItems: getValues('invoiceGlValues') }));
    if (response === 'success') {
      enqueueSnackbar('Gl Items updated successfully', { variant: 'success' });
    } else enqueueSnackbar('Error updating Gl Items', { variant: 'error' });
  };
  const handleAddGl = async () => {
    const result = await trigger();
    if (result) {
      append({
        glItemId: 0,
        invoiceId: id,
        facilityId: invoiceItemData?.invoice?.facilityId,
        glCode: '',
        agoraCategoryId: '',
        agoraCategory: '',
        agoraSubcategoryId: '',
        agoraSubcategory: '',
        amount: 0,
        shipping: 0,
        memo: '',
        referenceNumber: '',
      });
    }
  };
  const handleGlCategoryChange = (newValue, item, index) => {
    setValue(`invoiceGlValues.${index}.agoraCategoryId`, newValue?.categoryId);
    setValue(`invoiceGlValues.${index}.agoraCategory`, newValue?.category);

    setValue(`invoiceGlValues.${index}.agoraSubcategoryId`, '');
    setValue(`invoiceGlValues.${index}.agoraSubcategory`, '');
    setValue(`invoiceGlValues.${index}.glCode`, '');

    setUnsavedData((prev) => ({
      ...prev,
      [item.glItemId]: getValues(`invoiceGlValues.${index}`),
    }));
  };
  const handleGlSubcategoryChange = (newValue, item, index) => {
    setValue(`invoiceGlValues.${index}.agoraSubcategoryId`, newValue.subcategoryId);
    setValue(`invoiceGlValues.${index}.agoraSubcategory`, newValue.subcategory);
    setValue(`invoiceGlValues.${index}.glCode`, newValue.glCode);
    //set the right category
    setValue(`invoiceGlValues.${index}.agoraCategoryId`, newValue.categoryId);
    const { category } = categories
      ?.filter((category) => category?.facilityId === item?.facilityId)
      ?.find((item) => item.categoryId === newValue.categoryId);
    setValue(`invoiceGlValues.${index}.agoraCategory`, category);

    setUnsavedData((prev) => ({
      ...prev,
      [item.glItemId]: getValues(`invoiceGlValues.${index}`),
    }));
  };
  const getFilteredSubcategories = (facilityId, watchCategory, watchGlCode, watchExistingGls) =>
    subcategories?.filter((sub) => {
      const isDifferentGlCode = watchGlCode !== sub.glCode;
      const isNotInExistingGlCodes = !watchExistingGls.some(
        (existing) => existing.glCode === sub.glCode && existing.facilityId === sub.facilityId
      );

      return watchCategory !== undefined && watchCategory !== ''
        ? sub.facilityId === facilityId &&
            sub.categoryId === watchCategory &&
            sub.glCode !== null &&
            (isDifferentGlCode ? isNotInExistingGlCodes : true)
        : sub.facilityId === facilityId;
    });

  return (
    <>
      <Box sx={mainBoxStyle}>
        {glRows && (
          <form onSubmit={handleSubmit(handleGlsFormSave)}>
            <Grid container justifyContent="space-between" alignItems={'center'}>
              <Grid item>
                <Typography variant="h8" color="info.main" gutterBottom>
                  GL codes:
                </Typography>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    {itemsMissingGls && (
                      <Tooltip title={'invoice items are missing gl codes'} arrow="down">
                        <IconButton
                          color="error"
                          onClick={() => {
                            setHighlightMissingGls(true);
                          }}
                        >
                          <ErrorOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setExpandGl(!expandGl);
                      }}
                    >
                      {expandGl ? (
                        <Iconify icon={'lucide:shrink'} />
                      ) : (
                        <Iconify icon={'fluent:arrow-expand-24-filled'} />
                      )}
                    </IconButton>
                  </Grid>
                  {editGlItems && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        type="submit"
                        sx={{
                          mr: 1,
                        }}
                        disabled={!hasEditAccess}
                      >
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="inherit"
                        disabled={!editGlItems}
                        type="button"
                        sx={{
                          ml: 1,
                        }}
                        onClick={() => {
                          resetGlData();
                          setUnsavedData(null);
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Scrollbar sx={{ maxHeight: expandGl ? '650px' : '250px' }}>
              {/* {invoiceGLItems?.length === 0 ? (
              <Typography sx={{ textAlign: 'center', p: 2 }}>No GL codes are currently saved.</Typography>
            ) : ( */}
              <List sx={{ listStyle: 'decimal', pl: 4 }}>
                <>
                  {fields
                    .filter((x) => x.deleteFlag !== true)
                    .map((item, index) => {
                      const watchCategory = watch(`invoiceGlValues.[${index}].agoraCategoryId`);
                      const watchSubcategory = watch(`invoiceGlValues.[${index}].agoraSubcategoryId`);
                      const watchGlCode = watch(`invoiceGlValues.[${index}].glCode`);
                      const watchExistingGls = watch('invoiceGlValues')?.map((x) => ({
                        glCode: x.glCode,
                        facilityId: x.facilityId,
                      }));
                      const watchShipping = watch(`invoiceGlValues.[${index}].shipping`);
                      const watchAmount = watch(`invoiceGlValues.[${index}].amount`);
                      return (
                        <>
                          <ListItem sx={{ display: 'list-item' }} key={index}>
                            {invoiceItemData?.invoice?.sourceMethod === 'Manual' && (
                              <Typography color="secondary" gutterBottom>
                                Add GL code:
                              </Typography>
                            )}
                            <Stack spacing={1}>
                              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                <Typography sx={fieldStyle} variant="standard">
                                  Category:
                                </Typography>
                                <Controller
                                  name={`invoiceGlValues.${index}.agoraCategoryId`}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      {...field}
                                      sx={{ fontSize: '12px', flexGrow: 1 }}
                                      options={categories?.filter(
                                        (category) => category?.facilityId === item?.facilityId
                                      )}
                                      getOptionLabel={(option) => option.category}
                                      getOptionValue={(option) => option.categoryId}
                                      onChange={(event, newValue) => {
                                        handleGlCategoryChange(newValue, item, index);
                                      }}
                                      disabled={!hasEditAccess}
                                      value={
                                        watchCategory
                                          ? categories
                                              ?.filter((category) => category.facilityId === item?.facilityId)
                                              ?.find((x) => x.categoryId === watchCategory)
                                          : null
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={errors?.invoiceGlValues?.[index]?.agoraCategoryId}
                                          InputProps={{
                                            ...params.InputProps,
                                            style: {
                                              valueStyle,
                                            },
                                          }}
                                          variant="standard"
                                        />
                                      )}
                                    />
                                  )}
                                />
                                <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
                                  <ConfirmDialog
                                    icon="delete-outline"
                                    title="Delete"
                                    color="error"
                                    content={`Are you sure you want to delete this Gl item?`}
                                    actionButton="Delete"
                                    action={() => handleDeleteGl(index)}
                                  />
                                </ScopeGuard>
                              </Stack>
                              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                <Typography sx={fieldStyle} variant="standard">
                                  Gl code:
                                </Typography>
                                <Controller
                                  name={`invoiceGlValues.${index}.agoraSubcategoryId`}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                    <Autocomplete
                                      {...field}
                                      sx={{ fontSize: '12px', flexGrow: 1 }}
                                      options={getFilteredSubcategories(
                                        item?.facilityId,
                                        watchCategory,
                                        watchGlCode,
                                        watchExistingGls
                                      )}
                                      getOptionLabel={(option) => option.glCode}
                                      getOptionValue={(option) => option.subcategoryId}
                                      onChange={(event, newValue) => {
                                        handleGlSubcategoryChange(newValue, item, index);
                                      }}
                                      disabled={!hasEditAccess}
                                      value={
                                        watchSubcategory
                                          ? subcategories
                                              ?.filter(
                                                (sub) =>
                                                  sub.facilityId === item?.facilityId &&
                                                  sub.categoryId === watchCategory
                                              )
                                              ?.find((x) => x.subcategoryId === watchSubcategory)
                                          : null
                                      }
                                      disableClearable
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          error={errors?.invoiceGlValues?.[index]?.agoraSubcategoryId}
                                          InputProps={{
                                            ...params.InputProps,
                                            style: {
                                              valueStyle,
                                            },
                                          }}
                                          variant="standard"
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          {option.subcategory} ({option.glCode})
                                        </li>
                                      )}
                                    />
                                  )}
                                />
                              </Stack>
                              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                <Accordion key={index}>
                                  <AccordionSummary
                                    sx={{
                                      padding: 0,
                                    }}
                                    expandIcon={<ArrowDropDownIcon />}
                                  >
                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                      <Typography sx={fieldStyle} variant="standard">
                                        Total amount:
                                      </Typography>
                                      <Typography sx={valueStyle} variant="standard">
                                        {twoDecimal(parseFloat(watchAmount) + parseFloat(watchShipping))}
                                      </Typography>
                                    </Stack>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                      <Typography sx={fieldStyle} variant="standard">
                                        Amount:
                                      </Typography>
                                      <Controller
                                        name={`invoiceGlValues.${index}.amount`}
                                        control={control}
                                        rules={{
                                          required: { value: true, message: 'Required' },
                                          pattern: {
                                            value: /^[-+]?[0-9]*\.?[0-9]+$/,
                                            message: 'Please enter a valid number',
                                          },
                                        }}
                                        render={(field) => (
                                          <TextField
                                            {...field}
                                            sx={{ flexGrow: 1 }}
                                            variant="standard"
                                            disabled={!hasEditAccess}
                                            onInput={(e, newValue) => {
                                              //  console.log(e, newValue);
                                              // setValue(`invoiceGlValues.${index}.amount`, newValue);
                                              setUnsavedData((prev) => ({
                                                ...prev,
                                                [item.glItemId]: getValues(`invoiceGlValues.${index}`),
                                              }));
                                            }}
                                            {...register(`invoiceGlValues.${index}.amount`)}
                                            value={
                                              isEditing[`invoiceGlValues.${index}.amount`]
                                                ? watchAmount
                                                : fCurrency(watchAmount)
                                            }
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            error={!!errors?.invoiceGlValues?.[index]?.amount}
                                            helperText={errors?.invoiceGlValues?.[index]?.amount?.message}
                                          />
                                        )}
                                      />
                                    </Stack>
                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                      <Typography sx={fieldStyle} variant="standard">
                                        Shipping:
                                      </Typography>
                                      <Controller
                                        name={`invoiceGlValues.${index}.shipping`}
                                        control={control}
                                        rules={{
                                          required: { value: true, message: 'Required' },
                                          pattern: {
                                            value: /^[-+]?[0-9]*\.?[0-9]+$/,
                                            message: 'Please enter a valid number',
                                          },
                                        }}
                                        render={(field) => (
                                          <TextField
                                            {...field}
                                            sx={{ flexGrow: 1 }}
                                            variant="standard"
                                            disabled={!hasEditAccess}
                                            onInput={(e, newValue) => {
                                              setUnsavedData((prev) => ({
                                                ...prev,
                                                [item.glItemId]: getValues(`invoiceGlValues.${index}`),
                                              }));
                                            }}
                                            {...register(`invoiceGlValues.${index}.shipping`)}
                                            value={
                                              isEditing[`invoiceGlValues.${index}.shipping`]
                                                ? watchShipping
                                                : fCurrency(watchShipping)
                                            }
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            error={!!errors?.invoiceGlValues?.[index]?.shipping}
                                            helperText={errors?.invoiceGlValues?.[index]?.shipping?.message}
                                          />
                                        )}
                                      />
                                    </Stack>
                                  </AccordionDetails>
                                </Accordion>
                              </Stack>
                              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                <Typography sx={fieldStyle} variant="standard">
                                  Memo:
                                </Typography>
                                <TextField
                                  sx={{ flexGrow: 1 }}
                                  InputProps={{
                                    style: {
                                      valueStyle: '12px',
                                    },
                                  }}
                                  onInput={() => {
                                    setUnsavedData((prev) => ({
                                      ...prev,
                                      [item.glItemId]: getValues(`invoiceGlValues.${index}`),
                                    }));
                                  }}
                                  disabled={!hasEditAccess}
                                  variant="standard"
                                  {...register(`invoiceGlValues.${index}.memo`)}
                                  error={errors?.invoiceGlValues?.[index]?.memo}
                                />
                              </Stack>
                            </Stack>
                          </ListItem>
                          {fields.length > 2 && <Divider variant="fullWidth" sx={dividerStyle} />}
                        </>
                      );
                    })}
                </>
              </List>
              {/* )} */}
            </Scrollbar>
            {invoiceItemData?.invoice?.facilityId !== 0 && invoiceItemData?.invoice?.sourceMethod === 'Manual' && (
              <Button variant="outlined" type="button" onClick={handleAddGl} size="small" color="secondary">
                Add GL code
              </Button>
            )}
          </form>
        )}
      </Box>
    </>
  );
}

const dividerStyle = {
  backgroundColor: 'info.main',
  height: '2px',
  my: '20px',
};
const mainBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  padding: '25px',
  overflow: 'auto',
  border: 'solid 1px #c1c9d0',
  //maxHeight: '450px',
};
const fieldStyle = { color: '#060606', fontSize: '16px', fontWeight: '700' };
const valueStyle = { fontSize: '14px', color: '#060606' };
