import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Divider, Button } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { useSelector } from '../../../../redux/store';
import { getRentalIconForSubcategory } from '../../../../utils/cssStyles';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function RentalSubcategorySelect() {
  const {
    data: { categoryData },
  } = useSelector((state) => state.rentals);

  const [selectedItem, setSelectedItem] = useState(null);
  const [showEditToCart, setShowEditToCart] = useState(false);
  const [searchParams] = useSearchParams();
  const selectedCategory = searchParams.get('category');

  const handleItemClick = (item) => {
    setSelectedItem(item.label);
  };

  useEffect(() => {}, [showEditToCart, setShowEditToCart]);

  return (
    <>
      <div style={{ width: '100%' }}>
        <header>
          {/* SubCategory selection*/}
          <Grid container spacing={3} sx={{}}>
            {selectedCategory &&
              categoryData[selectedCategory]?.items &&
              categoryData[selectedCategory].items?.map((item, index) => (
                <Grid item key={item.label}>
                  <AwaitButton
                    sx={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      color: selectedCategory === item ? '#8cc53f' : 'black',
                    }}
                    onClick={() => handleItemClick(item)}
                    style={{
                      color: selectedItem === item.label ? '#8cc53f' : 'black',
                    }}
                  >
                    <Iconify
                      icon={getRentalIconForSubcategory(item.label)}
                      width={24}
                      height={24}
                      style={{ marginRight: '3px' }}
                    />{' '}
                    {item.label}
                  </AwaitButton>
                </Grid>
              ))}
          </Grid>
        </header>
      </div>
    </>
  );
}
