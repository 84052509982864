import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
// components
import { CartView } from '../../../../components/dashboard/orders/new-order/cart';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCart } from '../../../../redux/slices/cart';

// ----------------------------------------------------------------------

export default function Cart() {
  const dispatch = useDispatch();
  const { facilityId = '' } = useParams();

  const {
    data: { carts },
  } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getCart(facilityId));
  }, [dispatch, facilityId]);
  const [showSelection, setShowSelection] = useState('');

  return (
    <>
      <Helmet>
        <title>Cart | Platform Solutions</title>
      </Helmet>

      <Grid item xl={12}>
        <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
          {carts.map((items, index) => (
            <CartView
              key={index}
              items={items}
              index={index}
              setShowSelection={setShowSelection}
              showSelection={showSelection}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
