import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Button, Stack, TextField, Tooltip, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import Iconify from '../../../../reusable-components/iconify';
import { formatMoney } from '../../../../utils/formatNumber';
import { saveBudgetBreakdownData } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';

BudgetBreakdown.propTypes = {
  selectedRow: PropTypes.object,
};
export default function BudgetBreakdown({ selectedRow }) {
  const { id = '' } = useParams();

  const [localUnitPrice, setLocalUnitPrice] = useState(0);
  const [localQty, setLocalQty] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [changesMade, setChangesMade] = useState(false);
  const [localBudgetSource, setLocalBudgetSource] = useState(0);
  const [localBudgetSourceData, setLocalBudgetSourceData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setLocalData();
  }, []);
  const setLocalData = () => {
    setLocalUnitPrice(selectedRow.budgetPrice);
    setLocalQty(selectedRow.budgetQty);
    setLocalBudgetSource(selectedRow.budgetSource);
    setLocalBudgetSourceData(selectedRow.budgetSourceData);
    setChangesMade(false);
  };
  const handleClickOpen = (event) => {
    event.openTicket = true;
    setAnchorEl(event.currentTarget);
  };
  const handleCancelClose = () => {
    setLocalData();
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const saveBudget = () => {
    if (Number(localUnitPrice) !== selectedRow.budgetPrice[0] || Number(localQty) !== selectedRow.budgetQty[0]) {
      dispatch(saveBudgetBreakdownData(id, selectedRow.id, localUnitPrice, localQty));
      setLocalBudgetSource('MANUAL');
      setLocalBudgetSourceData([`${user.firstName} ${user.lastName}`]);
    }
    handleClose();
  };
  const getTotalDataForArray = () => {
    const totalSum = selectedRow.budgetSourceData?.reduce((accumulator, data, index) => {
      const itemTotal = selectedRow.budgetPrice[index] * selectedRow.budgetQty[index];
      return accumulator + itemTotal;
    }, 0);
    return totalSum;
  };
  return (
    <>
      <span>
        {' '}
        ${(selectedRow.budgetSourceData?.length > 1 ? getTotalDataForArray() : localQty * localUnitPrice).toFixed(2)}
      </span>
      <Tooltip title={'Budget Breakdown'}>
        <Iconify
          color="secondary"
          onClick={handleClickOpen}
          sx={{
            opacity: 0.8,
          }}
          icon="basil:settings-adjust-outline"
        />
      </Tooltip>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCancelClose}
      >
        <Grid container color="common.black" direction="column" textAlign="center">
          <Grid item>
            <Typography color="secondary" fontWeight="bold">
              Budget Breakdown{' '}
            </Typography>
          </Grid>
          {selectedRow.budgetSourceData?.map((row, index) => (
            <>
              <Grid item direction="row">
                <Typography fontSize={12}>
                  {localBudgetSource === 'MANUAL'
                    ? `Data adjusted by ${localBudgetSourceData}`
                    : `Data pulling from ${localBudgetSource}#${
                        selectedRow.budgetSourceData?.length > 1
                          ? selectedRow.budgetSourceData[index]
                          : localBudgetSourceData
                      }`}
                </Typography>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 1,
                  }}
                >
                  <Typography>Unit Price:</Typography>
                  <TextField
                    sx={{ width: '30%' }}
                    value={`$${formatMoney(
                      `${
                        (selectedRow.budgetSourceData?.length > 1 ? selectedRow.budgetPrice[index] : localUnitPrice) *
                        100
                      }`
                    )}`}
                    color="primary"
                    variant="standard"
                    type="text"
                    onChange={(e) => {
                      setLocalUnitPrice(formatMoney(e.target.value));
                      setChangesMade(true);
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item direction="row">
                <Stack spacing={1} direction="row" justifyContent="center" alignItems="center">
                  <Typography>Quantity:</Typography>
                  <TextField
                    sx={{ width: '30%' }}
                    value={selectedRow.budgetSourceData?.length > 1 ? selectedRow.budgetQty[index] : localQty}
                    color="primary"
                    variant="standard"
                    type="number"
                    onChange={(e) => {
                      setLocalQty(e.target.value);
                      setChangesMade(true);
                    }}
                  />
                </Stack>
              </Grid>
            </>
          ))}
          {changesMade && (
            <Grid item>
              <Button
                disabled={selectedRow.budgetSourceData?.length > 1}
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  minWidth: 120,
                  marginTop: 2,
                }}
                onClick={() => {
                  saveBudget();
                }}
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    overflow: 'auto',
    border: 'solid 1px #c1c9d0',
    minWidth: 150,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],

    '& .MuiMenuItem-root': {
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent', // or set this to the normal background color of your menu items
      },
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
