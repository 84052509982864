import PropTypes from 'prop-types';
import { useState } from 'react';
import { Typography, Grid, Card, Menu, Divider } from '@mui/material';
import Iconify from '../../../reusable-components/iconify';
import { useSelector } from '../../../redux/store';

InvoiceInfo.propTypes = {
  row: PropTypes.object,
};

export default function InvoiceInfo({ row }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    data: { invoiceItemDataById },
  } = useSelector((state) => state.invoicing);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const { invoice } = invoiceItemDataById[row?.id] || {};
  if (!invoice) return null;

  const { facility, id, invoiceId, vendor, vendorInvoiceId } = invoice || {};

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: -2 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 'bold', cursor: 'pointer' }}
          color="primary"
          onClick={handleClick}
        >
          {facility} <Iconify icon={open ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
        </Typography>
      </Grid>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Card sx={{ p: 1 }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ px: 3, pb: 1.5 }}
          >
            <Typography variant="body2" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.info.main }}>
              PS ID: {id}
            </Typography>

            <Typography sx={{ py: 0.5 }} variant="body1">
              PO #: {invoiceId}
            </Typography>
            <Divider sx={{ width: '100%' }} />
            <Typography sx={{ py: 0.5 }} variant="body2">
              Vendor ID: {vendorInvoiceId}
            </Typography>
            <Typography variant="body2" sx={{ py: 0.5 }}>
              Vendor: {vendor}
            </Typography>
          </Grid>
        </Card>
      </Menu>
    </>
  );
}
