import PropTypes from 'prop-types';
import 'quill-mention';
import { useCallback, useState } from 'react';
import { Switch, Tooltip, FormControlLabel, FormGroup, Grid, IconButton } from '@mui/material';

import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import { useTabs } from '../../context/TabContext';
import '../../utils/highlight';
import EditorToolbar, { formats } from './EditorToolbar';
import { StyledEditor } from './styles';
import { AwaitButton } from '../await-button';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

Editor.propTypes = {
  id: PropTypes.string,
  sx: PropTypes.object,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  removeHyperLink: PropTypes.bool,
  noFormat: PropTypes.bool,
  verySimple: PropTypes.bool,
  none: PropTypes.bool,
  openTicket: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.object,
  users: PropTypes.array,
  handleFileInputChange: PropTypes.func,
  handleSend: PropTypes.func,
  disabled: PropTypes.bool,
  attachFile: PropTypes.bool,
  emailSwitch: PropTypes.bool,
  sendButton: PropTypes.bool,
  sendIcon: PropTypes.bool,
  ticketEmailsOn: PropTypes.bool,
  setTicketEmailsOn: PropTypes.func,
};

export default function Editor({
  id = 'minimal-quill',
  error,
  value,
  onChange,
  noFormat = false,
  verySimple = false,
  disabled = false,
  simple = false,
  removeHyperLink = false,
  openTicket,
  helperText,
  sx,
  users,
  handleFileInputChange,
  attachFile = false,
  emailSwitch = false,
  sendButton = false,
  sendIcon = false,
  handleSend,
  ticketEmailsOn,
  setTicketEmailsOn = () => null,
  ...other
}) {
  const { activeTab } = useTabs();

  const baseUrl = window.location.href.includes('&start')
    ? window.location.href.split('&start')[0]
    : window.location.href;
  const sessionStorageKey = `tab-${activeTab.key}-editor-${baseUrl}`;

  const handleChangeMessage = (e) => {
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(e));
    onChange(e);
  };

  const [isDragOver, setIsDragOver] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setIsDragOver(false);

      const event = {
        target: {
          files: acceptedFiles,
        },
      };

      if (typeof handleFileInputChange === 'function') {
        handleFileInputChange(event);
      }
    },
    [handleFileInputChange]
  );

  const onDragOver = useCallback(() => {
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
  } = useDropzone({
    onDrop,
    onDragOver,
    onDragLeave,
    noClick: true,
    noKeyboard: true,
  });
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      isolateCharacter: true,
      mentionDenotationChars: ['@'],
      spaceAfterInsert: true,
      defaultMenuOrientation: 'top',
      positioningStrategy: 'fixed',
      showDenotationChar: false,
      source: useCallback((searchTerm, renderList) => {
        if (searchTerm?.length > 0) {
          const matches = [];
          for (let i = 0; i < users?.length; i++) {
            // Assuming value is in the format "firstName middleName lastName"
            const nameParts = users[i].value.split(' '); // Splits the full name into parts
            const matchFound = nameParts.some((part) => part.toLowerCase().startsWith(searchTerm.toLowerCase()));
            if (matchFound) {
              matches.push(users[i]);
              // Stop searching when 5 results are found
              if (matches.length >= 5) break;
            }
          }
          renderList(matches, searchTerm);
        }
      }, []),
    },
  };

  return (
    <div
      {...getRootProps()}
      style={{
        width: '100%',
        border: isDragOver ? '2px dashed #1976d2' : '2px dashed transparent',
        boxShadow: isDragOver ? '0px 0px 10px rgba(25, 118, 210, 0.5)' : 'none',
        transition: 'all 0.2s ease-in-out',
        position: 'relative', // For absolute positioning of the drop message
      }}
    >
      <input {...getInputProps()} />
      {isDragOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(3px)',
            zIndex: 1, // Make sure it's above the editor content
            pointerEvents: 'none', // Allows interaction with elements below
          }}
        >
          <p style={{ color: '#1976d2', fontSize: '1.2rem', fontWeight: 'bold' }}>Drop files here</p>
        </div>
      )}
      <StyledEditor
        openTicket={openTicket}
        disabled={disabled}
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
          width: '100%', // Ensure StyledEditor is full width
        }}
      >
        <ReactQuill
          readOnly={disabled}
          value={value}
          onChange={handleChangeMessage}
          modules={modules}
          formats={formats}
          placeholder="Message..."
          style={{ width: '100%', height: 'auto' }} // Ensure ReactQuill is full width
          {...other}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            // borderRadius: '4px', on the bottom right corner and bottom left corner
            borderRadius: '0 0 10px 10px',
            border: 'solid 1px #c1c9d0',
            backgroundColor: '#fff',
            width: '100%',
            position: 'relative',
            zIndex: 0,
            mt: -1.5,
          }}
        >
          <EditorToolbar
            id={id}
            isSimple={simple}
            removeHyperLink={removeHyperLink}
            noFormat={noFormat}
            verySimple={verySimple}
            openDropzone={openDropzone}
            attachFile={attachFile}
          />

          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              {emailSwitch && (
                <FormGroup>
                  <FormControlLabel
                    label={
                      <>
                        <Tooltip placement="top" arrow title="Email updates to all users for this message">
                          Email updates
                        </Tooltip>
                      </>
                    }
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={ticketEmailsOn}
                        onChange={() => setTicketEmailsOn((notify) => !notify)}
                        size="small"
                      />
                    }
                    sx={{
                      m: 0,
                      width: 1,
                      justifyContent: 'space-between',
                    }}
                  />
                </FormGroup>
              )}
              {sendButton && (
                <AwaitButton
                  size="small"
                  onClick={handleSend}
                  variant="contained"
                  sx={{
                    minWidth: '100px',
                    mx: 1,
                  }}
                >
                  Send
                </AwaitButton>
              )}
              {sendIcon && (
                <IconButton color="primary" onClick={handleSend}>
                  <Iconify icon="mdi:send" width={20} height={20} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </StyledEditor>
      {helperText && helperText}
    </div>
  );
}
