import { useCallback, useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Avatar, Box, Grid, IconButton, Tooltip, Button } from '@mui/material';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { useSelector, useDispatch } from '../../../redux/store';
import { PENDING_ORDERS } from '../../../reusable-components/datagrid/orderColumns';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { fDateTime } from '../../../utils/formatTime';
import CustomToolbar from './CustomToolbar';
import { getPendingOrders } from '../../../redux/slices/orders';
import TicketViewModal from './TicketViewModal';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { postCombineOrder, deleteOrder } from '../../../api/orders';
import { AwaitButton } from '../../../reusable-components/await-button';
import { useTabs } from '../../../context/TabContext';
import { stringToPrimaryColor } from '../../../utils/cssStyles';
import ManagerChipsWrapper from '../../../reusable-components/datagrid/ManagerChipsWrapper';

export default function PendingOrdersView() {
  const hasEditAccess = useScopeCheck(['Edit-Order'], true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showSelection, setShowSelection] = useState('');
  const [parentFacilityId, setParentFacilityId] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { addNewTabAndNavigate } = useTabs();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const selectedRows = useMemo(() => data.filter((row) => selected.includes(row.id)), [data, selected]);

  const {
    data: { pendingOrders } } = useSelector((state) => state.orders);
  const isLoading = useSelector((state) => state.orders.isLoading);
  const [activeRows, setActiveRows] = useState([]);

  useEffect(() => {
    setData(pendingOrders);
  }, [searchParams, pendingOrders]);

  const customToolbar = useCallback(() => {
    const isFiltered = (filter) => filter === searchParams.get('filter');
    return (
      <CustomToolbar
        setShowSelection={setShowSelection}
        showSelection={showSelection}
        setSelected={setSelected}
        selected={selected}
        isFiltered={isFiltered}
        activeRows={activeRows}
      />
    );
  }, [searchParams, showSelection, selected, activeRows]);

  const combineItems = (rowId, facilityId) => {
    setParentFacilityId(facilityId);
    setSelected((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        return prevSelected.filter((id) => id !== rowId);
      }
      return [...prevSelected, rowId];
    });
  };

  const combineOrdersFunction = async () => {
    const response = await postCombineOrder(selected);
    if (response.status === 200 && !response.data?.error) {
      dispatch(getPendingOrders());
      setSelected([]);
      setShowSelection('');
      enqueueSnackbar(response.data?.message, {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(response?.status === 200 ? response?.data?.message : 'Error splitting order', {
        variant: 'error',
      });
    }
  };

  const handleDelete = async (rowId) => {
    const response = await deleteOrder(rowId);
    if (response.status === 200) {
      dispatch(getPendingOrders());
      enqueueSnackbar('Order deleted successfully', { variant: 'success' });
    }
  };

  const ActionButtons = (params) => {
    const { orderNumber, id, facilityId } = params.row;
    return (
      <>
        <Tooltip title="Combine Orders">
          <IconButton
            color="primary"
            onClick={(e) => {
              e.combine = true;
              combineItems(id, facilityId);
            }}
          >
            <MergeTypeIcon />
          </IconButton>
        </Tooltip>

        <ConfirmDialog
          icon="delete-outline"
          title="Delete"
          color="error"
          content={`Are you sure you want to delete ${orderNumber}?`}
          actionButton="Delete"
          action={() => handleDelete(id)}
        />
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    width: 120,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };

  const messageCount = {
    field: 'messageCount',
    headerName: 'Notes',
    minWidth: 60,
    flex: 0.5,
    renderCell: (params) => <TicketViewModal params={params} />,
  };

  const manager = ManagerChipsWrapper(() => dispatch(getPendingOrders()), 'Orders');

  const reviewer = {
    field: 'reviews',
    headerName: 'Reviewer',
    type: 'customText',
    minWidth: 120,
    flex: 1.5,
    valueGetter: (params) => params.row.reviews?.map((r) => r.reviewerName).join(', '),
    renderCell: (params) => (
      <Tooltip
        title={params.row.reviews?.map((r) => (
          <Box key={r.compositeId}>
            {r.reviewed
              ? `${r.reviewerName} approved on ${fDateTime(r.timeStamp)}`
              : `Awaiting ${r.reviewerName}'s review`}
          </Box>
        ))}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
          {params.row.reviews?.map((r) =>
            r.reviewed ? (
              <Grid item key={r.compositeId}>
                <Avatar
                  sx={{
                    bgcolor: stringToPrimaryColor(r.reviewerName),
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    fontWeight: 'regular',
                  }}
                  variant="rounded"
                >
                  {r.reviewerName
                    .toUpperCase()
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                </Avatar>
              </Grid>
            ) : (
              <Grid item key={r.compositeId}>
                <Avatar
                  sx={{
                    bgcolor: '#ffffff',
                    color: stringToPrimaryColor(r.reviewerName),
                    border: '1px solid black',
                    borderColor: stringToPrimaryColor(r.reviewerName),
                    width: 30,
                    height: 30,
                    fontSize: 14,
                    fontWeight: 'regular',
                  }}
                  variant="rounded"
                >
                  {r.reviewerName
                    .toUpperCase()
                    .split(' ')
                    .map((n) => n[0])
                    .join('')}
                </Avatar>
              </Grid>
            )
          )}
        </Grid>
      </Tooltip>
    ),
  };

  // const columns = [...PENDING_ORDERS, manager, messageCount];
  // const columns = useMemo(() => {
  //   return [...PENDING_ORDERS, manager, messageCount];
  // }, []);

  const columns = useMemo(
    () => [
      ...PENDING_ORDERS,
      reviewer,
      manager,
      messageCount,
      ...(selected.length > 0 || hasEditAccess ? [actions] : []),
    ],
    []
  );

  const rowClick = (id) => {
    navigate(`/dashboard/orders/${id}?filter=pending`);
  };


  return (
    <>
      <Grid item xs={selected.length > 0 ? 10 : 12}>
        <CustomDataGrid
          gridId="pending-orders-view"
          boxSX={{ height: 'calc(100vh - 225px)' }}
          applyQuickFilter
          data={data}
          gridColumns={columns}
          sort={[{ field: 'dateAdded', sort: 'desc' }]}
          getRowId={(row) => row.id}
          onRowClick={(params, e) => {
            const selection = window.getSelection().toString();
            if (selection.length > 0) return;
            if (e.deleteChip || e.addChip || e.openTicket || e.combine || e.confirmDialog) return;
            if (e.navigateToTickets) {
              addNewTabAndNavigate(`/dashboard/tickets/${e.navigateToTickets}`);
              return;
            }
            rowClick(params.row.id);
          }}
          disableRowSelectionOnClick
          checkboxSelection={selected.length > 0}
          displayCheckboxRight
          CustomLeftToolbar={customToolbar}
          rowSelectionModel={selected}
          onRowSelectionModelChange={(newSelection) => {
            data?.map((row) => {
              if (row.id === newSelection[newSelection.length - 1]) {
                if (row.facilityId !== parentFacilityId) {
                  enqueueSnackbar("Can't combine order from different facility", { variant: 'error' });
                } else {
                  setSelected(newSelection);
                }
              }
              return null;
            });
          }}
          isLoading={isLoading}
          setActiveRows={setActiveRows}
        />
      </Grid>
      {selected.length > 0 && (
        <Grid item xs={2}>
          <Box
            sx={{
              ml: 2,
              p: 1.5,
              mt: 7,
            }}
          >
            <AwaitButton
              variant="outlined"
              color="primary"
              fullWidth
              sx={{ mb: 1, width: '200px', boxShadow: 3, fontWeight: 'bold' }}
              startIcon={<MergeTypeIcon />}
              onClick={combineOrdersFunction}
            >
              Combine to {selected[0]}
            </AwaitButton>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {selectedRows.map((row, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: 'solid 2px #8dc63f',
                      width: '200px',
                      height: '35px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '20px',
                        height: '35px',
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                      }}
                    >
                      {index + 1}
                    </Box>
                    <Box sx={{ ml: 1, color: 'primary.main' }}>{row.orderNumber}</Box>
                  </Box>
                  {index < selected.length - 1 && (
                    <Box
                      sx={{ width: '2px', height: '20px', backgroundColor: '#8dc63f', alignSelf: 'center', ml: 1 }}
                    />
                  )}
                </Box>
              ))}
            </Box>

            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <Grid item xs={6}>
                <Button
                  variant="text"
                  color="inherit"
                  sx={{
                    mb: 1,
                    width: '100%',
                    textDecoration: 'underline',
                  }}
                  onClick={() => setSelected([])}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
}
