import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Box, Grid, Tooltip, Typography, Button, IconButton } from '@mui/material';
import { GridRowModes, GridCellModes, useGridApiRef } from '@mui/x-data-grid';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';
import { ORDER_INFORMATION } from '../../../../reusable-components/datagrid/noFlexInvoiceColumns';
import { createColumns } from '../../../../reusable-components/datagrid/custom-filters';
import { useDispatch, useSelector } from '../../../../redux/store';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';
import Iconify from '../../../../reusable-components/iconify';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { patchInvoiceItems, deleteGlItems } from '../../../../redux/slices/invoicing';
import { useSnackbar } from '../../../../reusable-components/snackbar';

OrderInformation.propTypes = { highlightMissingGls: PropTypes.bool };
export default function OrderInformation({ highlightMissingGls }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { invoiceData, invoiceItemData, invoiceGLItems },
  } = useSelector((state) => state.invoicing);
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);
  const { invoice, maximumItemDiscrepancy } = invoiceItemData;
  const { categories, subcategories } = invoiceData;
  const isLoading = useSelector((state) => state.invoicing.isLoading);
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [missingSubcategories, setMissingSubcategories] = useState(false);
  const [rows, setRows] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const apiRef = useGridApiRef();

  const categoryDropdown = {
    field: 'categoryId',
    headerName: 'Category',
    editable: !invoiceItemData?.invoice?.multiFacility && invoiceGLItems?.length === 0 && hasEditAccess,
    width: 140,
    maxHeight: 45,
    overflow: 'hidden',
    type: 'singleSelect',
    valueOptions: ({ row }) =>
      categories
        ?.filter((category) => category?.facilityId === invoice?.facilityId)
        ?.map((category) => ({ label: category.category, value: category.categoryId })),
    renderCell: ({ row }) => (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.75rem',
        }}
      >
        {
          categories
            ?.filter((category) => category.facilityId === invoice?.facilityId)
            ?.find((x) => x.categoryId === row.categoryId)?.category
        }
      </Box>
    ),
  };
  const subCategoryDropdown = {
    field: 'subcategoryId',
    headerName: 'Subcategory',
    editable: !invoiceItemData?.invoice?.multiFacility && invoiceGLItems?.length === 0 && hasEditAccess,
    type: 'singleSelect',
    width: 240,
    valueOptions: ({ row }) =>
      subcategories
        ?.filter((sub) => sub.facilityId === invoice?.facilityId && sub.categoryId === row.categoryId)
        ?.map((sub) => ({ label: `${sub.subcategory} ${sub.glCode}`, value: sub.subcategoryId })) || [],
    // renderCell: ({ row }) => {
    //   const sub =
    //     subcategories
    //       ?.filter((sub) => sub.facilityId === invoice?.facilityId && sub.categoryId === row?.categoryId)
    //       ?.find((x) => x.subcategoryId === row?.subcategoryId) || null;
    //   console.log(sub);
    //   return (
    //     <Box
    //       component="div"
    //       sx={{
    //         wordWrap: 'break-word',
    //         whiteSpace: 'pre-wrap',
    //         fontSize: '0.75rem',
    //       }}
    //     >
    //       {sub !== null && `${sub?.subcategory}``${sub?.glCode}`}
    //     </Box>
    //   );
    // },
  };
  const price = {
    field: 'price',
    headerName: 'Price',
    type: 'customMoney',
    width: 100,
    editable: !invoiceItemData?.invoice?.multiFacility && invoiceGLItems?.length === 0 && hasEditAccess,
    renderCell: (params) => fCurrency(params.value),
    valueGetter: (params) => params.row.price,
  };
  const taxAmount = {
    field: 'taxAmount',
    headerName: 'Tax Amount',
    type: 'customMoney',
    width: 100,
    editable: !invoiceItemData?.invoice?.multiFacility && invoiceGLItems?.length === 0 && hasEditAccess,
    renderCell: (params) => fCurrency(params.value),
    valueGetter: (params) => params.row.taxAmount,
  };
  const expectedItemPrice = {
    field: 'expectedItemPrice',
    headerName: 'Our Price',
    width: 100,
    renderCell: (params) => {
      const overMax = Math.abs(params.row.price - params.row.expectedItemPrice) > maximumItemDiscrepancy;
      const notExact = params.row.price !== params.row.expectedItemPrice;
      return (
        <Tooltip
          title={
            overMax
              ? 'Our price does not match their price'
              : notExact
              ? `Price within acceptable range (${fCurrency(maximumItemDiscrepancy)})`
              : ''
          }
          placement="top"
          arrow
        >
          <Box color={overMax ? 'error.main' : notExact ? 'warning.main' : 'black'}>
            {notExact && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
            {fCurrency(params.row.expectedItemPrice)}
          </Box>
        </Tooltip>
      );
    },
    valueGetter: (params) => {
      const overMax = params.row.price - params.row.expectedItemPrice > maximumItemDiscrepancy;
      const notExact = params.row.price !== params.row.expectedItemPrice;
      return overMax
        ? `99999999${params.row.expectedItemPrice}`
        : notExact
        ? `88888888${params.row.expectedItemPrice}`
        : params.row.expectedItemPrice;
    },
  };

  const quantity = {
    field: 'quantity',
    headerName: 'Sent',
    width: 70,
    renderCell: (params) => {
      const moreSentThenOrdered = params.row.quantity > params.row.expectedAmount;
      return (
        <Tooltip
          title={moreSentThenOrdered ? `More sent then ordered (${params.row.expectedAmount})` : ''}
          placement="top"
          arrow
        >
          <Box color={moreSentThenOrdered ? 'error.main' : 'black'}>
            {moreSentThenOrdered && <Iconify icon={'material-symbols:error-outline'} width={13} height={13} />}
            {fNumber(params.row.quantity)}
          </Box>
        </Tooltip>
      );
    },
  };
  const getColumns = useCallback(() => {
    const commonColumns = [
      ...ORDER_INFORMATION.slice(0, 2),
      quantity,
      ...ORDER_INFORMATION.slice(2, 3),
      expectedItemPrice,
      taxAmount,
      ...ORDER_INFORMATION.slice(3),
      price,
      categoryDropdown,
      subCategoryDropdown,
    ];

    return commonColumns;
  }, [invoiceItemData?.invoice?.multiFacility, invoiceGLItems]);

  useEffect(() => {
    setRows(invoiceItemData.invoiceItems || []);
    setChangedRows(invoiceItemData.invoiceItems || []);
  }, [invoiceItemData.invoiceItems]);
  useEffect(() => {
    apiRef.current.updateColumns(getColumns());
  }, [invoiceItemData?.invoice?.multiFacility, getColumns, apiRef]);

  const handleSave = async () => {
    setHasUnsavedRows(false);
    // const response = await dispatch(patchInvoiceItems(id, invoice.id, changedRows));
    const response = await dispatch(patchInvoiceItems(invoice.id, changedRows));
    if (response.status === 200) {
      enqueueSnackbar('Invoice updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Unable to update invoice', { variant: 'error' });
    }
  };

  const handleCancel = () => {
    setHasUnsavedRows(false);
    setRows(JSON.parse(JSON.stringify(rows)));
  };
  const handleRemoveGls = async () => {
    const response = await dispatch(deleteGlItems(id));
    if (response === 'success') {
      enqueueSnackbar('Gl items deleted successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('failed to delete gl items', { variant: 'error' });
    }
  };
  const processRowUpdate = (newRow) => {
    setHasUnsavedRows(true);
    const updatedRow = { ...newRow, isNew: false };
    setChangedRows((prev) => {
      const updatedRows = prev.map((row) => {
        if (row.id === newRow.id) {
          const { categoryId, subcategoryId } = updatedRow;
          if (
            subcategories.find(
              (sub) =>
                sub.facilityId === invoice.facilityId &&
                sub.categoryId === categoryId &&
                sub.subcategoryId === subcategoryId
            )
          ) {
            setMissingSubcategories(false);
          } else {
            setMissingSubcategories(true);
          }
          return newRow;
        }
        return row;
      });

      return updatedRows;
    });
    return updatedRow;
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
          {invoiceGLItems?.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              onClick={handleRemoveGls}
              // sx={{ ml: 1, display: !hasUnsavedRows ? 'none' : 'block' }}
            >
              Unlock invoice items
            </Button>
          )}
          <Tooltip title={missingSubcategories ? 'Missing Subcategories' : ''} placement="top" arrow>
            <Box>
              <Button
                variant="outlined"
                size="small"
                color={missingSubcategories ? 'error' : 'secondary'}
                onClick={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
                sx={{ ml: 1, display: !hasUnsavedRows ? 'none' : 'block' }}
                disabled={missingSubcategories}
              >
                Save
              </Button>
            </Box>
          </Tooltip>

          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={handleCancel}
            sx={{ ml: 1, display: !hasUnsavedRows ? 'none' : 'block' }}
          >
            Cancel
          </Button>
        </Grid>
      </>
    ),
    [changedRows, hasUnsavedRows, missingSubcategories, invoiceGLItems]
  );
  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);
  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    if (event.target.nodeType === 1 && !event.currentTarget.contains(event.target)) {
      return;
    }

    setCellModesModel((prevModel) => ({
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View },
            }),
            {}
          ),
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        [params.field]: { mode: GridCellModes.Edit },
      },
    }));
  }, []);

  return (
    <>
      <Box sx={{ maxHeight: '400px' }}>
        {rows && (
          <CustomDataGrid
            gridId="invoicing-order-information"
            boxSX={{ width: '100%', height: '350px' }}
            data={rows}
            isModal
            apiRef={apiRef}
            gridColumns={getColumns()}
            sort={[{ field: 'expectedItemPrice', sort: 'desc' }]}
            isLoading={!rows.length && isLoading}
            onCellClick={handleCellClick}
            CustomLeftToolbar={CustomLeftToolbar}
            processRowUpdate={processRowUpdate}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            getRowClassName={({ id }) => {
              if (
                highlightMissingGls &&
                rows
                  ?.filter((item) => item.agoraSubcategory.id === 0)
                  ?.map((x) => x.id)
                  ?.includes(id)
              )
                return 'row--highlight';
              return '';
            }}
            sx={{
              '& .MuiDataGrid-cell': {
                marginY: '4px',
                marginRight: '8px',
                height: 38,
              },
              '& .MuiDataGrid-cell.MuiDataGrid-cell--editing, & .MuiDataGrid-cell--editable': {
                border: `solid 1px #404042`,
                borderRadius: '6px',
                fontSize: '0.8rem',
                fontWeight: '600',
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeader': {
                marginRight: '8px',
              },
              '& .MuiDataGrid-row.row--highlight': {
                backgroundColor: (theme) => {
                  if (theme.palette.mode === 'light') {
                    return 'rgba(143, 223, 130, 0.3)';
                  }
                  return 'rgba(143, 223, 130, 1)';
                },
              },
            }}
          />
        )}
      </Box>
    </>
  );
}
