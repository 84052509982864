import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { bookInvoice, postInvoiceForm, updateInvoiceItems } from '../../../api/invoicing';
import { HOST_API_URL } from '../../../config-global';
import { getInvoiceItemsDataFromRedux, removeInvoiceFromPending } from '../../../redux/slices/invoicing';
import { getPreviousOrderInfoByNumber } from '../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { useSnackbar } from '../../../reusable-components/snackbar';
import { CompletedOrderView } from '../orders/order';
import InvoiceViewTab from './InvoiceViewTab';
import SubmitInvoiceView from './SubmitInvoiceView';
import { AwaitButton } from '../../../reusable-components/await-button';

InvoiceModalView.propTypes = {
  row: PropTypes.object,
};

export default function InvoiceModalView({ row }) {
  const dispatch = useDispatch();
  const {
    data: { invoiceItemDataById },
  } = useSelector((state) => state.invoicing);

  const { invoice, bookingType, invoiceItems } = invoiceItemDataById[row.id] || {};

  const isUnknown = invoice?.invoiceId.split(' ')[0] === 'Unknown';

  const [open, setOpen] = useState(false);
  const [invoiceItemRows, setInvoiceItemRows] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errors, setErrors] = useState('');
  const [orderId, setOrderId] = useState(null);

  const updateDisabled = useMemo(
    () =>
      invoiceItems?.some(
        (item) =>
          item.agoraSubcategory?.id === 0 ||
          item.agoraCategory?.id === 0 ||
          !item.agoraCategory ||
          !item.agoraSubcategory
      ),
    [invoiceItems]
  );

  const getData = async () => {
    dispatch(getInvoiceItemsDataFromRedux(row.id));
  };

  useEffect(() => {
    if (open) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const { enqueueSnackbar } = useSnackbar();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const saveInvoiceItems = async () => {
    setIsSubmitting(true);
    const response = await updateInvoiceItems(row.id, invoiceItems);
    if (response.status === 200) {
      setConfirmOpen(true);
      enqueueSnackbar('Invoice updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Unable to update invoice', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const saveInvoice = async () => {
    const response = await postInvoiceForm(invoice);
    if (response.status === 200) {
      dispatch(getInvoiceItemsDataFromRedux(row.id));
      enqueueSnackbar('Invoice saved', { variant: 'success' });
    } else {
      enqueueSnackbar('Unable to save invoice', { variant: 'error' });
    }
  };

  const submitInvoice = async () => {
    setIsSubmitting(true);
    const response = await bookInvoice(invoice, invoiceItemRows);
    if (response.status === 200) {
      if (response.data.keepFormOpen) {
        setErrors(response.data.message);
        enqueueSnackbar(response.data.message, { variant: response.data.success ? 'success' : 'error', persist: true });
      } else {
        enqueueSnackbar(response.data.message, { variant: response.data.success ? 'success' : 'error' });
        dispatch(removeInvoiceFromPending(row.id));
        setOpen(false);
      }
    } else {
      enqueueSnackbar('Unable to submit invoice', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setConfirmOpen(false);
    setTab(0);
  };

  const [tab, setTab] = useState(0);

  const getPrevOrderInfo = async (invoiceId) => {
    const id = await dispatch(getPreviousOrderInfoByNumber(invoiceId));
    setOrderId(id);
    return id;
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 2) return;
    if (newValue === 1) getPrevOrderInfo(invoice?.invoiceId);
    setTab(newValue);
  };

  return (
    <>
      <Tooltip title="Process Invoice" placement="top" arrow>
        <IconButton onClick={handleClickOpen} color="info">
          <SettingsSuggestIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={confirmOpen ? 'md' : 'full'}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          {confirmOpen && (
            <IconButton
              onClick={() => {
                setConfirmOpen(false);
                setTab(0);
              }}
              sx={{
                position: 'absolute',
                left: 8,
                top: 8,
                color: (theme) => theme.palette.grey[900],
              }}
            >
              <Iconify icon="material-symbols:arrow-back" width={28} height={28} />
            </IconButton>
          )}
          {confirmOpen
            ? ` Book Invoice To: ${bookingType} `
            : ` Process Invoice ${row?.vendorInvoiceId} - ${invoice?.invoiceId} `}
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        {errors && <Alert severity="error">{errors}</Alert>}
        <DialogContent>
          {!confirmOpen && (
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="transparent"
              sx={{
                backgroundColor: '#f5f6f7',
              }}
            >
              <CustomTab label="Invoice" selected={tab === 0} />
              <CustomTab disabled={isUnknown} label="Previous Order" selected={tab === 1} />
              {invoice?.hasMainFile && (
                <CustomTab
                  onClick={() => {
                    const modal = document.createElement('div');
                    modal.style.position = 'fixed';
                    modal.style.zIndex = '100000';
                    modal.style.left = '0';
                    modal.style.bottom = '0';
                    modal.style.width = '100%';
                    modal.style.height = '100%';
                    modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                    modal.style.display = 'flex';
                    modal.style.justifyContent = 'center';
                    modal.style.alignItems = 'center';

                    // Create an iframe for the PDF
                    const iframe = document.createElement('iframe');
                    iframe.style.width = '70%';
                    iframe.style.height = '100%';
                    iframe.src = `${HOST_API_URL}Api/Invoices/ViewInvoiceFile?id=${invoice.id}&mainFile=true`;

                    // Append iframe to modal
                    modal.appendChild(iframe);

                    // Add a close functionality to the modal
                    modal.addEventListener('click', (e) => {
                      if (e.target === modal) {
                        document.body.removeChild(modal);
                      }
                    });
                    document.body.appendChild(modal);
                  }}
                  label="Vendor PDF"
                  selected={tab === 2}
                />
              )}
            </Tabs>
          )}
          {confirmOpen ? (
            <SubmitInvoiceView
              invoiceItemRows={invoiceItemRows}
              setInvoiceItemRows={setInvoiceItemRows}
              errors={errors}
              row={row}
              invoiceId={row.id}
            />
          ) : (
            <Grid item xs={12}>
              <Box sx={{ width: '100%', height: 'calc(100vh - 240px)' }}>
                {tab === 0 ? (
                  <InvoiceViewTab row={row} />
                ) : tab === 1 ? (
                  <>
                    <CompletedOrderView invoiceView overrideOrderId={orderId} />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Grid>
          )}
        </DialogContent>
        {tab === 0 && (
          <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
            <DialogActions>
              {confirmOpen ? (
                <>
                  <AwaitButton
                    variant="outlined"
                    color="primary"
                    onClick={saveInvoice}
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<Iconify icon="fa:save" />}
                  >
                    Save Invoice
                  </AwaitButton>
                  <AwaitButton
                    variant="contained"
                    color="primary"
                    onClick={submitInvoice}
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={<Iconify icon="fa:paper-plane-o" />}
                  >
                    Submit Invoice
                  </AwaitButton>
                </>
              ) : (
                <>
                  {updateDisabled && <Alert severity="warning">Please fill out all categories and subcategories</Alert>}
                  <AwaitButton
                    variant="contained"
                    color="primary"
                    onClick={saveInvoiceItems}
                    loading={isSubmitting}
                    disabled={updateDisabled}
                    loadingPosition="start"
                    startIcon={<Iconify icon="material-symbols:save" />}
                  >
                    Update Invoice
                  </AwaitButton>
                </>
              )}
            </DialogActions>
          </ScopeGuard>
        )}
      </Dialog>
    </>
  );
}
const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#DFE3E8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)', // Add shadow on hover for a more interactive feel
  },
}));
