import { useCallback, useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Grid, Chip, IconButton, Tooltip } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector, useDispatch } from '../../../../redux/store';
import { VENDORS } from '../../../../reusable-components/datagrid/vendorColumns';
import { SkeletonDataGrid } from '../../../../reusable-components/skeleton/SkeletonDataGrid';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import {
  createColumns,
  CustomFilter,
  CustomQuickFilter,
} from '../../../../reusable-components/datagrid/custom-filters';
import { useFilterModel } from '../../../../hooks/useFilterModel';
import CustomToolbar from '../CustomToolbar';

export default function InvoicingVendors({ CustomToolbar, setActiveRows }) {
  const [data, setData] = useState([]);

  const {
    data: { invoicingVendors },
  } = useSelector((state) => state.invoicing);

  const isLoading = useSelector((state) => state.invoicing.isLoading);

  useEffect(() => {
    setData(invoicingVendors);
  }, [invoicingVendors]);

  const columns = createColumns(VENDORS);

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="invoicing-vendors-invoicing-vendors"
          boxSX={{ height: 'calc(100vh - 280px)' }}
          data={data}
          sort={[{ field: 'invoiceVendorId', sort: 'asc' }]}
          gridColumns={[...columns]}
          CustomLeftToolbar={CustomToolbar}
          setActiveRows={setActiveRows}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
