import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { fCurrency } from '../../../../utils/formatNumber';
import { BUDGETS } from '../../../../reusable-components/datagrid/budgetsColumns';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify';
import BudgetModal from './BudgetModal';
import { ConfirmDialog } from '../../../../reusable-components/confirm-dialog';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { HOST_API_URL } from '../../../../config-global';
import CopyBudget from './CopyBudget';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { deleteBudget } from '../../../../redux/slices/budget';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';

Budgets.propTypes = {
  selectedRow: PropTypes.object,
};

export default function Budgets({ selectedRow }) {
  const {
    data: { agoraCategories, agoraSubcategories, budgets, facilities, glCodes },
  } = useSelector((state) => state.budget);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [activeRows, setActiveRows] = useState([]);
  const removeBudget = async (id) => {
    try {
      const response = await dispatch(deleteBudget(id));
      enqueueSnackbar('Budget deleted successfully', { variant: 'success' });
    } catch (error) {
      console.error('Error deleting budget:', error);
    }
  };
  const isLoading = useSelector((state) => state.budget.isLoading);
  const actionButtons = {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    renderCell: (params) => {
      const { id } = params.row;
      return (
        <>
          <ScopeGuard scopes={['Edit-Budgets']} allowAdmin>
            <BudgetModal edit row={params.row} selectedRow={selectedRow} />

            <ConfirmDialog
              icon="delete-outline"
              title="Delete"
              color="error"
              content={`Are you sure you want to delete budget?`}
              actionButton="Delete"
              action={() => removeBudget(id)}
            />
          </ScopeGuard>
        </>
      );
    },
    id: 'actions',
  };

  const CustomLeftToolbar = useCallback(
    () => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end">
        <>
          <ScopeGuard scopes={['Edit-Budgets']} allowAdmin>
            <Box>
              <BudgetModal selectedRow={selectedRow} />
            </Box>
            <Box>
              <CopyBudget selectedRow={selectedRow} facilities={facilities} agoraCategories={agoraCategories} />
            </Box>
          </ScopeGuard>
        </>
        <>
          <AwaitButton
            variant="outlined"
            sx={{ mr: 1 }}
            size="small"
            color="secondary"
            onClick={() => {
              window.open(`${HOST_API_URL}Api/Budget/DownloadBudgets`, '_blank');
            }}
            startIcon={<Iconify icon="file-icons:microsoft-excel" />}
          >
            Download
          </AwaitButton>
          <Box sx={{ textAlign: 'left', mr: 2 }}>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              Total
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'info.main' }}>
              {fCurrency(
                activeRows
                  .filter((item) => !item.excludeFromMain)
                  .map((item) => item.ppdBudget)
                  .reduce((a, b) => a + b, 0)
              )}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'left' }}>
            <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
              With excluded
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
              {fCurrency(activeRows.map((item) => item.ppdBudget).reduce((a, b) => a + b, 0))}
            </Typography>
          </Box>
        </>
      </Grid>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRow, facilities, agoraCategories, agoraSubcategories, glCodes, budgets, activeRows]
  );

  return (
    <>
      <Grid item xs={12}>
        <CustomDataGrid
          gridId="admin-budgets"
          boxSX={{ height: 'calc(100vh - 340px)' }}
          data={budgets}
          gridColumns={[...BUDGETS, actionButtons]}
          CustomLeftToolbar={CustomLeftToolbar}
          maxValue={{ ppdBudget: 2 }}
          setActiveRows={setActiveRows}
          pinnedColumns={{
            right: ['actions'],
            // left: ['picture'], just a example
          }}
          sort={[{ field: 'facility', sort: 'asc' }]}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
}
