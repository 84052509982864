import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Paper,
  Typography,
  Box,
  DialogActions,
  Grid,
  Tooltip,
} from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { INVOICE_FILES } from '../../../reusable-components/datagrid/invoiceColumns';
import { SkeletonDataGrid } from '../../../reusable-components/skeleton/SkeletonDataGrid';
import { getPendingInvoicing } from '../../../redux/slices/invoicing';
import {
  getInvoiceItemsFiles,
  setInvoiceFileAsMain,
  postAddInvoiceFile,
  deleteInvoiceFile,
  recreateInvoice,
  generateInvoicePdfFromData,
} from '../../../api/invoicing';
import Iconify from '../../../reusable-components/iconify';
import { useSnackbar } from '../../../reusable-components/snackbar';
import FileThumbnail from '../../../reusable-components/file-thumbnail';
import { ConfirmDialog } from '../../../reusable-components/confirm-dialog';
import { HOST_API_URL } from '../../../config-global';
import { ScopeGuard } from '../../../reusable-components/scopes';
import { useDispatch } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';

NewFileModal.propTypes = {
  row: PropTypes.object,
};

export default function NewFileModal({ row }) {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const getFiles = async () => {
    const response = await getInvoiceItemsFiles(row.id);
    if (response.data) {
      setData(response.data);
    }
  };
  useEffect(() => {
    if (open) {
      getFiles();
    }
  }, [open]);

  const handleDelete = async (rowId) => {
    const response = await deleteInvoiceFile(rowId);
    if (response.status === 200) {
      enqueueSnackbar('File deleted successfully', { variant: 'success' });
      getFiles();
    }
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const generatePDF = async () => {
    setIsSubmitting(true);
    const response = await generateInvoicePdfFromData(row.id);
    if (response.status === 200) {
      enqueueSnackbar('PDF generated successfully', { variant: 'success' });
      getFiles();
      dispatch(getPendingInvoicing({ pending: true }));
    } else {
      enqueueSnackbar('PDF generation failed', { variant: 'error' });
    }
    setIsSubmitting(false);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openDropzone,
  } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      addFile(acceptedFiles[0]);
    },
  });

  const addFile = async (file) => {
    const response = await postAddInvoiceFile(row.id, file);
    if (response.status === 200) {
      enqueueSnackbar('File added successfully', { variant: 'success' });
      getFiles();
    }
  };
  const recreateFile = async (id) => {
    const response = await recreateInvoice(id);
    if (response.status === 200) {
      enqueueSnackbar('File recreated successfully', { variant: 'success' });
      getFiles();
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    addFile(file);
  };

  const setAsMainFile = async (id) => {
    const response = await setInvoiceFileAsMain(id);
    if (response.status === 200) {
      enqueueSnackbar('File set as main successfully', { variant: 'success' });
      getFiles();
    }
  };

  const FileType = (params) => {
    const { fileName, fileExtension } = params.row;
    return (
      <>
        <FileThumbnail key={fileName} file={fileExtension} tooltip sx={{ width: 32, height: 32 }} />
      </>
    );
  };
  const ActionButtons = (params) => {
    const { invoiceAttachmentCategoryId, id, fileExtension } = params.row;
    return (
      <>
        <>
          <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
            {fileExtension === 'pdf' && (
              <Tooltip title="Set as main">
                <IconButton
                  color={invoiceAttachmentCategoryId === 1 ? 'primary' : 'default'}
                  onClick={() => {
                    setAsMainFile(id);
                  }}
                >
                  {invoiceAttachmentCategoryId === 1 ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                </IconButton>
              </Tooltip>
            )}
            {invoiceAttachmentCategoryId === 2 && (
              <Tooltip title="Recreate Invoice">
                <IconButton
                  color="secondary"
                  onClick={() => {
                    recreateFile(id);
                  }}
                >
                  <RestorePageIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              color="info"
              onClick={() => {
                window.open(`${HOST_API_URL}Api/Invoices/DownloadInvoiceFile?id=${id}&mainFile=false`, '_blank');
              }}
            >
              <DownloadIcon />
            </IconButton>
            <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
              <ConfirmDialog
                icon="delete-outline"
                title="Delete"
                color="error"
                content={`Are you sure you want to delete this invoice file?`}
                actionButton="Delete"
                action={() => handleDelete(id)}
              />
            </ScopeGuard>
          </Grid>
        </>
      </>
    );
  };

  const actions = {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: (params) => ActionButtons(params),
    id: 'actions',
    className: 'actions',
  };
  const fileExtension = {
    field: 'File Type',
    headerName: 'Type',
    flex: 0.5,
    renderCell: (params) => FileType(params),
    id: 'fileExtension',
    className: 'fileExtension',
  };
  const main = {
    field: 'invoiceAttachmentCategoryId',
    headerName: 'Main File',
    flex: 0.5,
    id: 'main',
    className: 'main',
    renderCell: (params) => {
      const { value } = params;
      return value === 1 ? <CheckCircleOutlineIcon color="primary" /> : <></>;
    },
  };

  return (
    <>
      <Tooltip title="Attach Invoice File">
        <IconButton onClick={handleClickOpen}>
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Invoice Files
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: 'calc(100vh - 600px)' }}>
            <DataGridPro
              getRowHeight={() => 'auto'}
              rows={data}
              columns={[...INVOICE_FILES, fileExtension, main, actions]}
              disableSelectionOnClick
              sortModel={[{ field: 'dateAdded', sort: 'desc' }]}
              // loading={data.length === 0}
              components={{ LoadingOverlay: SkeletonDataGrid }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
            <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
              <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
                <input {...getInputProps()} />
                <Paper
                  elevation={isDragActive ? 6 : 1}
                  sx={{
                    p: 2,
                    ml: 4,
                    bgcolor: 'background.paper',
                    color: 'info.main',
                    borderColor: 'info.main',
                    border: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      bgcolor: 'background.default',
                      boxShadow: 3,
                    },
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    Click to Upload or Drag and Drop
                  </Typography>
                </Paper>
              </Box>
            </ScopeGuard>
            <ScopeGuard scopes={['Invoice-Edit']} allowAdmin>
              {/* <Box sx={{ cursor: 'pointer' }} onClick={generatePDF}>
                <input {...getInputProps()} />
                <Paper
                  elevation={isDragActive ? 6 : 1}
                  sx={{
                    p: 2,
                    ml: 4,
                    bgcolor: 'background.paper',
                    color: 'secondary.main',
                    borderColor: 'info.main',
                    border: 1,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                      bgcolor: 'background.default',
                      boxShadow: 3,
                    },
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    Generate PDF invoice
                  </Typography>
                </Paper>
              </Box> */}
              <AwaitButton
                color="primary"
                size="large"
                type="submit"
                variant="outlined"
                loading={isSubmitting}
                onClick={generatePDF}
                sx={{
                  p: 3.5,
                  ml: 4,
                }}
              >
                Generate PDF invoice
              </AwaitButton>
            </ScopeGuard>
          </Grid>
        </DialogActions>
      </Dialog>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
    </>
  );
}
