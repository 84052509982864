import PropTypes from 'prop-types';
import { TextField, InputAdornment } from '@mui/material';
import { useDispatch } from '../../../redux/store';
import { changeInvoiceItemDataFromRedux } from '../../../redux/slices/invoicing';
import { useScopeCheck } from '../../../reusable-components/scopes';
import { formatMoney } from '../../../utils/formatNumber';

Price.propTypes = {
  invoiceId: PropTypes.number,
  row: PropTypes.object,
};

export default function Price({ row, invoiceId }) {
  const dispatch = useDispatch();
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);

  return hasEditAccess ? (
    <>
      <TextField
        size="small"
        type="number"
        value={row.price || ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={(e) => {
          const newValue = formatMoney(e.target.value);
          if (!Number.isNaN(newValue)) {
            dispatch(changeInvoiceItemDataFromRedux({ id: row.id, field: 'price', value: newValue }, invoiceId));
          }
        }}
      />
    </>
  ) : (
    <>
      <TextField
        size="small"
        type="number"
        disabled
        value={row.price || ''}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </>
  );
}
