import { Box, Button, Grid, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { postApproveOrder, postSaveOrder, setReviewOrder } from '../../../../api/orders';
import { useTabs } from '../../../../context/TabContext';
import { getUnreadData } from '../../../../redux/slices/dashboard';
import {
  clearTempOrderList,
  getPendingOrderInfo,
  getPendingOrders,
  getPreviousOrderInfo,
  removeCartCheckedFromRedux,
} from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import { AwaitButton } from '../../../../reusable-components/await-button';
import Iconify from '../../../../reusable-components/iconify';
import { ScopeGuard, useScopeCheck } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { fISODateTime } from '../../../../utils/formatTime';
import NewItemDialog from '../../admin/catalog/NewItemDialog';
import CartCheck from './CartCheck';
import DownloadOrderDropdown from './DownloadOrderDropdown';
import OrderTotal from './OrderTotal';

CustomToolbar.propTypes = {
  data: PropTypes.array,
  setSelected: PropTypes.func,
  setSplitOrder: PropTypes.func,
  addItemCategoryId: PropTypes.number,
  setAddItemCategoryId: PropTypes.func,
  allReqDates: PropTypes.array,
  altAddress: PropTypes.object,
};

export default function CustomToolbar({
  data,
  setSelected,
  setSplitOrder,
  addItemCategoryId,
  setAddItemCategoryId,
  allReqDates,
  altAddress,
}) {
  const [searchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const hasAccess = useScopeCheck(['Edit-Order', 'Submit-Order'], true);
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { addNewTabAndNavigate } = useTabs();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { orderById } = useSelector((state) => state.orders);
  const {
    unsavedChanges,
    items = [],
    facilityId,
    orderAgoraCategories = [],
    vendors = [],
    orderNumber,
  } = orderById[id] || {};

  const { user } = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    data: { pendingOrders },
  } = useSelector((state) => state.orders);

  const orderReviews = useMemo(
    () => pendingOrders?.find((o) => o.id === orderById[id]?.id)?.reviews,
    [id, orderById, pendingOrders]
  );

  const open = Boolean(anchorEl);
  const openMenu = Boolean(menuAnchorEl);

  const handleClickMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (e) => {
    setMenuAnchorEl(null);
  };
  const handleCancel = () => {
    //true = skipCartCheck
    dispatch(getPendingOrderInfo(id, true));
  };

  const handleSetReviewOrder = async (id) => {
    const response = await setReviewOrder(id);
    if (response.status === 200) {
      dispatch(getPendingOrders());
      dispatch(getPendingOrderInfo(id));

      enqueueSnackbar('Order has been reviewed', { variant: 'success' });
    }
  };

  const saveOrderFunction = async () => {
    const response = await postSaveOrder({ orderId: id, items });
    if (response.status === 200) {
      dispatch(clearTempOrderList(id));
      dispatch(getPendingOrderInfo(id));
      setSelected([]);
      setSplitOrder(false);
      setAddItemCategoryId(null);
      enqueueSnackbar('Order has been updated!', { variant: 'success' });
    } else {
      enqueueSnackbar('Error saving order', { variant: 'error' });
    }
  };
  const processOrderFunction = async () => {
    setLoading(true);
    dispatch(removeCartCheckedFromRedux());

    const filteredItems = items?.filter((item) => item.approved && item.deleted !== true);

    const response = await postApproveOrder({
      rerunOrder: filter === 'rerun',
      orderId: id,
      items: filteredItems,
      address: altAddress,
      vendorDates: allReqDates.map((date) => ({
        vendorId: date.vendorId,
        date: fISODateTime(date.date),
      })),
    });
    if (response.status === 200) {
      if (!response.data.success && response.data.message === 'Something has gone wrong') {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        return;
      }
      const fullSuccess = response.data.message === 'Success';
      dispatch(getPreviousOrderInfo(id));
      enqueueSnackbar(
        `${!fullSuccess ? `${response.data.message}\n` : ''}Order #${orderNumber || id} has been ` +
          `${fullSuccess ? 'successfully' : 'partially'} processed!`,
        {
          variant: fullSuccess ? 'success' : 'error',
          style: { whiteSpace: 'pre-wrap' },
          autoHideDuration: 3000,
          action: (key) => (
            <>
              <Button
                onClick={() => {
                  addNewTabAndNavigate(`/dashboard/orders/${id}?filter=previous`);
                }}
                color={fullSuccess ? 'secondary' : 'primary'}
                endIcon={<Iconify icon="material-symbols:open-in-new" />}
              >
                #{orderNumber || id}
              </Button>

              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
                sx={{
                  color: '#fff',
                }}
              >
                X
              </Button>
            </>
          ),
        }
      );
      navigate(`/dashboard/orders?filter=pending`);
      dispatch(getUnreadData());
    } else {
      enqueueSnackbar('Error processing order', { variant: 'error' });
    }
  };

  const itemMissingCategory = items?.some(
    (item) => item.approved === true && (!item.agoraCategory.id || !item.agoraSubcategory.id)
  );
  const noItems = items === null || items.length === 0;

  return (
    <>
      {addItemCategoryId ? (
        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: 1 }} spacing={2}>
          <Grid item>
            <NewItemDialog
              agoraCat={orderAgoraCategories}
              agoraSubCat={vendors}
              agoraVendors={vendors}
              addToOrderList={{
                facilityId,
                categoryId: addItemCategoryId,
              }}
            />
          </Grid>
          <Grid item>
            <OrderTotal />
          </Grid>
          <Grid item>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="#208ee0">
              Order #{orderNumber || id}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mb: 1 }}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item>
                  <Button
                    variant="text"
                    onClick={() => {
                      if (filter === 'rerun') {
                        navigate(`/dashboard/orders/${id}?filter=previous`);
                      } else {
                        navigate(`/dashboard/orders?filter=pending`);
                      }
                    }}
                    startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
                    sx={{
                      color: '#454F5B',
                      fontWeight: 'bold',
                    }}
                  >
                    {filter === 'rerun' ? 'Back to completed order' : ' Back to pending orders'}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="#208ee0">
                Order #{orderNumber || id}
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
                <Grid item>
                  <Grid item>
                    <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                      <Button
                        sx={{ mr: 1 }}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        startIcon={<Iconify icon="eva:plus-outline" />}
                        onClick={handleClickMenu}
                      >
                        Add items
                      </Button>
                    </ScopeGuard>
                    <Menu
                      id="basic-menu"
                      anchorEl={menuAnchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {orderAgoraCategories?.map((category) => (
                        <MenuItem
                          key={category.id}
                          onClick={() => {
                            setMenuAnchorEl(null);
                            setAddItemCategoryId(category.id);
                          }}
                        >
                          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                            {category.name}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>

                    <Box sx={{ display: 'inline-block', mr: 1 }}>
                      <DownloadOrderDropdown />
                    </Box>

                    <ScopeGuard scopes={['Edit-Order']} allowAdmin>
                      <Button
                        color="secondary"
                        onClick={() => {
                          setSplitOrder(true);
                        }}
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                        startIcon={<Iconify icon={'lucide:split'} />}
                      >
                        Split order
                      </Button>
                    </ScopeGuard>

                    <ScopeGuard scopes={['Cart-Check', 'Edit-Order']} allowAdmin>
                      <CartCheck data={data} />
                    </ScopeGuard>
                    <OrderTotal />
                    {orderReviews?.find((o) => o.reviewerEmail === user.email)?.reviewed === false && (
                      <AwaitButton
                        color="primary"
                        size="small"
                        variant="outlined"
                        loading={loading}
                        sx={{ mr: 1 }}
                        onClick={() => handleSetReviewOrder(id)}
                      >
                        Review order
                      </AwaitButton>
                    )}
                    {filter !== 'rerun' && unsavedChanges && !addItemCategoryId && hasAccess ? (
                      <>
                        <Button sx={{ mr: 1 }} size="small" variant="outlined" color="error" onClick={handleCancel}>
                          Cancel
                        </Button>

                        <AwaitButton
                          color="primary"
                          size="small"
                          variant="contained"
                          sx={{ mr: 1 }}
                          onClick={saveOrderFunction}
                        >
                          Save changes
                        </AwaitButton>
                      </>
                    ) : (
                      <Tooltip
                        placement="top"
                        title={
                          itemMissingCategory
                            ? 'Make sure all approved items have a category and subcategory.'
                            : noItems
                            ? 'There must be at least one item.'
                            : ''
                        }
                      >
                        <span>
                          <ScopeGuard scopes={['Submit-Order']} allowAdmin>
                            <AwaitButton
                              color="primary"
                              size="small"
                              variant="contained"
                              loading={loading}
                              sx={{ mr: 1 }}
                              disabled={itemMissingCategory || noItems}
                              onClick={processOrderFunction}
                            >
                              Process order
                            </AwaitButton>
                          </ScopeGuard>
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
