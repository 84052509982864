import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { cloudinaryFull, cloudinaryThumbnail } from '../../utils/formatCloudinary';
import { fCurrency } from '../../utils/formatNumber';
import { fDate, fMonthDayYear, fMonthDayYearShort } from '../../utils/formatTime';
import Iconify from '../iconify';
import Image from '../image';
import Lightbox from '../lightbox';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);

const invoiceId = {
  field: 'invoiceId',
  headerName: 'PO #',
  width: 90,
  type: 'customText',
};

const qty = { field: 'quantity', headerName: 'Qty', minWidth: 50, flex: 0.5 };
const date = {
  field: 'date',
  headerName: 'Invoice Date',
  flex: 0.7,
  renderCell: (params) => renderIcon(fMonthDayYearShort(params.row.date), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};
const invoiceDate = {
  field: 'invoiceDate',
  headerName: 'Invoice Date',
  flex: 0.7,
  renderCell: (params) => renderIcon(fDate(params.value), 'material-symbols:calendar-today-outline'),
  type: 'customDate',
  valueGetter: (params) => new Date(params.value),
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Sent on',
  type: 'customDate',
  flex: 1,
  renderCell: (params) => renderIcon(fMonthDayYearShort(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
};
const dateAddedFile = {
  field: 'dateAdded',
  headerName: 'Date Added',
  type: 'customDate',
  flex: 1,
  renderCell: (params) => renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
};
const dateSaved = {
  field: 'dateSaved',
  headerName: 'Date Added',
  type: 'customDate',
  flex: 1,
  renderCell: (params) => renderIcon(fDate(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
};

const total = {
  field: 'total',
  headerName: 'Total',
  minWidth: 70,
  flex: 0.5,
  type: 'customMoney',
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.total,
};
const invoiceItemTotal = {
  field: 'total',
  headerName: 'Total',
  flex: 0.5,
  type: 'customMoney',
  valueGetter: (params) => fCurrency(params.row.quantity * params.row.price + params.row.taxAmount),
};
const shipping = {
  field: 'shipping',
  headerName: 'Shipping',
  minWidth: 60,
  flex: 0.5,
  renderCell: (params) => fCurrency(params.value),
  valueGetter: (params) => params.row.shipping,
  type: 'customMoney',
};
const account = {
  field: 'account',
  headerName: 'Account',
  flex: 0.5,
};

const exists = {
  field: 'exists',
  headerName: 'Exists',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};
const success = {
  field: 'success',
  headerName: 'Success',
  flex: 0.5,
  align: 'right',
  headerAlign: 'right',
  renderCell: (params) => {
    const { value } = params;
    return value ? (
      <Iconify icon="material-symbols:check-circle-outline" color="primary" />
    ) : (
      <Iconify icon="ic:sharp-close" color="error" />
    );
  },
};

export default function RenderImage({ params }) {
  // if (params.row.productDetails[0].baseUOMAmount || params.row.productDetails[0].baseUOMType) {
  // }
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const picture = pictures[0];
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };
  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(picture)}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
const facility = {
  field: 'facility',
  headerName: 'Facility',
  minWidth: 100,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
  valueGetter: (params) => params.row.facility,
};
const vendorFacility = {
  field: 'vendorFacility',
  headerName: 'Vendor Facility',
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
  valueGetter: (params) => params.row.vendorFacility,
};
const fileName = {
  field: 'fileName',
  headerName: 'File Name',
  width: 300,
  valueGetter: (params) => params.row.fileName,
};
const sentTo = {
  field: 'sentTo',
  headerName: 'Sent To',
  minWidth: 90,
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
  valueGetter: (params) => params.row.sentTo,
};
const errorMessage = {
  field: 'errorMessage',
  headerName: 'Error',
  minWidth: 70,
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        color: 'red',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.errorMessage,
};

// const address = {
//   field: 'address',
//   headerName: 'Address',
//   flex: 0.8,
//   type: 'customText',
// };

const address = {
  field: 'address',
  headerName: 'Address',
  minWidth: 100,
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.address,
};
const description = {
  field: 'description',
  headerName: 'Description',
  flex: 0.8,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.description,
};

const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  minWidth: 100,
  flex: 0.5,
  type: 'customSelect',
};
const vendorInvoiceId = {
  field: 'vendorInvoiceId',
  headerName: 'Vendor Id',
  minWidth: 90,
  flex: 0.8,
  type: 'customText',
};
const invoiceVendorId = {
  field: 'invoiceVendorId',
  headerName: 'File',
  flex: 1,
  type: 'customText',
};
const enteredBy = {
  field: 'enteredBy',
  headerName: 'Entered By',
  width: 300,
  type: 'customText',
};

const vendorItemId = {
  field: 'vendorItemId',
  headerName: 'SKU',
  flex: 0.5,
  type: 'customText',
};

const file = {
  field: 'file',
  headerName: 'File Name',
  width: 300,
  type: 'customText',
};
const quantity = {
  field: 'quantity',
  headerName: 'Sent',
  flex: 0.5,
  type: 'customText',
};
const uomType = {
  field: 'uomType',
  headerName: 'UOM',
  flex: 0.5,
  type: 'customText',
};

const ordered = {
  field: 'expectedAmount',
  headerName: 'Ordered',
  flex: 0.5,
  type: 'customText',
};

//Gl Items columns
const glItemId = { field: 'glItemId', headerName: 'GlItemId' };
const deleteFlag = {
  field: 'deleteFlag',
  headerName: 'deleteFlag',
};

const addedBy = {
  field: 'addedBy',
  headerName: 'Added by',
  type: 'customSelect',
  minWidth: 100,
  flex: 0.5,
};
const approver = {
  field: 'approvedBy',
  headerName: 'Approver',
  type: 'customSelect',
  minWidth: 100,
  flex: 0.5,
};

const PENDING_INVOICES = [
  invoiceId,
  facility,
  sentTo,
  address,
  errorMessage,
  shipping,
  qty,
  total,
  vendor,
  date,
  dateAdded,
  addedBy,
];
const COMPLETED_INVOICES = [
  invoiceId,
  facility,
  sentTo,
  address,
  shipping,
  qty,
  total,
  vendor,
  vendorInvoiceId,
  date,
  dateAdded,
  addedBy,
  approver,
];

const INVOICE = [invoiceId, facility, sentTo, address, errorMessage, shipping, qty, total, vendor, vendorInvoiceId];
const SINGLE_INVOICE = [vendorItemId, description, ordered, uomType, invoiceItemTotal];
const INVOICE_GL_ITEM = [glItemId, deleteFlag];

const STATEMENTS = [total, file, dateSaved];
const STATEMENT_LINES = [vendorInvoiceId, facility, vendorFacility, account, exists];
const INVOICE_FILES = [fileName, enteredBy, dateAddedFile];
const UPLOAD_PDF = [invoiceVendorId, success];
const UPLOAD_STATEMENTS = [vendorInvoiceId, facility, vendorFacility, account, invoiceDate, total, exists];

export {
  SINGLE_INVOICE,
  INVOICE,
  PENDING_INVOICES,
  COMPLETED_INVOICES,
  INVOICE_FILES,
  STATEMENTS,
  STATEMENT_LINES,
  UPLOAD_PDF,
  UPLOAD_STATEMENTS,
  INVOICE_GL_ITEM,
};
