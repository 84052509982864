import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, Button, Grid, Typography } from '@mui/material';
import { ShoppingCart, GrandTotal } from './index';
import Iconify from '../../../../../reusable-components/iconify';
import { useSelector, useDispatch } from '../../../../../redux/store';
import { BudgetBox } from '../index';
import { ConfirmDialog } from '../../../../../reusable-components/confirm-dialog';
import { deleteCartApi } from '../../../../../api/cart';
import { ScopeGuard, useScopeCheck } from '../../../../../reusable-components/scopes';
import { HOST_API_URL } from '../../../../../config-global';
import { cartCheck, bulkUpdateCart } from '../../../../../redux/slices/cart';
import { useSnackbar } from '../../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../../reusable-components/await-button';

CartView.propTypes = {
  items: PropTypes.object,
  index: PropTypes.number,
  setShowSelection: PropTypes.func,
  showSelection: PropTypes.string,
};

export default function CartView({ items, index, setShowSelection, showSelection }) {
  const { enqueueSnackbar } = useSnackbar();

  const hasBudgetViewAccess = useScopeCheck(['View-Order'], true);
  const navigate = useNavigate();
  const {
    data: { unsavedChanges },
  } = useSelector((state) => state.cart);
  const { isLoading } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const {
    data: { facility },
  } = useSelector((state) => state.cart);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setShowSelection(e?.target?.textContent || '');
    setAnchorEl(null);
  };
  const {
    data: { budgets },
  } = useSelector((state) => state.cart);
  const { facilityId = '' } = useParams();

  const deleteCart = async () => {
    handleClose();
    await deleteCartApi(facilityId);
    //refresh the page
    navigate(`/dashboard/home`);
  };

  const bulkUpdate = () => {
    dispatch(bulkUpdateCart(facilityId));
  };

  const cartCheckFunction = () => {
    setShowErrorMsg(true);
    const res = dispatch(cartCheck(facilityId));
    res.then((response) => {
      if (response) {
        enqueueSnackbar('Cart Check Successful', { variant: 'success' });
      } else {
        enqueueSnackbar('Cart Check Failed', { variant: 'error' });
      }
    });
  };

  if (!items) {
    return null;
  }
  return (
    <Grid item xl={12}>
      {index === 0 && (
        <>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" color="primary">
                {facility?.label}
              </Typography>
            </Grid>
          </Grid>
          <ScopeGuard scopes={['View-Order']} allowAdmin>
            {' '}
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <ScopeGuard scopes={['Cart-Check', 'Edit-Order']} allowAdmin>
                  <AwaitButton
                    variant="outlined"
                    size="small"
                    color="info"
                    startIcon={<Iconify icon="mdi:cart-check" />}
                    sx={{ mr: 2 }}
                    onClick={cartCheckFunction}
                    loading={isLoading}
                  >
                    Cart Check
                  </AwaitButton>
                </ScopeGuard>

                <Button
                  variant="outlined"
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={() => {
                    window.open(`${HOST_API_URL}Api/DownloadCart?facilityId=${facilityId}`, '_blank');
                  }}
                  startIcon={<Iconify icon="mdi:tray-arrow-down" />}
                  color="secondary"
                >
                  Download Cart
                </Button>
                <AwaitButton
                  variant="outlined"
                  size="small"
                  startIcon={<Iconify icon="tabler:refresh" />}
                  onClick={bulkUpdate}
                  sx={{ mr: 2 }}
                  disabled={!unsavedChanges}
                >
                  Save Changes to Cart
                </AwaitButton>
                <Button
                  size="small"
                  color="error"
                  onClick={handleClick}
                  variant="outlined"
                  startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
                />

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem onClick={handleClose}>
                    <Button size="small" color="error" variant="text" startIcon={<Iconify icon="ph:check-fill" />}>
                      Select items to delete
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <ConfirmDialog
                      icon="delete-outline"
                      title="Delete all carts?"
                      color="error"
                      content="Doing so will delete all items in all carts. Are you sure you want to delete all carts?"
                      actionButton="Delete all carts"
                      button="text"
                      sx={{ mr: 2 }}
                      fullWidth
                      action={deleteCart}
                    />
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={2.5}>
                <GrandTotal items={items} />
              </Grid>
            </Grid>{' '}
          </ScopeGuard>
        </>
      )}
      <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={hasBudgetViewAccess ? 9 : 12}>
          <ShoppingCart
            items={items}
            showSelection={showSelection}
            showErrorMsg={showErrorMsg}
            handleClose={handleClose}
            setShowSelection={setShowSelection}
          />
        </Grid>
        <ScopeGuard scopes={['View-Order']} allowAdmin>
          <Grid item xs={3} sx={{ mt: 7 }}>
            <BudgetBox
              catId={budgets.find((budget) => budget.category === items?.agoraCategory)?.agoraCategoryId}
              cart={items.items.filter((item) => item.quantity > 0)}
              facId={facilityId}
            />
          </Grid>
        </ScopeGuard>
      </Grid>
    </Grid>
  );
}
