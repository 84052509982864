import { Box, Grid, Stack, Typography, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { postAddTicketImage } from '../../../../api/tickets';
import { getTicketMessagesFromOrder, sendTicketMessage } from '../../../../redux/slices/tickets';
import { updateMessageCountById } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { fMonthDayTimeYear } from '../../../../utils/formatTime';
import Lightbox from '../../../../reusable-components/lightbox';
import OrderNotesItem from './OrderNotesItem';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import Editor from '../../../../reusable-components/editor';

NotesView.propTypes = {
  id: PropTypes.number,
};

export default function NotesView({ id }) {
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const fileInputRef = useRef(null);

  const { user } = useSelector((state) => state.user);

  const [selectedImage, setSelectedImage] = useState(-1);
  const IMAGES = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];
  const { ticketMessages, users, ticketNumber } = useSelector((state) => state.tickets.data.ticketById[id] || {});

  const handleChangeMessage = (value) => {
    value = value.replace(/<h1>/g, '<b>').replace(/<\/h1>/g, '</b>');
    value = value.replace(/<h2>/g, '<b>').replace(/<\/h2>/g, '</b>');
    value = value.replace(/<h3>/g, '<b>').replace(/<\/h3>/g, '</b>');
    value = value.replace(/<h4>/g, '<b>').replace(/<\/h4>/g, '</b>');
    value = value.replace(/<h5>/g, '<b>').replace(/<\/h5>/g, '</b>');
    value = value.replace(/<h6>/g, '<b>').replace(/<\/h6>/g, '</b>');

    if (value.includes('<img src=')) {
      const imgSrcRegex = /<img src="([^"]*)"/g;
      const imgSrcMatch = value.matchAll(imgSrcRegex);
      const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
      imgSrcArray.forEach(async (img) => {
        if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = await postAddTicketImage(img.src);
          value = value.replace(img.src, imgUrl.data);
          setMessage(value);
        }
      });
    }
    setMessage(value);
  };
  const [attachments, setAttachments] = useState([]);

  const handleFileInputChange = (event) => {
    const maxAllowedFiles = 5;
    if (
      event.target.files.length > maxAllowedFiles ||
      event.target.files.length + attachments?.length > maxAllowedFiles
    ) {
      alert(`Only ${maxAllowedFiles} files can be uploaded at a time.`);
      event.target.value = '';
      return;
    }
    const { files } = event.target;
    const fileList = [];
    for (let i = 0; i < files.length; i++) {
      if (attachments?.some((x) => x.name === files[i].name)) {
        alert(`File ${files[i].name} already exists.`);
      } else fileList.push(files[i]);
    }
    setAttachments((prevAttachments) => {
      if (prevAttachments === undefined) {
        return fileList;
      }
      return [...prevAttachments, ...fileList];
    });
  };

  const removeFile = (file) => () => {
    setAttachments((prevAttachments) => prevAttachments.filter((x) => x !== file));
  };

  useEffect(() => {
    const scrollMessagesToBottom = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollMessagesToBottom();
  }, [ticketMessages]);
  //two options for imagesLightbox url one is from message.note contains img src and the other is from message.file
  const imagesLightbox = ticketMessages
    ?.filter(
      (message) =>
        message.note.includes('<img src="') || (message.file && IMAGES.includes(message.note.split('.').pop()))
    )
    .flatMap((message, index) => {
      if (message.file) {
        return [{ src: `/api/TicketFile?noteId=${message.id}` }];
        // eslint-disable-next-line no-else-return
      } else if (message.note.includes('img src="')) {
        const imageSources = message.note.matchAll(/src="([^"]*)"/g);
        return Array.from(imageSources, (match) => ({ src: match[1] }));
      }
      return [];
    });

  const getImageID = (imageUrl) => {
    const localIdRegex = /\/TicketFile\?noteId=(\d+)/; // Regular expression to match image ID in URL
    const idRegex = /\/(\w+)\.\w+$/; // Regular expression to match image ID in URL
    if (localIdRegex.test(imageUrl)) {
      return imageUrl.match(localIdRegex)[1];
    }
    if (idRegex.test(imageUrl)) {
      return imageUrl.match(idRegex)[1];
    }
    return imageUrl;
  };

  const handleOpenLightbox = (imageUrl) => {
    const imageIndex = imagesLightbox?.findIndex((image) => getImageID(image.src) === getImageID(imageUrl));
    setSelectedImage(imageIndex);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };

  useEffect(() => {
    if (id) {
      console.log('id', id);
      dispatch(getTicketMessagesFromOrder(id));
    }
  }, [dispatch, id]);

  const [message, setMessage] = useState('');

  const htmlFormatter = async (html) => {
    const pattern = /<p><br><\/p>$/i;
    while (pattern.test(html)) {
      html = html.replace(pattern, '');
    }
    const imgSrcRegex = /<img src="([^"]*)"/g;
    const imgSrcMatch = html.matchAll(imgSrcRegex);
    const imgSrcArray = Array.from(imgSrcMatch, (match) => ({ src: match[1] }));
    const convertedImgPromises = imgSrcArray.map(async (img) => {
      if (!img.src.includes('//res.cloudinary.com/agoraserv')) {
        const imgUrl = await postAddTicketImage(img.src);
        html = html.replace(img.src, imgUrl.data);
      }
    });
    await Promise.all(convertedImgPromises);
    return html;
  };

  const handleSend = async () => {
    if (!message && attachments?.length === 0) return;
    const formattedHtml = await htmlFormatter(message);

    try {
      await dispatch(
        sendTicketMessage({
          message: formattedHtml,
          ticketNumber,
          overrideTicketNumber: id,
          files: attachments,
          sentBy: user.email,
          fullName: `${user.firstName} ${user.lastName}`,
        })
      );

      setMessage('');
      setAttachments([]);
      dispatch(updateMessageCountById(id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid item xl={12} sx={{ padding: 1 }}>
        <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ height: 1 }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            {ticketMessages?.map((message, index) => {
              if (message.systemMessage) {
                // Render a system message with a timestamp.
                return (
                  <Stack key={index} direction="row" justifyContent="center" sx={{ my: 3 }}>
                    <Typography noWrap variant="caption" sx={{ color: 'text.disabled' }}>
                      {`${message.note},`} &nbsp;
                      {fMonthDayTimeYear(message.sentOn)}
                    </Typography>
                  </Stack>
                );
              }
              // Check if the current message has an attachment.
              const hasAttachment = message.file;

              if (hasAttachment) {
                // if (message.attachFileId === 0) {
                return (
                  <OrderNotesItem
                    key={index}
                    message={message}
                    fileArray={[message]}
                    previousMessage={ticketMessages[index - 1]}
                    users={users}
                    onOpenLightbox={(imgUrl) => handleOpenLightbox(imgUrl)}
                  />
                );
                // }
                // return null;
              }
              return (
                <OrderNotesItem
                  key={index}
                  message={message}
                  previousMessage={ticketMessages[index - 1]}
                  users={users}
                  onOpenLightbox={(imgUrl) => handleOpenLightbox(imgUrl)}
                />
              );

              // Render a regular message.
            })}
            <Lightbox
              index={selectedImage}
              slides={imagesLightbox}
              open={selectedImage >= 0}
              close={handleCloseLightbox}
            />
          </Grid>
        </Scrollbar>

        <Box sx={{ mt: 2 }}>
          <Divider
            sx={{
              //2px info color
              borderColor: 'info.main',
              borderWidth: 2,
            }}
          />
          <Stack direction="row" alignItems="flex-start">
            {attachments?.map((file) => (
              <FileThumbnail
                key={file.name}
                file={file}
                tooltip
                sx={{ width: 64, height: 64 }}
                onRemove={removeFile(file)}
              />
            ))}
          </Stack>

          <Editor
            attachFile
            verySimple
            id="ticket-reply"
            value={message}
            onChange={handleChangeMessage}
            placeholder="Add note..."
            sx={{ flexGrow: 1, borderColor: 'transparent' }}
            users={users}
            sendIcon
            handleSend={handleSend}
            handleFileInputChange={handleFileInputChange}
          />
        </Box>

        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
      </Grid>
    </>
  );
}
