import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { DateCalendar } from '@mui/x-date-pickers';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { getBudgets } from '../../../../redux/slices/orderSheet';
import { updateBudgetDate } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';
import { BudgetBar, SubcategoryBudget } from './index';
import { AwaitButton } from '../../../../reusable-components/await-button';

BudgetBox.propTypes = {
  cart: PropTypes.array,
  catId: PropTypes.number,
  facId: PropTypes.any,
  budgetDate: PropTypes.instanceOf(Date) || undefined,
  budgetData: PropTypes.object || undefined,
  changeBudget: PropTypes.bool,
};

export default function BudgetBox({
  cart,
  catId,
  facId,
  budgetData = undefined,
  budgetDate = undefined,
  changeBudget = false,
}) {
  const { id = '' } = useParams();

  const {
    data: { budgets },
  } = useSelector((state) => state.orderSheet);

  const { orderById } = useSelector((state) => state.orders);

  const actualData = budgetData ?? budgets;
  const subcategoryIds = actualData?.flatMap((item) =>
    item.subbudgets
      .filter((subcategory) => !subcategory.excludeFromMain)
      .map((subcategory) => subcategory.agoraSubcategoryId)
  );
  const calculateBudgetTotalAmount = (cart) =>
    cart
      ?.filter(
        (item) =>
          !subcategoryIds ||
          !item?.agoraSubcategory ||
          item?.agoraSubcategoryId === 0 ||
          subcategoryIds?.includes(item?.agoraSubcategoryId) ||
          item?.agoraSubcategory?.id === 0 ||
          subcategoryIds?.includes(item?.agoraSubcategory?.id)
      )
      .map((item) => item.price * item.quantity)
      .reduce((a, b) => a + b, 0);

  const [updatedBudgetDate, setUpdatedBudgetDate] = useState(
    budgetDate ??
      (changeBudget && orderById[id]?.budgetDate
        ? new Date(orderById[id]?.budgetDate)
        : new Date(new Date().toDateString()))
  );
  const getWeekRange = () => {
    const today = new Date(updatedBudgetDate);
    const start = startOfWeek(today);
    const end = endOfWeek(today);
    const startDate = start.getMonth() === today.getMonth() ? start : startOfMonth(today);
    const endDate = end.getMonth() === today.getMonth() ? end : endOfMonth(today);
    return { start: startDate, end: endDate };
  };

  const getDaysLength = (start, end) => Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('category') || '';
  let facilityId = facId;
  if (!facilityId) {
    facilityId = searchParams.get('facilityId');
  }
  const [tab, setTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [censusOpen, setCensusOpen] = useState(false);
  const [changeBudgetDate, setChangeBudgetDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(updatedBudgetDate);
  const [useCurrentCensus, setUseCurrentCensus] = useState(false);
  const [includeUnapproved, setIncludeUnapproved] = useState(false);
  const [budgetOpen, setBudgetOpen] = useState(false);
  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
  const budgetTotalAmount = useMemo(() => calculateBudgetTotalAmount(cart) || 0, [cart]);

  const { start, end } = useMemo(() => getWeekRange(), [updatedBudgetDate]);
  const daysLength = useMemo(() => getDaysLength(start, end), [start, end]);
  const today = new Date(new Date().toDateString());
  const monthDaysLength = getDaysInMonth(today.getFullYear(), today.getMonth());

  useEffect(() => {
    dispatch(
      getBudgets({
        facilityId,
        includeUnapproved,
        useCurrentCensus,
        category: [categoryId],
        orderNumber: changeBudget && id ? id : 0,
      })
    );
  }, [useCurrentCensus, includeUnapproved, facilityId, categoryId, changeBudget, id, updatedBudgetDate]);

  useEffect(() => {
    if (!changeBudget && budgetDate) setUpdatedBudgetDate(budgetDate);
  }, [budgetDate]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const currentBudget = useMemo(
    () => actualData?.find((budget) => budget?.agoraCategoryId === catId),
    [budgetData, budgets, catId]
  );

  if (!currentBudget) {
    return null;
  }

  const saveBudget = async () => {
    const response = await dispatch(updateBudgetDate(id, selectedDate));
    if (response) setUpdatedBudgetDate(selectedDate);
    setChangeBudgetDate(false);
  };

  const {
    category,
    censusAmount,
    monthBudgetAmount,
    monthSpent,
    ppdAmount,
    subbudgets,
    weekBudgetAmount,
    weekSpent,
    icon,
  } = currentBudget;

  return (
    <Box>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="transparent"
        sx={{
          borderRadius: '4px 4px 0px 0px',
          backgroundColor: '#ddeec6',
        }}
      >
        <CustomTab
          label={
            <>
              <Typography variant="subtitle1" color="success.contrastText">
                {updatedBudgetDate.toDateString() === today.toDateString() && 'This'} Month
              </Typography>
              <ButtonBase
                disabled={!changeBudget || !id || tab !== 0}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                  setChangeBudgetDate(!changeBudgetDate);
                }}
              >
                <Tooltip title={changeBudget && id ? 'Adjust Budget Date' : ''}>
                  <Typography variant="caption" color={tab === 0 ? 'secondary' : (theme) => theme.palette.grey[600]}>
                    {updatedBudgetDate.toLocaleString('default', { month: 'long' })}
                    {` ${updatedBudgetDate.getFullYear()}`}

                    {changeBudget && id && (
                      <BuildCircleIcon sx={{ fontSize: 'medium', position: 'absolute', bottom: 4, right: -20 }} />
                    )}
                  </Typography>
                </Tooltip>
              </ButtonBase>
            </>
          }
          selected={tab === 0}
        />
        {!budgetDate && (
          <CustomTab
            label={
              <>
                <Typography variant="subtitle1" color="success.contrastText">
                  {updatedBudgetDate.toDateString() === today.toDateString() && 'This'} Week
                </Typography>
                <ButtonBase
                  disabled={!changeBudget || !id || tab !== 1}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setChangeBudgetDate(!changeBudgetDate);
                  }}
                >
                  <Tooltip title={changeBudget && id ? 'Adjust Budget Date' : ''}>
                    <Typography variant="caption" color={tab === 1 ? 'secondary' : (theme) => theme.palette.grey[600]}>
                      {start.toLocaleString('default', { month: 'short', day: 'numeric' })} -
                      {end.toLocaleString('default', { month: 'short', day: 'numeric' })}
                      {changeBudget && id && (
                        <BuildCircleIcon sx={{ fontSize: 'medium', position: 'absolute', bottom: 4, right: -20 }} />
                      )}
                    </Typography>
                  </Tooltip>
                </ButtonBase>
              </>
            }
            selected={tab === 1}
          />
        )}
      </Tabs>
      <Popover
        anchorEl={anchorEl}
        open={changeBudgetDate}
        onClose={() => {
          setAnchorEl(null);
          setChangeBudgetDate(false);
          setSelectedDate(updatedBudgetDate);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <DateCalendar
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e);
            }}
            sx={{ width: '100%', height: '100%' }}
          />
          <AwaitButton
            sx={{ mr: 1, mb: 1, position: 'absolute', bottom: 10, right: 10 }}
            color="secondary"
            type="button"
            size="small"
            variant="outlined"
            onClick={() => {
              saveBudget();
            }}
          >
            Save
          </AwaitButton>
        </div>
      </Popover>
      <Card
        sx={{
          p: 2,
          borderTop: 'none',
          borderBottom: 'solid 1px #8dc63f',
          borderRight: 'solid 1px #8dc63f',
          borderLeft: 'solid 1px #8dc63f',
        }}
      >
        <Grid container direction="column" spacing={0.5}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" sx={{ mb: 1 }}>
            <Grid item>
              <Iconify icon={icon} width={20} height={35} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{ mt: 0.5, ml: 0.5 }}>
                {category}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid container sx={{ mb: 1 }}>
              <Grid item xs={6}>
                <ExcelBox
                  sx={{
                    borderRadius: '4px 0 0 0 ',
                    borderBottom: 'none',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: '11px',
                    }}
                  >
                    PPD: {fCurrency(ppdAmount)}
                  </Typography>
                </ExcelBox>
              </Grid>

              <Grid item xs={6}>
                <ExcelBox
                  sx={{
                    borderRadius: '0 4px 0 0 ',
                    borderBottom: 'none',
                    borderLeft: 'none',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setCensusOpen(true);
                  }}
                >
                  <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '11px',
                      }}
                    >
                      {useCurrentCensus ? 'Current' : 'Average'} Census: {fNumber(censusAmount)}
                    </Typography>
                    <Badge color="info" variant="dot" invisible={!includeUnapproved}>
                      <Iconify icon={censusOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
                    </Badge>
                  </Grid>
                </ExcelBox>
              </Grid>

              <Menu
                open={censusOpen}
                onClose={() => {
                  setAnchorEl(null);
                  setCensusOpen(false);
                }}
                anchorEl={anchorEl}
                sx={{
                  '& .MuiList-root': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
              >
                <MenuItem
                  sx={{
                    '&:hover': {
                      // backgroundColor: 'transparent',
                      cursor: 'default',
                    },
                    '& .MuiTouchRipple-root': {
                      display: 'none', // Removes the ripple effect
                    },
                  }}
                >
                  <>
                    <Button
                      size="small"
                      variant={useCurrentCensus ? 'outlined' : 'contained'}
                      sx={{
                        borderRadius: '1px',
                      }}
                      color="secondary"
                      onClick={() => setUseCurrentCensus(false)}
                    >
                      Average
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      variant={useCurrentCensus ? 'contained' : 'outlined'}
                      sx={{
                        borderRadius: '1px',
                      }}
                      onClick={() => setUseCurrentCensus(true)}
                    >
                      Current
                    </Button>
                  </>
                </MenuItem>
                <MenuItem
                  sx={{
                    '&:hover': {
                      cursor: 'default',
                    },
                    '& .MuiTouchRipple-root': {
                      display: 'none',
                    },
                  }}
                >
                  <>
                    <FormGroup
                      sx={{
                        ml: 1,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeUnapproved}
                            onChange={() => setIncludeUnapproved(!includeUnapproved)}
                            color="info"
                          />
                        }
                        label={<Typography variant="caption">Include Pending Orders</Typography>}
                      />
                    </FormGroup>
                  </>
                </MenuItem>
              </Menu>
              <Grid item xs={6}>
                <ExcelBox
                  sx={{
                    borderRadius: '0  0 0 4px  ',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setBudgetOpen(true);
                  }}
                >
                  <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '11px',
                      }}
                    >
                      Budget: {fCurrency(tab === 1 ? weekBudgetAmount : monthBudgetAmount)}
                    </Typography>{' '}
                    <Iconify icon={budgetOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} width={16} height={16} />
                  </Grid>
                </ExcelBox>
              </Grid>
              <Menu
                open={budgetOpen}
                onClose={() => {
                  setAnchorEl(null);
                  setBudgetOpen(false);
                }}
                anchorEl={anchorEl}
                sx={{
                  '& .MuiList-root': {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
              >
                <Card
                  sx={{
                    padding: 1,
                  }}
                >
                  <Typography color="secondary">{` Census = ${censusAmount}`}</Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.info.main,
                    }}
                  >{` PPD = ${fCurrency(ppdAmount)}`}</Typography>
                  <Typography color="primary">{` ${tab === 1 ? 'Week' : 'Month'} = ${
                    tab === 1 ? daysLength : monthDaysLength
                  } days`}</Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography color="secondary" display="inline">
                    Census
                  </Typography>
                  <Typography display="inline"> X </Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.info.main,
                    }}
                    display="inline"
                  >
                    PPD
                  </Typography>
                  <Typography display="inline"> X </Typography>
                  <Typography color="primary" display="inline">
                    {tab === 1 ? 'Week' : 'Month'}
                  </Typography>
                  <Typography display="inline">
                    {' '}
                    = {tab === 1 ? fCurrency(weekBudgetAmount) : fCurrency(monthBudgetAmount)}
                  </Typography>
                </Card>
              </Menu>
              <Grid item xs={6}>
                <ExcelBox
                  sx={{
                    borderLeft: 'none',
                    borderRadius: '0  0 4px 0 ',
                  }}
                >
                  <Grid container direction="row" justifyContent="space-around" alignItems="flex-start">
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '11px',
                      }}
                      color={
                        (tab === 1
                          ? weekBudgetAmount - weekSpent - budgetTotalAmount
                          : monthBudgetAmount - monthSpent - budgetTotalAmount) < 0
                          ? 'error'
                          : 'default'
                      }
                    >
                      {' '}
                      Remaining:{' '}
                      {fCurrency(
                        tab === 1
                          ? weekBudgetAmount - weekSpent - budgetTotalAmount
                          : monthBudgetAmount - monthSpent - budgetTotalAmount
                      )}
                    </Typography>
                    <Box
                      sx={{
                        width: 16,
                      }}
                    />
                  </Grid>
                </ExcelBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ ml: -0.5, mb: 1 }}>
            <BudgetBar
              includeUnapproved={includeUnapproved}
              budget={tab === 1 ? weekBudgetAmount : monthBudgetAmount}
              total={(tab === 1 ? weekSpent : monthSpent) + budgetTotalAmount}
              budgetDate={updatedBudgetDate}
              history={tab === 1 ? weekSpent : monthSpent}
              catId={catId}
              facId={facId}
            />
          </Grid>

          <SubcategoryBudget
            subbudgets={subbudgets}
            tab={tab}
            cart={cart}
            censusAmount={censusAmount}
            daysLength={daysLength}
            monthDaysLength={monthDaysLength}
            dataRange={'Week'}
            catId={catId}
            facId={facId}
            budgetDate={updatedBudgetDate}
            includeUnapproved={includeUnapproved}
          />
        </Grid>
      </Card>
    </Box>
  );
}
const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  flex: 1,
  maxWidth: '48%',
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  marginRight: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#ddeec6',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)',
  },
  '& .MuiTab-wrapper': {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ExcelBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 23,
  border: `2px solid ${theme.palette.primary.light}`,
  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
}));
