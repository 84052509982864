import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { postVendorForm } from '../../../../api/invoicing';
import { getVendors } from '../../../../redux/slices/invoicing';
import { useDispatch, useSelector } from '../../../../redux/store';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import FormTextWithValue from '../../../../reusable-components/form/FormTextWithValue';
import Iconify from '../../../../reusable-components/iconify';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

export default function NewVendorModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = useRef(null);

  const { vendors } = useSelector((state) => state.vendors.data);
  const vendorNames = useMemo(() => vendors.map((v) => v.name.toLowerCase().trim()), [vendors]);

  const [value, setValue] = useState({
    name: '',
    emailTo: '',
    emailToCC: '',
    address: '',
    zip: '',
    city: '',
    state: '',
    phone: '',
    logo: '',
    hasInvoices: true,
  });

  const handleRemoveImage = () => {
    setValue({ ...value, logo: '' });
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (file && file?.type.startsWith('image/')) {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64Image = reader.result; // Use the entire base64 data URL

          setValue({ ...value, logo: base64Image });
        };
        reader.onerror = () => {
          enqueueSnackbar('Error uploading image', { variant: 'error' });
        };
      } catch (error) {
        enqueueSnackbar('Invalid file type or error in uploading', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Invalid file type', { variant: 'error' });
    }
  };
  const onDrop = (acceptedFiles) => {
    // Handle file upload here
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue({ ...value, logo: reader.result });
    };
    reader.onerror = () => {
      enqueueSnackbar('Error reading file', { variant: 'error' });
    };
  };

  const {
    getRootProps,
    getInputProps,
    open: openDropzone,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const handleSave = async () => {
    const response = await postVendorForm(value);
    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    } else if (response.status === 200) {
      enqueueSnackbar(`Successfully added ${response.data.name}`, {
        variant: 'success',
      });
      dispatch(getVendors());
      setOpen(false);
    } else
      enqueueSnackbar(`Error: Could not add vendor`, {
        variant: 'error',
      });
  };

  const handleClickOpen = () => {
    setValue({
      name: '',
      emailTo: '',
      emailToCC: '',
      address: '',
      zip: '',
      city: '',
      state: '',
      phone: '',
      logo: '',
      hasInvoices: true,
    });
    setOpen(true);
  };
  const handleClose = () => {
    setValue({
      name: '',
      emailTo: '',
      emailToCC: '',
      address: '',
      zip: '',
      city: '',
      state: '',
      phone: '',
      logo: '',
      hasInvoices: false,
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        New Vendor
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          New Vendor
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Name*" flex={12} name="name" setValue={setValue} />
                {vendorNames.includes(value.name?.toLowerCase().trim()) && (
                  <Alert severity="error" sx={{ width: '100%' }}>
                    Vendor already exists
                  </Alert>
                )}
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Send PO to" flex={6} name="emailTo" setValue={setValue} />
                <FormTextWithValue value={value} title="Copy on PO" flex={6} name="emailToCC" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Address" flex={12} name="address" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="City" flex={6} name="city" setValue={setValue} />
                <FormTextWithValue value={value} title="State" flex={6} name="state" setValue={setValue} />
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <FormTextWithValue value={value} title="Zip" flex={3} name="zip" setValue={setValue} />

                <FormTextWithValue value={value} title="Phone Number" flex={9} name="phone" setValue={setValue} />
              </Stack>

              <DialogContentText sx={{ fontWeight: 'bold', mt: 3, mb: -1 }}>Image</DialogContentText>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={2}>
                  {value.logo && (
                    <Box
                      m={1}
                      boxShadow={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <FileThumbnail
                        file={{ name: value.logo, type: 'image' }}
                        onRemove={() => handleRemoveImage()}
                        sx={{ width: 128, height: 128 }}
                        preview={value.logo}
                        displayImage
                      />

                      <IconButton onClick={() => handleRemoveImage()}>
                        <Iconify icon="material-symbols:delete-outline" width={20} height={20} color="error" />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 2 }}>
                <Box {...getRootProps()} sx={{ cursor: 'pointer' }} onClick={openDropzone}>
                  <input {...getInputProps()} />
                  <Paper
                    elevation={isDragActive ? 6 : 1}
                    sx={{
                      p: 1,
                      bgcolor: 'background.paper',
                      color: 'info.main',
                      borderColor: 'info.main',
                      border: 1,
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        bgcolor: 'background.default',
                        boxShadow: 3,
                      },
                    }}
                  >
                    <Typography variant="body2" textAlign="center">
                      Click to Upload or Drag and Drop
                    </Typography>
                  </Paper>
                </Box>
              </Grid>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ mt: 1 }}>
                <Grid item xs={2}>
                  <Typography variant="body2">Image url </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    value={value.logo}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        logo: e.target.value,
                      })
                    }
                    label=""
                    size="small"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      autoComplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileInputChange}
              />
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
          <AwaitButton
            variant="contained"
            color="primary"
            size="medium"
            disabled={value.name === '' || vendorNames.includes(value.name?.toLowerCase().trim())}
            sx={{ width: 150 }}
            onClick={handleSave}
          >
            Save
          </AwaitButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
