import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Box, Menu, MenuItem, Tooltip } from '@mui/material';
import { useSelector } from '../../redux/store';
import Iconify from '../iconify';
import { useUpdateOrderItemRedux } from '../../hooks/useUpdateOrderItemRedux';
import { useScopeCheck } from '../scopes';

SubCategory.propTypes = {
  row: PropTypes.object,
};

export default function SubCategory({ row }) {
  const { id = '' } = useParams();
  const updateOrderItemRedux = useUpdateOrderItemRedux();
  const hasAccess = useScopeCheck(['Edit-Order'], true);
  const { orderById } = useSelector((state) => state.orders);
  const { agoraSubcategories = [] } = orderById[id] || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={row.agoraSubcategory?.name || ''}>
        {hasAccess ? (
          <Typography
            // color={stringToColor(row.agoraCategory?.name)}
            variant="subtitle2"
            sx={{
              border: 1,
              width: '125px',
              borderRadius: '3px',
              position: 'relative',
              display: 'flex', // Use flexbox
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: `#C4CDD5`,
              },
              my: '4px',
              py: '2px',
              px: 0.5,
            }}
            onClick={handleClick}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflow: 'hidden', // Hide overflow
                textOverflow: 'ellipsis', // Add ellipsis for overflow
                whiteSpace: 'nowrap', // Prevent wrapping
                pr: 2,
              }}
            >
              {row.agoraSubcategory?.name || ''}
            </Box>
            <Box>
              <Iconify icon={'mdi:chevron-down'} />
            </Box>
          </Typography>
        ) : (
          <Typography
            // color={stringToColor(row.agoraCategory?.name)}
            variant="subtitle2"
            sx={{
              border: 1,
              width: '125px',
              borderRadius: '3px',
              position: 'relative',
              display: 'flex', // Use flexbox
              alignItems: 'center',

              my: '4px',
              py: '5px',
              px: 0.5,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                overflow: 'hidden', // Hide overflow
                textOverflow: 'ellipsis', // Add ellipsis for overflow
                whiteSpace: 'nowrap', // Prevent wrapping
                pr: 2,
              }}
            >
              {row.agoraSubcategory?.name || ''}
            </Box>
          </Typography>
        )}
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {agoraSubcategories
          ?.filter(
            (sub) =>
              //sub.mainCategoryId === subCategory.mainCategoryId
              sub.mainCategoryId === row.agoraCategory?.id
          )
          .map((subCategory) => (
            <MenuItem
              key={subCategory?.id}
              onClick={() => {
                updateOrderItemRedux(row?.id, 'agoraSubcategory', {
                  id: subCategory?.id,
                  name: subCategory?.name,
                  mainCategoryId: subCategory.mainCategoryId,
                });
                handleClose();
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} color={subCategory?.name}>
                {subCategory?.name}
              </Typography>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
