import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import PropTypes from 'prop-types';

CustomDatePanel.propTypes = {
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default function CustomDatePanel({ activeFilter, filters, setFilters }) {
  const [value, setValue] = useState(filters[activeFilter]?.value || [null, null]);

  const handleDateChange = (newValue) => {
    // Clone newValue to avoid mutating state directly
    const adjustedValue = [...newValue];

    if (adjustedValue[1]) {
      const endDate = new Date(adjustedValue[1]);
      // Set time to 23:59:59
      endDate.setHours(23, 59, 59);
      // Update the end date in the adjusted value
      adjustedValue[1] = endDate;
    }

    setValue(adjustedValue);

    if (!adjustedValue[0] && !adjustedValue[1]) {
      const newFilters = { ...filters };
      delete newFilters[activeFilter];
      setFilters(newFilters);
    } else {
      setFilters((prev) => ({ ...prev, [activeFilter]: { value: adjustedValue, operator: 'dateRange' } }));
    }
  };

  const handleClear = () => {
    setValue([null, null]);
    const newFilters = { ...filters };
    delete newFilters[activeFilter];
    setFilters(newFilters);
  };

  return (
    <Box>
      <Typography id="date-range" gutterBottom>
        Date Range
      </Typography>
      <DateRangePicker
        startText="Start date"
        endText="End date"
        value={value}
        onChange={handleDateChange}
        calendars={1}
        autoFocus
        sx={{
          '& .MuiInputBase-root': {
            padding: '0px',
            borderRadius: '4px',
            maxHeight: '40px',
            // width: '130px',
          },
          '& .MuiFormLabel-root': {
            fontSize: '14px',
          },
        }}
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }} />
            <TextField {...endProps} />
          </>
        )}
      />
      {value[0] && value[1] && (
        <Box sx={{ ml: -1, mt: 1 }}>
          <Button variant="text" size="small" onClick={handleClear}>
            Clear
          </Button>
        </Box>
      )}
    </Box>
  );
}
