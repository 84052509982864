// DashboardHome.jsx
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Grid, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from '../../redux/store';
import { getDashboard } from '../../redux/slices/dashboard';
import { refreshLogin } from '../../redux/slices/user';
import { DashboardDataView, DashboardTickets, DashboardAlerts } from '../../components/dashboard/home';
import { SpinningLogo } from '../../reusable-components/logo';

export default function DashboardHome() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { roles } = useSelector((state) => state.user.user);
  const { isLoading, data } = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (searchParams.get('sso')) {
      dispatch(refreshLogin());
    }
    dispatch(getDashboard());
  }, [dispatch, searchParams]);

  return (
    <>
      <Helmet>
        <title>Dashboard | Platform Solutions</title>
      </Helmet>
      {isLoading && !data?.orders?.length && !data?.tickets?.length && !data?.invoices?.length && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 999999999999999,
          }}
        >
          <SpinningLogo />
        </Box>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={8} xl={8}>
          <DashboardDataView />
        </Grid>

        <Grid item xs={12} md={4} xl={4}>
          <DashboardTickets />
        </Grid>
      </Grid>
    </>
  );
}
