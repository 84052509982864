import { useParams } from 'react-router-dom';
import { updateOrderItemInRedux } from '../redux/slices/orders';
import { useDispatch } from '../redux/store';

export const useUpdateOrderItemRedux = () => {
  const dispatch = useDispatch();
  const { id: orderId = '' } = useParams();

  return (id, field, value) => {
    dispatch(updateOrderItemInRedux(orderId, id, field, value));
  };
};
