import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import {
  fetchVendorProductData as catalogFetchVendorProductData,
  productsFormData as catalogProductsFormData,
  updateProductFormData as catalogUpdateProductFormData,
  postAddProductImage as catalogPostAddProductImage,
  duplicateItemData as catalogDuplicateItemData,
} from '../../../../api/catalog';
import {
  fetchVendorProductData as warehouseFetchVendorProductData,
  productsFormData as warehouseProductsFormData,
  updateProductFormData as warehouseUpdateProductFormData,
  postAddProductImage as warehousePostAddProductImage,
  duplicateItemData as warehouseDuplicateItemData,
} from '../../../../api/warehouse';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { getAddToOrderList } from '../../../../redux/slices/orders';
import {
  getProductDetails as catalogGetProductDetails,
  getCatalog as catalogGetCatalog,
} from '../../../../redux/slices/catalog';
import {
  getProductDetails as warehouseGetProductDetails,
  getCatalog as warehouseGetCatalog,
} from '../../../../redux/slices/warehouse';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';

NewItemDialog.propTypes = {
  parentInfo: PropTypes.object,
  data: PropTypes.array,
  row: PropTypes.object,
  addToOrderList: PropTypes.object,
  isWarehouseItem: PropTypes.bool,
  agoraCat: PropTypes.array,
  agoraSubCat: PropTypes.array,
  agoraVendors: PropTypes.array,
};

export default function NewItemDialog({
  parentInfo,
  data,
  row,
  addToOrderList = null,
  isWarehouseItem = false,
  agoraCat,
  agoraSubCat,
  agoraVendors,
}) {
  const dispatch = useDispatch();
  const { id = '' } = useParams();

  const [open, setOpen] = useState(false);

  const [vendor, setVendor] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [uomType, setUomType] = useState(null);
  const [step, setStep] = useState(parentInfo ? 2 : 1);
  const [uomType2, setUomType2] = useState(null);
  const [uomTypes, setUomTypes] = useState(null);
  const [productData, setProductData] = useState(null);
  const [parentData, setParentData] = useState(null);
  const [additionalUOM, setAdditionalUOM] = useState(false);
  const [ppdOptions, setPpdOptions] = useState([]);
  const [categories, setCategories] = useState([
    {
      agoraCategoryId: addToOrderList?.categoryId || null,
      agoraSubcategoryId: null,
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    data: { agoraCategories, agoraSubcategories, vendors },
  } = useSelector((state) => (isWarehouseItem ? state.warehouse : state.catalog));
  const [allCategories, setAllCategories] = useState(null);
  const [allSubcategories, setAllSubCategories] = useState(null);
  const [allVendors, setAllVendors] = useState(null);
  useEffect(() => {
    if (addToOrderList) {
      setAllCategories(agoraCat);
      setAllSubCategories(agoraSubCat);
      setAllVendors(agoraVendors);
    } else {
      setAllCategories(agoraCategories);
      setAllSubCategories(agoraSubcategories);
      setAllVendors(vendors);
    }
  }, [agoraCategories]);
  const checkDuplicateItem = async (vendorId, vendorItemId) => {
    const response = isWarehouseItem
      ? await warehouseDuplicateItemData(vendorId, vendorItemId)
      : await catalogDuplicateItemData(vendorId, vendorItemId);
    if (response.status === 200) {
      if (response.data === true) {
        enqueueSnackbar('Item already exists with same vendor and vendor id', { variant: 'error' });
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    if (parentInfo) {
      setParentData({
        description: parentInfo.description,
        notes: parentInfo.notes,
      });
      setCategories(parentInfo.categories);
    }
  }, [parentInfo]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setVendor(null);
    setVendorId(null);
    setUomType(null);
    setUomTypes(null);
    setProductData(null);
    setAdditionalUOM(false);
    setStep(parentInfo ? 2 : 1);
    setOpen(false);
    setParentData(null);
    setCategories([
      {
        agoraCategoryId: addToOrderList?.categoryId || null,
        agoraSubcategoryId: null,
      },
    ]);
  };

  const addProduct = async () => {
    let picUrls = productData?.pictureUrls ? [...productData?.pictureUrls] : [];
    const processedUrls = await Promise.all(
      picUrls.map(async (url) => {
        if (!url.includes('//res.cloudinary.com/agoraserv')) {
          const imgUrl = isWarehouseItem
            ? await warehousePostAddProductImage(url)
            : await catalogPostAddProductImage(url);
          return imgUrl.data.length ? imgUrl.data : null;
        }
        return url;
      })
    );

    picUrls = processedUrls.filter((url) => url !== null);

    productData.pictureUrls = picUrls;
    productData.uomType = uomType.value;
    const response = isWarehouseItem
      ? await warehouseProductsFormData({
          parentData,
          productData,
          categories,
          vendorId,
          vendorValue: vendor?.value,
          ppdFacilities: ppdOptions,
        })
      : await catalogProductsFormData({
          parentData,
          productData,
          categories,
          vendorId,
          vendorValue: vendor?.value,
          ppdFacilities: ppdOptions,
        });
    if (response.status === 200) {
      if (addToOrderList) {
        dispatch(getAddToOrderList(id, addToOrderList.facilityId, addToOrderList.categoryId));
      } else {
        dispatch(isWarehouseItem ? warehouseGetCatalog() : catalogGetCatalog());
      }
      enqueueSnackbar('Item added successfully', { variant: 'success' });
      if (row !== undefined) {
        dispatch(
          isWarehouseItem
            ? warehouseGetProductDetails(row.productId, false)
            : catalogGetProductDetails(row.productId, false)
        );
      } else {
        dispatch(isWarehouseItem ? warehouseGetProductDetails(id, false) : catalogGetProductDetails(id, false));
      }
    } else enqueueSnackbar('Error Adding Item', { variant: 'error' });
    handleClose();
  };

  const saveProduct = async () => {
    let picUrls = productData.pictureUrls;
    if (picUrls) {
      const processedUrls = await Promise.all(
        picUrls.map(async (url) => {
          if (!url.includes('//res.cloudinary.com/agoraserv')) {
            const imgUrl = isWarehouseItem
              ? await warehousePostAddProductImage(url)
              : await catalogPostAddProductImage(url);
            return imgUrl.data.length ? imgUrl.data : null;
          }
          return url;
        })
      );
      picUrls = processedUrls.filter((url) => url !== null);
      productData.pictureUrls = picUrls;
    }

    const response = isWarehouseItem
      ? await warehouseUpdateProductFormData({
          categories,
          allItems: [
            ...data,
            {
              ...productData,
              vendorId: vendor?.value,
              uomType: uomType.value,
              vendorItemId: vendorId,
              pictures: picUrls,
              ppdFacilities: isWarehouseItem ? null : ppdOptions,
            },
          ],
          productId: parentInfo?.id,
          description: parentData?.description,
          notes: parentData?.notes,
          agoraCategoryId: categories[0].agoraCategoryId,
          agoraSubcategoryId: categories[0].agoraSubcategoryId,
        })
      : await catalogUpdateProductFormData({
          categories,
          allItems: [
            ...data,
            {
              ...productData,
              vendorId: vendor?.value,
              uomType: uomType.value,
              vendorItemId: vendorId,
              pictures: picUrls,
              ppdFacilities: isWarehouseItem ? null : ppdOptions,
            },
          ],
          productId: parentInfo?.id,
          description: parentData?.description,
          notes: parentData?.notes,
          agoraCategoryId: categories[0].agoraCategoryId,
          agoraSubcategoryId: categories[0].agoraSubcategoryId,
        });
    if (response.status === 200) {
      if (row !== undefined) {
        dispatch(
          isWarehouseItem
            ? warehouseGetProductDetails(row.productId, false)
            : catalogGetProductDetails(row.productId, false)
        );
        dispatch(isWarehouseItem ? warehouseGetCatalog(id) : catalogGetCatalog(id));
      } else {
        dispatch(isWarehouseItem ? warehouseGetProductDetails(id, false) : catalogGetProductDetails(id, false));
      }
      enqueueSnackbar('Item added successfully', { variant: 'success' });
      handleClose();
    } else enqueueSnackbar('Error Saving Item', { variant: 'error' });
  };

  const getProductData = async () => {
    const isDuplicate = await checkDuplicateItem(vendor?.value, vendorId);
    if (!isDuplicate) return;

    if (vendor && vendorId && uomType) {
      const response = isWarehouseItem
        ? await warehouseFetchVendorProductData(vendor?.value, vendorId, uomType.value)
        : await catalogFetchVendorProductData(vendor?.value, vendorId, uomType.value);
      const data = response;
      if (data.status === 200) {
        const obj = data.data;
        setProductData({ ...obj, manufacturerId: obj.manufacturerNumber });
      } else {
        setProductData({
          error: `${vendor.label} does not have item info. Please add manually.`,
          uomAmount: '',
          price: '',
          baseUOMAmount: '',
          baseUOMType: '',
          manufacturer: '',
          manufacturerId: '',
          baseMinUOMAmount: '',
          id: '',
          pictureUrls: [],
          vendorItemLink: '',
          vendorItemDescription: '',
          uomType: uomType.value,
        });
      }
    }
    setStep(3);
  };

  return (
    <div>
      {!isWarehouseItem && (
        <ScopeGuard scopes={['Item-Catalog-Edit']} allowAdmin>
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={handleClickOpen}
            startIcon={<Iconify icon="eva:plus-outline" />}
            sx={{ mt: 1 }}
          >
            Add Item
          </Button>
        </ScopeGuard>
      )}
      {isWarehouseItem && (
        <ScopeGuard superAdminOnly>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClickOpen}
            startIcon={<Iconify icon="eva:plus-outline" />}
          >
            Add Item
          </Button>
        </ScopeGuard>
      )}
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
            mt: 2,
          }}
        >
          New item
          <Box sx={{ m: 2, mt: 3, mr: 20, ml: 20 }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Box sx={{ width: '31%' }}>
                <LinearProgress variant="determinate" value={100} sx={{ height: 12, borderRadius: 3 }} />
              </Box>
              <Box sx={{ width: '31%' }}>
                <LinearProgress variant="determinate" value={step > 1 ? 100 : 0} sx={{ height: 12, borderRadius: 3 }} />
              </Box>
              <Box sx={{ width: '31%' }}>
                <LinearProgress variant="determinate" value={step > 2 ? 100 : 0} sx={{ height: 12, borderRadius: 3 }} />
              </Box>
            </Grid>
          </Box>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          {step === 3 ? (
            <Step3
              productData={productData}
              setProductData={setProductData}
              additionalUOM={additionalUOM}
              setAdditionalUOM={setAdditionalUOM}
              uomType2={uomType2}
              setUomType2={setUomType2}
              uomTypes={uomTypes}
              vendor={vendor}
              vendorId={vendorId}
              uomType={uomType}
              ppdOptions={isWarehouseItem ? null : ppdOptions}
              setPpdOptions={isWarehouseItem ? null : setPpdOptions}
              isWarehouseItem={isWarehouseItem}
              vendors={allVendors}
            />
          ) : step === 2 ? (
            <Step2
              vendor={vendor}
              setVendor={setVendor}
              vendorId={vendorId}
              setVendorId={setVendorId}
              uomType={uomType}
              setUomType={setUomType}
              uomTypes={uomTypes}
              setUomTypes={setUomTypes}
              setPpdOptions={isWarehouseItem ? null : setPpdOptions}
              isWarehouseItem={isWarehouseItem}
              vendors={allVendors}
            />
          ) : (
            <Step1
              parentData={parentData}
              setParentData={setParentData}
              categories={categories}
              setCategories={setCategories}
              isWarehouseItem={isWarehouseItem}
              agoraCategories={allCategories}
              agoraSubcategories={allSubcategories}
              agoraVendors={allVendors}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          {step === 3 ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: '200px' }}
                disabled={!vendor || !vendorId || !uomType}
                onClick={() => {
                  setProductData(null);
                  setStep(2);
                }}
              >
                Previous
              </Button>
              <Tooltip
                title={
                  vendor && vendorId && uomType && productData?.uomAmount && productData?.price && !additionalUOM
                    ? ''
                    : `
              ${!vendor ? 'Vendor Required' : ''} 
              ${!vendorId ? 'Vendor ID Required' : ''}
              ${uomType ? 'UOM Type Required' : ''}
              ${!productData?.uomAmount ? 'UOM Amount Required' : ''}
              ${!productData?.price ? 'Price Required' : ''}
              ${additionalUOM ? (!productData?.uomAmount2 ? 'UOM Amount Required' : '') : ''}
              ${additionalUOM ? (!productData?.price2 ? 'Price Required' : '') : ''}`
                }
                placement="top"
                // arrow
              >
                <Box>
                  <AwaitButton
                    variant="contained"
                    color="primary"
                    sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
                    disabled={
                      !vendor ||
                      !vendorId ||
                      !uomType ||
                      !productData?.uomAmount ||
                      !productData?.price ||
                      additionalUOM
                        ? !productData?.uomAmount2 || !productData?.price2 || !uomType2
                        : false
                    }
                    onClick={parentInfo ? saveProduct : addProduct}
                  >
                    Add Item
                  </AwaitButton>
                </Box>
              </Tooltip>
            </>
          ) : step === 2 ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                sx={{ width: '200px' }}
                onClick={() => {
                  setVendor(null);
                  setVendorId(null);
                  setUomType(null);
                  setUomTypes(null);
                  setStep(1);
                }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
                disabled={!vendor || !vendorId || !uomType}
                onClick={() => {
                  getProductData();
                }}
              >
                Next
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '200px', boxShadow: 3, fontWeight: 'bold' }}
              onClick={() => setStep(2)}
              disabled={
                !parentData?.description ||
                categories.some((category) => !category.agoraCategoryId || !category.agoraSubcategoryId)
              }
            >
              Next
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
