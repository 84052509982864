import { React, useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Accordion,
  Button,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Box,
  Switch,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useDispatch, useSelector } from '../../../../redux/store';
import { facilityModeToggleChange } from '../../../../redux/slices/invoicing';
import { useScopeCheck } from '../../../../reusable-components/scopes';
import { useSnackbar } from '../../../../reusable-components/snackbar';

MultiFacilityToggle.propTypes = {};
export default function MultiFacilityToggle() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const {
    data: { invoiceItemData },
  } = useSelector((state) => state.invoicing);
  const error = useSelector((state) => state.invoicing.error);
  const hasEditAccess = useScopeCheck(['Invoice-Edit'], true);
  const multiFacility = invoiceItemData?.invoice?.multiFacility;
  const { enqueueSnackbar } = useSnackbar();
  const [openMFDialog, setOpenMFDialog] = useState(false); //MF-multi facility
  const [openSFDialog, setOpenSFDialog] = useState(false); //SF-single facility
  const [disableNotifications, setDisableNotifications] = useState(false);
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(multiFacility);
  }, [multiFacility]);

  const handleSwitchChange = (event, value) => {
    // const notificationPreference = localStorage.getItem('disableMultiFacilityNotifications');
    // if (notificationPreference !== 'true') {
    //   if (value) {
    //     setOpenMFDialog(true);
    //     setOpenSFDialog(false);
    //   } else {
    //     setOpenSFDialog(true);
    //     setOpenMFDialog(false);
    //   }
    // } else {
    handleToggleFacilityMode(value ? 'multi' : 'single');
    // }
  };
  const handleCloseDialog = () => {
    setOpenMFDialog(false);
    setOpenSFDialog(false);
  };
  const handleToggleFacilityMode = async (mode) => {
    // if (disableNotifications) {
    //   localStorage.setItem('disableMultiFacilityNotifications', JSON.stringify(true));
    // }

    await dispatch(facilityModeToggleChange(id, mode));
    //handleCloseDialog();
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Box sx={mainBoxStyle}>
          <Typography sx={{ color: '#060606', fontSize: '16px', fontWeight: '700' }}>Multi facility invoice</Typography>
          <Switch
            checked={checked}
            onChange={handleSwitchChange}
            disabled={!hasEditAccess || invoiceItemData?.invoice?.facilityId === 0}
          />
        </Box>
      </Box>

      {/* <Dialog
        open={openMFDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Switch to Multi-Facility Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Typography>
                You are about to switch this invoice to a Multi-Facility Invoice. This means you will be able to split
                the GL codes into different facilities.
              </Typography>
            </Box>
            <br />
            <Typography> Please note:</Typography>
            <Box sx={{ p: 2 }}>
              <ul>
                <li>
                  <Typography>
                    Once switched to a Multi-Facility Invoice, you will no longer be able to edit the invoice items.{' '}
                  </Typography>
                </li>
                <li>
                  <Typography>The GL codes will determine how the invoice is split and billed. </Typography>
                </li>
              </ul>
            </Box>
            <FormControlLabel
              control={<Checkbox onChange={(event) => setDisableNotifications(event.target.checked)} />}
              label="Don't show this message again"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button onClick={() => handleToggleFacilityMode('multi')} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSFDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Switch to Single Facility Invoice</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Typography>
                You are about to switch this invoice to a Single Facility Invoice. This means all GL codes will be
                billed by one facility.
              </Typography>
            </Box>
            <br />
            <Typography> Please note:</Typography>
            <Box sx={{ p: 2 }}>
              <ul>
                <li>
                  <Typography>
                    Once switched to a Single Facility Invoice, you will be able to edit the invoice items.
                  </Typography>
                </li>
                <li>
                  <Typography> All GL codes will be consolidated and billed by a single facility. </Typography>
                </li>
              </ul>
            </Box>
            <FormControlLabel
              control={<Checkbox onChange={(event) => setDisableNotifications(event.target.checked)} />}
              label="Don't show this message again"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Disagree</Button>
          <Button onClick={() => handleToggleFacilityMode('single')} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

const mainBoxStyle = {
  borderRadius: '4px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  backgroundColor: '#fff',
  padding: '10px 25px',
  overflow: 'auto',
  border: 'solid 1px #c1c9d0',
  display: 'flex',
  alignItems: 'center',
  //maxHeight: '450px',
};
