import DownloadIcon from '@mui/icons-material/Download';
import { Box, IconButton } from '@mui/material';
import { fMonthDayTimeYearEST, fMonthDayYear } from '../../utils/formatTime';
import Iconify from '../iconify';
import { HOST_API_URL } from '../../config-global';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
const downloadEmail = async (rowId) => {
  //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
  window.open(`${HOST_API_URL}Api/Admin/SentEmail/DownloadSentEmail?id=${rowId}`, '_blank');
};
const actionButtons = (params) => (
  <>
    <Box id="action-button" className="action-button">
      <IconButton color="info" onClick={() => downloadEmail(params.id)} id="action-button" className="action-button">
        <DownloadIcon id="action-button" className="action-button" />
      </IconButton>
    </Box>
  </>
);

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  flex: 1,
  renderCell: (params) => renderIcon(fMonthDayYear(params.value), 'material-symbols:calendar-today-outline'),
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};
const dateSent = {
  field: 'dateSent',
  headerName: 'Date sent',
  flex: 1,
  renderCell: (params) =>
    params.row?.dateSent
      ? renderIcon(fMonthDayTimeYearEST(params.value), 'material-symbols:calendar-today-outline')
      : '-',
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};

const subject = {
  field: 'subject',
  headerName: 'Subject',
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const sentBy = {
  field: 'sentBy',
  headerName: 'Sent by',
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const sentTo = {
  field: 'sentTo',
  headerName: 'Sent To',
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};

const success = {
  field: 'success',
  headerName: 'Email Sent',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? (
      <Iconify icon="material-symbols:check-circle-outline" color="primary" />
    ) : (
      <Iconify icon="mdi:close" color="error" />
    );
  },
};
const download = {
  field: 'download',
  headerName: 'Download',
  flex: 0.5,
  renderCell: (params) => actionButtons(params),
};
const EMAIL_LIST = [subject, sentBy, sentTo, dateAdded, dateSent, success, download];

export { EMAIL_LIST };
