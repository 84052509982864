import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Tabs, Tab, Button, Grid, Typography, Badge } from '@mui/material';
import { DateRangePickerSelect } from '../../../reusable-components/date-range-picker';
import { HOST_API_URL } from '../../../config-global';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../reusable-components/iconify';
import { fCurrency, fNumber } from '../../../utils/formatNumber';
import { useScopeCheck } from '../../../reusable-components/scopes';
import ManualInvoiceUploader from './ManualInvoices/ManualInvoiceUploader';
import ProcessManualInvoices from './ManualInvoices/ProcessManualInvoices';
import SplitPDF from './SplitPDF';
import UploadPDF from './UploadPDF';
import UploadStatementPDF from './UploadStatementPDF';
import NewVendorModal from './Vendors/NewVendorModal';

CustomToolbar.propTypes = {
  isFiltered: PropTypes.func,
  activeRows: PropTypes.array,
};
export default function CustomToolbar({ isFiltered, activeRows }) {
  const navigate = useNavigate();
  const {
    data: { pendingInvoicing, manualInvoicing },
  } = useSelector((state) => state.invoicing);
  const hasFacilityAccess = useScopeCheck(['Facility-Manager']);

  const isSuperAdmin = useScopeCheck(['Super-Admin']);
  const canViewOtherReports = useScopeCheck(['View-OtherReports'], true);

  const applyParams = (startDate, endDate) => {
    const formattedStartDate = startDate?.toISOString();
    const formattedEndDate = endDate?.toISOString();
    return `&start=${formattedStartDate}&end=${formattedEndDate}`;
  };
  const handleTabChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate(`/dashboard/invoicing?filter=new`);
        break;
      case 1:
        navigate(`/dashboard/invoicing?filter=pending`);
        break;
      case hasFacilityAccess && 2:
        navigate(`/dashboard/invoicing?filter=pendingFilter`);
        break;
      case hasFacilityAccess ? 3 : 2:
        navigate(
          `/dashboard/invoicing?filter=previous&start=${new Date(
            new Date().setDate(new Date().getDate() - 29)
          ).toISOString()}&end=${new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()}`
        );
        break;
      case hasFacilityAccess ? 4 : 3:
        navigate(`/dashboard/invoicing?filter=incomplete`);
        break;
      case hasFacilityAccess ? 5 : 4:
        navigate(`/dashboard/invoicing?filter=complete`);
        break;
      case hasFacilityAccess ? 6 : 5:
        navigate(
          `/dashboard/invoicing?filter=reports${applyParams(
            new Date(new Date().setDate(new Date().getDate() - 29)),
            new Date()
          )}`
        );
        break;
      case hasFacilityAccess ? 7 : 6:
        navigate(`/dashboard/invoicing?filter=vendors`);
        break;
      default:
        break;
    }
  };

  const downloadFile = async () => {
    //currently this is not working on dev environment as HOST_API_URL is not set but it will work on production
    window.open(
      `${HOST_API_URL}Api/Invoices/DownloadInvoicesExcel?pending=${isFiltered('pending') ? 'true' : 'false'}`,
      '_blank'
    );
  };
  const downloadStatements = async () => {
    window.open(`${HOST_API_URL}Api/Invoices/DownloadInvoiceStatements`, '_blank');
  };
  //temporarly
  useEffect(() => {
    if (isFiltered('new') && !isSuperAdmin) navigate('/dashboard/invoicing?filter=pending');
  }, []);
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: -2 }}>
        <Box>
          <Box>
            <Tabs
              value={
                isFiltered('new')
                  ? 0
                  : isFiltered('pending')
                  ? 1 //0
                  : isFiltered('pendingFilter')
                  ? 2 //1
                  : isFiltered('previous')
                  ? hasFacilityAccess
                    ? 3 //2
                    : 2 //1
                  : isFiltered('incomplete')
                  ? hasFacilityAccess
                    ? 4 //3
                    : 3 //2
                  : isFiltered('complete')
                  ? hasFacilityAccess
                    ? 5 // 4
                    : 4 // 3
                  : isFiltered('reports')
                  ? hasFacilityAccess
                    ? 6 //5
                    : 5 //4
                  : isFiltered('vendors')
                  ? hasFacilityAccess
                    ? 7 //6
                    : 6 //5
                  : 1 //0
              }
              onChange={handleTabChange}
            >
              <Tab
                sx={{ display: isSuperAdmin ? 'block' : 'none' }}
                label={`New Invoices${manualInvoicing.length > 0 ? ` (${fNumber(manualInvoicing.length)})` : ''}`}
              />
              <Tab
                label={`All Pending Invoices${
                  pendingInvoicing.length > 0 ? ` (${fNumber(pendingInvoicing.length)})` : ''
                }`}
              />
              {hasFacilityAccess && (
                <Tab
                  label={`My Pending Invoices${
                    pendingInvoicing.length > 0
                      ? ` (${fNumber(
                          pendingInvoicing.filter((invoice) =>
                            invoice.facilityManagerAssignments.find((fma) => fma.currentUser)
                          )?.length
                        )})`
                      : ''
                  }`}
                />
              )}
              <Tab label="Processed Invoices" />
              <Tab label="Incomplete Statements" />
              <Tab label="Complete Statements" />
              {canViewOtherReports && <Tab label="Reports" />}
              {isSuperAdmin && (
                <Badge
                  badgeContent="Beta"
                  color="info"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  onClick={() => navigate('/dashboard/invoicing?filter=vendors')}
                  sx={{
                    '& .MuiBadge-badge': {
                      right: 17,
                      bottom: 13,
                      border: '2px solid #fff',
                      padding: '0 4px',
                    },
                  }}
                >
                  <Tab
                    label="Vendors"
                    sx={{
                      minWidth: '100px',
                    }}
                  />
                </Badge>
              )}
            </Tabs>
          </Box>
        </Box>
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="row" alignItems="flex-end" spacing={1} sx={{ mb: 1 }}>
            {!isFiltered('vendors') ? (
              <>
                {isFiltered('new') && (
                  <>
                    <Grid item>
                      <ManualInvoiceUploader />
                    </Grid>
                  </>
                )}
                {isFiltered('new') && (
                  <>
                    <Grid item>
                      <ProcessManualInvoices />
                    </Grid>
                  </>
                )}

                {isFiltered('previous') && (
                  <Grid item>
                    <DateRangePickerSelect />
                  </Grid>
                )}
                {isFiltered('previous') || isFiltered('pending') ? (
                  <Grid item>
                    <Box sx={{ display: 'inline-block', mr: 1 }}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={downloadFile}
                        startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                      >
                        Download Invoices
                      </Button>
                    </Box>
                  </Grid>
                ) : (
                  !isFiltered('new') &&
                  !isFiltered('reports') &&
                  !isFiltered('pendingFilter') && (
                    <Grid item>
                      <Box sx={{ display: 'inline-block', mr: 1 }}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={downloadStatements}
                          startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                        >
                          Download Statements
                        </Button>
                      </Box>
                      <UploadStatementPDF />
                    </Grid>
                  )
                )}
                {isFiltered('pending') && (
                  <>
                    <Grid item>
                      <SplitPDF />
                    </Grid>
                    <Grid item>
                      <UploadPDF />
                    </Grid>
                  </>
                )}
                {!isFiltered('reports') && (
                  <Grid item>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                        Total
                      </Typography>
                      <Typography variant="subtitle1" sx={{ color: 'info.main' }}>
                        {isFiltered('pending') || isFiltered('new')
                          ? fCurrency(activeRows.map((item) => item.total).reduce((a, b) => a + b, 0))
                          : isFiltered('pendingFilter')
                          ? fCurrency(activeRows.map((item) => item.total).reduce((a, b) => a + b, 0))
                          : isFiltered('previous')
                          ? fCurrency(
                              activeRows
                                .filter((item) => item.deleteFlag !== true)
                                .map((item) => item.total)
                                .reduce((a, b) => a + b, 0)
                            )
                          : isFiltered('incomplete')
                          ? fCurrency(
                              activeRows
                                ?.filter((st) => st.complete === false)
                                ?.map((item) => item.total)
                                ?.reduce((a, b) => a + b, 0)
                            )
                          : fCurrency(
                              activeRows
                                ?.filter((st) => st.complete === true)
                                ?.map((item) => item.total)
                                ?.reduce((a, b) => a + b, 0)
                            )}
                      </Typography>
                    </Box>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item>
                <NewVendorModal />
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    window.open(`${HOST_API_URL}Api/Invoices/DownloadVendors`, '_blank');
                  }}
                  startIcon={<Iconify icon="file-icons:microsoft-excel" />}
                >
                  Download
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
