import { Box, Switch, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import OrderHistoryDialog from '../../components/dashboard/admin/catalog/OrderHistoryDialog';
import { useUpdateOrderItemRedux } from '../../hooks/useUpdateOrderItemRedux';
import { cloudinaryFull, cloudinaryThumbnail } from '../../utils/formatCloudinary';
import { fCurrency, fifthDecimalCurrency } from '../../utils/formatNumber';
import { fMonthDayYear } from '../../utils/formatTime';
import Image from '../image';
import Lightbox from '../lightbox';
import { useScopeCheck } from '../scopes';
import Category from './Category';
import ProductItem from './ProductItem';
import QuantityField from './QuantityField';
import SubCategory from './SubCategory';
import UOMDropdown from './UOMDropdown';
import VendorField from './VendorField';

export default function RenderImage({ params }) {
  RenderImage.propTypes = {
    params: PropTypes.object,
  };
  const { pictures } = params.row;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={cloudinaryThumbnail(pictures?.[0])}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictures?.map((picture) => ({ src: cloudinaryFull(picture) }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}
export function RenderImageFromUrls({ params }) {
  RenderImageFromUrls.propTypes = {
    params: PropTypes.object,
  };
  const { pictureUrls } = params.row;
  const [selectedImage, setSelectedImage] = useState(-1);
  const handleOpenLightbox = () => {
    setSelectedImage(0);
  };

  const handleCloseLightbox = () => {
    setSelectedImage(-1);
  };
  return (
    <>
      <Image
        alt="attachment"
        src={pictureUrls?.[0]}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            opacity: 0.9,
          },
        }}
        onClick={() => handleOpenLightbox()}
      />
      <Lightbox
        index={0}
        slides={pictureUrls.map((url) => ({ src: url }))}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      />
    </>
  );
}

function ApprovedCell({ row }) {
  ApprovedCell.propTypes = {
    row: PropTypes.object,
  };
  const hasAccess = useScopeCheck(['Edit-Order'], true);

  const updateOrderItemRedux = useUpdateOrderItemRedux();

  return (
    <Switch
      disabled={!hasAccess}
      color="info"
      checked={row.approved}
      onChange={() => updateOrderItemRedux(row.id, 'approved', !row.approved)}
    />
  );
}
const categoryDropdown = {
  field: 'categoryDropdown',
  headerName: 'Category',
  type: 'customSelect',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 120,
  renderCell: (params) => <Category row={params.row} />,
  valueGetter: (params) => params.row.agoraCategory?.name || '',
  path: 'agoraCategory.name',
};

const subCategoryDropdown = {
  field: 'subCategoryDropdown',
  headerName: 'Subcategory',
  type: 'customSelect',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 140,
  renderCell: (params) => <SubCategory row={params.row} />,
  valueGetter: (params) => params.row.agoraSubcategory?.name || '',
  path: 'agoraSubcategory.name',
};
const category = {
  field: 'category',
  headerName: 'Category',
  type: 'customSelect',
  path: 'agoraCategory.name',
  valueGetter: (params) => params.row.agoraCategory?.name || '',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 115,
  renderCell: (params) => (
    <>
      <Tooltip title={params.row.agoraCategory?.name || ''}>
        <Typography
          // color={stringToColor(params.row.agoraCategory?.name)}
          variant="subtitle2"
          sx={{
            border: 1,
            width: '100px',
            borderRadius: '3px',
            position: 'relative',
            display: 'flex', // Use flexbox
            alignItems: 'center',
            my: '4px',
            py: '6px',
            px: 0.5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis for overflow
              whiteSpace: 'nowrap', // Prevent wrapping
              pr: 2,
            }}
          >
            {params.row.agoraCategory.name || ''}
          </Box>
        </Typography>
      </Tooltip>
    </>
  ),
};
const subCategory = {
  field: 'subCategory',
  headerName: 'Subcategory',
  type: 'customSelect',
  path: 'agoraSubcategory.name',
  valueGetter: (params) => params.row.agoraSubcategory?.name || '',
  onCellClick: (params) => {
    params.event.stopPropagation();
  },
  width: 140,
  renderCell: (params) => (
    <>
      <Tooltip title={params.row.agoraSubcategory?.name || ''}>
        <Typography
          // color={stringToColor(params.row.agoraCategory?.name)}
          variant="subtitle2"
          sx={{
            border: 1,
            width: '125px',
            borderRadius: '3px',
            position: 'relative',
            display: 'flex', // Use flexbox
            alignItems: 'center',
            my: '4px',
            py: '6px',
            px: 0.5,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden', // Hide overflow
              textOverflow: 'ellipsis', // Add ellipsis for overflow
              whiteSpace: 'nowrap', // Prevent wrapping
              pr: 2,
            }}
          >
            {params.row.agoraSubcategory.name || ''}
          </Box>
        </Typography>
      </Tooltip>
    </>
  ),
};
const picture = {
  field: 'picture',
  headerName: 'Image',
  width: 80,
  renderCell: (params) => <RenderImage params={params} />,
  sortable: false,
  filterable: false,
};
const pictureUrls = {
  field: 'picture',
  headerName: 'Image',
  width: 80,
  renderCell: (params) => <RenderImageFromUrls params={params} />,
  sortable: false,
  filterable: false,
};

const wideDescription = {
  field: 'description',
  headerName: 'Description',
  width: 220,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.description,
  type: 'customText',
};
const description = {
  field: 'description',
  headerName: 'Description',
  width: 210,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => params.row.description,
};
const vendorMessage = {
  field: 'message',
  headerName: 'Vendor Message',
  width: 120,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  valueGetter: (params) => params.row.message,
  type: 'customText',
};
const errorWide = {
  field: 'error',
  headerName: 'Error',
  width: 400,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
        color: 'red',
      }}
    >
      {params.row.errors?.map((error) => (
        <div key={error}>{error}</div>
      ))}
    </Box>
  ),
  type: 'customText',
  valueGetter: (params) => `${params.row.errors?.join(', ')}`,
};

const price = {
  field: 'price',
  headerName: 'Price',
  width: 100,
  renderCell: (params) =>
    params.row.productDetailUOMs?.length > 0 ? (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.8rem',
        }}
      >
        {params.row.productDetailUOMs?.map((obj, index) => (
          <div key={index}>{`${fCurrency(obj.price)} - ${obj.uomAmount}/${obj.uomType}`}</div>
        ))}
      </Box>
    ) : (
      fCurrency(params.row.price)
    ),
  valueGetter: (params) => params.row.price,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};
const perItem = {
  field: 'perItem',
  headerName: 'Per Item',
  width: 100,
  renderCell: (params) =>
    params.row.productDetailUOMs?.length > 0 ? (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          fontSize: '0.8rem',
        }}
      >
        {params.row.productDetailUOMs?.map((obj, index) => (
          <div key={index}>{`${fifthDecimalCurrency(obj.price / obj.uomAmount)}`}</div>
        ))}
      </Box>
    ) : (
      fifthDecimalCurrency(params.row.uomAmount === 0 ? params.row.price / 1 : params.row.price / params.row.uomAmount)
    ),
  valueGetter: (params) =>
    params.row.productDetailUOMs?.length > 0
      ? params.row.productDetailUOMs?.map((obj) => obj.price / obj.uomAmount).join(', ')
      : params.row.uomAmount === 0
      ? params.row.price / 1
      : params.row.price / params.row.uomAmount,
  type: 'number',
  headerAlign: 'left',
  align: 'left',
};

const changeUom = {
  field: 'change_uom',
  headerName: 'UOM',
  renderCell: (params) => {
    const uomAmount = params.row.uomAmount || '';
    const uomOrType = params.row.uomType || params.row.uom || '';

    return params.row.productDetailUOMs?.length > 0 ? <UOMDropdown row={params.row} /> : `${uomAmount}/${uomOrType}`;
  },
  valueGetter: (params) => params.row.uomType,
  width: 90,
  type: 'customSelect',
};
const uom = {
  field: 'uom',
  headerName: 'UOM',
  renderCell: (params) => {
    const uomAmount = params.row.uomAmount || '';
    const uomOrType = params.row.uomType || params.row.uom || '';

    return `${uomAmount}/${uomOrType}`;
  },
  valueGetter: (params) => `${params.row.uomAmount}/${params.row.uomType}`,
  width: 80,
  type: 'customSelect',
};
const total = {
  field: 'total',
  headerName: 'Total',
  width: 80,
  renderCell: (params) => fCurrency(params.row.quantity * params.row.price),
  valueGetter: (params) => params.row.quantity * params.row.price,
};

const previouslyOrdered = {
  field: 'previouslyOrdered',
  headerName: 'Prev. Ordered',
  width: 80,
  renderCell: (params) => (
    <Tooltip
      title={params.row.previouslyOrdered?.map((item) => {
        const itemUOM =
          item.uomAmount && item.uomType
            ? `(${item.uomAmount} / ${item.uomType})`
            : item.uomAmount
            ? `(UOM ${item.uomAmount})`
            : item.uomType
            ? `(UOM ${item.uomType})`
            : ``;
        return (
          <div key={item.orderNumber}>
            Order# {item.orderNumber}, Qty {item.ordered} {itemUOM}, {fMonthDayYear(item.orderedOn)}
            <br />
          </div>
        );
      })}
    >
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.previouslyOrdered?.map((item) => item.ordered).join(', ')}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) => params.row.previouslyOrdered?.map((item) => item.ordered).join(', '),
};

const previouslyOrderedItems = {
  field: 'previouslyOrderedItems',
  headerName: 'History',
  width: 100,
  renderCell: (params) => (
    <Tooltip
      title={
        params.row.previouslyOrderedItems?.length > 0
          ? params.row.previouslyOrderedItems?.map((item) => (
              <div key={item.orderNumber}>
                Order# {item.orderNumber}, Qty {item.ordered}, {fMonthDayYear(item.orderedOn)}
                <br />
              </div>
            ))
          : ''
      }
    >
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.previouslyOrderedItems?.map((item) => item.ordered).join(', ')}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) => params.row.previouslyOrderedItems?.map((item) => item.ordered).join(', '),
};
const previouslyOrderedErrorItem = {
  field: 'previouslyOrdered',
  headerName: 'History',
  width: 100,
  renderCell: (params) => (
    <Tooltip
      title={
        params.row?.previouslyOrdered?.length > 0
          ? params.row.previouslyOrdered?.map((item) => (
              <div key={item.orderNumber}>
                Order# {item.orderNumber}, Qty {item.ordered}, {fMonthDayYear(item.orderedOn)}
                <br />
              </div>
            ))
          : ''
      }
    >
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        {params.row.previouslyOrdered?.map((item) => item.ordered).join(', ')}
      </Box>
    </Tooltip>
  ),
  valueGetter: (params) => params.row.previouslyOrdered,
};

const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 140,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        fontSize: '0.7rem',
      }}
    >
      <VendorField row={params.row} />
    </Box>
  ),
  valueGetter: (params) => params.row.vendor,
  type: 'customText',
};
const vendorOnAddItem = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 100,
  // renderCell: (params) => params.row.vendorName,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.row.vendorName}
    </Box>
  ),
  valueGetter: (params) => params.row.vendorName,
  type: 'customText',
};
const vendorName = {
  field: 'vendor',
  headerName: 'Vendor',
  width: 150,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.row.vendor}
    </Box>
  ),
  valueGetter: (params) => params.row.vendor,
  type: 'customText',
};
const sku = {
  field: 'sku',
  headerName: 'SKU',
  width: 100,
  renderCell: (params) => {
    const hasLink = params.row.vendorItemLink;
    return hasLink ? (
      <a href={params.row.vendorItemLink} target="_blank" rel="noreferrer">
        {params.value}
      </a>
    ) : (
      <Box
        component="div"
        sx={{
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
          '&:hover': {
            cursor: 'copy',
          },
        }}
        onClick={() => {
          navigator.clipboard.writeText(params.value);
        }}
      >
        {params.value}
      </Box>
    );
  },
  type: 'customText',
  valueGetter: (params) => params.row.vendorItemId,
};

const productId = {
  field: 'productId',
  headerName: 'PS#',
  width: 90,
  renderCell: (params) => <ProductItem row={params.row} />,
  valueGetter: (params) => `PS${params.row.productId} AG${params.row.productId}`,
  type: 'customText',
};
const psNum = {
  field: 'productId',
  headerName: 'PS#',
  width: 90,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        '&:hover': {
          cursor: 'copy',
        },
      }}
      onClick={() => {
        navigator.clipboard.writeText(params.row.productId);
      }}
    >
      PS{params.row.productId}
    </Box>
  ),
  valueGetter: (params) => `PS${params.row.productId} AG${params.row.productId}`,
  type: 'customText',
};

const quantity = {
  field: 'quantity',
  headerName: 'Qty',
  minWidth: 150,
  renderCell: (params) => <QuantityField row={params.row} />,
};
const quantityPlain = {
  field: 'quantity',
  headerName: 'Qty',
  width: 90,
};
const approved = {
  field: 'approved',
  headerName: 'Approved',
  width: 65,
  renderCell: (params) => <ApprovedCell row={params.row} />,
};
const historyWithButton = {
  field: 'history',
  headerName: 'History',
  width: 65,
  renderCell: (params) => (
    <OrderHistoryDialog
      productId={params.row.productId}
      // tooltip={
      //   params.row?.previouslyOrdered?.length > 0
      //     ? params.row.previouslyOrdered?.map((item) => (
      //         <div key={item.orderNumber}>
      //           Order# {item.orderNumber}, Qty {item.ordered}, {fMonthDayYear(item.orderedOn)}
      //           <br />
      //         </div>
      //       ))
      //     : ''
      // }
    />
  ),
};
const isPPD = {
  field: 'isPPD',
  headerName: 'PPD',
  renderCell: (params) => (params.value ? 'Yes' : ''),
  width: 100,
  type: 'customSelect',
};
const PENDING_ORDER = [
  picture,
  description,
  categoryDropdown,
  subCategoryDropdown,
  sku,
  productId,
  quantity,
  previouslyOrdered,
  historyWithButton,
  perItem,
  price,
  changeUom,
  isPPD,
  vendor,
  total,
  approved,
];
const ADD_TO_ORDER = [
  picture,
  description,
  category,
  subCategory,
  sku,
  psNum,
  uom,
  price,
  isPPD,
  previouslyOrderedItems,
  vendorOnAddItem,
  total,
];
const ADD_CATALOG = [
  picture,
  description,
  uom,
  perItem,
  price,
  vendorOnAddItem,
  sku,
  psNum,
  category,
  subCategory,
  isPPD,
  previouslyOrderedItems,
];
const CART_CHECK = [picture, description, quantityPlain, price, total, vendorName, sku, errorWide];
const CURRENT_ERROR_ITEM = [
  picture,
  wideDescription,
  uom,
  perItem,
  price,
  sku,
  psNum,
  isPPD,
  previouslyOrderedErrorItem,
  vendorName,
  quantityPlain,
  total,
  errorWide,
];
const REPLACE_ITEM = [
  picture,
  wideDescription,
  uom,
  perItem,
  price,
  sku,
  psNum,
  isPPD,
  previouslyOrderedItems,
  vendorOnAddItem,
];
const ADD_ITEM = [pictureUrls, wideDescription, uom, perItem, price, sku, vendorMessage];
const REPLACE_VENDOR_ITEM = [
  picture,
  wideDescription,
  category,
  subCategory,
  sku,
  psNum,
  uom,
  perItem,
  price,
  isPPD,
  previouslyOrdered,
  vendorName,
  quantityPlain,
  total,
];
const VENDOR_ITEM = [
  picture,
  wideDescription,
  category,
  subCategory,
  sku,
  psNum,
  uom,
  perItem,
  price,
  isPPD,
  previouslyOrderedItems,
  vendorOnAddItem,
];

export {
  PENDING_ORDER,
  ADD_TO_ORDER,
  CART_CHECK,
  REPLACE_ITEM,
  ADD_ITEM,
  CURRENT_ERROR_ITEM,
  ADD_CATALOG,
  REPLACE_VENDOR_ITEM,
  VENDOR_ITEM,
};
