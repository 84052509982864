import { Button, Grid, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Iconify from '../../../../reusable-components/iconify';


ApprovalUserOptionsMenu.propTypes = {
  menuElements: PropTypes.array
};

export default function ApprovalUserOptionsMenu({ menuElements }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (<>
    <Button
      variant="outlined" color="secondary"
      id="basic-button"
      startIcon={<Iconify icon="mdi:dots-horizontal" width={24} height={24} sx={{ ml: 1 }} />}
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
    />
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{ 'aria-labelledby': 'basic-button', }}
    >
      {menuElements?.map((elem, index) => (
        <MenuItem key={index} onClick={(e) => handleClose()}>
          <Grid item>
            {elem}
          </Grid>
        </MenuItem>
      ))}
    </Menu>
  </>
  );
}
