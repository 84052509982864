import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions } from '@mui/material';
import Iconify from '../../../../reusable-components/iconify';
import { FacilityTab } from '.';

NewFacilityModal.propTypes = {
  companies: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
};

export default function NewFacilityModal({ companies, data, fetchData }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        size="small"
        color="secondary"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="eva:plus-outline" />}
      >
        New Facility
      </Button>
      <Dialog open={open} maxWidth="xl" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          New Facility
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <FacilityTab companies={companies} data={data} fetchData={fetchData} isAddFacilityModal />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
