import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Box, Button, Card, Badge, Tooltip } from '@mui/material';
import { fCurrency } from '../../../../../utils/formatNumber';
import { useSelector } from '../../../../../redux/store';
import Iconify from '../../../../../reusable-components/iconify';

GrandTotal.propTypes = {
  items: PropTypes.object,
};

export default function GrandTotal({ items }) {
  const navigate = useNavigate();
  const {
    data: { carts, unsavedChanges },
  } = useSelector((state) => state.cart);
  const { facilityId = '' } = useParams();

  const [price, setPrice] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [itemErrorCount, setItemErrorCount] = useState(0);

  useEffect(() => {
    let totalPrice = 0;
    let totalItems = 0;
    setItemErrorCount(0);
    carts.forEach((cart) => {
      cart.items.forEach((item) => {
        totalPrice += item.price * item.quantity;
        totalItems++;
        if (item.errors?.length > 0) {
          setItemErrorCount((prev) => prev + 1);
        }
      });
    });

    setPrice(totalPrice);
    setTotalItems(totalItems);
  }, [carts]);

  if (!items) {
    return null;
  }

  return (
    <Card sx={{ padding: 1.5, ml: 2 }}>
      <Box>
        <Typography variant="subtitle1">
          Subtotal {totalItems} item{totalItems !== 1 ? 's' : ''}: {fCurrency(price)}
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="contained"
            size="small"
            fullWidth
            onClick={() => {
              navigate(`/dashboard/orders/new-order/checkout?facilityId=${facilityId}`);
            }}
            sx={{ mt: 1 }}
            disabled={unsavedChanges}
          >
            <Badge badgeContent={itemErrorCount} color="error">
              Proceed to Checkout
            </Badge>
          </Button>
        </Box>
        <Box
          display="flex"
          sx={{
            mt: 1,
          }}
        >
          {carts.map((cart, index) => (
            <Tooltip key={index} title={cart?.agoraCategory} arrow>
              <Iconify
                icon={cart?.icon}
                width={25}
                height={25}
                sx={{
                  cursor: 'default !important',
                }}
              />
            </Tooltip>
          ))}
        </Box>
      </Box>
    </Card>
  );
}
