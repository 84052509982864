import { Box, Button, Card, Grid, MenuItem, Select, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { postSetTicketMessagesRead } from '../../../../api/tickets';
import { editTicketData } from '../../../../redux/slices/tickets';
import { getUnreadData } from '../../../../redux/slices/dashboard';
import { useDispatch, useSelector } from '../../../../redux/store';
import { ScopeGuard } from '../../../../reusable-components/scopes';
import { priorityColor } from '../../../../utils/cssStyles';
import { AwaitButton } from '../../../../reusable-components/await-button';
import { useSnackbar } from '../../../../reusable-components/snackbar';

export default function TicketActions() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { id = '' } = useParams();
  const subticketId = new URLSearchParams(location.search).get('subticket');

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { ticketNumber } = useSelector((state) => state.tickets.data.ticketById[subticketId || id]) || {};

  const { mainTicket } = useSelector((state) => state.tickets.data.ticketInfoById[id]) || {};
  const [priority, setPriority] = useState(mainTicket?.importance || 'Medium');

  const editTicket = async ({ Id, value, updateType }) => {
    const response = await dispatch(editTicketData({ Id: ticketNumber || Id, value, updateType }));
    console.log(response);
    if (response) {
      enqueueSnackbar('Ticket updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('Ticket failed to update', { variant: 'error' });
    }
    return response;
  };
  const handleComplete = (value) => {
    editTicket({ Id: ticketNumber || id, value, updateType: 'Complete' });
    navigate(`/dashboard/tickets?filter=active`);
  };

  const handleUnread = async () => {
    const response = await postSetTicketMessagesRead({ id: ticketNumber || id, markAsUnread: true });
    if (response.status === 200) {
      dispatch(getUnreadData());
    }
    navigate(`/dashboard/tickets?filter=active`);
  };

  const handleChange = (event) => {
    editTicket({ Id: ticketNumber || id, value: event.target.value, updateType: 'Importance' });
    setPriority(event.target.value);
  };
  useEffect(() => {
    setPriority(mainTicket?.importance || 'Medium');
  }, [mainTicket]);

  return (
    <>
      <Card sx={{ height: '100%', mb: 2, p: 2 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            {mainTicket?.complete ? (
              <Button variant="outlined" size="small" onClick={() => handleComplete(false)}>
                Reopen ticket
              </Button>
            ) : (
              <AwaitButton variant="contained" size="small" onClick={() => handleComplete(true)} color="secondary">
                Mark as complete
              </AwaitButton>
            )}
          </Grid>
          <Grid item>
            <Tooltip title="This is for the main ticket" arrow placement="top">
              <Box>
                <Select
                  value={priority}
                  onChange={handleChange}
                  displayEmpty
                  sx={{
                    borderRadius: '5px',
                    maxHeight: '30px',
                    border: (theme) => `solid 1px ${priorityColor(priority, theme)}`,
                    color: (theme) => priorityColor(priority, theme),
                    '& .MuiSelect-icon': {
                      color: (theme) => priorityColor(priority, theme),
                    },
                  }}
                >
                  <MenuItem value={'Low'}>Low Priority</MenuItem>
                  <MenuItem value={'Medium'}>Medium Priority</MenuItem>
                  <MenuItem value={'High'}>High Priority</MenuItem>
                  <MenuItem value={'Maximum'}>Maximum Priority</MenuItem>
                </Select>
              </Box>
            </Tooltip>
          </Grid>

          <ScopeGuard scopes={['Tickets-Edit']} allowAdmin>
            <Grid item>
              <AwaitButton variant="outlined" size="medium" onClick={handleUnread} color="secondary">
                Mark as unread
              </AwaitButton>
            </Grid>
          </ScopeGuard>
        </Grid>
      </Card>
    </>
  );
}
