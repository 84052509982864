import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDataGrid from '../../../reusable-components/datagrid/CustomDataGrid';
import { useSelector } from '../../../redux/store';
import { RENTAL_ORDER_ITEMS } from '../../../reusable-components/datagrid/rentalColumns';

RentalOrderItemsView.propTypes = {
  orderId: PropTypes.number,
};

export default function RentalOrderItemsView({ orderId }) {
  const {
    data: { pendingRentals },
  } = useSelector((state) => state.rentals);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const filteredRows = pendingRentals.filter((item) => item.orderId === orderId);

    const uniqueRows = filteredRows.reduce((acc, current) => {
      const exists = acc.some((item) => item.orderId === current.orderId && item.itemId === current.itemId);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);

    setRows(uniqueRows);
    setDataLoaded(true);
  }, [pendingRentals, orderId]);

  return (
    <>
      <Typography variant="subtitle1" sx={{ ml: 6, mt: 1, color: '#8dc63f', fontWeight: 'bold', fontSize: '15px' }}>
        Rental Order PSR{orderId}
      </Typography>
      <CustomDataGrid
        gridId="pending-rentals"
        boxSX={{ width: '100%', height: 'calc(100vh - 260px)' }}
        data={rows}
        getRowId={(row) => row.itemId}
        gridColumns={RENTAL_ORDER_ITEMS}
        isLoading={!dataLoaded}
        sort={[{ field: 'name', sort: 'asc' }]}
      />
    </>
  );
}
