import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { fMonthDayTimeYear } from '../../utils/formatTime';
import Iconify from '../iconify';
import PinToggle from './PinToggle';
import UrgentToggle from './UrgentToggle';

const renderIcon = (value, icon) => (
  <Box>
    <Iconify icon={icon} width={13} height={13} /> {value}
  </Box>
);
const renderChip = (value) =>
  value !== 'undefined' ? (
    <Chip
      color={value === 'Low' ? 'warning' : value === 'Medium' ? 'info' : value === 'High' ? 'secondary' : 'error'}
      label={value}
      size="small"
      sx={{
        color: '#fff',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    />
  ) : (
    <> </>
  );

const renderLeftLabel = (label) => {
  const read = label === 'responded';
  return (
    <Tooltip title={`Mark as ${read ? 'unread' : 'read'}`} placement="right" arrow>
      <Button
        size="small"
        variant="contained"
        onClick={(event) => {
          if (read) event.unread = true;
          else event.read = true;
        }}
        sx={{
          ml: -2,
          mr: 1,
          mb: '2px',
          maxWidth: '10px',
          minWidth: '10px',
          width: '10px',
          height: '100%',
          borderRadius: 0,
          backgroundColor: read ? '#fff' : '#208ee0',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: read ? '#fff' : '#208ee0',
            opacity: 0.8,
          },
        }}
      />
    </Tooltip>
  );
};

const renderLeftLabelDashboard = (label) =>
  label === 'responded' ? (
    <Button
      size="small"
      variant="contained"
      sx={{
        ml: -2,
        mr: 1,
        mb: '2px',
        maxWidth: '10px',
        minWidth: '10px',
        width: '10px',
        height: '50px',
        borderRadius: 0,
        backgroundColor: '#fff',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#fff',
          opacity: 0.8,
        },
      }}
    />
  ) : (
    <Button
      size="small"
      variant="contained"
      sx={{
        ml: -2,
        mr: 1,
        mb: '2px',
        maxWidth: '10px',
        minWidth: '10px',
        width: '10px',
        height: '50px',
        borderRadius: 0,
        backgroundColor: '#208ee0',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: '#208ee0',
          opacity: 0.8,
        },
      }}
    />
  );

const dateAdded = {
  field: 'createdOn',
  headerName: 'Date Added',
  flex: 1,
  minWidth: 85,
  renderCell: (params) => (
    <Box component="div" sx={{ whiteSpace: 'pre-wrap' }}>
      {renderIcon(fMonthDayTimeYear(params.value), 'material-symbols:calendar-today-outline')}
    </Box>
  ),
  valueGetter: (params) => new Date(params.value),
  type: 'customDate',
};

const lastResponseOn = {
  field: 'lastSentOn',
  headerName: 'Last Response On',
  flex: 1,
  minWidth: 85,
  renderCell: (params) => (
    <Box component="div" sx={{ whiteSpace: 'pre-wrap' }}>
      {params.value ? renderIcon(fMonthDayTimeYear(params.value), 'material-symbols:calendar-today-outline') : ''}
    </Box>
  ),
  valueGetter: (params) => (params.value ? new Date(params.value) : ''),
  type: 'customDate',
};

const createdBy = {
  field: 'createdBy',
  minWidth: 120,
  headerName: 'Created By',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.value}
    </Box>
  ),
  flex: 2,
  type: 'customText',
};

const lastResponseBy = {
  field: 'lastSentBy',
  minWidth: 120,
  headerName: 'Last Response By',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {params.value}
    </Box>
  ),
  flex: 2,
  type: 'customText',
};
const facility = {
  field: 'facility',
  headerName: 'Facility',
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.75rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  minWidth: 80,

  type: 'customSelect',
};
const extraInfo = {
  field: 'extraInfo',
  headerName: 'Subject',
  flex: 1.5,
  minWidth: 80,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.row.extraInfo}
    </Box>
  ),
};
const extraInfoDashboard = {
  field: 'extraInfo',
  headerName: 'Subject',
  flex: 1.5,
  type: 'customText',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.row.extraInfo}
    </Box>
  ),
};

const orderNumber = {
  field: 'ticketOrderId',
  headerName: 'Order #',
  flex: 1,
  minWidth: 80,
  type: 'customSelect',
  renderCell: (params) => (
    <Box
      sx={{
        display: 'flex', // Use flexbox
        alignItems: 'flex-bottom', // Vertically align items to center
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={(event) => {
        if (params.value > 0) {
          event.navigateToOrders = params.value;
        }
      }}
    >
      <Typography
        variant="subtitle2"
        // color="secondary"
        component="span" // Use span here to prevent nested paragraphs
        sx={{
          textDecoration: 'underline',
        }}
      >
        {params.value ? params.value : ''}
      </Typography>
      {params.value > 0 && (
        <Typography component="span" sx={{ color: 'secondary.main' }}>
          <Iconify icon="material-symbols:open-in-new-sharp" width={16} height={16} />
        </Typography>
      )}
    </Box>
  ),
};
const ticketNumber = { field: 'id', headerName: 'Ticket #', flex: 1, minWidth: 80, type: 'customText' };

const aboveLine = {
  field: 'aboveLine',
  headerName: '',
  flex: 0.2,
  renderCell: (params) => <PinToggle value={params.value} />,
  type: 'boolean',
  filterable: false,
};

const importance = {
  field: 'importance',
  headerName: 'Priority Level',
  flex: 1,
  minWidth: 80,
  renderCell: (params) => params.value && renderChip(params.value),
  valueGetter: (params) => params.row.importance,
  type: 'customSelect',
};

const importanceDashboard = {
  field: 'importance',
  headerName: 'Priority Level',
  flex: 1,
  minWidth: 80,
  renderCell: (params) => params.value && renderChip(params.value),
  valueGetter: (params) => params.row.importance,
  type: 'customSelect',
};

const urgent = {
  field: 'respondedTo',
  headerName: '',
  width: 1,
  alignItems: 'left',
  renderCell: (params) => <UrgentToggle value={params.value} />,
};

const labelUrgent = {
  field: 'respondedTo',
  headerName: '',
  width: 1,
  alignItems: 'left',
  renderCell: (params) => <UrgentToggle value={params.value} dashboard />,
};

const label = {
  field: 'label',
  headerName: '',
  minWidth: 30,
  maxWidth: 30,
  renderCell: (params) => renderLeftLabel(params.row.messageCount > 0 ? 'unresponded' : 'responded'),
  valueGetter: (params) => (params.row.messageCount > 0 ? 'unresponded' : 'responded'),
  // filterable: false,
};

const labelDashboard = {
  field: 'label',
  headerName: '',
  minWidth: 30,
  maxWidth: 30,
  renderCell: (params) => renderLeftLabelDashboard(params.row.messageCount > 0 ? 'unresponded' : 'responded'),
  filterable: false,
};

const ACTIVE_TICKETS = [
  label,
  urgent,
  extraInfo,
  importance,
  ticketNumber,
  dateAdded,
  facility,
  orderNumber,
  createdBy,
  lastResponseBy,
  lastResponseOn,
  aboveLine,
];
const DASHBOARD_TICKETS = [
  labelDashboard,
  labelUrgent,
  importanceDashboard,
  extraInfoDashboard,
  facility,
  // senderManagerDashboard
];
const COMPLETED_TICKETS = [extraInfo, urgent, importance, ticketNumber, dateAdded, createdBy, facility, orderNumber];
export { ACTIVE_TICKETS, COMPLETED_TICKETS, DASHBOARD_TICKETS };
