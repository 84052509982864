import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Autocomplete, Stack, Typography, Box, Button, TextField, DialogActions } from '@mui/material';
import { postFacilityBookingAccountForm } from '../../../../api/facility';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import { AwaitButton } from '../../../../reusable-components/await-button';
import CloseIconButton from '../../../../reusable-components/basic-buttons/CloseIconButton';

BookingAccountForm.propTypes = {
  fetchData: PropTypes.func,
  editRow: PropTypes.object,
  facilityRow: PropTypes.object,
  vendors: PropTypes.array,
  vendorBookingTypes: PropTypes.array,
  openForm: PropTypes.bool,
  close: PropTypes.func,
};

export default function BookingAccountForm({
  fetchData,
  vendors,
  vendorBookingTypes,
  editRow,
  facilityRow,
  openForm,
  close
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState({
    vendor: '',
    account: '',
  });

  useEffect(() => setValue({
    vendor: editRow ? { id: editRow.vendorId, label: editRow.vendor } : '',
    account: editRow ? { id: editRow.bookingVendorId, label: editRow.account } : ''
  }), [editRow, openForm])

  const handleSave = async () => {
    const response = await postFacilityBookingAccountForm(value, facilityRow?.id, editRow?.id);
    if (response.data?.message) {
      enqueueSnackbar(response.data?.message, {
        variant: response.data?.message === 'Account Added' || response.data?.message === 'Account Updated' ? 'success' : 'error',
      });
    } else {
      enqueueSnackbar('Failed to add booking account', { variant: 'error' });
    }
    fetchData();
    close();
  };

  return <Dialog open={openForm} onClose={close} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ textAlign: 'center' }} >
      {editRow ? 'Edit' : 'Add'} Booking Account
      <CloseIconButton onClick={close} />
    </DialogTitle>
    <DialogContent sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ width: '100%' }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Vendor
              </Typography>
              <Autocomplete
                fullWidth
                size="small"
                value={value.vendor}
                onChange={(event, newValue) => {
                  setValue({
                    vendor: newValue,
                    account: '',
                  });
                }}
                options={vendors?.map((type) => ({ id: type.value, label: type.label }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box sx={{ width: '100%' }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Booking Vendor Account
              </Typography>
              <Autocomplete
                fullWidth
                size="small"
                value={value.account}
                onChange={(event, newValue) => {
                  setValue({
                    ...value,
                    account: newValue,
                  });
                }}
                options={vendorBookingTypes
                  ?.filter((type) => type.vendorId === value?.vendor?.id)
                  ?.map((type) => ({ id: type.id, label: type.account }))}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => <TextField {...params} label="Search or select" />}
                sx={{
                  mt: 1,
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  '& .MuiFormLabel-root ': { display: 'none' },
                }}
              />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" color="error" size="medium" sx={{ width: 150, mr: 2 }} onClick={close}>
        Cancel
      </Button>
      <AwaitButton
        variant="contained"
        color="primary"
        size="medium"
        disabled={value.vendor === '' || value.account === ''}
        sx={{ width: 150 }}
        onClick={handleSave}
      >
        {editRow ? 'Save' : 'Add Account'}
      </AwaitButton>
    </DialogActions>
  </Dialog>;
}
