import PropTypes from 'prop-types';
import { useState } from 'react';
import { Calendar } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { Button, Typography, Grid } from '@mui/material';
import './demo.css';

MultiDateRange.propTypes = {
  datesValue: PropTypes.array,
  setDatesValue: PropTypes.func,
};

export default function MultiDateRange({ datesValue, setDatesValue }) {
  const [showYears, setShowYears] = useState(false);

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <Grid item>
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={() => {
              setShowYears(!showYears);
            }}
            size="small"
          >
            {showYears ? 'Hide' : 'Show'} Full Calendar View
          </Button>
        </Grid>{' '}
        <Grid item>
          <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
            Select multiple date ranges for your report
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <Calendar
            value={datesValue}
            onChange={setDatesValue}
            multiple
            range
            format="MM/DD/YYYY"
            plugins={[<DatePanel key={1} sort="date" />]}
            fullYear={showYears}
          />
        </Grid>
      </Grid>
    </>
  );
}
