import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from '../../../redux/store';
import { AwaitButton } from '../../../reusable-components/await-button';

AddEditInvoiceLine.propTypes = {
  row: PropTypes.object,
  isEdit: PropTypes.bool,
  setAddDialog: PropTypes.func,
  setInvoiceItemRows: PropTypes.func,
  disabled: PropTypes.bool,
};

export default function AddEditInvoiceLine({
  row,
  isEdit,
  setInvoiceItemRows,
  setAddDialog = () => {},
  disabled = false,
}) {
  const {
    data: { invoiceItemDataById, invoiceData },
  } = useSelector((state) => state.invoicing);
  const { subcategories } = invoiceData;
  const { invoice } = invoiceItemDataById[row?.id] || {};

  const [openConfirm, setOpenConfirm] = useState(false);

  const [glAccountNumber, setGlAccountNumber] = useState(null);
  const [transactionAmount, setTransactionAmount] = useState(0);
  const [shipping, setShipping] = useState(0);

  const [memo, setMemo] = useState('');

  const handleOpenConfirm = (e) => {
    e.confirmDialog = true;
    setOpenConfirm(true);
    setGlAccountNumber(row?.agoraSubcategory || {});
    setTransactionAmount(
      row?.transactionAmount ? row.transactionAmount : row?.price * row?.quantity + row?.taxAmount || 0
    );
    setMemo(row?.memo || '');
    setShipping(row?.shipping || 0);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setGlAccountNumber(row?.agoraSubcategory || {});
    setTransactionAmount(
      row?.transactionAmount ? row.transactionAmount : row?.price * row?.quantity + row?.taxAmount || 0
    );
    setMemo('');

    if (!isEdit) setAddDialog(false);
  };

  const handleConfirm = () => {
    if (isEdit) {
      setInvoiceItemRows((prev) => {
        const newData = [...prev];
        const index = newData.findIndex((item) => item.id === row.id);
        newData[index] = {
          ...newData[index],
          agoraSubcategory: glAccountNumber,
          transactionAmount,
          shipping,
          memo,
          transactionTotal: Number(transactionAmount) + Number(shipping),
        };
        return newData;
      });
    } else {
      setInvoiceItemRows((prev) => [
        ...prev,
        {
          agoraSubcategory: glAccountNumber,
          memo,
          id: Date.now(),
          price: Number(transactionAmount),
          quantity: 1,
          taxAmount: 0,
          transactionTotal: Number(transactionAmount) + Number(shipping),
        },
      ]);
    }
    setOpenConfirm(false);
    setGlAccountNumber(null);
    setTransactionAmount(0);
    setMemo('');
    setAddDialog(false);
  };

  return (
    <>
      {isEdit && (
        <Tooltip title={disabled ? `You can't edit a GL for this booking type` : 'edit'}>
          <span>
            {/*span is here so the tooltip can work on a disabled button*/}
            <IconButton onClick={handleOpenConfirm} id="action-button" className="action-button" disabled={disabled}>
              <EditIcon id="action-button" className="action-button" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Dialog fullWidth maxWidth="xs" open={openConfirm || !isEdit} onClose={handleCloseConfirm}>
        <DialogTitle sx={{ pb: 2 }}>{isEdit ? 'Edit Transaction' : 'Add Transaction'} </DialogTitle>

        <DialogContent sx={{ typography: 'body2' }}>
          <>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>GL Account Number *</DialogContentText>
                      <Autocomplete
                        size="small"
                        sx={{
                          width: '100%',
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                          '& .MuiFormLabelRoot ': 'none',
                        }}
                        type="new-password"
                        options={subcategories?.filter((sub) => sub.facilityId === invoice?.facilityId)}
                        autoHighlight
                        getOptionLabel={(option) => `${option?.glCode} - ${option?.subcategory || option?.name}`}
                        value={glAccountNumber}
                        onChange={(event, newValue) => {
                          setGlAccountNumber(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label=""
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>

              <Grid item xs={12}>
                <>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Transaction Amount</DialogContentText>
                      <TextField
                        size="small"
                        label=""
                        fullWidth
                        multiline
                        value={transactionAmount}
                        type="number"
                        variant="outlined"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setTransactionAmount(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid item xs={12}>
                <>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Shipping Amount</DialogContentText>
                      <TextField
                        size="small"
                        label=""
                        fullWidth
                        multiline
                        value={shipping}
                        type="number"
                        variant="outlined"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => setShipping(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid item xs={12}>
                <>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={12}>
                      <DialogContentText sx={{ fontWeight: 'bold', mt: 2 }}>Memo</DialogContentText>
                      <TextField
                        size="small"
                        label=""
                        fullWidth
                        multiline
                        value={memo}
                        variant="outlined"
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        onChange={(e) => {
                          setMemo(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </>
        </DialogContent>

        <DialogActions>
          <AwaitButton disabled={!glAccountNumber} variant="outlined" color="primary" onClick={handleConfirm}>
            {isEdit ? 'Save' : 'Add'}
          </AwaitButton>
          <Button variant="text" color="error" onClick={handleCloseConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
