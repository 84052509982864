import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

export async function getPossibleManagers(modelId, type) {
  const response = await axios.get(`/PossibleManagers?modelId=${modelId}&type=${type}`);
  return response;
}
export async function removeManager(modelId, type, facilityManagerId) {
  const response = await axios.post(
    `/AssignManager?active=false&modelId=${modelId}&type=${type}&facilityManagerId=${facilityManagerId}`
  );
  return response;
}

export async function addManagers(modelId, type, facilityManagerIds) {
  const response = await axios.post(
    `/AssignManagers?modelId=${modelId}&type=${type}`,
    objectToFormData({
      facilityManagerIds,
    })
  );
  return response;
}
