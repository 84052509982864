import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, Box, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { getOrderReviewers, postReviewerForm } from '../../../../api/facility';
import { getUsers } from '../../../../redux/slices/users';
import { useDispatch, useSelector } from '../../../../redux/store';
import FormDropdown from '../../../../reusable-components/form/FormDropdown';
import { useSnackbar } from '../../../../reusable-components/snackbar';
import ApprovalUserOptionsMenu from './ApprovalUserOptionsMenu';


ApprovalWorkflowTab.propTypes = {
  selectedRow: PropTypes.object,
  companies: PropTypes.array,
  data: PropTypes.array,
  fetchData: PropTypes.func,
  setTab: PropTypes.func,
  setSelectedRow: PropTypes.func,
};

export default function ApprovalWorkflowTab({ selectedRow }) {
  const { enqueueSnackbar } = useSnackbar();
  const [edit, setEdit] = useState(false);
  const { users } = useSelector((state) => state.users.data);
  const userData = useMemo(() => Object.values((users?.map(u => ({ id: u.email, label: `${u.firstName} ${u.lastName}` })))), [users]);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const [value, setValue] = useState({
    reviewers: [{
      user: { id: '', label: '' },
      categories: []
    }, {
      user: { id: '', label: '' },
      categories: []
    }] || '',
    users: [
      {
        name: { id: '', label: '' },
        reviewers: [
          { user: { id: '', label: '' }, categories: [] }
        ],
      }] || [],
  });

  const dispatch = useDispatch()

  const handleEdit = () => {
    setEdit(true);
  };


  const handleGetData = async () => {
    await dispatch(getUsers());

    const response = await getOrderReviewers(selectedRow.id);
    if (response.data) {

      const usersMap = response.data.facilityUsers?.map(user => ({
        name: { id: user.email, label: `${user.name} - ${user.position}` },
        reviewers:
          [...response.data.reviewers?.filter(rev => rev.facilityUserEmail === user.email)?.map(rev =>
          ({
            user: { id: rev.reviewerEmail, label: rev.reviewerName, orderReviewerId: rev.orderReviewerId },
            categories: [{ id: 3, label: 'DME' }, { id: 4, label: 'Office' }]
          }))]

      }))

      const facilityReviewsMap =
        response.data.reviewers?.filter(rev => rev.facilityUserEmail === null)
          .map(rev => ({
            user: { id: rev.reviewerEmail, label: rev.reviewerName, orderReviewerId: rev.orderReviewerId },
            categories: [{ id: 1, label: 'dietary' }, { id: 2, label: 'medical' }]
          }))

      setValue(({ ...value, reviewers: facilityReviewsMap, users: usersMap }))
    }
  }

  useEffect(() => {
    handleGetData();
  }, [])


  const handleApplyAll = async (user1) => {
    if (Array.isArray(user1)) {
      user1.forEach((newUser) => {
        value.users.forEach((user, userIndex) => {
          if (!user.reviewers.map(r => r?.user?.id).some(o => o === newUser?.user?.id)) {
            setValue(prevValue => {
              const newUsers = [...prevValue?.users];
              const newUserReviewers = [...newUsers[userIndex]?.reviewers];
              newUserReviewers.push(newUser);
              newUsers[userIndex] = { ...newUsers[userIndex], reviewers: newUserReviewers };
              return { ...prevValue, users: newUsers };
            })
          }
        });
      })
    } else {
      value.users.forEach((user, userIndex) => {
        if (!user.reviewers.map(r => r?.user?.id).some(o => o === user1?.user?.id)) {
          setValue(prevValue => {
            const newUsers = [...prevValue?.users];
            const newUserReviewers = [...newUsers[userIndex]?.reviewers];
            newUserReviewers.push(user1);
            newUsers[userIndex] = { ...newUsers[userIndex], reviewers: newUserReviewers };
            return { ...prevValue, users: newUsers };
          })
        }
      });
    }

  }


  const handleRemoveAll = async (user1) => {
    if (value.reviewers.some(r => r?.user?.id === user1?.user?.id)) {
      setValue(prevValue => {
        const newUsers = [...prevValue?.reviewers];
        const newUserReviewers = [...newUsers.filter(reviewer => reviewer.user.id !== user1.user.id)];
        return { ...prevValue, reviewers: newUserReviewers };
      });
    }

    value.users.forEach((user, userIndex) => {
      if (user.reviewers.map(r => r?.user?.id).some(o => o === user1?.user?.id)) {
        setValue(prevValue => {
          const newUsers = [...prevValue?.users];
          const newUserReviewers = [...newUsers[userIndex]?.reviewers.filter(reviewer => reviewer.user.id !== user1.user.id)];
          newUsers[userIndex] = { ...newUsers[userIndex], reviewers: newUserReviewers };
          return { ...prevValue, users: newUsers };
        });
      }

    });
  }


  const handleEditSave = async () => {
    const response = await postReviewerForm(value, selectedRow.id);

    if (response.data.error) {
      enqueueSnackbar(`Error: ${response.data.error}`, {
        variant: 'error',
      });
    }
    else if (response.status === 200) {
      enqueueSnackbar(`Successfully updated facility reviewers`, {
        variant: 'success',
      });

      setEdit(false);
    } else
      enqueueSnackbar(`Error: Could not update reviewers`, {
        variant: 'error',
      });
    handleGetData();
  };

  const accordionClicked = (index) => {
    if (expandedAccordions.includes(index))
      setExpandedAccordions(
        expandedAccordions.filter((number) => number !== index)
      );
    else setExpandedAccordions([...expandedAccordions, index]);
  };

  const collapseAll = () => {
    setExpandedAccordions([]);
  };

  const expandAll = () => {
    const newArray = [];
    value.users.forEach((log, index) => newArray.push(index));
    setExpandedAccordions(newArray);
  };



  return (
    <Paper sx={{ mt: 2, px: 8, pb: 4 }}>
      <Grid item xl={12}>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item sx={{ mr: 3, my: 3 }}>
              <Typography variant="h3" color="info.main" display="inline">
                {selectedRow.facilityName} | Review Workflow
              </Typography>
            </Grid>

            <Grid item>
              <Box>
                <>
                  {edit ? (
                    <>
                      <Button variant="outlined" size="small" color="primary" sx={{ mr: 1 }} onClick={handleEditSave}>
                        Save
                      </Button>
                      <Button variant="outlined" size="small" color="error" onClick={() => { setEdit(false); handleGetData(); }}>
                        Cancel
                      </Button>
                    </>) : (
                    <>
                      <Button variant="outlined" color="primary" size="small" startIcon={<EditIcon />} sx={{ mr: 1 }} onClick={handleEdit}>
                        Edit
                      </Button>
                    </>
                  )}
                </>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box>
          <Grid>
            <Alert severity="warning">Deleting a reviewer from the facility completely will delete their permission to edit orders</Alert>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={4.2} sx={{ my: 3 }}>
                <Typography variant="subtitle1" color="info.main">Facility Reviewers</Typography>
              </Grid>
            </Grid>

            <Grid container >
              {value?.reviewers?.length > 0 && value?.reviewers?.map((reviewer, reviewerIndex) =>
                <Grid item xs={4} key={reviewer.orderReviewerId}>
                  {edit ?
                    <Grid container sx={{ ml: 4 }} alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography variant="subtitle1" >Reviewer {reviewerIndex + 1}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormDropdown
                          disableClearable
                          disabled={!edit}
                          fullWidth
                          value={value?.reviewers[reviewerIndex].user}
                          onChange={(event, newValue) => {
                            setValue(prevValue => {
                              prevValue?.users.forEach((user) => {
                                const userRevIndex = user.reviewers?.map(u => u?.user?.id)?.findIndex(v => v === prevValue?.reviewers[reviewerIndex].user?.id);
                                if (userRevIndex > -1) {
                                  const revs = user.reviewers;
                                  revs[userRevIndex] = { categories: [], user: { ...newValue } };
                                }
                              });
                              const newReviewers = [...prevValue.reviewers];
                              const newReviewer = { categories: [], user: { ...newValue } };
                              newReviewers[reviewerIndex] = newReviewer;
                              return { ...prevValue, reviewers: newReviewers };
                            })
                          }}
                          options={userData}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          getOptionLabel={(option) => option?.label ? `${option?.label} - ${option?.id}` : ''}
                          renderInput={(params) => <TextField {...params} label="Search or select" />}
                        />
                      </Grid>
                      <Grid item>
                        <Button key="DelAll" color="error"
                          startIcon={<DeleteSweepOutlinedIcon />}
                          onClick={() => handleRemoveAll(reviewer)}>
                          Delete
                        </Button>
                      </Grid>
                    </Grid>
                    : <Grid container sx={{ ml: 4 }} alignItems="center" spacing={2}>
                      <Grid item sx={{ ml: 4 }}>
                        <Typography variant="subtitle1">Reviewer {reviewerIndex + 1}</Typography>
                      </Grid>
                      <Grid item sx={{ ml: 4 }}>
                        <Typography> {value?.reviewers[reviewerIndex]?.user?.label}</Typography>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              )
              }
            </Grid>
            {edit &&
            <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2, mt: 2 }}>
              <Grid item>
                <Button variant="outlined" color="secondary" size="small" onClick={() => {
                  setValue(prevValue => {
                    const newReviewers = [...prevValue.reviewers];
                    newReviewers.push({ user: {}, categories: [] });

                    prevValue.users.forEach(user => {
                      const revs = user.reviewers;
                      revs.push({ user: {}, categories: [] });
                    });

                    return { ...prevValue, reviewers: newReviewers };
                  });
                }} >Add Reviewer</Button>
              </Grid>
            </Grid>
            }

            <Divider sx={{ mt: 4, width: '100%', borderColor: '#4ca5e7', borderStyle: 'dashed' }} />

            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginBottom: 2, mt: 2 }}>
              <Grid item>
                <Typography variant="subtitle1" color="info.main">Facility Employee Reviewers</Typography>
              </Grid>
              <Grid item>
                <Button color="info" onClick={expandAll}>
                  Expand All
                </Button>
                <Button color="info" onClick={collapseAll}>
                  Collapse All
                </Button>
              </Grid>
            </Grid>

            {value?.users?.length > 0 && value?.users?.map((user, index) => (
              <Accordion key={user.name?.id} disableGutters
                onChange={() => accordionClicked(index)} expanded={expandedAccordions.includes(index)}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ArrowDropDownIcon />}>
                  <Typography> <strong>{index + 1})</strong> {value?.users[index]?.name?.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                    {user?.reviewers?.length > 0 && user?.reviewers?.map((reviewer, reviewerIndex) =>
                      <>
                        <Grid item xs={4}>
                          {edit ?
                            <Grid container sx={{ ml: 4 }} alignItems="center" spacing={2}>
                              <Grid item>
                                <Typography variant="subtitle1" >Reviewer {reviewerIndex + 1}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <FormDropdown
                                  disableClearable
                                  disabled={!edit}
                                  fullWidth
                                  value={value?.users[index]?.reviewers[reviewerIndex].user}
                                  onChange={(event, newValue) => {
                                    setValue(prevValue => {
                                      const newUsers = [...prevValue?.users];
                                      const newUserReviewers = [...newUsers[index]?.reviewers];
                                      newUserReviewers[reviewerIndex] = { ...newUserReviewers[reviewerIndex], user: newValue };
                                      newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                                      return { ...prevValue, users: newUsers };
                                    })
                                  }}
                                  options={userData}
                                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                  getOptionLabel={(option) => option?.label ? `${option?.label} - ${option?.id}` : ''}
                                  renderInput={(params) => <TextField {...params} label="Search or select" />}
                                />
                              </Grid>
                              <Grid item>
                                <ApprovalUserOptionsMenu menuElements={
                                  [<Button key="ApplyAll" color="black"
                                    startIcon={<DynamicFeedOutlinedIcon />}
                                    onClick={() => handleApplyAll(reviewer)}>
                                    Apply all
                                  </Button>,
                                  <Button key="DeleteOne" color="error"
                                    startIcon={<DeleteOutlineIcon />}
                                    onClick={() => {
                                      const usersArr = [...value.users];
                                      const reviewersArr = [...usersArr[index]?.reviewers]
                                      reviewersArr.splice(reviewerIndex, 1);
                                      usersArr[index] = { ...usersArr[index], reviewers: reviewersArr };
                                      setValue({ ...value, users: usersArr })
                                    }}>
                                    Delete
                                  </Button>,
                                  <Button key="DelAll" color="error"
                                    startIcon={<DeleteSweepOutlinedIcon />}
                                    onClick={() => handleRemoveAll(reviewer)}>
                                    Remove all
                                  </Button>]
                                } />
                              </Grid>
                            </Grid>
                            : <Grid container sx={{ ml: 4 }} alignItems="center" spacing={2}>
                              <Grid item sx={{ ml: 4 }}>
                                <Typography variant="subtitle1" >Reviewer {reviewerIndex + 1}</Typography>
                              </Grid>
                              <Grid item sx={{ ml: 4 }}>
                                <Typography> {value?.users[index]?.reviewers[reviewerIndex].user?.label}</Typography>
                              </Grid>
                            </Grid>
                          }
                        </Grid>

                      </>)
                    }
                  </Grid>
                  {edit &&
                    <Grid item xs={2} sx={{ ml: 4 }}>
                      <Button variant="outlined" color="secondary" size="small" onClick={() => {
                        setValue(prevValue => {
                          const newUsers = [...value.users];
                          const newUserReviewers = [...newUsers[index]?.reviewers];
                          newUserReviewers.push({ user: {}, categories: [] });
                          newUsers[index] = { ...newUsers[index], reviewers: newUserReviewers };
                          return { ...prevValue, users: newUsers };
                        });
                      }} >Add Reviewer</Button>
                    </Grid>}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Box>
      </Grid >
    </Paper >
  );
}
