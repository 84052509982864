import PropTypes from 'prop-types';
import { Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

import { useSelector } from '../../../../redux/store';
import Markdown from '../../../../reusable-components/markdown';
import { CustomAvatar } from '../../../../reusable-components/custom-avatar';
import FileThumbnail from '../../../../reusable-components/file-thumbnail';
import { stringToColor } from '../../../../utils/cssStyles';
import { fMonthDayTimeYear, convertToUnix } from '../../../../utils/formatTime';
import { HOST_API_URL } from '../../../../config-global';
import { useTabs } from '../../../../context/TabContext';

// ----------------------------------------------------------------------

TicketMessageItem.propTypes = {
  message: PropTypes.object,
  previousMessage: PropTypes.object,
  onOpenLightbox: PropTypes.func,
  users: PropTypes.array,
  index: PropTypes.number,
  fileArray: PropTypes.array,
  nextMessage: PropTypes.object,
  hideAvatar: PropTypes.bool,
};

export default function TicketMessageItem({
  message,
  previousMessage,
  onOpenLightbox,
  users,
  index,
  fileArray,
  nextMessage,
  hideAvatar,
}) {
  const { addNewTabAndNavigate } = useTabs();

  const { attachFileId, file, fullName, id, note, sentOn, sentBy, systemMessage, textRich, ticketNumber } = message;

  const { email } = useSelector((state) => state.user.user);
  const currentUser = sentBy === email;
  // const isImage = contentType === 'image';

  const name = fullName || sentBy;
  const previousMsgName = previousMessage?.fullName || previousMessage?.sentBy;
  const nextMsgName = nextMessage?.fullName || nextMessage?.sentBy;

  const replaceLinks = (text) => {
    // Split the text into parts based on <img> tags
    const parts = text.split(/(<img[^>]*>)/gi);
    // Process each part separately
    return parts
      .map((part) => {
        // If the part is an <img> tag, return it as is
        if (part.toLowerCase().startsWith('<img')) {
          return part;
        }

        // Regular expression to match HTTP and HTTPS URLs not already inside <a> tags or part of an <img> tag
        const urlRegex = /(\bhttps?:\/\/[^\s<>"']+[^\s<>"']*)(?![^<]*<\/a>)/gi;

        // Function to convert URL into an <a> tag
        const linkify = (url) => {
          // Check if the URL is part of an existing <a> tag with additional attributes
          const regUrl = url.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
          const existingATagPattern = new RegExp(`<a [^>]*href=["']${regUrl}["'][^>]*>`, 'gi');
          if (existingATagPattern.test(part)) {
            return url; // If so, return the URL as is
          }

          // Convert URL into an <a> tag if it's not part of an existing <a> tag
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        };
        // Apply the URL replacement for non-<img> parts
        return part.replace(urlRegex, linkify);
      })
      .join('');
  };

  const removeDetails =
    previousMessage &&
    previousMsgName === name &&
    convertToUnix(sentOn) - convertToUnix(previousMessage?.sentOn) < 120000;

  const removeAvatar =
    (nextMessage && nextMsgName === name && convertToUnix(nextMessage?.sentOn) - convertToUnix(sentOn) < 120000) ||
    false;

  const getTicketFile = async (noteId) => {
    window.open(`${HOST_API_URL}Api/TicketFile?noteId=${noteId}`, '_blank');
  };

  const sameSite = (url) => url.includes('localhost:44475/') || url.includes('app.platformsolutions.com');

  return (
    <Grid
      container
      direction="row"
      justifyContent={currentUser ? 'flex-end' : 'flex-start'}
      alignItems="flex-end"
      sx={{ mt: removeDetails ? 0.5 : 2, pr: currentUser ? 2 : 0 }}
      key={index}
    >
      {currentUser || removeAvatar ? (
        !currentUser && <Grid item xs={0.5} />
      ) : (
        <Grid item xs={0.5}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" key={index}>
            <>{!hideAvatar && <CustomAvatar name={name} email={sentBy} sx={{ ml: 0.75 }} />}</>
          </Grid>
        </Grid>
      )}

      <Grid item xs={11.5}>
        <Grid container direction="column" alignItems={currentUser ? 'flex-end' : 'flex-start'}>
          {removeDetails ? null : (
            <>
              <Grid
                direction="row"
                alignItems="center"
                sx={{
                  mr: currentUser ? 1.75 : 0,
                  ml: !currentUser ? 3 : 0,
                }}
              >
                <Typography noWrap variant="caption">
                  {!currentUser && `${name || sentBy},`}
                </Typography>
                <Typography noWrap variant="caption" sx={{ ml: 0.5, color: 'text.disabled' }} component="span">
                  {fMonthDayTimeYear(message.sentOn)}
                </Typography>
              </Grid>
            </>
          )}
          {file ? (
            <Grid
              container
              direction="row"
              justifyContent={currentUser ? 'flex-end' : 'flex-start'}
              alignItems={currentUser ? 'flex-end' : 'flex-start'}
              sx={{ p: 1 }}
            >
              {fileArray && fileArray.length > 1 ? (
                fileArray.map((file, index) => (
                  <Grid
                    item
                    key={index}
                    spacing={2}
                    sx={{
                      width: 96,
                      height: 96,
                      flexShrink: 0,
                      borderRadius: 1,
                      overflow: 'hidden',
                      position: 'relative',
                      backgroundColor: 'background.neutral',
                      ml: index !== fileArray.length - 1 && !currentUser ? 0.75 : 2,
                      mr: index !== fileArray.length - 1 && currentUser ? 0.75 : 0,
                      border: () => `solid 1px ${stringToColor(name)}`,
                    }}
                  >
                    <FileThumbnail
                      file={file.note}
                      tooltip
                      onDownload={() => getTicketFile(file.id)}
                      sx={{ width: 96, height: 96, mx: 1 }}
                      onImageView={() => onOpenLightbox(`/api/TicketFile?noteId=${file.id}`)}
                      preview={`/api/TicketFile?noteId=${file.id}`}
                    />
                  </Grid>
                ))
              ) : (
                <Grid
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: 96,
                    height: 96,
                    flexShrink: 0,
                    borderRadius: 1,
                    overflow: 'hidden',
                    position: 'relative',
                    backgroundColor: 'background.neutral',
                    ml: currentUser ? 0.75 : 2,
                    border: () => `solid 1px ${stringToColor(name)}`,
                  }}
                >
                  <FileThumbnail
                    file={note}
                    tooltip
                    onDownload={() => getTicketFile(id)}
                    sx={{ width: 96, height: 96 }}
                    onImageView={() => onOpenLightbox(`/api/TicketFile?noteId=${id}`)}
                    preview={`/api/TicketFile?noteId=${id}`}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Box>
              <Grid
                sx={{
                  p: 1,
                  mr: currentUser ? 1 : 0,
                  ml: !currentUser ? 3 : 0,
                  // pr: 6,
                  minWidth: note.includes('<img') ? '500px' : 'fit-content',
                  maxWidth: '40vw',
                  borderRadius: '9px',
                  wordWrap: 'break-word',
                  typography: 'body2',
                  border: () => `solid 1px ${stringToColor(name)}`,
                  display: 'inline-block',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                <Box
                  onClick={(event) => {
                    if (event.target.tagName === 'A') {
                      event.preventDefault();
                      if (sameSite(event.target.href)) {
                        addNewTabAndNavigate(event.target.href);
                      } else {
                        window.open(event.target.href, '_blank');
                      }
                    }
                    if (event.target.tagName === 'IMG') {
                      onOpenLightbox(event.target.src);
                    }
                  }}
                  sx={{
                    // pr: 2,
                    '& img': {
                      cursor: 'pointer',
                      '&:hover': {
                        filter: 'brightness(0.9)',
                        padding: 0.3,
                        border: () => `solid 1px ${stringToColor(name)}`,
                      },
                    },
                  }}
                >
                  <Markdown children={replaceLinks(note)} />
                </Box>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>

      {!currentUser || removeAvatar ? (
        currentUser && <Grid item xs={0.5} />
      ) : (
        <Grid item xs={0.5}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-end" key={index}>
            <>{!hideAvatar && <CustomAvatar name={name} email={sentBy} sx={{ mr: 1 }} />}</>
          </Grid>
        </Grid>
      )}
      {/* {removeDetails && <Box sx={{ ml: 7 }} />} */}
    </Grid>
  );
}
