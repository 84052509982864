import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Dialog, DialogTitle, DialogContent, Tooltip, IconButton, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import HistoryIcon from '@mui/icons-material/History';
import { useSelector, useDispatch } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';
import { getProductHistory, getOrderHistory, clearHistory } from '../../../../redux/slices/catalog';
import ItemHistory from './ItemHistory';
import OrderHistory from './OrderHistory';

OrderHistoryDialog.propTypes = {
  productId: PropTypes.number,
  tooltip: PropTypes.string,
};

export default function OrderHistoryDialog({ productId, tooltip }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [itemHistory, setItemHistory] = useState();
  const [ordHistory, setOrdHistory] = useState();
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const {
    data: { productHistory, orderHistory },
  } = useSelector((state) => state.catalog);

  useEffect(() => {
    setItemHistory(productHistory);
    setOrdHistory(orderHistory);
  }, [productHistory, orderHistory]);

  useEffect(() => {
    if (open) {
      if (!productHistory.length) {
        dispatch(getProductHistory(productId));
        dispatch(getOrderHistory(productId));
      } else if (productHistory[0].productId !== productId) {
        dispatch(getProductHistory(productId));
        dispatch(getOrderHistory(productId));
      }
    }
  }, [productId, open]);

  const handleClickOpen = (e) => {
    e.itemHistory = true;
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(clearHistory());
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={tooltip || 'Item history'}>
        <IconButton color="warning" onClick={handleClickOpen}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="full" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          History
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '100%', width: '100%' }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            indicatorColor="transparent"
            sx={{
              backgroundColor: '#f5f6f7',
            }}
          >
            <CustomTab label="Order History" selected={tab === 0} />
            <CustomTab label="Item History" selected={tab === 1} />
          </Tabs>
          <Grid item xs={12}>
            <Box sx={{ width: '100%', height: 'calc(100vh - 205px)' }}>
              {tab === 0 ? <OrderHistory data={ordHistory} /> : <ItemHistory data={itemHistory} />}
            </Box>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CustomTab = styled((props) => <Tab {...props} />)(({ selected }) => ({
  minHeight: '43px',
  height: '43px',
  maxHeight: '43px',
  borderBottom: 'none',
  marginTop: '5px',
  marginLeft: '4px',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  color: '#444',
  borderRadius: '5px 5px 0px 0px',
  backgroundColor: selected ? '#fff' : '#DFE3E8',
  boxShadow: selected ? '0px 2px 4px 1px rgba(0, 0, 0, 0.2)' : '0px 1px 2px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.2)', // Add shadow on hover for a more interactive feel
  },
}));
