import axios from '../../utils/axios';
import { objectToFormData } from '../utils';

// GET
// /Api/Admin/Facility/FacilityData
export const getFacilityData = async () => {
  const response = await axios.get('/Admin/Facility/FacilityData');
  return response;
};

// POST
// /Api/Admin/Facility/FacilityForm
export const postFacilityForm = async ({ form, id, attachment }) => {
  const updatedForm = {
    Beds: form.beds,
    CompanyId: form.company?.value ?? 0,
    ParentFacilityId: form.parentFacility?.id,
    FacilityName: form.facilityName,
    ShortName: form.shortName,
    Abbreviation: form.abbreviation,
    StreetAddress: form.streetAddress,
    City: form.city,
    State: form.state,
    Zip: form.zip,
    PhoneNumber: form.phoneNumber,
    TaxExempt: form.taxExempt,
    FacStructure: form.facStructure,
    ApPhone: form.apPhone,
    EINNumber: form.EIN,
    Subgroup: form.subgroup,
    InvoiceEmail: form.invoiceEmail,
    RepName: form.repName,
    RepEmail: form.repEmail,
    PayMethod: form.payMethod,
    logo: form.logo,
  };

  if (id) {
    updatedForm.Id = id;
  }
  if (attachment) {
    updatedForm.SiteMapFile = attachment.path;
  }

  const formData = objectToFormData({
    ...updatedForm,
    attachment,
  });

  const response = await axios.post('Admin/Facility/FacilityForm', formData, attachment);
  return response;
};

// POST
// /Api/Admin/Facility/DeleteFacility id
export const postDeleteFacility = async (id) => {
  const response = await axios.post(`/Admin/Facility/DeleteFacility?id=${id}`);
  return response;
};

// GET
// /Api/Admin/Facility/DownloadFacilities
////Download Api is used in code bec we need the `${HOST_API_URL}

// GET
// /Api/Admin/Facility/BookingVendorAccountsData id
export const getBookingVendorAccountsData = async (facilityId) => {
  const response = await axios.get(`/Admin/Facility/BookingVendorAccountsData?facilityId=${facilityId}`);
  return response;
};

// POST
// /Api/Admin/Facility/UpdateFacilityBookingType facilityId typeId
export const postUpdateFacilityBookingType = async (facilityId, typeId, account) => {
  const response = await axios.post(
    `/Admin/Facility/UpdateFacilityBookingType?facilityId=${facilityId}&typeId=${typeId}&account=${account}`
  );
  return response;
};

// POST
// /Api/Admin/Facility/DeleteFacilityBookingVendorAccount
export const postDeleteFacilityBookingVendorAccount = async (id) => {
  const response = await axios.post(`/Admin/Facility/DeleteFacilityBookingVendorAccount?id=${id}`);
  return response;
};

// POST
// /Api/Admin/Facility/FacilityBookingAccountForm
export const postFacilityBookingAccountForm = async (form, facilityId, id) => {
  const updatedForm = {
    FacilityId: facilityId,
    BookingId: form.account.id,
    VendorId: form.vendor.id,
  };

  if (id) {
    updatedForm.Id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Admin/Facility/FacilityBookingAccountForm', formData);
  return response;
};

// POST
// /Api/Admin/Facility/CopyFacilityVendorBookingAccounts copyFrom copyTo
export const postCopyFacilityVendorBookingAccounts = async (copyFrom, copyTo) => {
  const response = await axios.post(
    `/Admin/Facility/CopyFacilityVendorBookingAccounts?copyFrom=${copyFrom}&copyTo=${copyTo}`
  );
  return response;
};

// POST
// /Api/Admin/Facility/UpdateFacilityExtraField facilityId type value
export const postUpdateFacilityExtraField = async (facilityId, value, type) => {
  const response = await axios.post(
    `/Admin/Facility/UpdateFacilityExtraField?facilityId=${facilityId}&type=${type}&value=${value}`
  );
  return response;
};

// POST
// /Api/Admin/Facility/APContactForm facilityId email id
export const postAPContactForm = async (facilityId, email, id) => {
  const updatedForm = {
    FacilityId: facilityId,
    Email: email,
  };

  if (id) {
    updatedForm.Id = id;
  }

  const formData = objectToFormData({
    ...updatedForm,
  });

  const response = await axios.post('Admin/Facility/APContactForm', formData);
  return response;
};

// GET/Api/OrderReviewers; facilityId
export async function getOrderReviewers(facilityId) {
  const response = await axios.get(`Admin/Facility/OrderReviewers?facilityId=${facilityId}`);
  return response;
}

// POST Api/Admin/Facility/DeleteFacilityContact id
export async function deleteFacilityContact(id) {
  const response = await axios.post(`Admin/Facility/DeleteFacilityContact?id=${id}`);
  return response;
}

// POST
// /Api/Admin/Facility/ReviewerForm
export const postReviewerForm = async (form, facilityId) => {
  const updatedForm = {
    FacilityId: facilityId,
  };

  const formData = objectToFormData({
    ...updatedForm,
  });

  //Facility level reviewers
  for (let index = 0; index < form.reviewers.length; ++index) {
    const facilityReviewer = form.reviewers[index]?.user?.id;
    const facilityReviewerId = form.reviewers[index]?.user?.orderReviewerId ?? 0;
    if (facilityReviewer) {
      formData.append(`FacilityReviewers[${index}].Email`, facilityReviewer);
      formData.append(`FacilityReviewers[${index}].Id`, facilityReviewerId);
    }
  }

  //facility user level reviewers
  for (let i = 0; i < form.users.length; ++i) {
    const facilityUser = form.users[i];
    if (facilityUser?.name?.id) {
      formData.append(`FacilityUsers[${i}].Email`, facilityUser.name.id);
      const countRevs = facilityUser?.reviewers?.length ?? 0;
      for (let j = 0; j < countRevs; ++j) {
        const userReviewer = facilityUser.reviewers[j]?.user?.id;
        const userReviewerId = facilityUser.reviewers[j]?.user?.orderReviewerId ?? 0;
        if (userReviewer) {
          formData.append(`FacilityUsers[${i}].UserReviewers[${j}].Email`, userReviewer);
          formData.append(`FacilityUsers[${i}].UserReviewers[${j}].Id`, userReviewerId);
        }
      }
    }
  }

  const response = await axios.post('Admin/Facility/OrderReviewerForm', formData);
  return response;
};
