import * as React from 'react';
// External libraries
import { useNavigate } from 'react-router-dom';
import { DialogTitle, DialogContentText, TextField, Autocomplete } from '@mui/material';
import { useDispatch } from '../../../../redux/store';
import Iconify from '../../../../reusable-components/iconify';

export default function CategorySelect() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryOptions = [
    { label: ' DME', icon: <Iconify icon="openmoji:man-in-manual-wheelchair" width={50} height={50} /> },
    { label: ' Respiratory', icon: <Iconify icon="icon-park:medical-files" width={40} height={40} /> },
  ];

  return (
    <>
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgba(112, 112, 112, 0.5)',
        }}
      >
        Rental Type
      </DialogTitle>
      <DialogContentText sx={{ fontWeight: 'bold' }}>Category Name</DialogContentText>{' '}
      <Autocomplete
        sx={{
          width: '100%',
          mt: 1,
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiFormLabel-root ': 'none',
        }}
        type="new-password"
        options={categoryOptions}
        autoHighlight
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <li {...props}>
            {option.icon}
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      />
    </>
  );
}
