import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import Iconify from '../../../../reusable-components/iconify';
import { getBudgetItemDetailsData } from '../../../../api/orders';
import { BUDGET_HISTORY } from '../../../../reusable-components/datagrid/orderColumns';

HistoryModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  includeUnapproved: PropTypes.bool,
  budgetDate: PropTypes.any,
  catId: PropTypes.number,
  facId: PropTypes.any,
  subcategoryId: PropTypes.number,
};

export default function HistoryModal({
  openModal,
  setOpenModal,
  includeUnapproved,
  budgetDate,
  catId,
  facId,
  subcategoryId,
}) {
  const [data, setData] = useState([]);

  const getBudgetItemDetails = async () => {
    const response = await getBudgetItemDetailsData({
      categoryId: catId,
      facilityId: facId,
      budgetDate,
      includeUnapproved,
      subcategoryId,
    });
    if (response.data) {
      setData(response.data);
    }
  };
  useEffect(() => {
    if (openModal) {
      getBudgetItemDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catId, facId, includeUnapproved, openModal]);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle
          sx={{
            textAlign: 'center',
          }}
        >
          Budget Ordered History
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:sharp-close" width={28} height={28} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CustomDataGrid
            gridId="orders-new-order-history-modal"
            boxSX={{ height: 'calc(100vh - 240px)', width: '100%' }}
            data={data}
            gridColumns={BUDGET_HISTORY}
            sort={[{ field: 'orderNumber', sort: 'asc' }]}
            isModal
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
