import { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Grid, Button, Typography, IconButton, Tooltip } from '@mui/material';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { removedFromBudget } from '../../../../api/orders';
import { getPreviousOrderInfo } from '../../../../redux/slices/orders';
import { useDispatch, useSelector } from '../../../../redux/store';
import CustomDataGrid from '../../../../reusable-components/datagrid/CustomDataGrid';
import { ORDER_COLUMNS } from '../../../../reusable-components/datagrid/noFlexOrderColumns';
import Iconify from '../../../../reusable-components/iconify';
import { useScopeCheck, ScopeGuard } from '../../../../reusable-components/scopes';
import { fMonthDayYear } from '../../../../utils/formatTime';
import CompletedOrderInfo from './CompletedOrderInfo';
import DownloadOrderDropdown from './DownloadOrderDropdown';
import OrdersSent from './OrdersSent';
import ReRunDropdown from './ReRunDropdown';
import CompletedOrderTicket from './CompletedOrderTicket';
import Scrollbar from '../../../../reusable-components/scrollbar';
import { CollapseSidebar } from '../../../../reusable-components/collapse-sidebar';
import BudgetBreakdown from './BudgetBreakdown';
import { BudgetBox } from '../new-order';

CompletedOrderView.propTypes = {
  overrideOrderId: PropTypes.string,
  invoiceView: PropTypes.bool,
  newInvoiceView: PropTypes.bool,
};

export default function CompletedOrderView({ invoiceView, newInvoiceView, overrideOrderId }) {
  const { id: orderId = '' } = useParams();
  const id = overrideOrderId || orderId;

  const hasEditAccess = useScopeCheck(['Edit-Budgets'], true);
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(true);
  const [currentTicket, setCurrentTicket] = useState(0);
  const [openTicket, setOpenTicket] = useState('close');
  const [addItemsToTicket, setAddItemsToTicket] = useState('');
  const [selected, setSelected] = useState([]);
  const [pinnedColumns, setPinnedColumns] = useState({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'pictures'],
    right: ['action', 'vendorEDINumber', 'ordered', 'vendor', 'statusCode'],
  });
  const { orderById } = useSelector((state) => state.orders);
  const { items = [], orderNumber = '', linkedTickets = [], facilityId, budgets = [] } = orderById[id] || {};
  const isLoading = useSelector((state) => state.orders.isLoading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [openTracking, setOpenTracking] = useState(false);
  useEffect(() => {
    if (addItemsToTicket) {
      setPinnedColumns({
        left: ['pictures'],
        right: ['action', 'vendorEDINumber', 'ordered', 'vendor', 'statusCode', GRID_CHECKBOX_SELECTION_COL_DEF.field],
      });
    }
  }, [addItemsToTicket]);
  useEffect(() => {
    if (items) {
      const newItemsOrder = [];
      items.map((item) => {
        if (item.statusCode === 'Accepted with Changes' || item.statusCode === 'Unapproved') {
          newItemsOrder.unshift(item);
        } else {
          newItemsOrder.push(item);
        }

        return newItemsOrder;
      });

      setData(newItemsOrder);
    }
  }, [items]);

  const budgetHandler = async (rowId, removeBudget) => {
    const response = await removedFromBudget(rowId, removeBudget);
    if (response.status === 200) {
      dispatch(getPreviousOrderInfo(id));
    }
  };
  const departments = useMemo(() => {
    const seen = new Set();
    const result = [];
    const allItems = [...(items || [])];
    allItems.forEach((row) => {
      if (row.agoraCategory && !seen.has(row.agoraCategory)) {
        seen.add(row.agoraCategory);
        result.push({ name: row.agoraCategory, id: row.agoraCategoryId });
      }
    });
    return result;
  }, [items, id]);

  const action = {
    field: 'action',
    width: 120,
    headerName: 'Budget',
    renderCell: (params) =>
      hasEditAccess ? (
        <div>
          <Tooltip
            title={
              params.row.price * params.row.quantity === 0
                ? ''
                : params.row.removedFromBudget
                ? 'Add to Budget'
                : 'Remove from Budget'
            }
          >
            <IconButton
              sx={{
                opacity: 0.8,
              }}
              onClick={() => budgetHandler(params.row.id, !params.row.removedFromBudget)}
            >
              {params.row.removedFromBudget || params.row.price * params.row.quantity === 0 ? (
                <MoneyOffIcon width={28} height={28} />
              ) : (
                <AttachMoneyIcon color="primary" width={28} height={28} />
              )}
            </IconButton>
          </Tooltip>
          <ScopeGuard scopes={['Super-Admin']}>
            <BudgetBreakdown selectedRow={params.row} />
          </ScopeGuard>
        </div>
      ) : (
        <></>
      ),
  };

  const status = {
    field: 'statusCode',
    width: 200,
    headerName: 'Status',
    headerAlign: 'flex-start',
    renderCell: (params) => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item>
          {(params.row?.statusUpdates?.length > 0 || params.row?.comments?.length > 0) && (
            <Tooltip
              title={
                <>
                  {params.row.comments?.map((comment, index) => (
                    <div key={index}>Note: {comment}</div>
                  ))}
                  {params.row?.statusUpdates?.length > 0
                    ?
                    params.row?.statusUpdates?.map((status) => {
                     const originalIq = items.find(j => j.id === status.replacedByItemQuantityId); 
                       return( <div key={status}>
                         <div>Status: {status.status} {originalIq ? `By: ${originalIq.description}` : ``}</div>
                          <div>Date: {fMonthDayYear(status.dateAdded)}</div>
                          <div>
                            Updated by: {status.updatedBy}
                            {params.row.vendorEDINumber ? ` ${params.row.vendorEDINumber}` : ``}
                          </div>
                          <br />
                          <br />
                        </div>
                    )})
                    : ''}
                </>
              }
            >
              <Iconify color="info" icon="material-symbols:info-outline" width={16} height={16} />
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Box
              component="div"
              sx={{
                fontSize: '0.7rem',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'pre-wrap',
              }}
            >
              {params.row.statusCode}
              {params.row.trackingNumbers?.length > 0 && (
                <Tooltip
                  title={
                    params.row.trackingNumbers?.length > 0 &&
                    (params.row.trackingUrls
                      ? params.row.trackingNumbers?.map((trackingNumber, index) => (
                          <a
                            key={index}
                            href={params.row.trackingUrls + trackingNumber}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: '#fff' }}
                          >
                            <Box
                              component="div"
                              sx={{
                                wordWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                              }}
                            >
                              {trackingNumber}
                            </Box>
                          </a>
                        ))
                      : params.row.trackingNumbers?.map((trackingNumber, index) => (
                          <Box
                            key={index}
                            component="div"
                            sx={{
                              wordWrap: 'break-word',
                              whiteSpace: 'pre-wrap',
                              '&:hover': {
                                cursor: 'copy',
                              },
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(trackingNumber);
                            }}
                          >
                            {trackingNumber}
                          </Box>
                        )))
                  }
                >
                  <IconButton edge="end">
                    <>
                      <Iconify sx={{ ml: 0.5 }} color="secondary" icon="mdi:local-shipping" width={20} height={20} />
                    </>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            {openTracking &&
              params.row.trackingNumbers?.length > 0 &&
              (params.row.trackingUrls
                ? //open a new tab with the tracking url
                  params.row.trackingNumbers?.map((trackingNumber, index) => (
                    <a
                      key={index}
                      href={params.row.trackingUrls + trackingNumber}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#fff' }}
                    >
                      <Box
                        component="div"
                        sx={{
                          wordWrap: 'break-word',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {trackingNumber}
                      </Box>
                    </a>
                  ))
                : params.row.trackingNumbers?.map((trackingNumber, index) => (
                    <Box
                      key={index}
                      component="div"
                      sx={{
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        '&:hover': {
                          cursor: 'copy',
                        },
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(trackingNumber);
                      }}
                    >
                      {trackingNumber}
                    </Box>
                  )))}
          </Grid>
        </Grid>
      </Grid>
    ),
    valueGetter: (params) => (params.row.statusCode || params.row?.statusUpdates?.length > 0 ? '0' : '1'),
  };
  const selectedRows = useMemo(() => data?.filter((row) => selected.includes(row.id)), [data, selected]);
  const CustomLeftToolbar = useCallback(
    () => (
      <>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={1}>
          {!invoiceView && !newInvoiceView && (
            <Grid item>
              <ReRunDropdown />
            </Grid>
          )}
          {!invoiceView && !newInvoiceView && (
            <Grid item>
              <OrdersSent />
            </Grid>
          )}
          {!invoiceView && !newInvoiceView && (
            <Grid item>
              <Box sx={{ display: 'inline-block', mr: 1 }}>
                <DownloadOrderDropdown />
              </Box>
            </Grid>
          )}
        </Grid>
      </>
    ),
    [invoiceView, newInvoiceView]
  );

  return !newInvoiceView ? (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid item xs={openSidebar ? 9.3 : 11.2}>
          <Box sx={{ mt: 1, width: '100%', height: 'calc(100vh - 180px)' }}>
            <>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                {!invoiceView && (
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Grid item>
                        <Button
                          variant="text"
                          onClick={() => {
                            navigate(
                              `/dashboard/orders?filter=previous&start=${new Date(
                                new Date().setDate(new Date().getDate() - 29)
                              ).toISOString()}&end=${new Date(
                                new Date().getTime() + 24 * 60 * 60 * 1000
                              ).toISOString()}`
                            );
                          }}
                          startIcon={<Iconify icon="mingcute:left-fill" width={24} height={24} sx={{ mx: -1 }} />}
                          sx={{
                            color: '#454F5B',
                            fontWeight: 'bold',
                          }}
                        >
                          Back to completed orders
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }} color="#208ee0">
                    Order #{orderNumber}
                  </Typography>
                </Grid>
                <Grid item />
              </Grid>
            </>
            <CustomDataGrid
              gridId="orders-completed-order-view"
              data={data}
              gridColumns={[...ORDER_COLUMNS, action, status]}
              CustomLeftToolbar={CustomLeftToolbar}
              maxValue={{ perItem: 1000, total: 1000, price: 1000 }}
              isLoading={isLoading}
              isModal
              checkboxSelection={!!addItemsToTicket}
              rowSelectionModel={selected || addItemsToTicket}
              onRowSelectionModelChange={(newSelection) => {
                setSelected(newSelection);
              }}
              dontGetRowHeight
              pinnedColumns={pinnedColumns}
              onPinnedColumnsChange={(params) => {
                setPinnedColumns(params.pinnedColumns);
              }}
              forceReRender={items}
            />
          </Box>
        </Grid>

        <Grid item xs={openSidebar ? 2.7 : 0.8} style={{ alignSelf: 'stretch', position: 'relative' }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CollapseSidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}>
              {!newInvoiceView && (
                <>
                  {openSidebar ? (
                    <Scrollbar sx={{ height: 'calc(100vh - 200px)' }}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ pb: '50px' }}
                      >
                        <CompletedOrderInfo overrideOrderId={overrideOrderId} />
                        <ScopeGuard scopes={['View-Order']} allowAdmin>
                          {departments?.map((department, index) => (
                            <Grid item key={index} sx={{ mt: 2 }}>
                              <BudgetBox
                                changeBudget
                                budget={budgets?.find((budget) => budget.category === department.name)}
                                cart={[...(items || [])].filter(
                                  (item) =>
                                    item.quantity > 0 &&
                                    item.approved === true &&
                                    item.deleted !== true &&
                                    item.agoraCategory === department.name
                                )}
                                catId={department?.id || null}
                                facId={facilityId || null}
                              />
                            </Grid>
                          ))}
                        </ScopeGuard>
                      </Grid>
                    </Scrollbar>
                  ) : (
                    <>
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        sx={{ height: 'calc(100vh - 180px)' }}
                      >
                        <Grid item>
                          {linkedTickets?.length > 0 &&
                            linkedTickets?.map((ticket) => (
                              <Button
                                key={ticket}
                                onClick={() => {
                                  setCurrentTicket(ticket);
                                  setOpenSidebar(true);
                                  setOpenTicket('open');
                                }}
                                size="small"
                                sx={{ mb: 1 }}
                                startIcon={
                                  <Iconify icon="icomoon-free:bubble" width={20} height={20} sx={{ color: '#fff' }} />
                                }
                                variant="contained"
                                color="secondary"
                              >
                                {ticket}
                              </Button>
                            ))}
                          <Button
                            onClick={() => {
                              setCurrentTicket(0);
                              setOpenSidebar(true);
                              setOpenTicket('open');
                            }}
                            size="small"
                            sx={{ mb: 1 }}
                            startIcon={<Iconify icon="fa6-solid:plus" width={20} height={20} sx={{ color: '#fff' }} />}
                            variant="contained"
                          >
                            Ticket
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {openSidebar && (
                    <CompletedOrderTicket
                      currentTicket={currentTicket}
                      setCurrentTicket={setCurrentTicket}
                      openTicket={openTicket}
                      setOpenTicket={setOpenTicket}
                      addItemsToTicket={addItemsToTicket}
                      setAddItemsToTicket={setAddItemsToTicket}
                      selectedRows={selectedRows}
                      setSelected={setSelected}
                    />
                  )}
                </>
              )}
            </CollapseSidebar>
          </Grid>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      {/* //new invoice view */}
      <CustomDataGrid
        gridId="orders-completed-order-view"
        boxSX={{ width: '100%', height: '350px' }}
        dontGetRowHeight
        data={data}
        gridColumns={[...ORDER_COLUMNS, action, status]}
        CustomLeftToolbar={CustomLeftToolbar}
        maxValue={{ perItem: 1000, total: 1000, price: 1000 }}
        isLoading={isLoading}
        isModal
        forceReRender={items}
        checkboxSelection={!!addItemsToTicket}
        rowSelectionModel={selected || addItemsToTicket}
        onRowSelectionModelChange={(newSelection) => {
          setSelected(newSelection);
        }}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={(params) => {
          setPinnedColumns(params.pinnedColumns);
          }}
      />
    </>
  );
}
