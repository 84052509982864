import { Box } from '@mui/material';
import Iconify from '../iconify';
import { fMonthDayYear, fMonthDayTimeYear } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import VendorIdToName from './VendorIdToName';

const name = {
  field: 'name',
  headerName: 'Name',
  minWidth: 100,
  flex: 1,
  type: 'customText',
};

const complete = {
  field: 'complete',
  headerName: 'Complete',
  minWidth: 50,
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};
const globalVendor = {
  field: 'globalVendor',
  headerName: 'Global account',
  minWidth: 100,
  flex: 0.7,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};
const orderingDisabled = {
  field: 'orderingDisabled',
  headerName: 'Ordering Disabled',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};
const isPPD = {
  field: 'isPPD',
  headerName: 'PPD',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};

const paymentTerms = {
  field: 'paymentTerms',
  headerName: 'Payment Terms',
  flex: 0.5,
  align: 'center',
  renderCell: (params) => {
    const { value } = params;
    return value;
  },
};

const facility = {
  field: 'facility',
  headerName: 'Name',
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customText',
};
const vendor = {
  field: 'vendor',
  headerName: 'Vendor',
  flex: 1,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};
const description = {
  field: 'description',
  headerName: 'Description',
  flex: 1,
  minWidth: 100,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        fontSize: '0.7rem',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'pre-wrap',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};
const primaryContact = {
  field: 'primaryContact',
  headerName: 'Primary Contact',
  flex: 1,
  minWidth: 200,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};
const value = {
  field: 'value',
  headerName: 'Pickup Value',
  minWidth: 100,
  flex: 1.5,
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // fontSize: '0.8rem',
      }}
    >
      {params.value}
    </Box>
  ),
  type: 'customSelect',
};
const account = {
  field: 'account',
  headerName: 'Account',
  flex: 1,
  type: 'customText',
};
const area = {
  field: 'area',
  headerName: 'Area',
  flex: 1,
  minWidth: 80,
  type: 'customText',
};

const emailTo = {
  field: 'emailTo',
  headerName: 'Po sent to',
  minWidth: 120,
  flex: 1,
  type: 'customText',
};
const address = {
  field: 'address',
  headerName: 'Address',
  minWidth: 80,
  flex: 1,
  type: 'customText',
};
const zip = {
  field: 'zip',
  headerName: 'Zip',
  minWidth: 70,
  flex: 1,
  type: 'customText',
};
const city = {
  field: 'city',
  headerName: 'City',
  minWidth: 80,
  flex: 1,
  type: 'customText',
};
const state = {
  field: 'state',
  headerName: 'State',
  minWidth: 40,
  flex: 1,
  type: 'customText',
};
const phone = {
  field: 'phone',
  headerName: 'Phone',
  minWidth: 60,
  flex: 1,
  type: 'customText',
};
const code = {
  field: 'code',
  headerName: 'Code',
  flex: 1,
  type: 'customText',
};
const agoraCode = {
  field: 'agoraCode',
  headerName: 'PS Code',
  flex: 1,
  type: 'customText',
};
const vendorIdToName = {
  field: 'vendorId',
  headerName: 'Name',
  renderCell: (params) => (
    <Box
      component="div"
      sx={{
        overFlow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <VendorIdToName raw vendorId={params.row.vendorId} />
    </Box>
  ),
  valueGetter: (params) => <VendorIdToName vendorId={params.row.vendorId} raw />,
  flex: 1,
  minWidth: 120,
  type: 'customSelect',
};

const purchasingVendorIdToName = {
  field: 'purchasingVendorId',
  headerName: 'Name',
  renderCell: (params) => <VendorIdToName vendorId={params.row.purchasingVendorId} />,
  valueGetter: (params) => <VendorIdToName vendorId={params.row.purchasingVendorId} raw />,
  flex: 1,
  type: 'customText',
};

const title = {
  field: 'title',
  headerName: 'Type',
  flex: 1,
  type: 'customSelect',
};
const weekly = {
  field: 'weekly',
  headerName: 'Weekly',
  width: 80,
  renderCell: (params) => {
    const { value } = params;
    return value ? <Iconify icon="material-symbols:check-circle-outline" color="primary" /> : <></>;
  },
};

const bookingVendor = {
  field: 'bookingVendor',
  headerName: 'Booking Vendor',
  flex: 1,
  type: 'customSelect',
};
const type = {
  field: 'type',
  headerName: 'Type',
  flex: 1,
  minWidth: 70,
  type: 'customSelect',
};
const rule = {
  field: 'rule',
  headerName: 'Rule',
  flex: 1,
  type: 'customText',
};
const ruleType = {
  field: 'ruleType',
  headerName: 'Type',
  flex: 1,
  type: 'customText',
};
const forFor = {
  field: 'for',
  headerName: 'For',
  flex: 0.7,
  minWidth: 70,
  type: 'customSelect',
};

const dateAdded = {
  field: 'dateAdded',
  headerName: 'Date Added',
  minWidth: 70,
  flex: 0.5,
  type: 'customDate',
  renderCell: (params) => fMonthDayYear(params.value),
  valueGetter: (params) => new Date(params.value),
};
const startDate = {
  field: 'startDate',
  headerName: 'Start Date',
  minWidth: 70,
  flex: 0.5,
  type: 'customDate',
  renderCell: (params) => fMonthDayTimeYear(params.value),
  valueGetter: (params) => new Date(params.value),
};
const endDate = {
  field: 'endDate',
  headerName: 'End Date',
  minWidth: 70,
  flex: 0.5,
  type: 'customDate',
  renderCell: (params) => fMonthDayTimeYear(params.value),
  valueGetter: (params) => new Date(params.value),
};
const addedBy = {
  field: 'addedBy',
  headerName: 'Added By',
  flex: 0.7,
};
const fileType = {
  field: 'fileType',
  headerName: 'Type',
  flex: 0.5,
};
const fileName = {
  field: 'fileName',
  headerName: 'File Name',
  flex: 0.7,
  minWidth: 90,
  renderCell: (params) => (
    <Box sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', wordWrap: 'break-word' }}>{params.value}</Box>
  ),
};
const minimumTotal = {
  field: 'minimumTotal',
  headerName: 'Minimum Total',

  flex: 0.5,
  type: 'number',
  renderCell: (params) => fCurrency(params.value),
};

const VENDORS = [name, emailTo, address, zip, city, state, phone, dateAdded];
const FACILITY = [facility, vendor, account, isPPD, paymentTerms];
const MINIMUM = [facility, vendor, minimumTotal];
const BOOKING = [vendor, bookingVendor, account];
const STATUS = [purchasingVendorIdToName, code, agoraCode];
const EMAIL = [vendorIdToName, value, type, forFor];
const IGNORED = [vendorIdToName, value, area];
const NOTES = [vendorIdToName, description, type, primaryContact, complete];
const EDI = [vendorIdToName, orderingDisabled];
const SHIPPING_LIST = [vendor, facility, title, startDate, endDate, weekly];
const CXML = [vendorIdToName, orderingDisabled];
const PUNCH_OUT = [vendorIdToName];
const RULES = [rule, ruleType, vendor];
const FILES = [fileName, fileType, addedBy, dateAdded];
const WAREHOUSE_VENDORS = [name, globalVendor];

export {
  VENDORS,
  FACILITY,
  BOOKING,
  MINIMUM,
  STATUS,
  EMAIL,
  NOTES,
  EDI,
  SHIPPING_LIST,
  RULES,
  FILES,
  PUNCH_OUT,
  CXML,
  IGNORED,
  WAREHOUSE_VENDORS,
};
